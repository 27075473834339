import React, { ChangeEvent, useMemo, useState } from "react"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from "./SpecialistsPage.module.scss"
import { Layout } from "../../common/Layout"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { MainTable } from "../../common/MainTable"
import { AllRoutes } from "../../constans/appRoutes"
import { message } from "antd"
import { useDebounce } from "../../hooks/useDebaunce"
import {
	useDeleteSpecialistMutation,
	useDeleteSpecialistReviewMutation,
	useGetSpecialistReviewsQuery,
	useGetSpecialistsQuery,
} from "../../services/specialist"
import SpecialistPageEdit from "./SpecialistPageEdit"
import SpecialistPageCreate from "./SpecialistPageCreate"
import SpecialistReviewEdit from "./SpecialistReviewEdit"
import SpecialistReviewCreate from "./SpecialistReviewCreate"
import { columns, columnsReviews, getTitle, getUrlsList } from "./helpers"
import { useSpecialistContext } from "./SpecialistContext"
import { AdditionalServicesModalProvider } from "./parts/AdditionalServicesModal/AdditionalServicesModalContext"

type Props = {}

const SpecialistsPage = (props: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentSpecialistId = params?.id

	const isEdit = !!params?.edit
	const isCreate = !!params?.create
	const isReviews = !!params?.reviews
	const isReviewEdit = !!params?.editReview
	const isReviewCreate = !!params?.createReview

	const [search, setSearch] = useState("")
	const debouncedSearch = useDebounce(search, 500)

	const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
		setSearch(target.value)

	const { isCustomize, loadingContextSpesialist } = useSpecialistContext()

	const { data, isLoading } = useGetSpecialistsQuery(debouncedSearch)
	const [deleteSpecialist] = useDeleteSpecialistMutation()
	const { data: specialistReviewsData, isLoading: isLoadingRew } =
		useGetSpecialistReviewsQuery(
			{
				specialistId: +currentSpecialistId,
			},
			{ skip: !currentSpecialistId }
		)

	const loading = useMemo(() => {
		return (
			isLoading ||
			isLoadingRew ||
			(loadingContextSpesialist && !!currentSpecialistId)
		)
	}, [isLoading, isLoadingRew, loadingContextSpesialist, currentSpecialistId])

	const [deleteSpecialistReview] = useDeleteSpecialistReviewMutation()

	const onHandleDelete = async (id: number) => {
		const result = await deleteSpecialist({ id })

		if (result && "data" in result) {
			message.success("Специалист успешно удален")
			navigate(AllRoutes.Specialists)
		}
	}

	const onHandleDeleteReview = (id: number) => {
		deleteSpecialistReview({ id })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Отзыв успешно удален")
				}
			})
			.catch(err => {
				message.error("Не удалось удалить отзыв")
			})
	}

	const goEdit = (id?: number) =>
		navigate(AllRoutes.Specialists + `?edit=true&id=${id}`)
	const goCreate = () => navigate(AllRoutes.Specialists + `?create=true`)
	const goEditReview = (id?: number) =>
		navigate(AllRoutes.Specialists + `?edit=true&id=1&editReview=${id}`)
	const goCreateReview = () =>
		navigate(
			AllRoutes.Specialists +
				`?edit=true&id=${currentSpecialistId}&createReview=true`
		)

	const title = useMemo(() => {
		return getTitle({
			isCreate,
			isEdit,
			isReviews,
			isReviewEdit,
			isReviewCreate,
			isCustomize,
		})
	}, [isCreate, isEdit, isReviews, isReviewEdit, isReviewCreate, isCustomize])

	const urlsList = useMemo(() => {
		return getUrlsList({
			isCreate,
			isCustomize,
			isEdit,
			isReviews,
			isReviewCreate,
			isReviewEdit,
			currentSpecialistId,
		})
	}, [
		isCreate,
		isCustomize,
		isEdit,
		isReviews,
		isReviewCreate,
		isReviewEdit,
		currentSpecialistId,
	])

	return (
		<Layout
			search={
				!isEdit && !isCreate
					? { value: search, onSearch: handleSearch }
					: undefined
			}
			createBtn={!!isReviews || (!isCreate && !isEdit)}
			noData={isLoading ? false : !data}
			onClickCreateBtn={!isReviews ? goCreate : goCreateReview}
			currentUrl={{
				path: `${useLocation().pathname}${useLocation().search}`,
				title,
			}}
			load={loading}
			prevLinks={urlsList}
		>
			{!isEdit && !isCreate && !isReviews && !isReviewEdit && (
				<MainTable
					columns={columns}
					data={data?.data ? data.data : ([] as any)}
					onDelete={onHandleDelete}
					onEdit={goEdit}
					isPerson
					isSpecialist
				/>
			)}
			{!isCreate &&
				isEdit &&
				!isReviews &&
				!isReviewEdit &&
				!isReviewCreate && (
					<AdditionalServicesModalProvider>
						<SpecialistPageEdit />
					</AdditionalServicesModalProvider>
				)}

			{!isEdit &&
				isCreate &&
				!isReviews &&
				!isReviewEdit &&
				!isReviewCreate && (
					<AdditionalServicesModalProvider>
						<SpecialistPageCreate />
					</AdditionalServicesModalProvider>
				)}
			{isEdit && isReviews && !isCreate && !isReviewEdit && !isReviewCreate && (
				<MainTable
					columns={columnsReviews}
					data={specialistReviewsData?.data ? specialistReviewsData.data : []}
					onDelete={onHandleDeleteReview}
					onEdit={goEditReview}
					isReviews
				/>
			)}
			{!isCreate && !isReviews && isReviewEdit && isEdit && !isReviewCreate && (
				<SpecialistReviewEdit />
			)}
			{!isCreate && !isReviews && !isReviewEdit && isEdit && isReviewCreate && (
				<SpecialistReviewCreate />
			)}
		</Layout>
	)
}

export default SpecialistsPage
