import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { ICity } from "../models/City"

type ResponseCitiesType = {
	data: ICity
}

type ResponseCitiesList = {
	data: ICity[]
}

type ResponseCreateCityType = {
	message: string
	data: ICity
	errors: {
		error: string
		message: string
	}
}

type RequestCityTypeParams = {
	city_id: number
}

type RequestCreateCityTypeParams = {
	name: string
	phone: string
	timezone: number
}

type RequestCityType = {
	offset?: number
	limit?: number
	search?: string
}

type RequestEditCityTypeParams = {
	name: string
	city_id: number
	phone: string
	timezone: number
}

export const cityItems = createApi({
	reducerPath: "adminCities",
	baseQuery,
	tagTypes: ["Cities"],
	endpoints: build => ({
		getCityItem: build.query<ResponseCitiesType, RequestCityTypeParams>({
			query: ({ city_id }: RequestCityTypeParams) => ({
				url: `/admin/cities${city_id ? `/${city_id}` : ""}`,
			}),
			providesTags: result =>
				result
					? [{ type: "Cities", id: "LIST" }]
					: [{ type: "Cities", id: "LIST" }],
		}),
		getCityItems: build.query<ResponseCitiesList, RequestCityType>({
			query: params => ({
				url: `/admin/cities`,
				params,
			}),
			providesTags: [{ type: "Cities", id: "LIST" }],
		}),
		deleteCityItem: build.mutation<
			ResponseCreateCityType,
			RequestCityTypeParams
		>({
			query: ({ city_id }: RequestCityTypeParams) => ({
				url: `/admin/cities${city_id ? `/${city_id}` : ""}`,
				method: "DELETE",
				city_id,
			}),
			invalidatesTags: [{ type: "Cities", id: "LIST" }],
		}),
		createCityItem: build.mutation<
			ResponseCreateCityType,
			RequestCreateCityTypeParams
		>({
			query: ({ name, phone, timezone }: RequestCreateCityTypeParams) => ({
				url: `/admin/cities`,
				method: "POST",
				body: { name, phone, timezone },
			}),
			invalidatesTags: [{ type: "Cities", id: "LIST" }],
		}),
		editCityItem: build.mutation<
			ResponseCreateCityType,
			RequestEditCityTypeParams
		>({
			query: ({
				name,
				city_id,
				phone,
				timezone,
			}: RequestEditCityTypeParams) => ({
				url: `/admin/cities${`/${city_id}`}`,
				method: "PUT",
				body: { name, phone, timezone },
			}),
			invalidatesTags: [{ type: "Cities", id: "LIST" }],
		}),
	}),
})

export const {
	useCreateCityItemMutation,
	useDeleteCityItemMutation,
	useGetCityItemQuery,
	useGetCityItemsQuery,
	useEditCityItemMutation,
} = cityItems
