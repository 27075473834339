import { FC } from "react";
import { Select } from "../../../common/Select";
import { ReactComponent as DeleteIcon } from "../../../constans/icons/delete.svg";
import { ReactComponent as PlusIcon } from "../../../constans/icons/add.svg";
import styles from "../Prepare.module.scss";
import { useGetServicesQuery } from "../../../services/services";
import { useGetComplexesQuery } from "../../../services/complexes";

type Props = {
  id: number;
  services: number[];
  comboServices: number[];
  onChange: (
    rullId: number,
    servId?: { index: number; id: number },
    comboId?: { index: number; id: number }
  ) => void;
  addField: (type: "serv" | "combo", rullId: number) => void;
  onDelete: (type: "serv" | "combo", entiyId: number, rullId: number) => void;
  deleteRulle: (id: number) => void;
};

const Rules: FC<Props> = ({
  id,
  services,
  comboServices,
  onChange,
  addField,
  onDelete,
  deleteRulle,
}) => {
  const { data: servicesData } = useGetServicesQuery({});
  const { data: comboServData } = useGetComplexesQuery("");

  const servicesArr = servicesData?.data || [];
  const comboServicesArr = comboServData?.data || [];

  const servicesOptions = servicesArr
    .filter((serv) => serv.id !== services.find((s) => s === serv.id))
    .map((serv) => ({
      id: serv.id,
      value: serv.name + serv.id,
      label: serv.name,
    }));

  const comboOptions = comboServicesArr
    .filter((serv) => serv.id !== comboServices.find((id) => id === serv.id))
    .map((serv: any) => ({
      id: serv.id,
      value: serv.name + serv.id,
      label: serv.name,
    }));

  const findServOfId = (id: number) => {
    return servicesArr.find((serv) => serv.id === id);
  };

  const findComboOfId = (id: number) => {
    return comboServicesArr.find((serv) => serv.id === id);
  };

  return (
    <section className={styles.rulesContainer}>
      <label className={styles.label}>Правила</label>
      <div className={styles.servicesContainer}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <span onClick={deleteRulle.bind(null, id)} className={styles.delete}>
            Удалить
          </span>
        </div>

        <div className={styles.servicesFlex}>
          <div className={styles.wrap}>
            {services.map((serv, i) => (
              <div key={i} className={styles.selectorWrapper}>
                <Select
                  showSearch
                  onChange={(_, options: any) =>
                    onChange(id, { index: i, id: options.id })
                  }
                  value={findServOfId(serv)?.name || ""}
                  options={servicesOptions}
                  stylePlaceholder={{ backgroundColor: "#fff" }}
                  placeholder="Услуга"
                />
                <DeleteIcon
                  onClick={onDelete.bind(null, "serv", serv, id)}
                  className={styles.deleteSvg}
                />
              </div>
            ))}

            <button
              onClick={addField.bind(null, "serv", id)}
              className={styles.serviceBtn}
            >
              <PlusIcon style={{ marginRight: 17 }} />
              <span>Добавить услугу</span>
            </button>
          </div>
          <div className={styles.wrap}>
            {comboServices.map((serv, i) => (
              <div key={i} className={styles.selectorWrapper}>
                <Select
                  showSearch
                  onChange={(_, options: any) =>
                    onChange(id, undefined, { index: i, id: options.id })
                  }
                  value={findComboOfId(serv)?.name || ""}
                  options={comboOptions}
                  stylePlaceholder={{ backgroundColor: "#fff" }}
                  placeholder="Комплекс"
                />
                <DeleteIcon
                  onClick={onDelete.bind(null, "combo", serv, id)}
                  className={styles.deleteSvg}
                />
              </div>
            ))}

            <button
              onClick={addField.bind(null, "combo", id)}
              className={styles.serviceBtn}
            >
              <PlusIcon style={{ marginRight: 17 }} />
              <span>Добавить комплекс</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rules;
