import { CSSProperties, FC, useCallback, useRef, useState, memo } from "react"
import { ReactComponent as DropArrow } from "../../constans/icons/dropArrow.svg"
import CheckBox from "../CheckBox/CheckBox"
import styles from "./Dropdown.module.scss"
import { DropDownOptions } from "./types"

type Props = {
	value: string
	height?: number
	styled?: {
		menu?: CSSProperties
	}
	options: DropDownOptions[]
	onSelect: (opt: DropDownOptions) => void
	closeAfterSelect?: boolean
}

const DropDown: FC<Props> = ({
	value,
	height = 64,
	styled,
	options,
	onSelect,
	closeAfterSelect,
}) => {
	const [val, setValue] = useState(value || "")
	const [isOpen, setIsOpen] = useState(false)
	const ref = useRef<HTMLDivElement>(null)

	const isCheked = (label: string) => label === val

	const handleSelect = (opt: DropDownOptions) => {
		setValue(opt.label)
		onSelect(opt)

		closeAfterSelect && close()
	}

	// Обработчик по клику по любой части документа
	// Если клик происходит внутри селектора, то ничего не делается
	const onHandleClickDocument = useCallback((event: MouseEvent) => {
		if (
			event &&
			event.target &&
			ref &&
			ref.current &&
			ref.current.contains(event.target as HTMLElement)
		) {
			return
		}

		close()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Функция открытия
	const open = useCallback(() => {
		setIsOpen(true)
		document.addEventListener("click", onHandleClickDocument)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setIsOpen])

	// Функция закрытия
	const close = useCallback(() => {
		setIsOpen(false)
		document.removeEventListener("click", onHandleClickDocument)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setIsOpen])

	const toggleOpen = () => {
		if (isOpen) {
			close()
		} else {
			open()
		}
	}

	return (
		<div onClick={toggleOpen} ref={ref} className={styles.dropDown}>
			<div className={styles.selectValue}>
				<span>{val}</span>
				<DropArrow />
			</div>

			<ul
				onClick={e => e.stopPropagation()}
				style={
					isOpen
						? { display: "block", top: height, ...styled?.menu }
						: { top: height, ...styled?.menu }
				}
				className={styles.menu}
			>
				{options.map(opt => (
					<li onClick={handleSelect.bind(null, opt)} key={opt.value}>
						<CheckBox checked={isCheked(opt.label)} />
						<span>{opt.label}</span>
					</li>
				))}
			</ul>
		</div>
	)
}

export default memo(DropDown)
