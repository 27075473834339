import { useCallback, useMemo } from "react"
import { BannerState } from "../CreateOrEditBanners.controller"
import { useGetSpecialistsQuery } from "../../../../../../services/specialist"

type Props = {
	state: BannerState[]
	setState: React.Dispatch<React.SetStateAction<BannerState[]>>
}

export const useSpecialistBanner = ({ state, setState }: Props) => {
	const { data: specData, isLoading: loadingSpec } = useGetSpecialistsQuery("")

	const specOptions = useMemo(() => {
		return (
			specData?.data.map(spec => ({
				value: spec.id,
				label: `${spec.user.surname} ${spec.user.name} ${spec.user.patronymic}`,
			})) || []
		)
	}, [specData])

	const findSpecOfId = useCallback(
		(id: number) => {
			return specData?.data.find(spec => spec.id === id)
		},
		[specData]
	)

	const handleSpecChange = useCallback(
		(value: number, index: number) => {
			const find = specOptions.find(spec => spec.value === value)?.value

			if (find) {
				const newState = state.map((item, i) => {
					if (i === index) {
						return {
							...item,
							spec: find,
						}
					}
					return item
				})
				setState(newState)
			}
		},
		[setState, state, specOptions]
	)
	return { specOptions, loadingSpec, findSpecOfId, handleSpecChange }
}
