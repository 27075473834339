import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { IMailings, TriggerSettings } from "../models/Mailings"

type RequestMailingsParams = {
	offset?: number
	limit?: number
	search?: string
}

type ResponseMailingsType = {
	data: IMailings[]
}

export type Deeplinks = {
	MAIN: "Главная"
	PRIVACY_POLICY: "Политика конфиденциальности"
	USER_AGREEMENT: "Пользовательское соглашение"
	USER_INFO: "Информация о пользователе"
	SERVICES: "Услуги"
	SERVICE_INFO: "Информация об услуге"
	MRT: "МРТ"
	PROFILE: "Личный профиль"
	NOTES: "Заметки"
	SUPPORT: "Тех поддержка"
	CLOSE_PEOPLE: "Близкие люди"
	ADD_PERSON: "Добавить персону"
	MRT_CONTRAST: "МРТ с контрастированием"
	SPECIALIST_INFO: "Информация о специалисте"
	SPECIALIST_SERVICES: "Услуги специалиста"
	ANALYZES: "Анализы"
	ONLINE_PAY: "Оплата"
	DETAIL_NOTES: "Детали записи"
	RESULT_DETAILS: "Результаты деталей"
	CHAT: "Чаты"
	INSIDE_CHAT: "Конкретный чат"
	NOTIFICATIONS: "Уведомления"
	COMPLEX_INFO: "Информация о комплексе"
	PROMOTION: "Информация о акции"
}

export type DeeplinksTypes = keyof Deeplinks

export interface IDeeplinks {
	message: string
	count: number
	data: Deeplinks
	errors: []
}

export type RequestCreateMailings = {
	subject: string
	message: string
	sending_time_type?: string
	trigger_settings: TriggerSettings
	sending_time?: string
	trigger_type_code?: string
}

export interface RequestUpdateMailings extends RequestCreateMailings {
	id: number
}

enum Endpoints {
	Get = "admin/mailings",
}

export const mailingApi = createApi({
	reducerPath: "mailingApi",
	baseQuery,
	tagTypes: ["getMailing", "deeplinks"],
	endpoints: build => ({
		getMailings: build.query<ResponseMailingsType, RequestMailingsParams>({
			query: params => ({
				url: Endpoints.Get,
				params,
			}),
			providesTags: ["getMailing"],
		}),
		getMailingsOfId: build.query<{ data: IMailings }, { id: number }>({
			query: ({ id }) => ({
				url: Endpoints.Get + "/" + id,
			}),
			providesTags: ["getMailing"],
		}),
		createMailings: build.mutation<{ data: IMailings }, RequestCreateMailings>({
			query: body => ({
				url: Endpoints.Get,
				body,
				method: "POST",
			}),
			invalidatesTags: () => ["getMailing"],
		}),
		updateMailings: build.mutation<{ data: IMailings }, RequestUpdateMailings>({
			query: body => ({
				url: Endpoints.Get + "/" + body.id,
				body,
				method: "PUT",
			}),
			invalidatesTags: () => ["getMailing"],
		}),
		deleteMailings: build.mutation<string, { id: number }>({
			query: ({ id }) => ({
				url: Endpoints.Get + "/" + id,
				method: "DELETE",
			}),
			invalidatesTags: () => ["getMailing"],
		}),
		getDeeplinks: build.query<IDeeplinks, any>({
			query: () => ({
				url: Endpoints.Get + "/deeplinks-list",
			}),
			providesTags: ["deeplinks"],
		}),
	}),
})

export const {
	useCreateMailingsMutation,
	useDeleteMailingsMutation,
	useGetMailingsOfIdQuery,
	useGetMailingsQuery,
	useUpdateMailingsMutation,
	useGetDeeplinksQuery,
} = mailingApi
