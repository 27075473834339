import { useCallback, useMemo } from "react"
import { useGetServicesQuery } from "../../../../../../services/services"
import { BannerState } from "../CreateOrEditBanners.controller"

type Props = {
	state: BannerState[]
	setState: React.Dispatch<React.SetStateAction<BannerState[]>>
}

export const useServicesBanner = ({ state, setState }: Props) => {
	const { data: servData, isLoading: loadingServ } = useGetServicesQuery({})

	const servOptions = useMemo(() => {
		return (
			servData?.data.map(serv => ({
				value: serv.id,
				label: serv.name,
			})) || []
		)
	}, [servData])

	const findServOfId = useCallback(
		(id: number) => {
			return servData?.data.find(serv => serv.id === id)
		},
		[servData]
	)

	const handleServChange = useCallback(
		(value: number, index: number) => {
			const find = servOptions.find(serv => serv.value === value)?.value

			if (find) {
				const newState = state.map((item, i) => {
					if (i === index) {
						return {
							...item,
							service: find,
						}
					}
					return item
				})
				setState(newState)
			}
		},
		[servOptions, setState, state]
	)

	return { loadingServ, handleServChange, findServOfId, servOptions }
}
