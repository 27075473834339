import { useCallback, useMemo } from "react"
import { BannerState } from "../CreateOrEditBanners.controller"
import { useGetComplexesQuery } from "../../../../../../services/complexes"

type Props = {
	state: BannerState[]
	setState: React.Dispatch<React.SetStateAction<BannerState[]>>
}

export const useComplexBanner = ({ state, setState }: Props) => {
	const { data: complexesData, isLoading: loadingComplex } =
		useGetComplexesQuery("")

	const complexOptions = useMemo(() => {
		return (
			complexesData?.data?.map(serv => ({
				value: serv.id,
				label: serv.name,
			})) || []
		)
	}, [complexesData])

	const findComplexOfId = useCallback(
		(id: number) => {
			return complexesData?.data?.find(complex => complex.id === id)
		},
		[complexesData]
	)

	const handleComplexChange = useCallback(
		(value: number, index: number) => {
			const find = complexOptions.find(
				complex => complex.value === value
			)?.value

			if (find) {
				const newState = state.map((item, i) => {
					if (i === index) {
						return {
							...item,
							complex: find,
						}
					}
					return item
				})
				setState(newState)
			}
		},
		[complexOptions, setState, state]
	)

	return {
		handleComplexChange,
		findComplexOfId,
		complexOptions,
		loadingComplex,
	}
}
