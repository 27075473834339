import { useSendFileMutation } from "../../../../../../services/file"
import { BannerState } from "../CreateOrEditBanners.controller"

type Props = {
	state: BannerState[]
	setState: React.Dispatch<React.SetStateAction<BannerState[]>>
}

export const useIconBanner = ({ setState, state }: Props) => {
	const [sendFile] = useSendFileMutation()

	const handleChangeIcon = async (file: File, index: number) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data

			const newState = state.map((item, i) => {
				if (i === index) {
					return {
						...item,
						fileId: id,
						photoUrl: url,
					}
				}
				return item
			})
			setState(newState)
		}
	}

	const handledeleteImage = (index: number) => {
		const newState = state.map((item, i) => {
			if (i === index) {
				return {
					...item,
					fileId: 0,
					photoUrl: "",
				}
			}
			return item
		})
		setState(newState)
	}

	return { handleChangeIcon, handledeleteImage }
}
