import React from "react";
import styles from "./CitiesPage.module.scss";
import { useForm } from "react-hook-form";
import { TextField } from "../../common/Form/TextField";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "../../constans/appRoutes";
import { timeZone } from "../../constans/formOptions";

type Props = {
  onSubmit: (name: string, phone: string, timezone: number) => void;
};

const CitiesPageCreate = ({ onSubmit }: Props) => {
  const navigate = useNavigate();
  const {
    register,
    formState: { isValid, errors },
    watch,
  } = useForm({ mode: "onBlur" });

  const name = watch("name");
  const phone = watch("phone");
  const timezone = watch("timezone");

  return (
    <div className={styles.createCityWrapper}>
      <TextField
        fieldname="name"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.name?.message as string}
        palcehoder="Название"
      />
      <TextField
        fieldname="phone"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.phone?.message as string}
        palcehoder="Телефон"
      />
      <TextField
        fieldname="timezone"
        type="number"
        min={-12}
        max={12}
        register={register}
        options={timeZone}
        error={errors.phone?.message as string}
        palcehoder="Выбор часового пояса"
      />
      <div className={styles.createCityBtnWrapper}>
        <Button
          title="Сохранить"
          disabled={!isValid}
          onClick={() => onSubmit(name, phone, +timezone)}
        />
        <Button
          title="Отмена"
          disabled={false}
          onClick={() => navigate(AllRoutes.Cities)}
          isTransparent
        />
      </div>
    </div>
  );
};

export default CitiesPageCreate;
