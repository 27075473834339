import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { IQuestion } from "../models/Questions"

type questionsResponse = {
	message: "string"
	count: 0
	data: IQuestion[]
	error: ["string"]
}

type editeQuestion = {
	id: number
	text: string
}

const endpoint = "/admin/cancel-options"

export const questionsAPI = createApi({
	reducerPath: "questionsAPI",
	baseQuery,
	tagTypes: ["questions"],
	endpoints: build => ({
		getQuestions: build.query<questionsResponse, any>({
			query: () => ({
				url: endpoint,
			}),
			providesTags: ["questions"],
		}),
		addQuestion: build.mutation<questionsResponse, any>({
			query: () => ({
				url: "/admin/cancel-option",
				method: "POST",
			}),
			invalidatesTags: ["questions"],
		}),
		deleteQuestion: build.mutation<questionsResponse, number>({
			query: id => ({
				url: `/admin/cancel-option/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["questions"],
		}),
		editeQuestion: build.mutation<questionsResponse, editeQuestion>({
			query: ({ id, text }) => ({
				url: `/admin/cancel-option/${id}`,
				method: "PUT",
				body: { text },
			}),
			invalidatesTags: ["questions"],
		}),
	}),
})

export const {
	useGetQuestionsQuery,
	useAddQuestionMutation,
	useDeleteQuestionMutation,
	useEditeQuestionMutation,
} = questionsAPI
