import React, {
	FC,
	useMemo,
	createContext,
	useContext,
	ReactNode,
	useState,
	useCallback,
} from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { ActionTypes, EntityType } from "../../types"
import {
	useDeletebannerMutation,
	useGetBannersQuery,
} from "../../../../services/mainBanner"
import { IMainBanners } from "../../../../models/MainEntities"

type IContext = {
	load: boolean
	name: string | null
	modal: boolean
	action: ActionTypes
	entity: EntityType
	bannersData?:
		| {
				data: IMainBanners[]
		  }
		| undefined
	handleDelete: () => void
	handleAction: (item: IMainBanners, action: ActionTypes) => void
	clearName: () => void
}

const MainContext = createContext<IContext>({} as IContext)

type MainProviderType = {
	children: ReactNode
}

export const MainProvider: FC<MainProviderType> = ({ children }) => {
	const nav = useNavigate()
	const { pathname } = useLocation()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const [name, setName] = useState<string | null>(null)
	const [editedId, setEditedId] = useState(0)
	const [deletedId, setDeletedId] = useState(0)
	const [modal, setModal] = useState(false)
	const { data: bannersData } = useGetBannersQuery({
		city_id: +params?.cityId,
	})

	const [deleteBanner, { isLoading: banerLoad }] = useDeletebannerMutation()

	const action = useMemo(() => {
		return params?.action as ActionTypes
	}, [params?.action])

	const entity = useMemo(() => {
		return params?.entity as EntityType
	}, [params?.entity])

	const cityId = useMemo(() => {
		return params?.cityId
	}, [params?.cityId])

	const load = useMemo(() => {
		return banerLoad
	}, [banerLoad])

	const handleAction = useCallback(
		(item: IMainBanners, action: ActionTypes) => {
			if (action === "delete") {
				setName(item.name)
				setModal(true)
				setDeletedId(item.id)
			} else if (action === "edit") {
				setEditedId(item.id)
				nav(pathname + `?cityId=${cityId}&action=${action}&entity=${item.id}`)
			} else {
				nav(pathname + `?cityId=${cityId}&action=${action}&entity=${entity}`)
			}
		},
		[cityId, nav, pathname, entity]
	)

	const clearName = useCallback(() => {
		setName(null)
		setModal(false)
	}, [])

	const handledeletebanners = useCallback(async () => {
		await deleteBanner({ id: deletedId })
	}, [deleteBanner, deletedId])

	const handleDelete = useCallback(() => {
		if (name) {
			handledeletebanners()
		}

		setName(null)
		setModal(false)
	}, [handledeletebanners, name])

	const value = useMemo(() => {
		return {
			load,
			name,
			modal,
			action,
			entity,
			bannersData,
			clearName,
			handleDelete,
			handleAction,
		}
	}, [
		load,
		name,
		modal,
		action,
		entity,
		bannersData,
		clearName,
		handleDelete,
		handleAction,
	])

	return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}

export const useMainContext = () => {
	return useContext(MainContext)
}
