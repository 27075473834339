import React, { useEffect } from "react"
import styles from "./CitiesPage.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../common/Form/TextField"
import { Button } from "../../common/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useGetCityItemQuery } from "../../services/cities"
import {
	useDeleteClinicItemMutation,
	useGetClinicItemsQuery,
} from "../../services/clinics"
import { MainTable } from "../../common/MainTable"
import { message } from "antd"
import { AllRoutes } from "../../constans/appRoutes"
import { timeZone } from "../../constans/formOptions"

type Props = {
	onNameChange: (
		name: string,
		city_id: number,
		phone: string,
		timezone: number
	) => void
}

const CitiesPageEdit = ({ onNameChange }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params.id

	const isClinicEdit = !!params?.clinicedit

	const {
		register,
		formState: { errors, isValid },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const name = watch("name")
	const phone = watch("phone")
	const timezone = watch("timezone")
	const { data: CityData } = useGetCityItemQuery({ city_id: +currentId })
	const { data: clinicsData } = useGetClinicItemsQuery({ cityId: +currentId })
	const [deleteClinic] = useDeleteClinicItemMutation()

	const goClinicEdit = (id?: number) =>
		id
			? navigate(
					AllRoutes.Cities +
						`?edit=true&id=${currentId}&clinicedit=true&clinicId=${id}`
			  )
			: navigate(
					AllRoutes.Cities + `?edit=true&id=${currentId}&clinicedit=true`
			  )

	const columns = [
		{
			title: "Индентификатор",
			width: 2,
		},
		{
			title: "Название",
			width: 2,
		},
		{
			title: "",
			width: 1,
		},
	]

	const onHandleDelete = async (clinic_id: number) => {
		await deleteClinic({ clinic_id })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Клиника успешно удалена")
				}
			})
			.catch(err => {
				message.error("Не удалось удалить клинику")
			})
	}

	useEffect(() => {
		if (CityData?.data) {
			setValue("name", CityData.data.name)
			setValue("phone", CityData.data.phone)
			setValue("timezone", CityData.data.timezone)
		}
	}, [CityData, setValue])

	return (
		<div className={styles.editCityWrapper}>
			{!isClinicEdit && (
				<>
					<div className={styles.createCityWrapper}>
						<TextField
							defaultValue={CityData?.data.name}
							fieldname='name'
							register={register}
							options={{ required: "Поле обязательно для заполнения" }}
							error={errors.name?.message as string}
							palcehoder='Название'
							isMarginBottom
						/>
						<TextField
							defaultValue={CityData?.data.phone}
							fieldname='phone'
							register={register}
							options={{ required: "Поле обязательно для заполнения" }}
							error={errors.phone?.message as string}
							palcehoder='Телефон'
						/>
						<TextField
							defaultValue={CityData?.data.timezone + ""}
							fieldname='timezone'
							type='number'
							min={-12}
							max={12}
							register={register}
							options={timeZone}
							error={errors.timezone?.message as string}
							palcehoder='Выбор часового пояса'
						/>

						<div className={styles.createCityBtnWrapper}>
							<Button
								title='Сохранить'
								disabled={!isValid}
								onClick={() =>
									onNameChange(
										name !== "" ? name : CityData?.data.name,
										Number(params.id),
										phone !== "" ? phone : CityData?.data.phone,
										+timezone
									)
								}
							/>
							<Button
								title='Отмена'
								disabled={false}
								onClick={() => navigate(AllRoutes.Cities)}
								isTransparent
							/>
						</div>
					</div>

					<div className={styles.tableEditWrapper}>
						<h3 className={styles.tableTitle}>Клиники</h3>
						<div className={styles.clinicsTable}>
							{clinicsData && clinicsData.data.length ? (
								<MainTable
									columns={columns}
									data={clinicsData?.data ? clinicsData?.data : []}
									onDelete={onHandleDelete}
									onEdit={goClinicEdit}
								/>
							) : (
								<span className={styles.noClinics}>
									Клиники в этом городе отсутствуют
								</span>
							)}
						</div>
						<Button
							title='ДОБАВИТЬ КЛИНИКУ'
							onClick={() => goClinicEdit()}
							plusSvg={true}
							disabled={false}
							classes={{ btn: styles.clinicAddBtn }}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default CitiesPageEdit
