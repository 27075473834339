import React, {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react"
import styles from "./ComplexesPage.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../common/Form/TextField"
import { Button } from "../../common/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { SelectProps, message } from "antd"
import { useGetServicesQuery } from "../../services/services"
import {
	useEditComplexMutation,
	useGetCompelxesFieldsQuery,
	useGetComplexQuery,
} from "../../services/complexes"
import { fileApi } from "../../services/file"
import { FileField } from "../../common/Form/FileField"
import { ReactComponent as NoFileIcon } from "../../constans/icons/noFileIcon.svg"
import { Select } from "../../common/Select"
import { IServices } from "../../models/Specialist"
import { useComplexServices } from "../../hooks/useService"
import { TreeSelect } from "../../common/TreeSelect"
import { prepareData, sortFields } from "./helpers"
import { treeSelectorData } from "./parts/ComplexesFieldCreate/ComplexesFieldCreate"
import { TextArea } from "../../common/TextArea"
import { findAdditionalServicesForComplex } from "../ServicesPage/helpers"

type Props = {}

export type Service = {
	id: number
	name: string
	full_name: string
	description: string
	duration: number
	notAddedToSchedule: number
	icon: null
	price: null
}

const ComplexesPageEdit: FC<Props> = () => {
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.complexId
	const navigate = useNavigate()
	const {
		register,
		formState: { isValid, errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const [sendFile] = fileApi.useSendFileMutation()
	const { data: servicesData } = useGetServicesQuery("")
	const { data: currentComplexData } = useGetComplexQuery({
		complex_id: +currentId,
	})
	const { data: complexesFields } = useGetCompelxesFieldsQuery("")
	const [editComplex, { isLoading: isLoadingEdit }] = useEditComplexMutation()

	const complexServices = useComplexServices()

	const generatedServicesSelectorData = useMemo(() => {
		const selectorData: SelectProps["options"] = []
		if (servicesData?.data) {
			servicesData.data.forEach(service => {
				const obj = {
					label: service.name,
					value: service.id,
				}
				selectorData.push(obj)
			})
		}
		return selectorData
	}, [servicesData?.data])

	const generatedComplexSelectorData = useMemo(() => {
		const selectorData: SelectProps["options"] = []
		complexServices.forEach(complex => {
			const obj = {
				label: complex.name,
				value: complex.id,
			}
			selectorData.push(obj)
		})
		return selectorData
	}, [complexServices])

	const name = watch("name") ? watch("name") : currentComplexData?.data.name
	const duration = watch("duration")
		? watch("duration")
		: currentComplexData?.data.duration
	const [fileId, setFileId] = useState<number>(0)
	const [photoUrl, setPhotoUrl] = useState("")

	const [selectedServicesId, setSelectedServicesId] = useState<number[]>([])
	const [combosShowables, setCombosShowables] = useState<number[]>([])
	const [
		selectedComplexsCombosShowablesId,
		setSelectedComplexsCombosShowablesId,
	] = useState<number[]>([])
	const [selectedComplexsId, setSelectedComplexsId] = useState<number[]>([])
	const [parentFieldValue, setParentFieldValue] = useState<string>()
	const [parentFieldId, setParentFieldId] = useState<number | undefined>()

	const [description, setDescription] = useState(
		() => currentComplexData?.data.description || ""
	)
	const [selectedAdditionalServices, setSelectedAdditionalServices] = useState<
		number[]
	>([])

	const onChangeSelect = useCallback((newValue: string) => {
		setParentFieldValue(newValue)
	}, [])

	const dataForPrepare = useMemo(() => {
		if (complexesFields) {
			const sortedData = [...complexesFields.data]
			return prepareData(sortFields(sortedData as any))
		}
	}, [complexesFields])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (complexesFields) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [complexesFields, dataForPrepare])

	const findFieldValue = (id: number | null, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.id === id) {
				setParentFieldValue(field.value)
			}
			if (field.id !== id && field.children.length) {
				findFieldValue(id, field.children)
			}
		})
	}

	const findFieldValueResult = useMemo(() => {
		if (currentComplexData?.data && generatedFieldData.length) {
			findFieldValue(currentComplexData.data.combo_field_id, generatedFieldData)
		}
	}, [currentComplexData?.data, generatedFieldData])

	const findFieldIdByValue = (value: string, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.value === value) {
				setParentFieldId(field.id)
			}
			if (field.value !== value && field.children.length) {
				findFieldIdByValue(value, field.children)
			}
		})
	}

	const findFieldIdResult = useMemo(() => {
		if (parentFieldValue && generatedFieldData.length) {
			findFieldIdByValue(parentFieldValue, generatedFieldData)
		}
	}, [parentFieldValue, generatedFieldData])

	useEffect(() => {
		if (currentComplexData?.data && servicesData?.data) {
			setSelectedServicesId(
				currentComplexData.data.services.map((service: Service) => service.id)
			)
			setCombosShowables(
				currentComplexData.data.show.services.map(
					(service: Service) => service.id
				)
			)
			setFileId(currentComplexData.data.icon?.id ?? 0)
			setPhotoUrl(currentComplexData.data.icon?.url)
			setDescription(currentComplexData.data.description)
			setSelectedAdditionalServices(
				findAdditionalServicesForComplex(
					servicesData.data,
					currentComplexData.data.additional_services.map(
						(serv: any) => serv.id
					)
				)
			)
		}
	}, [currentComplexData?.data, servicesData])

	const selectedServices: Service[] = useMemo((): Service[] => {
		const result: Service[] = []
		selectedServicesId.forEach(serviceId => {
			if (servicesData?.data) {
				const findService = servicesData.data.find(
					(service: IServices) => service.id === serviceId
				)

				if (findService) {
					const serviceObj: Service = {
						id: findService?.id,
						name: findService?.name,
						full_name: findService?.full_name,
						description: findService?.description,
						duration: findService?.duration,
						notAddedToSchedule: findService?.notAddedToSchedule,
						icon: findService?.icon as any,
						price: findService?.price as any,
					}
					result.push(serviceObj)
				}
			}
		})
		return result
	}, [selectedServicesId, servicesData?.data])

	const selectedServicesCombosShowables: Service[] = useMemo((): Service[] => {
		const result: Service[] = []
		combosShowables.forEach(serviceId => {
			if (servicesData?.data) {
				const findService = servicesData.data.find(
					(service: IServices) => service.id === serviceId
				)

				if (findService) {
					const serviceObj: Service = {
						id: findService?.id,
						name: findService?.name,
						full_name: findService?.full_name,
						description: findService?.description,
						duration: findService?.duration,
						notAddedToSchedule: findService?.notAddedToSchedule,
						icon: findService?.icon as any,
						price: findService?.price as any,
					}
					result.push(serviceObj)
				}
			}
		})
		return result
	}, [combosShowables, servicesData?.data])

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data
			setPhotoUrl(url)
			setFileId(id)
		}
	}

	const onSave = () => {
		const selectedServiceIds = [
			...selectedComplexsId.map(complexId => [
				Array.from(
					complexServices.find(it => it.id === complexId)?.services || []
				),
			]),
			...selectedServices.map(service => service.id),
		].join(",")

		const selectedCombosShowablesIds = [
			...selectedComplexsCombosShowablesId.map(complexId => [
				Array.from(
					complexServices.find(it => it.id === complexId)?.services || []
				),
			]),
			...selectedServicesCombosShowables.map(service => service.id),
		].join(",")

		const sendData = {
			id: +currentId,
			name,
			duration,
			services: selectedServiceIds,
			show: {
				services: selectedCombosShowablesIds,
			},
			icon_id: fileId !== 0 && fileId ? fileId : null,
			combo_field_id: parentFieldId,
			description,
			additional_services: selectedAdditionalServices,
		}

		editComplex(sendData)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Комплекс успешно изменен")
					navigate(AllRoutes.Complexes)
				}
			})
			.catch(err => {
				message.error("Не удалось изменить комплекс")
			})
	}

	const hadnleDeleteIcon = () => setFileId(0)

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	return (
		<div className={styles.createComplexWrapper}>
			<FileField
				Icon={NoFileIcon}
				onChange={handleChangeIcon}
				onDelete={hadnleDeleteIcon}
				src={photoUrl}
			/>
			<TreeSelect
				value={parentFieldValue}
				dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
				placeholder='Родительская папка'
				allowClear
				treeDefaultExpandAll
				onChange={onChangeSelect}
				treeData={generatedFieldData}
			/>
			<TextField
				fieldname='name'
				register={register}
				options={{ required: "Поле обязательно для заполнения" }}
				error={errors.name?.message as string}
				palcehoder='Название*'
				isMarginBottom={false}
				defaultValue={currentComplexData?.data.name}
			/>
			<TextField
				fieldname='duration'
				register={register}
				options={{ required: "Поле обязательно для заполнения" }}
				error={errors.duration?.message as string}
				palcehoder='Продолжительность (в минутах)*'
				type='number'
				isMarginBottom={false}
				defaultValue={currentComplexData?.data.duration.toString()}
			/>
			<Select
				mode='multiple'
				optionFilterProp='label'
				allowClear
				style={{ width: "100%" }}
				placeholder='Услуги'
				value={selectedServicesId}
				onChange={setSelectedServicesId}
				options={generatedServicesSelectorData}
			/>
			<Select
				mode='multiple'
				optionFilterProp='label'
				allowClear
				style={{ width: "100%" }}
				placeholder='Комплекс услуг'
				value={selectedComplexsId}
				onChange={setSelectedComplexsId}
				options={generatedComplexSelectorData}
			/>
			<Select
				mode='multiple'
				optionFilterProp='label'
				allowClear
				style={{ width: "100%" }}
				placeholder='Расположение в услуге'
				value={combosShowables}
				onChange={setCombosShowables}
				options={generatedServicesSelectorData}
			/>
			<Select
				mode='multiple'
				optionFilterProp='label'
				allowClear
				style={{ width: "100%" }}
				placeholder='Расположение в папке услуг'
				value={selectedComplexsCombosShowablesId}
				onChange={setSelectedComplexsCombosShowablesId}
				options={generatedComplexSelectorData}
			/>
			<Select
				mode='multiple'
				allowClear
				showSearch
				optionFilterProp='label'
				style={{ width: "100%" }}
				placeholder='Дополнительные услуги'
				value={selectedAdditionalServices}
				onChange={setSelectedAdditionalServices}
				options={servicesData?.data.map((service: IServices, i) => ({
					value: service.id,
					label: service.name,
				}))}
			/>
			<TextArea
				rows={1}
				draggable={false}
				placeholder='Описание*'
				required
				defaultValue={""}
				value={description}
				onChange={handleDescriptionOnChange}
			/>
			<Button
				title='Цены'
				disabled={false}
				classes={{ btn: styles.citiesBtn }}
				isTransparent
				onClick={() =>
					navigate(AllRoutes.ComplexesEdit + currentId + "&showPrices=true")
				}
			/>
			<div className={styles.createComplexBtnWrapper}>
				<Button
					title='Сохранить'
					disabled={false}
					onClick={onSave}
					loading={isLoadingEdit}
				/>
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Complexes)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default ComplexesPageEdit
