import classNames from "classnames";
import { CSSProperties, FC, ReactNode } from "react";
import styles from "./Modal.module.scss";

interface Props {
  visible: boolean;
  onClose: () => void;
  children?: ReactNode;
  title?: string;
  subTitle?: string;
  cancelBtn?: {
    text: string;
    onClick: () => void;
  };
  successBtn?: {
    text: string;
    onClick: () => void;
  };
  styled?: {
    container?: CSSProperties;
  };
}

const Modal: FC<Props> = ({
  visible,
  onClose,
  title,
  subTitle,
  successBtn,
  cancelBtn,
  children,
  styled,
}) => {
  return (
    <div
      onClick={onClose}
      className={classNames(styles.modal, { [styles.show]: visible })}
    >
      <div
        className={styles.content}
        style={
          children
            ? styled?.container
            : { backgroundColor: "#fff", borderRadius: 12 }
        }
        onClick={(e) => e.stopPropagation()}
      >
        {children && children}
        {!children && (
          <>
            <span className={styles.content__title}>{title}</span>
            <span>{subTitle}</span>
            <div className={styles.buttonsContainer}>
              {successBtn && (
                <span className={styles.success} onClick={successBtn.onClick}>
                  {successBtn.text}
                </span>
              )}

              {cancelBtn && (
                <span className={styles.cancel} onClick={cancelBtn.onClick}>
                  {cancelBtn.text}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
