import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { IMainServices } from "../models/MainEntities";

type RequestGetServices = {
  offset?: number;
  limit?: number;
  city_id: number;
};

type RequestCreateService = {
  order: number;
  catalog_item_id: number;
  city_id: number;
  photo_id: number;
  name: string;
};

interface ReqestUpdateBunner extends RequestCreateService {
  id: number;
}

const Endpoint = "admin/main-catalog-items";

export const mainServiceApi = createApi({
  reducerPath: "mainServiceApi",
  baseQuery,
  tagTypes: ["getServices"],
  endpoints: (build) => ({
    getServices: build.query<{ data: IMainServices[] }, RequestGetServices>({
      query: (params) => ({
        url: Endpoint,
        params,
      }),
      providesTags: ["getServices"],
    }),
    getServiceOfId: build.query<{ data: IMainServices }, { id: number }>({
      query: ({ id }) => ({
        url: Endpoint + `/${id}`,
      }),
      providesTags: ["getServices"],
    }),
    createService: build.mutation<
      { data: IMainServices },
      RequestCreateService
    >({
      query: (body) => ({
        url: Endpoint,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["getServices"],
    }),
    updateService: build.mutation<{ data: IMainServices }, ReqestUpdateBunner>({
      query: (body) => ({
        url: Endpoint + `/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: () => ["getServices"],
    }),
    deleteService: build.mutation<{ data: IMainServices }, { id: number }>({
      query: ({ id }) => ({
        url: Endpoint + `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => ["getServices"],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetServiceOfIdQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
} = mainServiceApi;
