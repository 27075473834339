import { ChangeEvent, useState } from "react";
import { useDebounce } from "../../hooks/useDebaunce";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AllRoutes } from "../../constans/appRoutes";
import { ActionType } from "./types";
import {
  useDeletePreparaionsMutation,
  useGetPreparaionsQuery,
} from "../../services/prepare";

export const usePrepareController = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const [search, setSearch] = useState("");
  const [deletedId, setDeletedId] = useState(0);
  const [editedId, setEditedId] = useState(0);
  const debauncedSearch = useDebounce(search, 200);

  const [deletePreparation] = useDeletePreparaionsMutation();

  const { data: preparationDAta } = useGetPreparaionsQuery({
    search: debauncedSearch,
  });

  const preparations = preparationDAta?.data || [];

  const action = params?.action as ActionType;

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);

  const handleDelete = (id: number) => setDeletedId(id);

  const fetchDeeltePreparation = async () => {
    await deletePreparation({ id: deletedId });
    setDeletedId(0);
  };

  const closeModal = () => setDeletedId(0);

  const goAction = (type: "edit" | "create", editId?: number) => {
    editId && setEditedId(editId);
    navigate(AllRoutes.Prepare + `?action=${type}`);
  };

  return {
    action,
    search,
    editedId,
    deletedId,
    preparations,
    goAction,
    closeModal,
    handleSearch,
    handleDelete,
    fetchDeeltePreparation,
  };
};
