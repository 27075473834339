import { FC } from "react"
import { Layout } from "../../common/Layout"
import { SearchField } from "../../common/Form/SearchField"
import { ReactComponent as EditIcon } from "../../constans/icons/redact.svg"
import { ReactComponent as CoppyIcon } from "../../constans/icons/copy.svg"
import { ReactComponent as DeleteIcon } from "../../constans/icons/delete.svg"
import { NotofycationHeader } from "../../constans/constans"
import styles from "./Notifications.module.scss"
import { useNotifycationController } from "./Notifycations.controller"
import NotifycationEdit from "./parts/NotifycationEdit"
import { Modal } from "../../common/Modal"
import { dateFormat } from "../../helpers/date"
import { useNotifycationEditController } from "./parts/NotifycationEdit.controller"

const Notifications: FC = () => {
	const {
		action,
		mailings,
		deletedItem,
		breadCrumbsTitle,
		endDelete,
		handleEdit,
		startDelete,
		handleDeelte,
	} = useNotifycationController()

	const { isLoading } = useNotifycationEditController()

	return (
		<Layout
			load={isLoading}
			isLink={true}
			createBtn={!action}
			currentUrl={
				action
					? { title: breadCrumbsTitle, path: `/notifications?action=${action}` }
					: undefined
			}
			prevLinks={
				action
					? [
							{
								title: "Рассылка уведомлений",
								path: "/notifications",
							},
					  ]
					: undefined
			}
			onClickCreateBtn={handleEdit.bind(null, "create", 0)}
		>
			{action && <NotifycationEdit action={action} />}
			{!action && (
				<div>
					<SearchField maxWidth='40%' value='' onChange={() => null} />

					<ul className={styles.header}>
						{NotofycationHeader.map(item => (
							<li key={item}>{item}</li>
						))}
					</ul>

					<ul className={styles.body}>
						{mailings.map(item => (
							<li key={item.id.toString()}>
								<span>{item.id}</span>
								<span style={{ textTransform: "capitalize" }}>
									{item.subject}
								</span>
								<span>
									{item.created_at && dateFormat(item.created_at, true)}
								</span>
								<span>
									<CoppyIcon
										onClick={handleEdit.bind(null, "copy", item.id)}
										style={{ marginRight: 30 }}
									/>
									<EditIcon
										style={{ marginRight: 30 }}
										onClick={handleEdit.bind(null, "edit", item.id)}
									/>
									<DeleteIcon onClick={startDelete.bind(null, item.id)} />
								</span>
							</li>
						))}
					</ul>
				</div>
			)}

			<Modal
				visible={deletedItem > 0}
				onClose={endDelete}
				title='Удалить'
				subTitle='Вы уверены, что хотите удалить рассылку “Заголовок”?'
				successBtn={{
					text: "Отмена",
					onClick: endDelete,
				}}
				cancelBtn={{
					text: "Удалить",
					onClick: handleDeelte,
				}}
			/>
		</Layout>
	)
}

export default Notifications
