import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "../ServicesPage/ServicesPage.module.scss";
import { Button } from "../../common/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetCityItemsQuery } from "../../services/cities";
import { ResponseComplexPricesType } from "../../services/complexes";
import { AllRoutes } from "../../constans/appRoutes";
import { SearchField } from "../../common/Form/SearchField";
import { ICity } from "../../models/City";

type Props = {
  servicePricesData: ResponseComplexPricesType;
};

const PriceComplexCitiesList = ({ servicePricesData }: Props) => {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const complexId = params?.complexId;
  const navigate = useNavigate();
  const currentUrl = `${useLocation().pathname}${useLocation().search}`;

  const [list, setList] = useState<ICity[]>([]);
  const [search, setSearch] = useState("");

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);

  const goEditPrice = (id: number) => {
    navigate(currentUrl + `&priceId=${id}`);
  };

  const goCreatePrice = (city_id: number) => {
    navigate(
      AllRoutes.ComplexesEdit +
        complexId +
        `&showPrices=true&createPrice=true&cityId=${city_id}`
    );
  };

  const { data: citiesData } = useGetCityItemsQuery({});

  const getPriceInCity = (
    cityId: number,
    servicePricesData: ResponseComplexPricesType
  ) => {
    return servicePricesData?.data.find(
      (price) => price.city.id && price.city.id === cityId
    )?.price;
  };

  const getPriceIdInCity = (
    cityId: number,
    servicePricesData: ResponseComplexPricesType
  ) => {
    return servicePricesData?.data.find(
      (price) => price.city.id && price.city.id === cityId
    )?.id;
  };

  useEffect(() => {
    if (citiesData?.data) {
      setList(citiesData?.data);
    }
  }, [citiesData?.data]);

  useEffect(() => {
    if (search !== "") {
      setList(
        list.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else if (search === "" && citiesData?.data) {
      setList(citiesData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, citiesData?.data]);

  return (
    <div className={styles.priceCities__list}>
      {<SearchField value={search} onChange={handleSearch} maxWidth={750} />}

      {servicePricesData?.data &&
        list.map((city: ICity) => {
          return (
            <Button
              key={`${city.name} ${city.id}`}
              title={
                <div className={styles.priceCities__text}>
                  <span>{city.name}</span>
                  <span>
                    {getPriceInCity(city.id, servicePricesData)
                      ? `${getPriceInCity(city.id, servicePricesData)} ₽`
                      : "Нет цены"}
                  </span>
                </div>
              }
              disabled={false}
              classes={{ btn: styles.citiesBtn }}
              isTransparent
              onClick={() =>
                getPriceIdInCity(city.id, servicePricesData)
                  ? goEditPrice(
                      getPriceIdInCity(city.id, servicePricesData) ?? 0
                    )
                  : goCreatePrice(city.id)
              }
            />
          );
        })}
    </div>
  );
};

export default PriceComplexCitiesList;
