import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { IMainBanners } from "../models/MainEntities"
import { EntityType } from "../pages/MainEditPage/types"

type RequestGetBanners = {
	offset?: number
	limit?: number
	city_id: number
}

export type AngleBanner =
	| "MAIN"
	| "RIGHT_ANGLE"
	| "TOP_LEFT_ANGLE"
	| "BOTTOM_LEFT_ANGLE"
	| "LEFT_ANGLE"
	| "TOP_RIGHT_ANGLE"
	| "BOTTOM_RIGHT_ANGLE"
	| "BOTTOM_SMALL"

type RequestCreateBanner = {
	name: string
	description: string
	order: number
	city_id: number
	type: KeyTypesBanners
	items: {
		entity: EntityType
		entity_id: number
		angle: AngleBanner

		photo_id: number
	}[]
}

interface ReqestUpdateBunner extends RequestCreateBanner {
	id: number
	name: string
	description: string
	order: number
	city_id: number
	type: KeyTypesBanners
	items: {
		entity: EntityType
		entity_id: number
		angle: AngleBanner

		photo_id: number
	}[]
}

export interface ITypesBanners {
	TYPE_1: ["MAIN"]
	TYPE_2: ["TOP_LEFT_ANGLE", "BOTTOM_LEFT_ANGLE", "RIGHT_ANGLE"]
	TYPE_3: ["LEFT_ANGLE", "RIGHT_ANGLE"]
	TYPE_4: ["LEFT_ANGLE", "TOP_RIGHT_ANGLE", "BOTTOM_RIGHT_ANGLE"]
	TYPE_5: [
		"TOP_LEFT_ANGLE",
		"TOP_RIGHT_ANGLE",
		"BOTTOM_LEFT_ANGLE",
		"BOTTOM_RIGHT_ANGLE"
	]
	TYPE_6: ["BOTTOM_SMALL"]
}

export type KeyTypesBanners = keyof ITypesBanners

const Endpoint = "admin/main-banners"

export const mainBannerApi = createApi({
	reducerPath: "mainBannerApi",
	baseQuery,
	tagTypes: ["getBanners", "typesBanners"],
	endpoints: build => ({
		// получение типов банеров
		getTypesBanners: build.query<{ data: ITypesBanners }, any>({
			query: params => ({
				url: "/admin/banners-types",
				params,
			}),
			providesTags: ["typesBanners"],
		}),
		getBanners: build.query<{ data: IMainBanners[] }, RequestGetBanners | void>(
			{
				query: params => ({
					url: Endpoint,
					params: params || {},
				}),
				providesTags: ["getBanners"],
			}
		),
		getBannerOfId: build.query<{ data: IMainBanners }, { id: number }>({
			query: ({ id }) => ({
				url: Endpoint + `/${id}`,
			}),
			providesTags: ["getBanners"],
		}),
		createBanner: build.mutation<{ data: IMainBanners }, RequestCreateBanner>({
			query: body => ({
				url: Endpoint,
				method: "POST",
				body,
			}),
			invalidatesTags: () => ["getBanners"],
		}),
		updateBanner: build.mutation<{ data: IMainBanners }, ReqestUpdateBunner>({
			query: body => ({
				url: Endpoint + `/${body.id}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: () => ["getBanners"],
		}),
		deletebanner: build.mutation<{ data: IMainBanners }, { id: number }>({
			query: ({ id }) => ({
				url: Endpoint + `/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: () => ["getBanners"],
		}),
	}),
})

export const {
	useGetBannersQuery,
	useGetBannerOfIdQuery,
	useCreateBannerMutation,
	useUpdateBannerMutation,
	useDeletebannerMutation,
	useGetTypesBannersQuery,
} = mainBannerApi
