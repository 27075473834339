import { FC, memo } from "react"
import styles from "./SelectedTreeServiceItem.module.scss"
import { IServices, ServiceType } from "../../../models/Specialist"
import { Checkbox } from "../../CheckBox"
import { ServicesSelected } from "../SelectedServicesFilds/useSelectedServicesFilds"

type Props = {
	service: IServices
	checked: boolean
	onSelectService: (serv: ServicesSelected) => void
	currentType?: ServiceType
}

const SelectedTreeServiceItem: FC<Props> = ({
	checked,
	service,
	currentType,
	onSelectService,
}) => {
	const handleSelectServ = () => {
		currentType &&
			onSelectService({
				service_id: service.id,
				service_field: service.service_field.id,
				type: currentType as ServiceType,
			})
	}

	return (
		<div className={styles.treeServices}>
			<div
				onClick={handleSelectServ}
				style={{ display: "flex", alignItems: "center" }}
			>
				<Checkbox checked={checked} />
				<div className={styles.servicenameBlock}>
					<span className={styles.servicename}>{service.name}</span>
				</div>
			</div>
		</div>
	)
}

export default memo(SelectedTreeServiceItem)
