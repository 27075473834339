import ForwardDirectoryTree from "antd/es/tree/DirectoryTree"
import { FC, memo } from "react"
import {
	ServicesSelected,
	useSelectedServicesFilds,
} from "./useSelectedServicesFilds"

export type PropsSelectedServicesFilds = {
	setServicesSelected: (servicesSelected: ServicesSelected[]) => void
	servicesSelected?: ServicesSelected[]
}

const SelectedServicesFilds: FC<PropsSelectedServicesFilds> = props => {
	const { generatedServicesData } = useSelectedServicesFilds(props)

	return (
		<ForwardDirectoryTree
			defaultExpandAll={false}
			treeData={generatedServicesData()}
			itemHeight={64}
			rootStyle={{
				background: "transparent",
				border: "0.5px solid #DADADA",
				height: "100%",
			}}
		/>
	)
}

export default memo(SelectedServicesFilds)
