import { FC, memo } from "react"
import { ReactComponent as DeleteIcon } from "../../../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../../../constans/icons/redact.svg"
import styles from "./Main.module.scss"
import { IMainBanners } from "../../../../models/MainEntities"

type Props = {
	banners?: IMainBanners[]
	onAction: (item: IMainBanners, action: "delete" | "create" | "edit") => void
}

const Banners: FC<Props> = ({ banners, onAction }) => {
	return (
		<section className={styles.root}>
			<h1 className={styles.title}>Баннеры</h1>
			<ul className={styles.list}>
				<li className={styles.item}>
					<span className={styles.headerTableText}>Индентификатор</span>
					<span className={styles.headerTableText}>Название</span>
				</li>
				{banners?.map((item, i) => (
					<li className={styles.item} key={i.toString()}>
						<div className={styles.leftContent}>
							<span>{item.id}</span>
						</div>

						<div className={styles.rightContent}>
							<span>{item.name || "item.name"}</span>
							<div className={styles.btns}>
								<EditIcon onClick={onAction.bind(null, item, "edit")} />
								<DeleteIcon
									className={styles.delete}
									onClick={onAction.bind(null, item, "delete")}
								/>
							</div>
						</div>
					</li>
				))}
			</ul>
		</section>
	)
}

export default memo(Banners)
