import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export type ClinicType = {
  address: string;
  id: number;
  name: string;
  phone_number: string;
};

type ResponseClinicsType = {
  data: ClinicType;
};

type RequestClinicsTypeParams = {
  cityId?: number;
};

type RequestClinicTypeParams = {
  clinic_id?: number;
};

type RequestDeleteClinicTypeParams = {
  clinic_id: number;
};

type RequestEditClinicTypeParams = {
  clinic_id: number;
  name: string;
  address: string;
  phone_number: string;
};

type RequestCreateClinicTypeParams = {
  city_id: number;
  external_id: string;
  name: string;
  address: string;
  phone_number: string;
};

type ResponseDeleteClinicType = {
  message: string;
  data: string[];
};

export const clinicItems = createApi({
  reducerPath: "adminClinics",
  baseQuery,
  tagTypes: ["Clinics"],
  endpoints: (build) => ({
    getClinicItems: build.query({
      query: ({ cityId }: RequestClinicsTypeParams) => ({
        url: `/admin/clinics${cityId ? `?cityId=${cityId}` : ""}`,
      }),
      providesTags: (result) =>
        result && result.length
          ? [...result.map(({ id }: { id: number }) => ({ type: "Clinics" as const, id })), { type: "Clinics", id: "LIST" }]
          : [{ type: "Clinics", id: "LIST" }],
    }),
    getClinicItem: build.query<any, RequestClinicTypeParams>({
      query: ({ clinic_id }: RequestClinicTypeParams) => ({
        url: `/admin/clinics/${clinic_id}`,
      }),
      providesTags: (result) =>
        result && result.length
          ? [...result.map(({ id }: { id: number }) => ({ type: "Clinics" as const, id })), { type: "Clinics", id: "LIST" }]
          : [{ type: "Clinics", id: "LIST" }],
    }),
    deleteClinicItem: build.mutation<ResponseDeleteClinicType, RequestDeleteClinicTypeParams>({
      query: ({ clinic_id }: RequestDeleteClinicTypeParams) => ({
        url: `/admin/clinics/${clinic_id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Clinics", id: "LIST" }],
    }),
    editClinicItem: build.mutation<any, RequestEditClinicTypeParams>({
      query: ({ clinic_id, address, name, phone_number }: RequestEditClinicTypeParams) => ({
        url: `/admin/clinics/${clinic_id}`,
        method: "PUT",
        body: { address, name, phone_number },
      }),
      invalidatesTags: [{ type: "Clinics", id: "LIST" }],
    }),
    createClinicItem: build.mutation<any, RequestCreateClinicTypeParams>({
      query: ({ city_id, external_id, name, address, phone_number }: RequestCreateClinicTypeParams) => ({
        url: `/admin/clinics`,
        method: "POST",
        body: { city_id, external_id, name, address, phone_number },
      }),
      invalidatesTags: [{ type: "Clinics", id: "LIST" }],
    }),
  }),
});

export const { useGetClinicItemsQuery, useDeleteClinicItemMutation, useGetClinicItemQuery, useEditClinicItemMutation, useCreateClinicItemMutation } =
  clinicItems;
