import { FC, ReactNode, memo } from "react"
import classNames from "classnames"
import styles from "./Button.module.scss"
import { ReactComponent as AddIcon } from "../../constans/icons/add.svg"

interface Props {
	title: string | ReactNode
	disabled: boolean
	onClick?: () => void
	plus?: boolean
	plusSvg?: boolean
	isGrey?: boolean
	loading?: boolean
	classes?: {
		btn: string
	}
	isHeader?: boolean
	isTransparent?: boolean
}

const Button: FC<Props> = ({
	title,
	plus,
	isGrey,
	loading,
	disabled,
	isHeader,
	onClick,
	classes,
	plusSvg,
	isTransparent = false,
}) => {
	return (
		<button
			onClick={!loading ? onClick : () => null}
			style={isHeader ? { borderRadius: 12 } : {}}
			className={classNames(
				[
					styles.btn,
					classes?.btn,
					{ [styles.plus]: plus, [styles.disabled]: disabled },
				],
				isTransparent && styles.transparent
			)}
		>
			{plus && <div className={styles.plusIcon} />}
			{plusSvg && (
				<AddIcon
					style={{ marginRight: 10 }}
					className={`${disabled ? styles.disabled : styles.active} ${
						isGrey && styles.activeGrey
					}`}
					width={16}
					height={16}
				/>
			)}
			{loading ? <div className={styles.loading} /> : title}
		</button>
	)
}

export default memo(Button)
