import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { Service } from "../pages/ComplexesPage/ComplexesPageCreate"
import { ICity } from "../models/City"
import { IComboService } from "../models/ComboServices"
import { IServices } from "../models/Specialist"

type ResponseSpecType = {
	data: ComplexType
	message: string
}

type RequestComplexTypeParams = {
	complex_id: number
}

type RequestCreateComplexTypeParams = {
	name: string
	duration: number
	services: string
	show: {
		services: string
	}
	icon_id: null | number
	description: string
	additional_services: number[]
	combo_field_id?: number
}

type ResponseDeleteComplexType = {
	message: string
	data: string[]
}

export type ComplexType = {
	id: number
	name: string
	duration: number
	services: Service[]
	icon: any
	description: string
	additional_services: IServices[]
	combo_field_id: number | null
	show: {
		services: Service[]
		services_fields: any[]
	}
}

export type EditComplexType = {
	id: number
	name: string
	duration: number
	services: string
	show: {
		services: string
	}
	icon_id: null | number
	combo_field_id?: number
	description: string
	additional_services: number[]
}

type ResponseEditComplexType = {
	message: string
	data: ComplexType
}

type ComplexEditPriceType = {
	id: number
	discount_percent: number
	old_price: number
	price: number
}

type ComplexCreatePriceType = {
	discount_percent: number
	old_price: number
	price: number
	service_combo_id: number
	city_id: number
}

export type ResponseComplexPricesType = {
	data: {
		id: number
		price: number
		old_price: number
		discount_percent: number
		service_combo: ComplexType
		city: ICity
		city_id: number
	}[]
}

export type ResponseComplexPriceType = {
	data: {
		id: number
		price: number
		old_price: number
		discount_percent: number
		service_combo: ComplexType
		city: ICity
	}
}

export interface IComplexField {
	id: string
	name: string
	description: string
	parent_id: number

	created_at: string
	updated_at: string
}

export interface ResponseComplexesFields {
	data: IComplexField[]
}

export interface ResponseComplexField {
	data: IComplexField
	message: string
}

export type CreateComplexField = {
	name: string
	description: string
	parent_id?: number | string
}

export type EditeComplexField = {
	id: number | string
	body: {
		name: string
		description: string
		parent_id?: number | string
	}
}

export interface ResponseComplexCreateField {
	id: string
	name: string
	description: string
	parent_id: number
	message: string
}

const endpointField = "/admin/combo-field"

export const complexItems = createApi({
	reducerPath: "adminSpecializations",
	baseQuery,
	tagTypes: ["Complexes", "complex", "complexes-fields", "complex-field"],
	endpoints: build => ({
		getComplex: build.query<ResponseSpecType, RequestComplexTypeParams>({
			query: ({ complex_id }: RequestComplexTypeParams) => ({
				url: `/admin/services-combos/${complex_id}`,
			}),
			providesTags: ["complex"],
		}),
		getComplexes: build.query<{ data: IComboService[] }, string>({
			query: (search?: string) => ({
				url: `/admin/services-combos${
					search !== "" ? `?search=${search}` : ""
				}`,
			}),
			providesTags: [{ type: "Complexes", id: "LIST" }],
		}),
		deleteComplex: build.mutation<
			ResponseDeleteComplexType,
			RequestComplexTypeParams
		>({
			query: ({ complex_id }: RequestComplexTypeParams) => ({
				url: `/admin/services-combos/${complex_id}`,
				method: "DELETE",
			}),
			invalidatesTags: [{ type: "Complexes", id: "LIST" }],
		}),
		createComplex: build.mutation<
			ResponseEditComplexType,
			RequestCreateComplexTypeParams
		>({
			query: body => ({
				url: `/admin/services-combos`,
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "Complexes", id: "LIST" }],
		}),
		editComplex: build.mutation<ResponseEditComplexType, EditComplexType>({
			query: body => ({
				url: `/admin/services-combos/${body.id}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: [{ type: "Complexes", id: "LIST" }, "complex"],
		}),
		getCompelxPrices: build.query<
			ResponseComplexPricesType,
			{ service_combo_id: number }
		>({
			query: ({ service_combo_id }: { service_combo_id: number }) => ({
				url: `/admin/services-combos-prices?service_combo_id=${service_combo_id}`,
			}),
			providesTags: result =>
				result && result.data.length
					? [
							...result.data.map(({ id }: { id: number }) => ({
								type: "Complexes" as const,
								id,
							})),
							{ type: "Complexes", id: "LIST" },
					  ]
					: [{ type: "Complexes", id: "LIST" }],
		}),
		getCompelxPrice: build.query<ResponseComplexPriceType, { id: number }>({
			query: ({ id }: { id: number }) => ({
				url: `/admin/services-combos-prices/${id}`,
			}),
			providesTags: result => [{ type: "Complexes", id: "LIST" }],
		}),
		editComplexPrice: build.mutation<
			ResponseEditComplexType,
			ComplexEditPriceType
		>({
			query: ({
				id,
				price,
				old_price,
				discount_percent,
			}: ComplexEditPriceType) => ({
				url: `/admin/services-combos-prices/${id}`,
				method: "PUT",
				body: { price, old_price, discount_percent },
			}),
			invalidatesTags: [{ type: "Complexes", id: "LIST" }],
		}),
		createComplexPrice: build.mutation<
			ResponseEditComplexType,
			ComplexCreatePriceType
		>({
			query: ({
				price,
				old_price,
				discount_percent,
				city_id,
				service_combo_id,
			}: ComplexCreatePriceType) => ({
				url: `/admin/services-combos-prices`,
				method: "POST",
				body: { price, old_price, discount_percent, city_id, service_combo_id },
			}),
			invalidatesTags: [{ type: "Complexes", id: "LIST" }],
		}),

		// получение папок для комлпексов
		getCompelxesFields: build.query<ResponseComplexesFields, any>({
			query: () => ({
				url: endpointField + "s",
			}),
			providesTags: ["complexes-fields"],
		}),
		// получение папки
		getCompelxField: build.query<ResponseComplexField, number | string>({
			query: id => ({
				url: endpointField + `/${id}`,
			}),
			providesTags: ["complex-field"],
		}),
		// создание папки для комплекса
		createComplexField: build.mutation<
			ResponseComplexCreateField,
			CreateComplexField
		>({
			query: body => ({
				url: endpointField,
				method: "POST",
				body,
			}),
			invalidatesTags: ["complexes-fields"],
		}),
		// редактирование папки для комплекса
		editeComplexField: build.mutation<ResponseComplexField, EditeComplexField>({
			query: ({ body, id }) => ({
				url: endpointField + `/${id}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: ["complexes-fields"],
		}),
		// удаление папки
		deleteCompelxField: build.mutation<ResponseComplexField, number | string>({
			query: id => ({
				url: endpointField + `/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["complexes-fields"],
		}),
	}),
})

export const {
	useGetComplexQuery,
	useGetComplexesQuery,
	useDeleteComplexMutation,
	useCreateComplexMutation,
	useEditComplexMutation,
	useGetCompelxPricesQuery,
	useGetCompelxPriceQuery,
	useEditComplexPriceMutation,
	useCreateComplexPriceMutation,
	useGetCompelxesFieldsQuery,
	useGetCompelxFieldQuery,
	useCreateComplexFieldMutation,
	useEditeComplexFieldMutation,
	useDeleteCompelxFieldMutation,
} = complexItems
