import React, { ChangeEvent, useMemo, useState } from "react"
import styles from "./ServicesPage.module.scss"
import "./ServicesPage.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../common/Form/TextField"
import { Button } from "../../common/Button"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { message } from "antd"
import {
	RequestCreateFieldType,
	useCreateServiceFieldMutation,
} from "../../services/services"
import { prepareData } from "./ServicesPageEditField"
import { sortFields } from "./ServicesPage"
import { TreeSelect } from "../../common/TreeSelect"
import { TextArea } from "../../common/TextArea"
import { ServicesFieldsType } from "../../models/CatalogItems"
import Contraindications from "./Contraindications"
import { ContraindicationsType } from "./types"

export type treeSelectorData = {
	title: string | React.ReactNode
	id: number
	parent_id?: number | null
	value: string
	children: treeSelectorData[]
}

type Props = {
	fieldsData: ServicesFieldsType[]
}

const ServicesPageCreateField = ({ fieldsData }: Props) => {
	const navigate = useNavigate()
	const {
		register,
		formState: { errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const dataForPrepare = useMemo(() => {
		if (fieldsData) {
			const sortedData = [...fieldsData]
			return prepareData(sortFields(sortedData))
		}
	}, [fieldsData])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (fieldsData) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [fieldsData, dataForPrepare])

	const [createServiceField] = useCreateServiceFieldMutation()

	const [parentFieldValue, setParentFieldValue] = useState<string>()
	const [parentFieldId, setParentFieldId] = useState<number>(0)
	const [description, setDescription] = useState("")
	const [preparationDesc, setPreparationDesc] = useState("")
	const [contrindications, setContrindications] = useState<
		ContraindicationsType[]
	>([])

	const onChangeSelect = (newValue: string) => {
		setParentFieldValue(newValue)
	}

	const name = watch("name")

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	const handlePreparationDescOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setPreparationDesc(event.target.value)
	}

	const handleCreateField = async () => {
		const sendData: RequestCreateFieldType = {
			name,
			description,
			preparation_desc: preparationDesc,
			external_id: name,
			contraindications: contrindications.map(
				({ contraindication, note, is_required }) => ({
					description: contraindication,
					note,
					is_required,
				})
			),
		}
		if (parentFieldId) {
			sendData.parent_id = parentFieldId.toString()
		}

		await createServiceField(sendData)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Папка успешно создана")
					navigate(AllRoutes.Services)
				}
			})
			.catch(err => {
				message.error("Не удалось создать папку")
			})
	}

	const findFieldId = (key: string, fieldData: treeSelectorData[]) => {
		fieldData.forEach(field => {
			if (field.value === key) {
				setParentFieldId(field.id)
			}
			if (field.value !== key && field.children.length) {
				findFieldId(key, field.children)
			}
		})
	}

	const findFieldIdResult = useMemo(() => {
		if (parentFieldValue && generatedFieldData.length) {
			findFieldId(parentFieldValue, generatedFieldData)
		}
	}, [parentFieldValue, generatedFieldData])

	return (
		<div style={{ padding: "30px 0" }}>
			<div className={styles.halfContaienr}>
				<TreeSelect
					value={parentFieldValue}
					dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
					placeholder='Родительская папка'
					allowClear
					treeDefaultExpandAll
					onChange={onChangeSelect}
					treeData={generatedFieldData}
				/>
				<TextField
					fieldname='name'
					register={register}
					options={{ required: "" }}
					error={errors.name?.message as string}
					palcehoder='Название'
					isMarginBottom={false}
				/>
				<TextArea
					rows={9}
					draggable={false}
					placeholder='Описание*'
					required
					defaultValue={""}
					value={description}
					onChange={handleDescriptionOnChange}
				/>
				{/* <TextArea
					rows={9}
					draggable={false}
					placeholder='Подготовка*'
					required
					defaultValue={""}
					value={preparationDesc}
					onChange={handlePreparationDescOnChange}
				/> */}

				<Contraindications
					contrindications={contrindications}
					setContrindications={setContrindications}
				/>
			</div>
			<div className={styles.createServiceBtnWrapper}>
				<Button
					title='Сохранить'
					disabled={!name}
					onClick={handleCreateField}
				/>
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Services)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default ServicesPageCreateField
