import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "../../constans/appRoutes";
import { loadFingerPrintId } from "../../helpers/loadFingrPrintid";
import { useAppDispatch } from "../../hooks/redux";
import { login } from "../../services/login";
import { signIn } from "../../store/Slices/authSlice";

export const useLoginPageController = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [fetchLogin, { isLoading }] = login.useLoginMutation({});
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data: any) => {
    if (!isValid) return;

    const fingerprint = await loadFingerPrintId();

    const response = await fetchLogin({
      email: data.email,
      password: data.password,
      fingerprint,
    });

    if ("data" in response && response.data.message === "Success") {
      const { access_token, user } = response.data.data;

      if (access_token) {
        dispatch(signIn(access_token, user));
        reset();
      }

      if ("errors" in response.data) {
        const { errors } = response.data as any;
        !!errors &&
          errors[0] === "Wrong login or password" &&
          setError("password", { message: "Не верный Email или Пароль" });
      }
    }
  };

  return {
    isValid,
    errors,
    isLoading,
    goRecovery: () => navigate(AllRoutes.Recovery),
    register,
    onSubmit,
    handleSubmit,
  };
};
