import { createApi } from "@reduxjs/toolkit/query/react";
import { IFile } from "../models/Files";
import { baseQuery } from "./baseQuery";

type ResponseSendFileType = {
  data: IFile;
};

const endpoint = "files";

export const fileApi = createApi({
  reducerPath: "fileApi",
  baseQuery,
  endpoints: (build) => ({
    sendFile: build.mutation<ResponseSendFileType, FormData>({
      query: (data: FormData) => ({
        url: endpoint,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useSendFileMutation } = fileApi;
