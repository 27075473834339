import { TextField } from "../../common/Form/TextField";
import { useForm } from "react-hook-form";
import { useCreateServicePriceMutation } from "../../services/services";
import { Button } from "../../common/Button";
import styles from "./ServicesPage.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";
import { AllRoutes } from "../../constans/appRoutes";
import { useEffect } from "react";

const PriceCitiesCreate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const currentId = params?.serviceId;
  const cityId = params?.cityId;
  const fromCreate = params?.fromCreate;

  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({ mode: "onBlur" });

  const [createServicePrice] = useCreateServicePriceMutation();

  const price = watch("price");
  const oldPrice = watch("oldPrice");
  const discount = watch("discount");

  const onHandleCreate = () => {
    createServicePrice({
      price: +price,
      old_price: oldPrice > 0 ? +discount : 0,
      discount_percent: discount > 0 ? +discount : 0,
      service_id: +currentId,
      city_id: +cityId,
    })
      .unwrap()
      .then((result) => {
        if (result.message === "Success") {
          message.success("Цена успешно создана");

          if (fromCreate) {
            navigate(AllRoutes.Services);
          } else {
            navigate(
              AllRoutes.Services +
                `?serviceEdit=true&serviceId=${currentId}&showPrices=true`
            );
          }
        }
      })
      .catch((err) => {
        message.error("Не удалось создать цену");
      });
  };

  useEffect(() => {
    if (price && oldPrice) {
      setValue(
        "discount",
        Math.round(((+oldPrice - +price) * 100) / +oldPrice)
      );
    }
  }, [price, oldPrice, setValue]);

  return (
    <div>
      <div style={{ maxWidth: 555 }}>
        <TextField
          fieldname="price"
          register={register}
          options={{ required: "" }}
          error={errors.price?.message as string}
          palcehoder="Цена"
          isTopDescr
          isMarginBottom={true}
        />
        <TextField
          fieldname="oldPrice"
          register={register}
          options={{ required: "" }}
          error={errors.oldPrice?.message as string}
          palcehoder="Старая цена"
          isTopDescr
          isMarginBottom={true}
        />
        <TextField
          fieldname="discount"
          register={register}
          options={{}}
          error={errors.discount?.message as string}
          palcehoder="Скидка в процентах"
          isTopDescr
          isMarginBottom={true}
        />
        <div className={styles.createServiceBtnWrapper}>
          <Button
            title="Сохранить"
            disabled={false}
            onClick={() => onHandleCreate()}
          />
          <Button
            title="Отмена"
            disabled={false}
            onClick={() =>
              navigate(AllRoutes.ServiceEdit + `${currentId}&showPrices=true`)
            }
            isTransparent
          />
        </div>
      </div>
    </div>
  );
};

export default PriceCitiesCreate;
