import { TextField } from "../../common/Form/TextField";
import { useForm } from "react-hook-form";
import {
  ResponseServicePriceType,
  useEditServicePriceMutation,
} from "../../services/services";
import { Button } from "../../common/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";
import { AllRoutes } from "../../constans/appRoutes";
import styles from "./ServicesPage.module.scss";
import { useEffect, useMemo } from "react";

type Props = {
  priceData?: ResponseServicePriceType;
};

const PriceCitiesEdit = ({ priceData }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const currentId = params?.serviceId;
  const cityId = params?.cityId;

  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({ mode: "onBlur" });

  const [editServicePrice] = useEditServicePriceMutation();

  const price = watch("price");
  const oldPrice = watch("oldPrice");
  const discount = watch("discount");

  const priceNumber = useMemo(() => {
    return price ? price : priceData?.data?.price.toString();
  }, [price, priceData]);

  const oldPriceNumber = useMemo(() => {
    return oldPrice ? oldPrice : priceData?.data?.old_price.toString();
  }, [oldPrice, priceData]);

  useEffect(() => {
    if (oldPriceNumber && priceNumber) {
      setValue(
        "discount",
        Math.round(((+oldPriceNumber - +priceNumber) * 100) / +oldPriceNumber)
      );
    }
  }, [priceNumber, oldPriceNumber, setValue]);

  useEffect(() => {
    if (!!priceData?.data?.discount_percent) {
      setValue("discount", priceData?.data?.discount_percent);
    }
  }, [priceData, setValue]);

  const onHandleEdit = () => {
    if (priceData?.data) {
      const data = {
        id: priceData.data.id,
        price: price !== "" ? +price : priceData.data.price,
        old_price: oldPrice !== "" ? +oldPrice : priceData?.data?.old_price,
        discount_percent: discount > 0 ? +discount : 0,
        service_id: +currentId,
        city_id: +cityId,
      };

      editServicePrice(data)
        .unwrap()
        .then((result) => {
          if (result.message === "Success") {
            message.success("Цена успешно редактирована");
            navigate(
              AllRoutes.Services +
                `?serviceEdit=true&serviceId=${currentId}&showPrices=true`
            );
          }
        })
        .catch((err) => {
          message.error("Не удалось редактировать цену");
        });
    }
  };

  return (
    <div>
      <div style={{ maxWidth: 555 }}>
        <TextField
          fieldname="price"
          type="number"
          register={register}
          options={{ required: "" }}
          error={errors.price?.message as string}
          palcehoder="Цена"
          isTopDescr
          isMarginBottom={true}
          defaultValue={priceData?.data?.price.toString() ?? ""}
        />
        <TextField
          fieldname="oldPrice"
          type="number"
          register={register}
          options={{ required: "" }}
          error={errors.oldPrice?.message as string}
          palcehoder="Старая цена"
          isTopDescr
          isMarginBottom={true}
          defaultValue={priceData?.data?.old_price.toString() ?? ""}
        />
        <TextField
          fieldname="discount"
          register={register}
          options={{}}
          type="number"
          error={errors.discount?.message as string}
          palcehoder="Скидка в процентах"
          isTopDescr
          isMarginBottom={true}
          defaultValue={priceData?.data?.discount_percent.toString() ?? ""}
        />
        <div className={styles.createServiceBtnWrapper}>
          <Button
            title="Сохранить"
            disabled={false}
            onClick={() => onHandleEdit()}
          />
          <Button
            title="Отмена"
            disabled={false}
            onClick={() =>
              navigate(AllRoutes.ServiceEdit + `${currentId}&showPrices=true`)
            }
            isTransparent
          />
        </div>
      </div>
    </div>
  );
};

export default PriceCitiesEdit;
