import { IDiscountField } from "../../services/discounts"

export const addForChildrenKey = (array: any, parentKey: any) => {
	const res = array.map((item: any, i: number) => {
		const key = `${parentKey}-${i}`
		const newItem = { ...item }
		if (item.children) {
			newItem.children = addForChildrenKey(item.children, key)
		}
		if (!item.children) {
			newItem.children = []
		}
		newItem.value = key
		newItem.title = item.name
		return newItem
	})

	return res
}

export const prepareData = (array: any[], addKey?: any) => {
	const mapItems = new Map(array.map(item => [item.id, item]))

	array.forEach(it => {
		const item = { ...it }
		if (item.id) {
			const parent = mapItems.get(item.id)
			mapItems.set(item.id, {
				...parent,
			})
		}
	})
	const tt = Object.values(Object.fromEntries(mapItems))
	return addKey ? addKey(tt, "0") : addForChildrenKey(tt, "0")
}

export function sortFields(arr: IDiscountField[]) {
	return arr.sort((a, b) => {
		if (b.id === null || b.id === undefined) return -1
		if (a.id === null || a.id === undefined) return 1
		return b.id - a.id
	})
}

export const getTimePeriodText = (rangePeriods: any, period: string) => {
	const p = rangePeriods[period]

	const startHours = `${new Date(p.start).getHours()}`
	const startMinutes = `${new Date(p.start).getMinutes()}`

	const start = `${startHours.toString().padStart(2, "0")}:${startMinutes
		.toString()
		.padStart(2, "0")}`

	const endHours = `${new Date(p.end).getHours()}`
	const endMinutes = `${new Date(p.end).getMinutes()}`

	const end = `${endHours.toString().padStart(2, "0")}:${endMinutes
		.toString()
		.padStart(2, "0")}`

	return ` (${start} - ${end})`
}
