import { FC, useCallback, useState } from "react"
import Field from "../Field/Field"
import { Button } from "../../../../common/Button"
import styles from "./FieldsList.module.scss"
import {
	useAddQuestionMutation,
	useDeleteQuestionMutation,
	useGetQuestionsQuery,
} from "../../../../services/questions"
import { Modal } from "../../../../common/Modal"
import { IQuestion } from "../../../../models/Questions"

const FieldsList: FC = props => {
	const { data: questions } = useGetQuestionsQuery("")
	const [addQuestion] = useAddQuestionMutation()
	const [deleteQuestion] = useDeleteQuestionMutation()
	const [item, setItem] = useState<null | IQuestion>(null)

	const onClickCreateBtn = useCallback(() => {
		addQuestion("")
	}, [addQuestion])

	const onClose = useCallback(() => {
		setItem(null)
	}, [])

	const deleteHandler = useCallback(() => {
		item && deleteQuestion(item?.id)
		onClose()
	}, [deleteQuestion, item, onClose])

	return (
		<>
			<ul>
				{questions?.data.map((item, i) => {
					return <Field setItem={setItem} key={item.id} item={item} index={i} />
				})}
			</ul>

			<Modal
				visible={!!item}
				onClose={onClose}
				title='Удалить'
				subTitle={`Вы уверены, что хотите удалить вопрос “${item?.text}”?`}
				successBtn={{
					text: "Отмена",
					onClick: onClose,
				}}
				cancelBtn={{
					text: "Удалить",
					onClick: deleteHandler,
				}}
			/>

			<div>
				<Button
					title={"Добавить вопрос"}
					onClick={onClickCreateBtn}
					disabled={false}
					plusSvg
					isHeader
					classes={{
						btn: styles.btnCreate,
					}}
				/>
			</div>
		</>
	)
}

export default FieldsList
