import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AllRoutes } from "../../constans/appRoutes";
import { useDebounce } from "../../hooks/useDebaunce";
import { CatalogItemType, ICatalogItem } from "../../models/CatalogItems";
import { admin } from "../../services/admin";
import { useGetCityItemsQuery } from "../../services/cities";
import {
  useDeleteCatalogFieldMutation,
  useGetItemsQuery,
} from "../../services/catalog";
import { treeData } from "../ServicesPage/ServicesPage";
import { DirectoryTreeProps } from "antd/es/tree";
import { ICity } from "../../models/City";

export type ItemsType = {
  id: number;
  mainCategory: string;
  category: string;
  type: string;
  service: string;
};

export const useCatalogPageController = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  const isEdit = !!params?.edit;
  const isCreate = params?.create;
  const isCatalogTree = params?.cityId;

  const [search, setSearch] = useState("");
  const [filetrParentId, setParentId] = useState(0);
  const [items, setItems] = useState<ItemsType[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [catalogItems, setCatalogItems] = useState<ICatalogItem[]>([]);
  const [selectedFieldId, setSelectedFieldId] = useState(0);

  const debouncedSearch = useDebounce(search, 500);

  const { data } = admin.useGetItemsQuery({
    search: debouncedSearch.length >= 2 ? debouncedSearch : undefined,
    parentId: filetrParentId > 0 ? filetrParentId : undefined,
  });
  const { data: citiesData } = useGetCityItemsQuery({ search });
  const { data: catalogData, isLoading } = useGetItemsQuery(
    { cityId: +isCatalogTree },
    { skip: !isCatalogTree }
  );

  const [deleteCatalogField] = useDeleteCatalogFieldMutation();

  function catalogCategoryTitle(text: CatalogItemType) {
    let newTitle = "";

    switch (text) {
      case "category":
        newTitle = "Категории";
        break;
      case "specialists":
        newTitle = "Специалисты";
        break;
      case "services":
        newTitle = "Услуги";
        break;
      case "category_icons":
        newTitle = "Категории иконками";
        break;

      default:
        newTitle = "Категории";
    }

    return newTitle;
  }

  const onSelectField: DirectoryTreeProps<treeData>["onSelect"] = (_, info) => {
    setSelectedFieldId(info.node.id);
  };

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);

  const handleFilterOfCategories = (data: any) => setParentId(data.value);

  const handleSetDeleteId = (
    event: React.MouseEvent<HTMLButtonElement>,
    setDelete: (id: number) => void,
    id: number
  ) => {
    event.stopPropagation();
    setDelete(id);
  };

  const goCreate = () =>
    navigate(AllRoutes.Catalog + `?cityId=${isCatalogTree}&create=true`);

  useLayoutEffect(() => {
    if (data && "data" in data) {
      const items = data.data.map((item) => ({
        id: item.id,
        mainCategory: item.name,
        category: item.parent?.name || "",
        type: catalogCategoryTitle(item.type),
        service: "service",
      }));
      setItems(items);
    }
  }, [data]);

  useEffect(() => {
    if (citiesData?.data) {
      setCities(citiesData.data);
    }
  }, [citiesData?.data]);

  useEffect(() => {
    if (catalogData?.data && isCatalogTree) {
      setCatalogItems(catalogData.data);
    } else {
      setCatalogItems([]);
    }
  }, [catalogData?.data, isCatalogTree]);

  return {
    items,
    cities,
    catalogItems,
    search,
    loading: isLoading,
    isEdit,
    isCreate,
    isCatalogTree,
    deleteCatalogField,
    handleSetDeleteId,
    onSelectField,
    handleFilterOfCategories,
    goCreate,
    handleSearch,
    catalogCategoryTitle,
    selectedFieldId,
  };
};
