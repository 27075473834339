import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthLinks } from "../constans/appRoutes";

function AuthRoute() {
  return (
    <BrowserRouter>
      <Routes>
        {AuthLinks.map(({ Page, path }) => (
          <Route key={path} path={path} element={Page} />
        ))}
        <Route path="*" element={<Navigate replace to={AuthLinks[0].path} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AuthRoute;
