import { useState } from "react"
import styles from "./MainTable.module.scss"
import { ReactComponent as DeleteIcon } from "../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../constans/icons/redact.svg"
import { Modal } from "../Modal"
import classNames from "classnames"

type Props = {
	data: Array<any>
	onEdit: (id: number) => void
	onDelete: (id: number) => any
	columns?: number
	isPerson?: boolean
	isReviews?: boolean
	isSpecialist?: boolean
}

const TableBody = ({
	data,
	onDelete,
	onEdit,
	isPerson = false,
	isReviews = false,
	isSpecialist,
}: Props) => {
	const [del, setDel] = useState(0)

	const confirmDeleteItem = async () => {
		await onDelete(del)
		setDel(0)
	}

	const getDate = (dateValue: number | null) => {
		if (dateValue !== null) {
			const dateNew = new Date(dateValue * 1000)
			const day = dateNew.getDate().toString().padStart(2, "0")
			const month = (dateNew.getMonth() + 1).toString().padStart(2, "0")
			const year = dateNew.getFullYear().toString()
			return `${day}-${month}-${year}`
		}
		return "- - -"
	}

	return (
		<div className={styles.tableBodyWrapper}>
			{data.map(item => (
				<div className={styles.tableBody__row} key={`${item.name}${item.id}`}>
					<div className={styles.tableBody__col} style={{ flex: 2 }}>
						{item.id}
					</div>
					<div className={styles.tableBody__col} style={{ flex: 2 }}>
						{!isPerson && item.name}
						{isPerson &&
							!isReviews &&
							`${item?.user.surname} ${item?.user.name} ${
								item.user.patronymic || ""
							}`}
					</div>
					{isSpecialist && (
						<div className={styles.tableBody__col} style={{ flex: 2 }}>
							{`${item?.specialization?.name || "Не определено"}`}
						</div>
					)}

					{isReviews && (
						<div className={styles.tableBody__col} style={{ flex: 2 }}>
							{item.summary}
						</div>
					)}
					{isReviews && (
						<div className={styles.tableBody__col} style={{ flex: 2 }}>
							{getDate(item.review_date)}
						</div>
					)}
					<div className={styles.tableBody__btns} style={{ flex: 1 }}>
						<button
							onClick={() => onEdit(item.id)}
							className={styles.tableBody__btn}
						>
							<EditIcon />
						</button>
						<button
							onClick={() => setDel(item.id)}
							className={styles.tableBody__btn}
						>
							<DeleteIcon />
						</button>
					</div>
				</div>
			))}

			{!!del && (
				<Modal visible={!!del} onClose={() => setDel(0)}>
					<div className={styles.modalWrapper}>
						<span className={styles.modalTitle}>Удалить</span>
						<p className={styles.modalDescription}>
							Вы уверены, что хотите удалить{" "}
							{!isPerson
								? data.filter(item => item.id === del)[0].name
								: data.filter(item => item.id === del)[0].user.name}
							?
						</p>
						<div className={styles.modalButtons}>
							<button
								onClick={() => setDel(0)}
								className={classNames(styles.modalBtn, styles.cancel)}
							>
								Отмена
							</button>
							<button onClick={confirmDeleteItem} className={styles.modalBtn}>
								Удалить
							</button>
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default TableBody
