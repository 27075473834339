import { FC, memo } from "react"
import styles from "./DiscountPageCreate.module.scss"
import { TextField } from "../../../../common/Form/TextField"
import { Button } from "../../../../common/Button"
import { AllRoutes } from "../../../../constans/appRoutes"
import { TreeSelect } from "../../../../common/TreeSelect"
import {
	EntityDiscounts,
	EntityDiscountsOptions,
	useDiscountPageCreateAndEdit,
} from "../../useDiscountPageCreateAndEdit"
import MultiDatePicker from "../MultiDatePicker/MultiDatePicker"
import DiscountPeriodCards from "../DiscountPeriodCards/DiscountPeriodCards"
import { Select } from "../../../../common/Select"
import SelectedServicesFilds from "../../../../common/Services/SelectedServicesFilds/SelectedServicesFilds"
import SelectedComplexesFilds from "../../../../common/Complexes/SelectedComplexesFilds/SelectedComplexesFilds"

type Props = {}

const DiscountPageCreate: FC<Props> = () => {
	const {
		navigate,
		register,
		errors,
		parentFieldValue,
		onChangeSelect,
		onSave,
		setSelectedRange,
		selectedRange,
		periods,
		changePeriod,
		addPeriod,
		discoutPeriodsOptions,
		entitySelect,
		onChangeEntity,
		selectedServices,
		setSelectedComplexes,
		setSelectedServices,
		selectedComplexes,
		deletePeriod,
		disabled,
		discountsFields,
		rangePeriods,
		isLoadingCreate,
		isLoadingEdit,
		generatedFieldData,
	} = useDiscountPageCreateAndEdit()

	return (
		<div className={styles.createDiscountWrapper}>
			<div className={styles.container}>
				<div className={styles.wrapp}>
					<TextField
						fieldname='name'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.name?.message as string}
						palcehoder='Название скидки'
						isMarginBottom={false}
					/>
				</div>

				{!!discountsFields?.data.length && (
					<div className={styles.wrapp}>
						<TreeSelect
							value={parentFieldValue}
							dropdownStyle={{
								maxHeight: 400,
								overflow: "auto",
							}}
							style={{
								marginBottom: 24,
							}}
							placeholder='Родительская папка'
							allowClear
							treeDefaultExpandAll
							onChange={onChangeSelect}
							treeData={generatedFieldData}
						/>
					</div>
				)}

				<div className={styles.wrapp}>
					<TextField
						fieldname='title'
						title='Заголовок'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.name?.message as string}
						palcehoder='Заголовок'
						isMarginBottom={false}
						styled={{
							container: styles.titleInput,
						}}
					/>
				</div>

				<MultiDatePicker
					selectedDate={selectedRange}
					setSelectedDate={setSelectedRange}
				/>

				{!!rangePeriods && (
					<DiscountPeriodCards
						periods={periods}
						discoutPeriodsOptions={discoutPeriodsOptions}
						changePeriod={changePeriod}
						addPeriod={addPeriod}
						deletePeriod={deletePeriod}
						rangePeriods={rangePeriods}
					/>
				)}

				<div className={styles.entity}>
					<div className={styles.entitySelect}>
						<Select
							optionFilterProp='label'
							style={{ width: "100%", marginBottom: 22 }}
							placeholder='Выбрать услуги\комплекс'
							defaultValue={"Выбрать услуги|комплекс"}
							value={entitySelect}
							onChange={onChangeEntity}
							options={EntityDiscountsOptions}
							classes={{ placeholder: styles.placeholderSelect }}
						/>
					</div>

					<div className={styles.entityCount}>
						<p>выбрано комплексов: {selectedComplexes.length}</p>
						<p>выбрано услуг: {selectedServices.length}</p>
					</div>
				</div>

				{entitySelect === EntityDiscounts.services && (
					<SelectedServicesFilds
						setServicesSelected={setSelectedServices}
						servicesSelected={selectedServices}
					/>
				)}

				{entitySelect === EntityDiscounts.complexes && (
					<SelectedComplexesFilds
						setComplexesSelected={setSelectedComplexes}
						complexesSelected={selectedComplexes}
					/>
				)}
			</div>

			<div className={styles.createDiscountBtnWrapper}>
				<div className={styles.btns}>
					<Button
						title='Сохранить'
						disabled={disabled}
						onClick={onSave}
						loading={isLoadingCreate || isLoadingEdit}
					/>
					<Button
						title='Отмена'
						disabled={false}
						onClick={() => navigate(AllRoutes.Discounts)}
						isTransparent
					/>
				</div>
			</div>
		</div>
	)
}

export default memo(DiscountPageCreate)
