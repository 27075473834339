import { FC, memo, useEffect, useState } from "react"
import { TextField } from "../../../../common/Form/TextField"
import { useForm } from "react-hook-form"
import styles from "./Field.module.scss"
import { IQuestion } from "../../../../models/Questions"
import { useEditeQuestionMutation } from "../../../../services/questions"

type Props = {
	item: IQuestion
	index: number
	setItem: (item: IQuestion) => void
}

const Field: FC<Props> = ({ item, index, setItem }) => {
	const [editeQuestion] = useEditeQuestionMutation()
	const [editeMode, setEditeMode] = useState(false)

	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const question = watch("question")

	useEffect(() => {
		if (!!item.text) {
			setValue("question", item.text)
		}
	}, [item, setValue])

	const saveHandler = () => {
		editeQuestion({
			id: item.id,
			text: question,
		})
	}

	const editeHandler = (value: boolean) => {
		setEditeMode(value)

		if (!value) {
			saveHandler()
		}
	}

	return (
		<li>
			<TextField
				fieldname='question'
				register={register}
				options={{ required: "" }}
				error={errors.name?.message as string}
				palcehoder={`Вопрос ${index + 1}`}
				isMarginBottom={false}
				isDisabled={!editeMode}
				deleteIcon={{
					onClick: () => setItem(item),
				}}
				editeIcon={{
					onClick: editeHandler,
					style: styles.editeBtn,
				}}
				styled={{
					container: styles.field,
				}}
			/>
		</li>
	)
}

export default memo(Field)
