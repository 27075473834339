import styles from "./ServicesPage.module.scss";
import { useGetServicePriceQuery, useGetServicePricesQuery } from "../../services/services";
import PriceCitiesList from "./PriceCitiesList";
import { useSearchParams } from "react-router-dom";
import PriceCitiesEdit from "./PriceCitiesEdit";
import PriceCitiesCreate from "./PriceCitiesCreate";

type Props = {
  onPriceEdit: () => void;
  onPriceEditCancel: () => void;
};

const PriceCitiesPage = ({ onPriceEdit, onPriceEditCancel }: Props) => {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const currentId = params?.serviceId;
  const isPriceEdit = params?.priceId;
  const isPriceCreate = params?.createPrice;

  const { data: pricesData } = useGetServicePricesQuery({ id: +currentId });
  const { data: priceData } = useGetServicePriceQuery({ id: +isPriceEdit }, { skip: !isPriceEdit });

  return (
    <div className={styles.priceCities__wrapper}>
      {pricesData && !isPriceEdit && !isPriceCreate && <PriceCitiesList servicePricesData={pricesData?.data} />}
      {isPriceEdit && !isPriceCreate && <PriceCitiesEdit priceData={priceData} />}
      {!isPriceEdit && isPriceCreate && <PriceCitiesCreate />}
    </div>
  );
};

export default PriceCitiesPage;
