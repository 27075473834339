import { FC, useMemo, useState } from "react"
import { Checkbox } from "../../../../common/CheckBox"
import DropDown from "../../../../common/DropDown/DropDown"
import { ServicesTypeOptions } from "../../../../constans/constans"
import { IServices, ServiceType } from "../../../../models/Specialist"
import styles from "./TreeServiceItem.module.scss"
import { ReactComponent as AddIcon } from "../../../../constans/icons/add.svg"
import { DropDownOptions } from "../../../../common/DropDown/types"
import { useSpecialistContext } from "../../SpecialistContext"

type Props = {
	service: IServices
	checked: boolean
	onSelectService: (serv: { service_id: number; type: ServiceType }) => void
	currentType?: ServiceType
	showAdditional?: boolean
	showDescription?: boolean
}

const TreeServiceItem: FC<Props> = ({
	checked,
	service,
	currentType,
	onSelectService,
	showAdditional = true,
	showDescription = true,
}) => {
	const {
		toggleSelectAdditionalServiceModal,
		setService,
		selectedServices,
		handleSelectServiceDescription,
	} = useSpecialistContext()

	const [type, setType] = useState(
		ServicesTypeOptions?.find(type => type.value === currentType)?.label ||
			ServicesTypeOptions[2].label
	)

	const findRequiredServices = useMemo(() => {
		return selectedServices.find(it => it.service_id === service.id)
	}, [selectedServices, service])

	const handleSelectType = (opt: DropDownOptions) => {
		setType(opt.label)
		handleSelectServ(opt.value as ServiceType)
	}

	const handleSelectServ = (typed?: ServiceType) => {
		const currentType = typed
			? typed
			: ServicesTypeOptions.find(opt => opt.label === type)?.value
		currentType &&
			onSelectService({
				service_id: service.id,
				type: currentType as ServiceType,
			})
	}

	const addHandler = () => {
		toggleSelectAdditionalServiceModal()
		setService(service)
	}

	const handleDescription = (event: any) => {
		event.stopPropagation()
		handleSelectServiceDescription(service)
	}

	return (
		<div className={styles.treeServices}>
			<div
				onClick={handleSelectServ.bind(null, undefined)}
				style={{ display: "flex", alignItems: "center" }}
			>
				<Checkbox checked={checked} />
				<div className={styles.servicenameBlock}>
					<span className={styles.servicename}>{service.name}</span>
					{!!findRequiredServices?.required_service_id && showDescription && (
						<span className={styles.connection} onClick={handleDescription}>
							*Есть связь с обязательной
						</span>
					)}
				</div>
			</div>

			<div className={styles.btns}>
				<div className={styles.dropDown}>
					<DropDown
						styled={{
							menu: {
								borderTop: 0,
								borderRight: 0,
								borderColor: "#dadada",
								borderWidth: 2,
							},
						}}
						height={44}
						value={type}
						options={ServicesTypeOptions}
						onSelect={handleSelectType}
					/>
				</div>

				{showAdditional && checked && (
					<div className={styles.additionalyService} onClick={addHandler}>
						<span>Доп. услуга</span>
						<AddIcon
							style={{ marginRight: 10 }}
							className={styles.plus}
							width={16}
							height={16}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default TreeServiceItem
