import { useNavigate, useSearchParams } from "react-router-dom"
import { ActionTypes, Entities, EntityType } from "../../../types"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useSpecialistBanner } from "./hooks/useSpecialistBanner"
import { useIconBanner } from "./hooks/useIconBanner"
import { useTypeBanner } from "./hooks/useTypeBanner"
import { useServicesBanner } from "./hooks/useServicesBanner"
import { useBanner } from "./hooks/useBanner"
import { useCreateBanner } from "./hooks/useCreateBanner"
import { useUpdateBanner } from "./hooks/useUpdateBanner"
import { useEntiyBanner } from "./hooks/useEntiyBanner"
import { useComplexBanner } from "./hooks/useComplexBanner"

type Props = {
	action: ActionTypes
}

export type ItemGroupBanner = {
	id: number
	title: string
}

export type BannerState = {
	fileId: number
	entity: Entities.Serv | Entities.Spec | string
	spec: number
	service: number
	complex: number
	photoUrl: string
}

export const useCreateBanners = ({ action }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const goBack = () => navigate(-1)

	const {
		register,
		watch,
		setValue,
		formState: { errors },
	} = useForm({ mode: "onBlur" })

	const order = watch("order")
	const name = watch("name")
	const title = watch("title")

	const [state, setState] = useState<BannerState[]>([
		{
			fileId: 0,
			entity: "",
			spec: 0,
			service: 0,
			complex: 0,
			photoUrl: "",
		},
	])

	const [description, setDescription] = useState("")

	// банеры
	const { bannerData, banners, loadingBanner } = useBanner({ params })
	// сущности
	const { handleEntiyChange } = useEntiyBanner({ setState, state })
	// услги
	const { findServOfId, handleServChange, loadingServ, servOptions } =
		useServicesBanner({ setState, state })
	// специалисты
	const { findSpecOfId, handleSpecChange, loadingSpec, specOptions } =
		useSpecialistBanner({ setState, state })
	// комплексы
	const {
		complexOptions,
		loadingComplex,
		findComplexOfId,
		handleComplexChange,
	} = useComplexBanner({ setState, state })
	// иконки банера
	const { handleChangeIcon, handledeleteImage } = useIconBanner({
		setState,
		state,
	})
	// типы банера
	const { handleTypeBannerChange, setType, type, loadingTypes, typesBanners } =
		useTypeBanner({
			setState,
			state,
		})
	// создание банера
	const { handleCreate, loadingCreate } = useCreateBanner({
		name,
		order,
		params,
		state,
		type,
		typesBanners,
		description,
		title,
	})
	// обновление банера
	const { handleUpdate, loadingUpdate } = useUpdateBanner({
		name,
		order,
		params,
		state,
		type,
		typesBanners,
		bannerData,
		description,
		title,
	})

	const disabled = useMemo(() => {
		return state.some(it => {
			return (
				!it.fileId ||
				!it.entity ||
				!it.photoUrl ||
				(!it.service && !it.spec && !it.complex) ||
				!name ||
				!order ||
				(!description.length && type === "TYPE_1") ||
				(!title?.length && type === "TYPE_1")
			)
		})
	}, [state, name, order, description, type, title])

	const loadingData = useMemo(() => {
		return (
			loadingBanner ||
			loadingSpec ||
			loadingServ ||
			loadingTypes ||
			loadingComplex
		)
	}, [loadingBanner, loadingSpec, loadingServ, loadingTypes, loadingComplex])

	const loading = useMemo(() => {
		return loadingCreate || loadingUpdate
	}, [loadingCreate, loadingUpdate])

	const onSubmit = () => (action === "create" ? handleCreate() : handleUpdate())

	useEffect(() => {
		if (bannerData && action === "edit") {
			const stateData: BannerState[] = bannerData.data.items.map(it => {
				let entity = Entities.Serv

				if (it.entity_type === EntityType.Service) {
					entity = Entities.Serv
				}

				if (it.entity_type === EntityType.Specialists) {
					entity = Entities.Spec
				}

				if (it.entity_type === EntityType.Complex) {
					entity = Entities.Complex
				}

				return {
					entity,
					fileId: it.photo.id,
					spec: it.entity_type === EntityType.Specialists ? it.entity_id : 0,
					service: it.entity_type === EntityType.Service ? it.entity_id : 0,
					photoUrl: it.photo.url,
					complex: it.entity_type === EntityType.Complex ? it.entity_id : 0,
				}
			})

			setType(bannerData.data.type || "TYPE_1")

			setTimeout(() => {
				setState(stateData)
			}, 300)
			setValue("order", bannerData.data.order)
			setValue("name", bannerData.data.name)
			setValue("title", bannerData.data.title || "")
			setDescription(bannerData.data.description || "")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, bannerData])

	return {
		banners,
		loading,
		loadingData,
		state,
		errors,
		type,
		servOptions,
		specOptions,
		disabled,
		complexOptions,
		description,
		setDescription,
		findComplexOfId,
		handleComplexChange,
		handleTypeBannerChange,
		goBack,
		onSubmit,
		register,
		findServOfId,
		findSpecOfId,
		handleChangeIcon,
		handleEntiyChange,
		handleServChange,
		handleSpecChange,
		handledeleteImage,
	}
}
