import { FC } from "react";
import { ReactComponent as LogoIcon } from "../LoginPage/icons/logo.svg";
import { Button } from "../../common/Button";
import { TextField } from "../../common/Form/TextField";
import { ReactComponent as UserIcon } from "../LoginPage/icons/Union.svg";
import { formOptions } from "../../constans/formOptions";
import { useRecoveryPageController } from "./RecoveryPage.controller";
import { ReactComponent as EmailIcon } from "./icons/Email.svg";
import styles from "./RecoveryPage.module.scss";
import classNames from "classnames";

const RecoveryPage: FC = (props) => {
  const {
    isValid,
    errors,
    isLoading,
    isRecovery,
    register,
    onSubmit,
    handleSubmit,
  } = useRecoveryPageController();
  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.wrapper, {
          [styles.recovery]: isRecovery,
        })}
      >
        <div className={styles.top}>
          <LogoIcon />
          <span className={styles.topTitle}>Спектр диагностика</span>
        </div>

        {!isRecovery && (
          <span className={styles.emailTitle}>
            Введите почту для восстановления пароля
          </span>
        )}

        {isRecovery ? (
          <div className={styles.recoveryContainer}>
            <div className={styles.recoveryText}>
              На ваш email отправлено письмо с ссылкой для восстановления пароля
            </div>
            <EmailIcon />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              Icon={UserIcon}
              options={formOptions.email}
              error={errors.email?.message as string}
              palcehoder="email"
              fieldname="email"
              type="email"
              register={register}
            />

            <div className={styles.btn}>
              <Button
                loading={isLoading}
                title="Восстановить пароль"
                disabled={!isValid}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default RecoveryPage;
