import React, { ReactNode, useMemo, useState } from "react"
import styles from "./ServicesPage.module.scss"
import "./ServicesPage.scss"
import { Layout } from "../../common/Layout"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { Spin, Tree, message } from "antd"
import type { DirectoryTreeProps } from "antd/es/tree"
import {
	useDeleteServiceFieldMutation,
	useDeleteServiceMutation,
	useGetServicesFieldsQuery,
	useGetServicesQuery,
} from "../../services/services"
import { ReactComponent as ServiceIcon } from "../../constans/icons/serviceIcon.svg"
import { ReactComponent as GreyField } from "../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../constans/icons/greenField.svg"
import { ReactComponent as DeleteIcon } from "../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../constans/icons/redact.svg"
import { Modal } from "../../common/Modal"
import classNames from "classnames"
import { AllRoutes } from "../../constans/appRoutes"
import ServicesPageCreateField from "./ServicesPageCreateField"
import ServicesPageEditField from "./ServicesPageEditField"
import ServicesPageEdit from "./ServicesPageEdit"
import ServicesPageCreate from "./ServicesPageCreate"
import { ICatalogItem, ServicesFieldsType } from "../../models/CatalogItems"
import { LoadingOutlined } from "@ant-design/icons"
import { IServices } from "../../models/Specialist"
import { sortChildrenForOrder } from "../../helpers/sort"

const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: 20 }} spin />

const { DirectoryTree } = Tree

export type treeData = {
	title: string | React.ReactNode
	id: number
	parent_id?: number | null
	key: string
	children: treeData[]
	order: number | null
	icon: ReactNode
	type?: string
}

export function sortFields(arr: ServicesFieldsType[] | ICatalogItem[]) {
	return arr.sort((a, b) => {
		if (b.parent_id === null || b.parent_id === undefined) return -1
		if (a.parent_id === null || a.parent_id === undefined) return 1
		return b.parent_id - a.parent_id
	})
}

const ServicesPage = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const isServiceEdit = !!params?.serviceEdit
	const isServiceCreate = !!params?.serviceCreate
	const isFieldEdit = !!params?.fieldEdit
	const isFieldCreate = !!params?.fieldCreate
	const isShowPrices = !!params?.showPrices
	const isPriceEdit = !!params?.priceId

	const defaultParentId = params?.defaultParentId
	const isPriceCreate = !defaultParentId && isShowPrices

	const { data: servicesFieldData, isLoading: loading1 } =
		useGetServicesFieldsQuery("")
	const { data: servicesData, isLoading: loading2 } = useGetServicesQuery("")

	const [deleteField, { isLoading: loading3 }] = useDeleteServiceFieldMutation()
	const [deleteService, { isLoading: loading4 }] = useDeleteServiceMutation()

	const loading = useMemo(() => {
		return loading1 || loading2 || loading3 || loading4
	}, [loading1, loading2, loading3, loading4])

	const [selectedFieldId, setSelectedFieldId] = useState(0)
	const [deleteFieldId, setDeleteFieldId] = useState(0)
	const [deleteServiceId, setDeleteServiceId] = useState(0)

	const handleSetDeleteId = (
		event: React.MouseEvent<HTMLButtonElement>,
		setDelete: (id: number) => void,
		id: number
	) => {
		event.stopPropagation()
		setDelete(id)
	}

	const handleGoEditService = (
		event: React.MouseEvent<HTMLButtonElement>,
		id: number
	) => {
		event.stopPropagation()
		navigate(AllRoutes.ServiceEdit + id)
	}

	const handleDeleteField = async () => {
		await deleteField({ id: deleteFieldId })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Папка успешно удалена")
					setDeleteFieldId(0)
					navigate(AllRoutes.Services)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить папку")
			})
	}

	const handleDeleteService = async () => {
		await deleteService({ id: deleteServiceId })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Услуга успешно удалена")
					setDeleteServiceId(0)
					navigate(AllRoutes.Services)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить услугу")
			})
	}

	const findParentField = (service: IServices, dataArr: treeData[]) => {
		dataArr.forEach(item => {
			if (service.service_field.id === item.id && item.type === "field") {
				item.children?.push({
					title: (
						<>
							{service.name}
							<div className={styles.tree__btns} style={{ flex: 1 }}>
								<button
									onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
										handleGoEditService(event, service.id)
									}
									className={styles.tree__btn}
								>
									<EditIcon />
								</button>
								<button
									onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
										handleSetDeleteId(event, setDeleteServiceId, service.id)
									}
									className={styles.tree__btn}
								>
									<DeleteIcon />
								</button>
							</div>
						</>
					),
					id: service.id,
					key: `${item.key}-${item.children.length}`,
					children: [],
					order: service.order || null,
					icon: <ServiceIcon />,
				})
			}

			if (service.service_field.id !== item.id && item.children?.length) {
				findParentField(service, item.children)
			}
		})
	}

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}
			newItem.key = key
			newItem.icon =
				(key.length > 3 && item?.childrent) || item.parent ? (
					<GreyField />
				) : (
					<GreenField />
				)
			newItem.title = (
				<>
					{item.name}
					<div className={styles.tree__btns} style={{ flex: 1 }}>
						<button
							onClick={() => navigate(AllRoutes.ServicesFieldEdit + item.id)}
							className={styles.tree__btn}
						>
							<EditIcon />
						</button>
						<button
							onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
								handleSetDeleteId(event, setDeleteFieldId, item.id)
							}
							className={styles.tree__btn}
						>
							<DeleteIcon />
						</button>
					</div>
				</>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const prepareData = (array: any[]) => {
		const mapItems = new Map(array.map(item => [item.id, item]))
		array.forEach(item => {
			if (item.parent) {
				const parent = mapItems.get(item.parent_id)
				mapItems.set(item.parent_id, {
					...parent,
					children: [...(parent.children ?? []), mapItems.get(item.id)],
				})
			}
		})
		const tt = Object.values(Object.fromEntries(mapItems)).filter(
			(item: any) => item.parent_id === null
		)
		return addKey(tt, "0")
	}

	const dataForPrepare = useMemo(() => {
		if (servicesFieldData?.data) {
			const sortedData = [...servicesFieldData.data]
			return prepareData(sortFields(sortedData))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [servicesFieldData?.data, servicesData?.data])

	const generateData = (
		fieldsData: ServicesFieldsType[],
		servicesData: IServices[]
	) => {
		const data: treeData[] = []

		// const sortDataForPrepare = sortChildrenForOrder(dataForPrepare)

		dataForPrepare.forEach((item: any) => {
			data.push(item)
		})

		const sortData = sortChildrenForOrder(servicesData)

		sortData.forEach(service => {
			findParentField(service, data)
		})

		return data
	}

	const generatedData = useMemo(() => {
		if (servicesFieldData?.data && servicesData?.data) {
			return generateData(servicesFieldData.data, servicesData.data)
		}
		return []
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [servicesFieldData, servicesData, dataForPrepare])

	const onSelect: DirectoryTreeProps<treeData>["onSelect"] = (keys, info) => {
		if (info.node.type === "field") {
			setSelectedFieldId(info.node.id)
		} else {
			setSelectedFieldId(0)
		}
	}

	const goToCreateField = () => {
		navigate(AllRoutes.ServicesFieldCreate)
	}
	const goToCreateService = () => {
		if (selectedFieldId !== 0) {
			navigate(AllRoutes.ServiceCreate + selectedFieldId)
		}
	}

	const prevUrls = useMemo(() => {
		return [{ title: "Услуги", path: "/services" }]
	}, [])

	const prevUrlsForPrices = useMemo(() => {
		return [
			...prevUrls,
			{
				title: isServiceCreate ? "Добавить услугу" : "Редактирование услуги",
				path: !isServiceCreate
					? `/services?serviceEdit=true&serviceId=${params?.serviceId}`
					: `/services?serviceCreate=true&defaultParentId=${defaultParentId}`,
			},
		]
	}, [prevUrls, isServiceCreate, params?.serviceId, defaultParentId])

	const prevUrlsForPriceEdit = useMemo(() => {
		return [
			...prevUrlsForPrices,
			{
				title: "Города (цены)",
				path: !isServiceCreate
					? `/services?serviceEdit=true&serviceId=${params?.serviceId}&showPrices=true`
					: `/services?serviceCreate=true&defaultParentId=&showPrices=true`,
			},
		]
	}, [prevUrlsForPrices, isServiceCreate, params?.serviceId])

	const prevLinks = useMemo(() => {
		if (isShowPrices && !isPriceEdit && !isServiceCreate) {
			return prevUrlsForPrices
		}

		if (isPriceEdit || isPriceCreate) {
			return prevUrlsForPrices
		}

		if (isServiceCreate || isServiceEdit || isFieldCreate || isFieldEdit) {
			return prevUrls
		}

		return []
	}, [
		isShowPrices,
		isPriceEdit,
		isPriceCreate,
		isServiceEdit,
		isServiceCreate,
		prevUrlsForPrices,
		isFieldCreate,
		isFieldEdit,
		prevUrls,
	])

	const titlePage = useMemo(() => {
		if (isFieldCreate) {
			return "Добавить папку"
		}

		if (isFieldEdit) {
			return "Редактировать папку"
		}

		if (isServiceCreate && !isShowPrices) {
			return "Добавить услугу"
		}

		if (isServiceEdit && !isShowPrices) {
			return "Редактировать услугу"
		}

		if (isShowPrices && !isPriceEdit) {
			return "Города (цены)"
		}

		if (isPriceEdit) {
			return "Цены"
		}

		return ""
	}, [
		isFieldCreate,
		isFieldEdit,
		isPriceEdit,
		isShowPrices,
		isServiceCreate,
		isServiceEdit,
	])

	return (
		<>
			<Layout
				load={loading}
				createBtn={
					!isFieldCreate && !isFieldEdit && !isServiceCreate && !isServiceEdit
						? true
						: false
				}
				isCreateBtnDisabled={!!!selectedFieldId}
				createBtnTitle='Добавить услугу'
				secondCreateBtn={
					!isFieldCreate && !isFieldEdit && !isServiceCreate && !isServiceEdit
						? true
						: false
				}
				secondCreateBtnTitle='Добавить папку'
				onClickSecondCreateBtn={goToCreateField}
				noData={!servicesFieldData?.data || !servicesData?.data ? true : false}
				onClickCreateBtn={goToCreateService}
				currentUrl={{
					path: `${useLocation().pathname}${useLocation().search}`,
					title: titlePage,
				}}
				prevLinks={prevLinks}
			>
				{!isFieldCreate &&
					!isFieldEdit &&
					!isServiceEdit &&
					!isServiceCreate && (
						<>
							{!generatedData.length ? (
								<div style={{ display: "flex", justifyContent: "center" }}>
									<Spin indicator={antIcon} />
								</div>
							) : (
								<DirectoryTree
									defaultExpandAll={false}
									onSelect={onSelect}
									treeData={generatedData}
									itemHeight={64}
									rootStyle={{
										background: "transparent",
										border: "0.5px solid #DADADA",
									}}
								/>
							)}
						</>
					)}
				{isFieldCreate && !isFieldEdit && servicesFieldData && (
					<ServicesPageCreateField fieldsData={servicesFieldData?.data} />
				)}
				{isFieldEdit && !isFieldCreate && servicesFieldData && (
					<ServicesPageEditField fieldsData={servicesFieldData?.data} />
				)}
				{isServiceEdit && servicesFieldData && (
					<ServicesPageEdit fieldsData={servicesFieldData?.data} />
				)}
				{isServiceCreate && servicesFieldData && (
					<ServicesPageCreate fieldsData={servicesFieldData?.data} />
				)}
			</Layout>
			{!!deleteFieldId && (
				<Modal visible={!!deleteFieldId} onClose={() => setDeleteFieldId(0)}>
					<div className={styles.modalWrapper}>
						<span className={styles.modalTitle}>Удалить</span>
						<p className={styles.modalDescription}>
							Вы уверены, что хотите удалить{" "}
							{
								servicesFieldData?.data.filter(
									(item: any) => item.id === deleteFieldId
								)[0].name
							}
							?
						</p>
						<div className={styles.modalButtons}>
							<button
								onClick={() => setDeleteFieldId(0)}
								className={classNames(styles.modalBtn, styles.cancel)}
							>
								Отмена
							</button>
							<button onClick={handleDeleteField} className={styles.modalBtn}>
								Удалить
							</button>
						</div>
					</div>
				</Modal>
			)}
			{!!deleteServiceId && (
				<Modal
					visible={!!deleteServiceId}
					onClose={() => setDeleteServiceId(0)}
				>
					<div className={styles.modalWrapper}>
						<span className={styles.modalTitle}>Удалить</span>
						<p className={styles.modalDescription}>
							Вы уверены, что хотите удалить{" "}
							{
								servicesData?.data.filter(
									(item: any) => item.id === deleteServiceId
								)[0]?.name
							}
							?
						</p>
						<div className={styles.modalButtons}>
							<button
								onClick={() => setDeleteServiceId(0)}
								className={classNames(styles.modalBtn, styles.cancel)}
							>
								Отмена
							</button>
							<button onClick={handleDeleteService} className={styles.modalBtn}>
								Удалить
							</button>
						</div>
					</div>
				</Modal>
			)}
		</>
	)
}

export default ServicesPage
