import { FC, memo, useMemo } from "react"
import { ReactComponent as QwestionIcon } from "../../../../../../constans/icons/noFileIcon.svg"
import { Select } from "../../../../../../common/Select"
import { FileField } from "../../../../../../common/Form/FileField"
import { Entities, EntitiesOption } from "../../../../types"
import styles from "./BannerTypeItemContent.module.scss"
import { DefaultOptionType } from "antd/es/select"
import { ISpeialist, IServices } from "../../../../../../models/Specialist"
import { BannerState } from "../CreateOrEditBanners.controller"
import { KeyTypesBanners } from "../../../../../../services/mainBanner"
import { IComboService } from "../../../../../../models/ComboServices"

type Props = {
	state: BannerState
	index: number
	type: KeyTypesBanners

	handleEntiyChange: (value: number, index: number) => void

	specOptions: DefaultOptionType[]
	handleSpecChange: (value: number, index: number) => void
	findSpecOfId: (id: number) => ISpeialist | undefined

	servOptions: DefaultOptionType[]
	handleServChange: (value: number, index: number) => void
	findServOfId: (id: number) => IServices | undefined

	complexOptions: DefaultOptionType[]
	handleComplexChange: (value: number, index: number) => void
	findComplexOfId: (id: number) => IComboService | undefined

	handleChangeIcon: (file: File, index: number) => Promise<void>
	handledeleteImage: (index: number) => void
}

const BannerTypeItemContent: FC<Props> = ({
	state,
	specOptions,
	servOptions,
	complexOptions,
	index,
	type,
	findSpecOfId,
	findServOfId,
	findComplexOfId,
	handleEntiyChange,
	handleSpecChange,
	handleServChange,
	handleChangeIcon,
	handledeleteImage,
	handleComplexChange,
}) => {
	const isSpec = useMemo(() => {
		return state.entity === Entities.Spec
	}, [state])

	const isServ = useMemo(() => {
		return state.entity === Entities.Serv
	}, [state])

	const isComplex = useMemo(() => {
		return state.entity === Entities.Complex
	}, [state])

	const btnTextFile = useMemo(() => {
		let text = "Загрузить изображение "

		if (type === "TYPE_1") {
			text += "(343*192)"
		}

		if (type === "TYPE_2") {
			if (index === 0) {
				text += "(164*125)"
			}

			if (index === 1) {
				text += "(164*125)"
			}

			if (index === 2) {
				text += "(164*260)"
			}
		}

		if (type === "TYPE_3") {
			text += "(164*260)"
		}

		if (type === "TYPE_4") {
			if (index === 0) {
				text += "(164*260)"
			}

			if (index === 1) {
				text += "(164*125)"
			}

			if (index === 2) {
				text += "(164*125)"
			}
		}

		if (type === "TYPE_5") {
			text += "(164*125)"
		}

		if (type === "TYPE_6") {
			text += "(343*80)"
		}

		return text
	}, [type, index])

	const titleFile = useMemo(() => {
		let text = "Изображение баннера "

		if (type === "TYPE_2") {
			if (index === 2) {
				text += "(правый)"
			}

			if (index === 0) {
				text += "(верхний левый)"
			}

			if (index === 1) {
				text += "(нижний левый)"
			}
		}

		if (type === "TYPE_3") {
			if (index === 0) {
				text += "(левый)"
			}

			if (index === 1) {
				text += "(правый)"
			}
		}

		if (type === "TYPE_4") {
			if (index === 0) {
				text += "(левый)"
			}

			if (index === 1) {
				text += "(верхний правый)"
			}

			if (index === 2) {
				text += "(нижний правый)"
			}
		}

		if (type === "TYPE_5") {
			if (index === 0) {
				text += "(верхний левый)"
			}

			if (index === 1) {
				text += "(верхний правый)"
			}

			if (index === 2) {
				text += "(нижний левый)"
			}

			if (index === 3) {
				text += "(нижний правый)"
			}
		}

		if (type === "TYPE_6") {
			if (index === 0) {
				text += "(Записаться онлайн)"
			}
		}

		return text
	}, [type, index])

	return (
		<div className={styles.item}>
			<div className={styles.selector}>
				<Select
					value={state.entity}
					onChange={value => handleEntiyChange(value, index)}
					options={EntitiesOption}
					placeholder='Сущность'
					classes={{ placeholder: styles.titleField }}
				/>
			</div>

			{isSpec && (
				<div className={styles.selector}>
					<Select
						value={
							findSpecOfId(state.spec)
								? `${findSpecOfId(state.spec)?.user.surname} ${
										findSpecOfId(state.spec)?.user.name
								  } ${findSpecOfId(state.spec)?.user.patronymic}`
								: ""
						}
						showSearch
						optionFilterProp='label'
						classes={{ placeholder: styles.titleField }}
						onChange={value => handleSpecChange(value, index)}
						options={specOptions}
						placeholder='Специалист'
					/>
				</div>
			)}

			{isServ && (
				<div className={styles.selector}>
					<Select
						classes={{ placeholder: styles.titleField }}
						value={findServOfId(state.service)?.name}
						onChange={value => handleServChange(value, index)}
						options={servOptions}
						placeholder='Услуга'
						showSearch
						optionFilterProp='label'
					/>
				</div>
			)}

			{isComplex && (
				<div className={styles.selector}>
					<Select
						classes={{ placeholder: styles.titleField }}
						value={findComplexOfId(state.complex)?.name}
						onChange={value => handleComplexChange(value, index)}
						options={complexOptions}
						placeholder='Комплекс'
						showSearch
						optionFilterProp='label'
					/>
				</div>
			)}

			<FileField
				classes={{ flexContainer: styles.fileContainer }}
				Icon={QwestionIcon}
				onChange={value => handleChangeIcon(value, index)}
				onDelete={() => handledeleteImage(index)}
				title={titleFile}
				btnText={btnTextFile}
				src={state.photoUrl}
			/>
		</div>
	)
}

export default memo(BannerTypeItemContent)
