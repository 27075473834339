import {
	ChangeEvent,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react"
import { useForm } from "react-hook-form"
import {
	RequestCreateMailings,
	RequestUpdateMailings,
	useCreateMailingsMutation,
	useGetDeeplinksQuery,
	useGetMailingsOfIdQuery,
	useUpdateMailingsMutation,
} from "../../../services/mailing"
import { ActionType, SendingTimeType } from "../../../models/Mailings"
import { DateType, validateDate } from "../../../helpers/validate"
import { useGetTriggersQuery } from "../../../services/trigerTypes"
import { useNavigate, useSearchParams } from "react-router-dom"
import { pushSelector } from "../../../constans/constans"
import { message as MessageNoty } from "antd"
import { Entities, EntitiesOption } from "../../MainEditPage/types"
import { useGetSpecialistsQuery } from "../../../services/specialist"
import { useGetServicesQuery } from "../../../services/services"
import { AllRoutes } from "../../../constans/appRoutes"
import { useGetComplexesQuery } from "../../../services/complexes"
import { useGetBannersQuery } from "../../../services/mainBanner"

type TimeSettings = "" | "В установленное время" | "По триггеру"

type FormType = { name: string; message: string; trigertime: string }

export type OptionMalling = {
	value: number
	label: string
	deeplink: string
}

export const useNotifycationEditController = (action?: ActionType) => {
	const navigate = useNavigate()
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({ mode: "onBlur" })

	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const { data: mailingData, isLoading: loadingEdit } = useGetMailingsOfIdQuery(
		{
			id: +params?.id,
		},
		{ skip: !params?.id }
	)

	const [timeSettings, setTimeSettings] = useState<TimeSettings>("")
	const [triggerOption, setTriggerOptions] = useState("")
	const [selectedDay, setSelectedDay] = useState("")
	const [selectedTime, setSelectedTime] = useState("")
	const [triggerTime, setTriggerTime] = useState(0)
	const [triggerFormat, setTrigerFormat] = useState<DateType>(DateType.Minutes)

	const { data: specialists, isLoading: loadingSpec } =
		useGetSpecialistsQuery("")
	const { data: services, isLoading: loadingServ } = useGetServicesQuery({})

	const { data: complexes, isLoading: loadingComplexes } =
		useGetComplexesQuery("")

	const { data: deeplinks, isLoading: loadingDeeplink } =
		useGetDeeplinksQuery("")

	const { data: bannersData } = useGetBannersQuery()

	const [entity, setEntity] = useState<Entities | undefined>()
	const [specialistId, setSpecialistId] = useState<number | undefined>()
	const [servicesId, setServicesId] = useState<number | undefined>()
	const [complexId, setComplexId] = useState<number | undefined>()
	const [promotionId, setPromotionId] = useState<number | undefined>()

	const { data: triggerData } = useGetTriggersQuery({})

	const [fetchCreate, { isLoading: createLodaing }] =
		useCreateMailingsMutation()
	const [fetchUpdate, { isLoading: updateLodaing }] =
		useUpdateMailingsMutation()

	const isLoading = useMemo(() => {
		return (
			createLodaing ||
			updateLodaing ||
			loadingEdit ||
			loadingSpec ||
			loadingServ ||
			loadingDeeplink ||
			loadingComplexes
		)
	}, [
		createLodaing,
		updateLodaing,
		loadingEdit,
		loadingSpec,
		loadingServ,
		loadingDeeplink,
		loadingComplexes,
	])

	const sending_time_type = Object.entries(pushSelector)
		.filter(([_, value]) => value === timeSettings)
		.map(([key, _]) => key)
		.flat()
		.join("") as SendingTimeType

	const specialistsOptions = useMemo(() => {
		return (
			specialists?.data.map(spec => ({
				value: spec.id,
				label: `${spec.user.name} ${spec.user.patronymic}`,
				deeplink: "SPECIALIST_INFO",
			})) || []
		)
	}, [specialists])

	const servicesOptions = useMemo(() => {
		return (
			services?.data.map(serv => ({
				value: serv.id,
				label: serv.name,
				deeplink: "SERVICE_INFO",
			})) || []
		)
	}, [services])

	const complexesOptions = useMemo(() => {
		return (
			complexes?.data.map(serv => ({
				value: serv.id,
				label: serv.name,
				deeplink: "COMPLEX_INFO",
			})) || []
		)
	}, [complexes])

	const promotionOptions = useMemo(() => {
		const filterBanners = bannersData?.data.filter(b => b.type === "TYPE_1")
		return (
			filterBanners?.map(baner => ({
				value: baner.id,
				label: baner.name,
				deeplink: "PROMOTION_INFO",
			})) || []
		)
	}, [bannersData])

	const handleSpecChange = useCallback(
		(val: number) => {
			const find = specialistsOptions.find(spec => spec.value === val)?.value

			if (find) {
				setSpecialistId(find)
			}
		},
		[setSpecialistId, specialistsOptions]
	)

	const handleEntiyChange = useCallback((value: number) => {
		const find = EntitiesOption.find(ent => +ent.value === +value)?.label

		if (find) {
			setEntity(find)
		}

		setSpecialistId(undefined)
		setServicesId(undefined)
	}, [])

	const handleServChange = (val: number) => {
		const find = servicesOptions.find(serv => serv.value === val)?.value

		if (find) {
			setServicesId(find)
		}
	}

	const handleComplexChange = (val: number) => {
		const find = complexesOptions.find(serv => serv.value === val)?.value

		if (find) {
			setComplexId(find)
		}
	}

	const handlePromotionChange = (val: number) => {
		const find = promotionOptions.find(baner => baner.value === val)?.value

		if (find) {
			setPromotionId(find)
		}
	}

	const onSubmit = async (data: any) => {
		const isEdit = action === "edit"

		if (isEdit) {
			await handleUpdate(data as FormType)
		} else {
			await handleCreate(data as FormType)
		}
	}

	const currentMailing = mailingData?.data

	const findTriggerOfLabel = (label: string) =>
		triggerData?.data.find(trigger => trigger.name === label)

	const handleChangeTriggerFormat = (_: any, option: any) => {
		setTrigerFormat(option.label)
	}

	const handleChangeTriggerData = ({
		target,
	}: ChangeEvent<HTMLInputElement>) => {
		setTriggerTime(+target.value)
	}

	const handleDateChange = (_: any, dateString: string) => {
		setSelectedDay(dateString)
	}

	const handleTimeChange = (_: any, timeString: string) => {
		setSelectedTime(timeString + ":00")
	}

	const handleTimeSettigsChange = (_: any, option: any) => {
		setTimeSettings(option.label)

		if (option.label === "По триггеру") {
			setEntity(undefined)
			setSpecialistId(undefined)
			setServicesId(undefined)
		}
	}

	const handleChangeTrigger = (_: any, option: any) => {
		setTriggerOptions(option.label)
	}

	const handleUpdate = async ({ message, name }: FormType) => {
		const sending_time =
			selectedDay && selectedTime ? `${selectedDay} ${selectedTime}` : ""

		const data: RequestUpdateMailings = {
			id: +params?.id || 0,
			message,
			subject: name,
			sending_time,
			sending_time_type,
			trigger_settings: {
				selector: triggerFormat,
				time: triggerTime,
			},
		}

		if (isTrigger) {
			data.trigger_type_code = findTriggerOfLabel(triggerOption)?.code || ""
		}

		if (entity === Entities.Spec && specialistId) {
			data.trigger_settings.id = specialistId
			data.trigger_settings.deeplink = "SPECIALIST_INFO"
		}

		if (entity === Entities.Serv && servicesId) {
			data.trigger_settings.id = servicesId
			data.trigger_settings.deeplink = "SERVICE_INFO"
		}

		if (entity === Entities.Complex && complexId) {
			data.trigger_settings.id = complexId
			data.trigger_settings.deeplink = "COMPLEX_INFO"
		}

		if (entity === Entities.Promotion && promotionId) {
			data.trigger_settings.id = promotionId
			data.trigger_settings.deeplink = "PROMOTION"
		}

		const result = await fetchUpdate(data)

		if (result && "data" in result) {
			MessageNoty.success("Рассылка обновленна")
			navigate("/notifications")
		}
	}

	const handleCreate = async ({ message, name }: FormType) => {
		const sending_time =
			selectedDay && selectedTime ? `${selectedDay} ${selectedTime}` : ""

		const data: RequestCreateMailings = {
			message,
			subject: name,
			sending_time,
			sending_time_type,
			trigger_settings: {
				selector: triggerFormat,
				time: triggerTime,
			},
		}

		if (isTrigger) {
			data.trigger_type_code = findTriggerOfLabel(triggerOption)?.code || ""
		}

		if (entity === Entities.Spec && specialistId) {
			data.trigger_settings.id = specialistId
			data.trigger_settings.deeplink = "SPECIALIST_INFO"
		}

		if (entity === Entities.Serv && servicesId) {
			data.trigger_settings.id = servicesId
			data.trigger_settings.deeplink = "SERVICE_INFO"
		}

		if (entity === Entities.Complex && complexId) {
			data.trigger_settings.id = complexId
			data.trigger_settings.deeplink = "COMPLEX_INFO"
		}

		if (entity === Entities.Promotion && promotionId) {
			data.trigger_settings.id = promotionId
			data.trigger_settings.deeplink = "PROMOTION"
		}

		const result = await fetchCreate(data)

		if (result && "data" in result) {
			MessageNoty.success("Рассылка созданна")
			navigate("/notifications")
		}
	}

	const isDate = timeSettings === "В установленное время"
	const isTrigger = timeSettings === "По триггеру"
	const selectTime =
		!!triggerOption?.length && triggerOption !== "Осталось до приема"
	const triggerOptions = triggerData?.data.map(trigger => ({
		value: trigger.id,
		label: trigger.name,
	}))

	const disabled = () => {
		const errorsinValid = !!Object.keys(errors)?.length
		if (timeSettings === "В установленное время") {
			return errorsinValid || !selectedDay || !selectedTime
		} else {
			if (triggerOption !== "Осталось до приема") {
				return errorsinValid || !!!triggerTime || !!!triggerFormat
			} else {
				return errorsinValid || !!!triggerFormat
			}
		}
	}

	useLayoutEffect(() => {
		if (mailingData?.data) {
			setTimeSettings(
				pushSelector[mailingData.data.sending_time_type] as TimeSettings
			)

			if (mailingData.data?.message && mailingData.data?.subject) {
				setValue("name", mailingData.data?.subject)
				setValue("message", mailingData.data?.message)
			}

			if (
				mailingData?.data?.sending_time?.length &&
				mailingData?.data?.sending_time?.length
			) {
				setSelectedDay(mailingData?.data?.sending_time.split(" ")[0])
				setSelectedTime(mailingData?.data?.sending_time.split(" ")[1])
			}

			if (mailingData.data?.trigger_settings) {
				const { selector, time } = mailingData.data.trigger_settings
				const currentFormat =
					selector === DateType.Minutes
						? DateType.Minutes
						: selector === DateType.Days
						? DateType.Days
						: DateType.Hource
				setTrigerFormat(currentFormat)
				setTriggerTime(time)
			}

			if (mailingData.data?.trigger_type_code) {
				const currentTriggerOption =
					triggerData?.data?.find(
						opt => opt.code === mailingData.data?.trigger_type_code
					)?.name || ""

				setTriggerOptions(currentTriggerOption)
			}

			if (mailingData?.data?.trigger_settings?.deeplink === "SERVICE_INFO") {
				setEntity(Entities.Serv)
				setServicesId(mailingData.data.trigger_settings.id)
			}

			if (mailingData?.data?.trigger_settings?.deeplink === "SPECIALIST_INFO") {
				setEntity(Entities.Spec)
				setSpecialistId(mailingData.data.trigger_settings.id)
			}

			if (mailingData?.data?.trigger_settings?.deeplink === "COMPLEX_INFO") {
				setEntity(Entities.Complex)
				setComplexId(mailingData.data.trigger_settings.id)
			}

			if (mailingData?.data?.trigger_settings?.deeplink === "PROMOTION") {
				setEntity(Entities.Promotion)
				setPromotionId(mailingData.data.trigger_settings.id)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mailingData, triggerData])

	useEffect(() => {
		setTriggerTime(validateDate(triggerTime, triggerFormat))
	}, [triggerFormat, triggerTime])

	return {
		isDate,
		errors,
		isLoading,
		isTrigger,
		selectTime,
		selectedDay,
		triggerTime,
		selectedTime,
		timeSettings,
		triggerFormat,
		triggerOption,
		triggerOptions,
		currentMailing,
		entity,
		specialistId,
		servicesId,
		specialists,
		services,
		specialistsOptions,
		servicesOptions,
		deeplinks,
		updateLodaing,
		createLodaing,
		complexId,
		complexesOptions,
		promotionOptions,
		promotionId,
		handlePromotionChange,
		handleComplexChange,
		disabled,
		register,
		onSubmit,
		handleSubmit,
		handleTimeChange,
		handleDateChange,
		handleChangeTrigger,
		handleChangeTriggerData,
		handleTimeSettigsChange,
		handleChangeTriggerFormat,
		handleEntiyChange,
		handleServChange,
		handleSpecChange,
	}
}
