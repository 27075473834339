import { useNavigate, useSearchParams } from "react-router-dom";
import { AllRoutes } from "../../constans/appRoutes";
import { useEffect, useState } from "react";
import {
  useDeleteMailingsMutation,
  useGetMailingsQuery,
} from "../../services/mailing";
import { message } from "antd";
import { ActionType } from "../../models/Mailings";

export const useNotifycationController = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const [action, setAction] = useState<ActionType | null>(null);
  const [deletedItem, setDeletedItem] = useState(0);
  const { data: mailingsData } = useGetMailingsQuery({});
  const [fetchDelete] = useDeleteMailingsMutation();

  const mailings = mailingsData?.data || [];

  const handleEdit = (action: ActionType, id?: number) => {
    setAction(action);

    const editeditempath = id ? `&id=${id.toString()}` : "";
    navigate(AllRoutes.NotifycationsCreate + action + editeditempath);
  };

  const startDelete = (id: number) => {
    setDeletedItem(id);
  };

  const endDelete = () => setDeletedItem(0);

  const handleDeelte = async () => {
    const result = await fetchDelete({ id: deletedItem });

    if (result && "data" in result) {
      setDeletedItem(0);
      message.success("Рассылка удалена");
    }
  };

  const breadCrumbsTitle = action === "edit" ? "Редактирование" : "Создание";

  const isAction = !!Object.keys(params).length;

  useEffect(() => {
    !isAction && setAction(null);
  }, [isAction]);

  return {
    action,
    mailings,
    deletedItem,
    breadCrumbsTitle,
    endDelete,
    handleEdit,
    startDelete,
    handleDeelte,
  };
};
