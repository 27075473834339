import { FC, memo } from "react"
import styles from "./DiscountPeriodCards.module.scss"
import {
	PeriodDiscountType,
	RangePeriodsDiscunt,
} from "../../useDiscountPageCreateAndEdit"
import DiscountPeriodCard from "../DiscountPeriodCard/DiscountPeriodCard"
import { DiscoutPeriods } from "../../../../services/discounts"

type Props = {
	periods: PeriodDiscountType[]
	changePeriod: (period: PeriodDiscountType) => void
	addPeriod: () => void
	deletePeriod: (period: PeriodDiscountType) => void
	discoutPeriodsOptions?: {
		label: string
		value: DiscoutPeriods
	}[]
	rangePeriods: RangePeriodsDiscunt | null
}

const DiscountPeriodCards: FC<Props> = ({
	periods,
	changePeriod,
	addPeriod,
	deletePeriod,
	discoutPeriodsOptions,
	rangePeriods,
}) => {
	return (
		<div className={styles.cards}>
			{periods.slice(0, 4).map((period, i) => {
				return (
					<DiscountPeriodCard
						key={period.period}
						discoutPeriodsOptions={discoutPeriodsOptions}
						changePeriod={changePeriod}
						addPeriod={addPeriod}
						deletePeriod={deletePeriod}
						period={period}
						isLast={i === periods.length - 1}
						isFirst={i === 0}
						hiddenLastCardBtn={i === periods.length - 1 && periods.length === 4}
						rangePeriods={rangePeriods}
					/>
				)
			})}
		</div>
	)
}

export default memo(DiscountPeriodCards)
