import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

type ResponseSpecType = {
  data: { id: number; name: string };
  message: string;
};

type RequestSpecializationsTypeParams = {
  specialization_id: number;
};

type RequestCreateSpecializationTypeParams = {
  external_id: string;
  name: string;
};

type ResponseDeleteSpecializationType = {
  message: string;
  data: string[];
};

export type SpecializationType = {
  id: number;
  name: string;
};

type ResponseEditSpecializationType = {
  message: string;
  data: SpecializationType;
};

export const specializationItems = createApi({
  reducerPath: "adminSpecialization",
  baseQuery,
  tagTypes: ["Specialization"],
  endpoints: (build) => ({
    getSpecialization: build.query<ResponseSpecType, RequestSpecializationsTypeParams>({
      query: ({ specialization_id }: RequestSpecializationsTypeParams) => ({
        url: `/admin/specializations/${specialization_id}`,
      }),
      providesTags: (result) => (result ? [{ type: "Specialization", id: "LIST" }] : [{ type: "Specialization", id: "LIST" }]),
    }),
    getSpecializations: build.query<any, string>({
      query: (search: string) => ({
        url: `/admin/specializations${search !== "" ? `?search=${search}` : ""}`,
      }),
      providesTags: (result) =>
        result && result.length
          ? [...result.map(({ id }: { id: number }) => ({ type: "Specialization" as const, id })), { type: "Specialization", id: "LIST" }]
          : [{ type: "Specialization", id: "LIST" }],
    }),
    deleteSpecialization: build.mutation<ResponseDeleteSpecializationType, RequestSpecializationsTypeParams>({
      query: ({ specialization_id }: RequestSpecializationsTypeParams) => ({
        url: `/admin/specializations${specialization_id ? `/${specialization_id}` : ""}`,
        method: "DELETE",
        specialization_id,
      }),
      invalidatesTags: [{ type: "Specialization", id: "LIST" }],
    }),
    createSpecialization: build.mutation<ResponseEditSpecializationType, RequestCreateSpecializationTypeParams>({
      query: ({ external_id, name }: RequestCreateSpecializationTypeParams) => ({
        url: `/admin/specializations`,
        method: "POST",
        body: { external_id, name },
      }),
      invalidatesTags: [{ type: "Specialization", id: "LIST" }],
    }),
    editSpecialization: build.mutation<ResponseEditSpecializationType, SpecializationType>({
      query: ({ id, name }: SpecializationType) => ({
        url: `/admin/specializations/${id}`,
        method: "PUT",
        body: { name },
      }),
      invalidatesTags: [{ type: "Specialization", id: "LIST" }],
    }),
  }),
});

export const {
  useDeleteSpecializationMutation,
  useGetSpecializationQuery,
  useGetSpecializationsQuery,
  useCreateSpecializationMutation,
  useEditSpecializationMutation,
} = specializationItems;
