import { useLayoutEffect, useState } from "react"
import { Loader } from "../common/loader"
import { loadFingerPrintId } from "../helpers/loadFingrPrintid"
import { useAppDispatch, useAppSelector } from "../hooks/redux"
import { login } from "../services/login"
import { signIn } from "../store/Slices/authSlice"
import AuthRoute from "./AuthRoute"
import MainRoute from "./MainRoute"

function App() {
	const [sendRefresh, { isLoading }] = login.useSendRefreshMutation()
	const dispacth = useAppDispatch()
	const { isAuth } = useAppSelector(state => state.auth)
	const [ready, setRedy] = useState(false)

	const isLogin = async () => {
		const fingerprint = await loadFingerPrintId()

		if (fingerprint) {
			const response = await sendRefresh({ fingerprint })

			if (response && "data" in response) {
				const { user, access_token } = response.data.data

				dispacth(signIn(access_token, user))
				!isLoading && setRedy(true)
			} else {
				!isLoading && setRedy(true)
			}
		}
	}

	useLayoutEffect(() => {
		isLogin()
	}, [])

	if (ready) {
		return <>{!isAuth ? <AuthRoute /> : <MainRoute />}</>
	} else {
		return <Loader />
	}
}

export default App
