import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { ITriggerType } from "../models/TriggerTypes";

type ResponseGetTrigger = {
  data: ITriggerType[];
};

const endpoind = "admin/trigger_types";

export const triggerTYpeApi = createApi({
  reducerPath: "triggerTYpeApi",
  baseQuery,
  tagTypes: ["getItems", "getItem"],
  endpoints: (build) => ({
    getTriggers: build.query<ResponseGetTrigger, {}>({
      query: () => endpoind,
    }),
  }),
});

export const { useGetTriggersQuery } = triggerTYpeApi;
