import { ChangeEvent, useMemo, useState } from "react"
import { Layout } from "../../common/Layout"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { MainTable } from "../../common/MainTable"
import { AllRoutes } from "../../constans/appRoutes"
import { message } from "antd"
import { useDebounce } from "../../hooks/useDebaunce"
import {
	useCreateComplexPriceMutation,
	useDeleteComplexMutation,
	useEditComplexPriceMutation,
	useGetComplexQuery,
	useGetComplexesQuery,
} from "../../services/complexes"
import ComplexesPageCreate from "./ComplexesPageCreate"
import ComplexesPageEdit from "./ComplexesPageEdit"
import PriceCitiesComplexPage from "./PriceCitiesComplexPage"
import ComplexesFields from "./parts/ComplexesFields/ComplexesFields"

type Props = {}

const ComplexesPage = (props: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.complexId

	const isEdit = !!params?.edit
	const isCreate = !!params?.create
	const isShowPrices = params?.showPrices

	const isFieldCreate = !!params?.fieldCreate
	const isFieldEdit = !!params?.fieldEdit

	const [search, setSearch] = useState("")
	const debouncedSearch = useDebounce(search, 500)

	const columns = [
		{
			title: "Индентификатор",
			width: 2,
		},
		{
			title: "Название",
			width: 2,
		},
		{
			title: "",
			width: 1,
		},
	]

	const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
		setSearch(target.value)

	const { data, isLoading } = useGetComplexesQuery(debouncedSearch)
	const {
		data: currentComplexData,
		isLoading: loading2,
		isFetching: isFetchingCurrentComplex,
	} = useGetComplexQuery(
		{
			complex_id: +currentId,
		},
		{ skip: !currentId }
	)

	const [deleteComplex] = useDeleteComplexMutation()

	const [editComplexPrice] = useEditComplexPriceMutation()
	const [createComplexPrice] = useCreateComplexPriceMutation()

	const loading = useMemo(() => {
		return isLoading || loading2 || (isFetchingCurrentComplex && !!currentId)
	}, [isLoading, loading2, isFetchingCurrentComplex, currentId])

	const [complexIds, setComplexIds] = useState<number[]>([])

	const dataFilter = useMemo(() => {
		return data?.data.filter(it => !complexIds.includes(it.id))
	}, [data, complexIds])

	const onHandleDelete = (complex_id: number) => {
		deleteComplex({ complex_id })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Комплекс успешно удален")
					navigate(AllRoutes.Complexes)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить комплекс")
			})
	}

	const onHandleEditPrice = (
		id: number,
		price: number,
		old_price: number,
		discount_percent: number
	) => {
		editComplexPrice({ id, price, old_price, discount_percent })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Цена комплекса успешно изменен")
					navigate(
						AllRoutes.ComplexesEdit +
							`${currentId ? `${currentId}&showPrices=true` : ""}`
					)
				}
			})
			.catch(err => {
				message.error("Не удалось изменить комплекс")
			})
	}

	const onHandleCreatePrice = (
		price: number,
		old_price: number,
		discount_percent: number,
		service_combo_id: number,
		city_id: number
	) => {
		createComplexPrice({
			price,
			old_price,
			discount_percent,
			service_combo_id,
			city_id,
		})
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Цена комплекса успешно создана")
					navigate(
						AllRoutes.ComplexesEdit +
							`${currentId ? `${currentId}&showPrices=true` : ""}`
					)
				}
			})
			.catch(err => {
				message.error("Не удалось создать цену комплекса")
			})
	}

	const goEdit = (id?: number) => navigate(AllRoutes.ComplexesEdit + `${id}`)
	const goCreate = () => navigate(AllRoutes.ComplexesCreate)

	const prevUrls = [{ title: "Комплексы", path: "/complexes" }]
	const prevUrlsForShowPrices = [
		...prevUrls,
		{
			title: "Редактирование комплекса",
			path: `/complexes?edit=true&complexId=${currentId}`,
		},
	]

	const goToCreateField = () => {
		navigate(AllRoutes.ComplexesFieldCreate)
	}

	return (
		<Layout
			search={
				!isFieldCreate && !isFieldEdit && !isCreate && !isEdit
					? { value: search, onSearch: handleSearch }
					: undefined
			}
			secondCreateBtnTitle='Добавить папку'
			secondCreateBtn={
				!isFieldCreate && !isFieldEdit && !isCreate && !isEdit ? true : false
			}
			onClickSecondCreateBtn={goToCreateField}
			createBtn={
				!isFieldCreate && !isFieldEdit && !isEdit && !isCreate ? true : false
			}
			noData={isLoading ? false : !data}
			load={loading}
			onClickCreateBtn={goCreate}
			currentUrl={{
				path: `${useLocation().pathname}${useLocation().search}`,
				title: isCreate
					? "Создать комплекс"
					: isEdit && !isShowPrices
					? "Редактирование комплекса"
					: isShowPrices
					? "Установка цены в городах"
					: "",
			}}
			prevLinks={
				isCreate || (isEdit && !isShowPrices)
					? prevUrls
					: isShowPrices
					? prevUrlsForShowPrices
					: []
			}
		>
			{!isEdit &&
				!isCreate &&
				!isShowPrices &&
				!isFieldCreate &&
				!isFieldEdit && (
					<MainTable
						columns={columns}
						data={dataFilter || []}
						onDelete={onHandleDelete}
						onEdit={goEdit}
					/>
				)}
			{!isEdit && !isShowPrices && isCreate && <ComplexesPageCreate />}
			{isEdit && !isShowPrices && !isCreate && <ComplexesPageEdit />}

			<ComplexesFields complexes={data?.data} setComplexIds={setComplexIds} />

			{isShowPrices && (
				<PriceCitiesComplexPage
					onPriceEdit={onHandleEditPrice}
					onPriceCreate={onHandleCreatePrice}
					onPriceEditCancel={() =>
						navigate(
							AllRoutes.ComplexesEdit +
								`${currentId ? `${currentId}&showPrices=true` : ""}`
						)
					}
				/>
			)}
		</Layout>
	)
}

export default ComplexesPage
