import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import styles from "./ComplexesFieldEdite.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../../../common/Form/TextField"
import { Button } from "../../../../common/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import { message } from "antd"
import { TextArea } from "../../../../common/TextArea"
import { prepareData, sortFields } from "../../helpers"
import {
	IComplexField,
	EditeComplexField,
	useEditeComplexFieldMutation,
	useGetCompelxFieldQuery,
} from "../../../../services/complexes"

export type treeSelectorData = {
	title: string | React.ReactNode
	id: number
	parent_id?: number | null
	value: string
	children: treeSelectorData[]
}

type Props = {
	fieldsData: IComplexField[]
}

const ComplexesFieldEdite = ({ fieldsData }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.fieldId

	const {
		register,
		formState: { errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const dataForPrepare = useMemo(() => {
		if (fieldsData) {
			const sortedData = [...fieldsData]
			return prepareData(sortFields(sortedData as any))
		}
	}, [fieldsData])

	// не трогать так надо
	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (fieldsData) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [fieldsData, dataForPrepare])

	const [editeComplexField] = useEditeComplexFieldMutation()
	const { data: currentField } = useGetCompelxFieldQuery(currentId)

	const [description, setDescription] = useState(
		() => currentField?.data.description || ""
	)

	const name = watch("name")

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	const handleEditeField = async () => {
		const sendData: EditeComplexField = {
			body: {
				name,
				description,
				// parent_id: currentField?.data.parent_id,
			},
			id: currentId,
		}

		await editeComplexField(sendData)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Папка успешно изменена")
					navigate(AllRoutes.Complexes)
				}
			})
			.catch(err => {
				console.log(err)

				message.error("Не удалось изменить папку")
			})
	}

	useEffect(() => {
		setDescription(currentField?.data.description || "")
	}, [currentField])

	return (
		<div style={{ padding: "30px 0" }}>
			<div className={styles.halfContaienr}>
				<TextField
					defaultValue={currentField?.data.name}
					fieldname='name'
					register={register}
					options={{ required: "" }}
					error={errors.name?.message as string}
					palcehoder='Название'
					isMarginBottom={false}
				/>
				{/* <TextArea
					rows={9}
					draggable={false}
					placeholder='Описание*'
					required
					defaultValue={""}
					value={description}
					onChange={handleDescriptionOnChange}
				/> */}
			</div>
			<div className={styles.createServiceBtnWrapper}>
				<Button
					title='Сохранить'
					// disabled={description.length === 0 || !name}
					disabled={!name}
					onClick={handleEditeField}
				/>
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Complexes)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default ComplexesFieldEdite
