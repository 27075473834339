import { ChangeEvent, FC } from "react";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import styles from "./SearchField.module.scss";

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxWidth?: number | string;
}

const SearchField: FC<Props> = ({ value, onChange, maxWidth = 343 }) => {
  return (
    <div className={styles.container} style={{ maxWidth: maxWidth }}>
      <SearchIcon style={{ marginRight: 20 }} />
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={styles.input}
        placeholder="Поиск"
      />
    </div>
  );
};

export default SearchField;
