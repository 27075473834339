import React, { useEffect, useMemo } from "react"
import { TextField } from "../../common/Form/TextField"
import { useForm } from "react-hook-form"
import { Button } from "../../common/Button"
import styles from "../ServicesPage/ServicesPage.module.scss"
import {
	ResponseComplexPriceType,
	useGetCompelxPriceQuery,
} from "../../services/complexes"
import { useSearchParams } from "react-router-dom"

type Props = {
	onPriceEdit: (
		id: number,
		price: number,
		old_price: number,
		discount_percent: number
	) => void
	onPriceCreate: (
		price: number,
		old_price: number,
		discount_percent: number,
		city_id: number,
		service_combo_id: number
	) => void
	onPriceEditCancel: () => void
}

const PriceComplexCitiesEdit = ({
	onPriceEdit,
	onPriceCreate,
	onPriceEditCancel,
}: Props) => {
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.complexId
	const currentCityId = params?.cityId
	const currentPriceId = params?.priceId

	const { data: priceData } = useGetCompelxPriceQuery(
		{ id: +currentPriceId },
		{ skip: !currentPriceId }
	)

	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const price = watch("price")
	const oldPrice = watch("oldPrice")
	const discount = watch("discount")

	const priceNumber = useMemo(() => {
		return price ? price : priceData?.data?.price.toString()
	}, [price, priceData])

	const oldPriceNumber = useMemo(() => {
		return oldPrice ? oldPrice : priceData?.data?.old_price.toString()
	}, [oldPrice, priceData])

	useEffect(() => {
		if (oldPriceNumber && priceNumber) {
			setValue(
				"discount",
				Math.round(((+oldPriceNumber - +priceNumber) * 100) / +oldPriceNumber)
			)
		}
	}, [priceNumber, oldPriceNumber, setValue])

	useEffect(() => {
		if (!!priceData?.data?.discount_percent) {
			setValue("discount", priceData?.data?.discount_percent)
		}
	}, [priceData, setValue])

	return (
		<div>
			<div style={{ maxWidth: 555 }}>
				<TextField
					fieldname='price'
					register={register}
					options={{ required: "" }}
					error={errors.price?.message as string}
					palcehoder='Цена'
					isTopDescr
					isMarginBottom={true}
					defaultValue={priceData?.data?.price?.toString() ?? ""}
				/>
				<TextField
					fieldname='oldPrice'
					register={register}
					options={{ required: "" }}
					error={errors.oldPrice?.message as string}
					palcehoder='Старая цена'
					isTopDescr
					isMarginBottom={true}
					defaultValue={priceData?.data?.old_price?.toString() ?? ""}
				/>
				<TextField
					fieldname='discount'
					register={register}
					options={{}}
					error={errors.discount?.message as string}
					palcehoder='Скидка в процентах'
					isTopDescr
					isMarginBottom={true}
					defaultValue={priceData?.data?.discount_percent?.toString() ?? ""}
				/>
				<div className={styles.createServiceBtnWrapper}>
					<Button
						title='Сохранить'
						disabled={false}
						onClick={() =>
							priceData?.data && !!currentPriceId
								? onPriceEdit(
										priceData?.data?.id ?? 0,
										price && price !== "" ? price : priceData?.data?.price,
										oldPrice && oldPrice !== ""
											? oldPrice
											: priceData?.data?.old_price,
										discount && discount !== ""
											? discount
											: priceData?.data?.discount_percent
								  )
								: price !== "" && oldPrice !== "" && discount !== ""
								? onPriceCreate(
										+price,
										+oldPrice,
										+discount,
										+currentId,
										+currentCityId
								  )
								: null
						}
					/>
					<Button
						title='Отмена'
						disabled={false}
						onClick={() => onPriceEditCancel()}
						isTransparent
					/>
				</div>
			</div>
		</div>
	)
}

export default PriceComplexCitiesEdit
