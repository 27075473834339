import { TreeSelect } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { CSSProperties, FC } from "react"
import styles from "./TreeSelect.module.scss"

type Props = {
	value?: any
	placeholder?: string
	treeData?: DefaultOptionType[]
	dropdownStyle?: CSSProperties
	treeDefaultExpandAll?: boolean
	allowClear?: boolean
	onChange: (value: any) => void
	style?: CSSProperties
}

const TreeSelectField: FC<Props> = ({
	value,
	treeData,
	allowClear,
	placeholder,
	dropdownStyle,
	treeDefaultExpandAll,
	onChange,
	style,
}) => {
	return (
		<div className={styles.treeSeelct}>
			{placeholder && <span className={styles.placeholder}>{placeholder}</span>}
			<TreeSelect
				style={{ width: "100%", ...style }}
				value={value}
				dropdownStyle={dropdownStyle}
				placeholder={placeholder}
				allowClear={allowClear}
				treeDefaultExpandAll={treeDefaultExpandAll}
				onChange={onChange}
				treeData={treeData}
			/>
		</div>
	)
}

export default TreeSelectField
