import {
	useContext,
	createContext,
	useMemo,
	FC,
	ReactElement,
	useEffect,
	useState,
	useCallback,
} from "react"
import TreeServiceFieldItems from "./TreeServiceFieldItems"
import { ReactComponent as GreyField } from "../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../constans/icons/greenField.svg"
import { ReactComponent as ServiceIcon } from "../../constans/icons/serviceIcon.svg"
import {
	useGetServicesFieldsQuery,
	useGetServicesQuery,
} from "../../services/services"
import { IServices, ServiceType } from "../../models/Specialist"
import { treeData } from "../ServicesPage/ServicesPage"
import TreeServiceItem from "./parts/TreeServiceItem/TreeServiceItem"
import {
	ResponseSpecialistType,
	useGetSpecialistQuery,
} from "../../services/specialist"
import { useNavigate, useSearchParams } from "react-router-dom"

export type SelectedServices = {
	service_id: number
	type: ServiceType
	required_service_id?: number | null
	req_service_description?: string | null
	id?: number
}

type IContext = {
	generatedData: () => any
	handleCancelSelectService: () => void
	saveHandler: () => void
	setSelectedServices: React.Dispatch<
		React.SetStateAction<
			{
				service_id: number
				type: ServiceType
			}[]
		>
	>
	specialistData: ResponseSpecialistType | undefined
	servicesData: { data: IServices[] } | undefined
	isCustomize: boolean
	selectedServices: SelectedServices[]
	selectAdditionalServiceModal: boolean
	setSelectAdditionalServiceModal: React.Dispatch<
		React.SetStateAction<boolean | boolean>
	>
	toggleSelectAdditionalServiceModal: (callback?: any) => void
	toggleDescriptionServiceModal: () => void
	descriptionModal: boolean
	setDescriptionModal: React.Dispatch<React.SetStateAction<boolean>>
	setService: React.Dispatch<React.SetStateAction<IServices | undefined>>
	service: IServices | undefined
	loadingContextSpesialist: boolean
	handleSelectServiceDescription: (serv: IServices) => void
	setSelectedFieldsServices: React.Dispatch<React.SetStateAction<number[]>>
}

const SpecialistContext = createContext<IContext>({} as IContext)

type AppProviderType = {
	children: ReactElement
}

export const SpecialistProvider: FC<AppProviderType> = ({ children }) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const currentId = useMemo(() => {
		return params?.id
	}, [params])

	const isEdit = !!params?.edit
	const isCreate = !!params?.create

	const isCustomize = !!params?.cusomize

	const { data: servicesData, isLoading } = useGetServicesQuery("")
	const { data: servicesFieldData, isLoading: loading2 } =
		useGetServicesFieldsQuery("")
	const {
		data: specialistData,
		isLoading: loading3,
		isFetching,
	} = useGetSpecialistQuery(
		{
			id: +currentId,
		},
		{ skip: !currentId }
	)

	const loadingContextSpesialist = useMemo(() => {
		return isLoading || loading2 || loading3 || (isFetching && !!currentId)
	}, [isLoading, loading2, loading3, isFetching, currentId])

	const [descriptionModal, setDescriptionModal] = useState<boolean>(false)

	const [selectedServices, setSelectedServices] = useState<SelectedServices[]>(
		[]
	)
	const [selectedFieldsServices, setSelectedFieldsServices] = useState<
		number[]
	>([])

	const [selectAdditionalServiceModal, setSelectAdditionalServiceModal] =
		useState<boolean>(false)

	const [service, setService] = useState<undefined | IServices>()

	const handleCancelSelectService = useCallback(() => {
		navigate(`/specialists?edit=true&id=${currentId}`)
	}, [currentId, navigate])

	const handleSelectServiceDescription = useCallback((serv: IServices) => {
		setService(serv)
		setDescriptionModal(true)
	}, [])

	const isCheckedField = (fieldId: number) =>
		!!selectedFieldsServices.find(id => id === fieldId)

	const isHalfFill = (ids: number[], currentId: number) => {
		const servicesOfFieldId = servicesData?.data.filter(
			serv => serv.service_field.id === currentId
		)
		const selectedServ = selectedServices.filter(
			serv =>
				serv.service_id ===
				servicesOfFieldId?.find(s => s.id === serv.service_id)?.id
		)

		if (isCheckedField(currentId)) {
			if (!selectedServ) {
				return false
			}

			const selectedFields = selectedFieldsServices.filter(
				fieldId => fieldId === ids.find(id => id === fieldId)
			)

			return (
				servicesOfFieldId?.length !== selectedServ?.length ||
				selectedFields?.length !== ids?.length
			)
		} else {
			return false
		}
	}

	const handleSelectAllField = (ids: number[], item: any) => {
		const currentId = item.id
		let parentIds: number[] = []

		const map = (item: any, isDelete?: boolean) => {
			const condition = isDelete
				? !selectedFieldsServices.find(fieldId => fieldId === item.parent?.id)
				: true
			if (item.parent && condition) {
				parentIds = [...parentIds, item.parent?.id]
				map(item.parent)
			}
		}

		if (!isCheckedField(currentId)) {
			map(item, true)
			setSelectedFieldsServices(prev => [
				...prev,
				currentId,
				...ids,
				...parentIds,
			])
			;[currentId, ...ids].forEach(id => {
				const services = servicesData?.data
					.filter(serv => serv.service_field.id === id)
					.map(serv => ({
						service_id: serv.id,
						type: "additionaly" as ServiceType,
					}))
				if (services) {
					setSelectedServices(prev => [...prev, ...services])
				}
			})
		} else {
			map(item)
			const curentIds = [...ids, currentId]
			curentIds.forEach(id => {
				const services = servicesData?.data
					.filter(serv => serv.service_field.id === id)
					.map(serv => ({
						service_id: serv.id,
						type: "additionaly" as ServiceType,
					}))
				if (services) {
					setSelectedServices(prev =>
						prev.filter(
							serv =>
								serv.service_id !==
								services.find(s => s.service_id === serv.service_id)?.service_id
						)
					)
				}
			})

			// eslint-disable-next-line array-callback-return
			const emptyFieldIdOfParent = parentIds.filter(id => {
				const serviceOfId = servicesData?.data?.find(
					serv => serv.service_field.id === id
				)
				const selectedServ = selectedServices.find(
					serv => serv.service_id === serviceOfId?.id
				)

				if (!selectedServ) {
					return id
				}
			})

			;[...curentIds, ...emptyFieldIdOfParent].forEach(id => {
				setSelectedFieldsServices(prev => prev.filter(f => f !== id))
			})
		}
	}

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}

			let ids: number[] = []

			const recurseId = (arr: any) => {
				if (arr) {
					arr.map((item: any) => {
						ids.push(item.id)
						if (item?.children) {
							recurseId(item?.children)
						}

						return ids
					})
				}

				return ids
			}

			const allIds: number[] =
				(!!item.children?.length && recurseId(item.children)) || []

			newItem.key = key
			newItem.icon = key?.length > 3 ? <GreyField /> : <GreenField />

			newItem.title = (
				<TreeServiceFieldItems
					half={isHalfFill(allIds, item?.id)}
					checked={isCheckedField(item.id)}
					ids={allIds}
					selectField={handleSelectAllField}
					item={item}
				/>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const prepareData = (array: any[]) => {
		const mapItems = new Map(array.map(item => [item.id, item]))

		array.forEach(item => {
			if (item.parent) {
				const parent = mapItems.get(item.parent_id)
				mapItems.set(item.parent_id, {
					...parent,
					children: [...(parent.children ?? []), mapItems.get(item.id)],
				})
			}
		})
		const tt = Object.values(Object.fromEntries(mapItems)).filter(
			(item: any) => item.parent_id === null
		)
		return addKey(tt, "0")
	}

	const dataForPrepare = () => {
		if (servicesFieldData?.data) {
			return prepareData(servicesFieldData.data)
		}
	}

	const findSelectedServcieOfServiceFieldId = (service: IServices) => {
		return !!selectedServices.find(serv => serv.service_id === service.id)
	}

	const generateData = (servicesData: IServices[]) => {
		const data: treeData[] = []
		dataForPrepare().forEach((item: any) => {
			data.push(item)
		})
		servicesData.forEach(service => {
			findParentField(service, data)
		})
		return data
	}

	const generatedData = () => {
		if (servicesData?.data) {
			return generateData(servicesData.data)
		}
		return []
	}

	const serviecOFFieldId = (fieldId: number) => {
		const services: any = servicesData?.data.filter(
			serv => serv.service_field.id === fieldId
		)
		const selectedServ = selectedServices.filter(
			s =>
				s.service_id ===
				services?.find((serv: any) => serv.id === s.service_id)?.id
		)
		return selectedServ
	}

	const onHandleSelectService = (service: {
		service_id: number
		type: ServiceType
	}) => {
		const { service_id, type } = service

		const currentField = servicesData?.data.find(
			serv => serv.id === service_id
		)?.service_field

		const item = (generatedData() as any).find(
			(item: any) => item.external_id === currentField?.name
		)

		const childrenIds = item?.children
			.filter((it: any) => it?.name)
			.filter(Boolean)
			.map((i: any) => i?.id)
			.flat()

		const servicesOfChildren = servicesData?.data.filter(
			serv =>
				serv.service_field.id ===
				childrenIds?.find((id: number) => id === serv.service_field.id)
		)

		const selectedServiceOfChildren = selectedServices.filter(
			serv =>
				serv.service_id ===
				servicesOfChildren?.find(s => s.id === serv.service_id)?.id
		)

		let parentId = currentField?.parent?.id
		const parentIds = currentField
			? parentId
				? [currentField?.id, parentId]
				: [currentField?.id]
			: []

		const isIncludeField = !!selectedFieldsServices.find(
			fieldId => fieldId === currentField?.id
		)

		const isIncludes = selectedServices.find(
			serv => serv.service_id === service_id
		)

		if (isIncludes && isIncludes.type === type) {
			if (
				currentField?.id &&
				serviecOFFieldId(currentField?.id).length === 1 &&
				!selectedServiceOfChildren?.length
			) {
				if (!serviecOFFieldId(parentId)?.length) {
					parentIds.forEach(fid => {
						setSelectedFieldsServices(prev => prev.filter(id => id !== fid))
					})
				} else {
					setSelectedFieldsServices(prev =>
						prev.filter(id => id !== currentField?.id)
					)
				}
			}

			setSelectedServices(prev =>
				prev.filter(serv => serv.service_id !== service_id)
			)
		} else if (isIncludes && isIncludes.type !== type) {
			setSelectedServices(prev =>
				prev.map(serv =>
					serv.service_id === service_id ? { ...serv, type } : serv
				)
			)
		} else {
			if (!isIncludeField && currentField?.id) {
				setSelectedFieldsServices(prev => [...prev, ...parentIds])
			}
			setSelectedServices(prev => [...prev, service])
		}
	}

	const findTypeOfServiceId = (id: number) => {
		return specialistData?.data?.services_specialists.find(
			serv => serv.service?.id === id
		)?.type
	}

	const findParentField = (service: IServices, dataArr: treeData[]) => {
		dataArr.forEach(item => {
			if (service.service_field.id === item.id && item.type === "field") {
				item.children?.push({
					title: (
						<TreeServiceItem
							checked={findSelectedServcieOfServiceFieldId(service)}
							service={service}
							onSelectService={onHandleSelectService}
							currentType={findTypeOfServiceId(service?.id)}
						/>
					),
					id: service.id,
					key: `${item.key}-${item.children?.length}`,
					children: [],
					order: item.order || null,
					icon: <ServiceIcon />,
				})
			}

			if (service.service_field.id !== item.id) {
				findParentField(service, item.children)
			}
		})
	}

	const initServiecFields = (fieldsIds: number[]) => {
		const data = generatedData()
		const arr: any[] = []
		const ids: number[] = []

		const looop = (items: any[]) => {
			items.forEach(i => {
				arr.push(i)

				if (i.children && i.name) {
					looop(i.children)
				}
			})
		}

		data.forEach(item => looop(item.children))
		const currentIds =
			arr.filter(
				(item: any) => item.id === fieldsIds.find(id => id === item.id)
			) || []

		const loopParent = (item: any) => {
			if (item.parent) {
				ids.push(item.parent.id)
				loopParent(item.parent)
			}
		}

		currentIds.forEach(itemData => {
			loopParent(itemData)
		})

		return [...fieldsIds, ...ids]
	}

	useEffect(() => {
		if (specialistData?.data) {
			const selectedServicesArr: SelectedServices[] = []
			specialistData.data.services_specialists?.forEach((service, index) => {
				selectedServicesArr.push({
					service_id: service.service.id,
					type: service.type,
					req_service_description: service.req_service_description,
					required_service_id: service.required_service_id,
					id: index,
				})
			})

			setSelectedServices(
				specialistData.data?.services_specialists.map(s => ({
					service_id: s?.service?.id,
					type: s.type,
					req_service_description: s.req_service_description,
					required_service_id: s.required_service_id,
				}))
			)
			const allFields = specialistData.data?.services_specialists
				.map(serv => serv.service.service_field.id)
				.flat()

			setSelectedFieldsServices(initServiecFields(allFields))
		}
	}, [specialistData?.data])

	const saveHandler = () => {
		if (isEdit) navigate(`/specialists?edit=true&id=${currentId}`)
		if (isCreate) navigate(`/specialists?create=true`)
	}

	const toggleSelectAdditionalServiceModal = useCallback((callback?: any) => {
		setSelectAdditionalServiceModal(prev => !prev)
		if (typeof callback === "function") !!callback && callback()
	}, [])

	const toggleDescriptionServiceModal = useCallback(() => {
		const findRequiredServices = selectedServices.find(
			it => it.service_id === service?.id
		)

		setDescriptionModal(prev => {
			if (prev && !findRequiredServices) {
				setSelectAdditionalServiceModal(true)
			}

			return !prev
		})
	}, [selectedServices, service])

	useEffect(() => {
		if (descriptionModal || selectAdditionalServiceModal) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "auto"
		}
	}, [descriptionModal, selectAdditionalServiceModal])

	const value = useMemo(() => {
		return {
			generatedData,
			handleCancelSelectService,
			saveHandler,
			setSelectedServices,
			setSelectAdditionalServiceModal,
			toggleSelectAdditionalServiceModal,
			setDescriptionModal,
			toggleDescriptionServiceModal,
			setService,
			handleSelectServiceDescription,
			setSelectedFieldsServices,
			specialistData,
			servicesData,
			isCustomize,
			selectedServices,
			selectAdditionalServiceModal,
			descriptionModal,
			service,
			loadingContextSpesialist,
		}
	}, [
		generatedData,
		handleCancelSelectService,
		setSelectAdditionalServiceModal,
		saveHandler,
		setSelectedServices,
		toggleSelectAdditionalServiceModal,
		setDescriptionModal,
		toggleDescriptionServiceModal,
		setService,
		handleSelectServiceDescription,
		setSelectedFieldsServices,
		specialistData,
		servicesData,
		isCustomize,
		selectedServices,
		selectAdditionalServiceModal,
		descriptionModal,
		service,
		loadingContextSpesialist,
	])

	return (
		<SpecialistContext.Provider value={value}>
			{children}
		</SpecialistContext.Provider>
	)
}

export const useSpecialistContext = () => {
	return useContext(SpecialistContext)
}
