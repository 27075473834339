import { FC } from "react"
import { SearchField } from "../../../../common/Form/SearchField"
import { ReactComponent as ArrowIcon } from "../../../../constans/icons/goArrow.svg"
import { ICity } from "../../../../models/City"
import styles from "./Cities.module.scss"

interface Props {
	data: ICity[]
	onSelect: (cityId: number) => void
}

const CitiesPage: FC<Props> = ({ data, onSelect }) => {
	return (
		<div style={{ width: "50%" }}>
			<SearchField maxWidth={539} value='' onChange={() => null} />

			<ul className={styles.cityList}>
				{data?.map(({ name, id }) => (
					<li onClick={onSelect.bind(null, id)} key={id}>
						<span>{name}</span>
						<ArrowIcon />
					</li>
				))}
			</ul>
		</div>
	)
}

export default CitiesPage
