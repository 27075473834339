import React, { memo, FC } from "react"
import styles from "./DiscountFields.module.scss"
import "./Tree.scss"
import { Spin, Tree } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import DeleteModal from "../DeleteModal/DeleteModal"
import DeleteComplexModal from "../DeleteDiscountModal/DeleteDiscountModal"
import DiscountFieldCreate from "../DiscountFieldCreate/DiscountFieldCreate"
import { useDiscountFields } from "./useDiscountFields.controller"
import DiscountFieldEdite from "../DiscountFieldEdite/DiscountFieldEdite"
import { IDiscount } from "../../../../services/discounts"

const { DirectoryTree } = Tree

const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: 20 }} spin />

type Props = {
	discounts: IDiscount[] | undefined
	setComplexIds: (arr: number[]) => void
}

const DiscountFields: FC<Props> = props => {
	const {
		isFieldCreate,
		isFieldEdit,
		deleteFieldId,
		isEdit,
		isCreate,
		del,
		generatedData,
		discountsFields,
		setDeleteFieldId,
		isLoading,
		setDel,
		onSelect,
	} = useDiscountFields(props)

	return (
		<div className={styles.fields}>
			{isFieldCreate && !isFieldEdit && <DiscountFieldCreate />}
			{!isFieldCreate && isFieldEdit && discountsFields && (
				<DiscountFieldEdite fieldsData={discountsFields?.data} />
			)}

			{!!deleteFieldId && (
				<DeleteModal
					deleteFieldId={deleteFieldId}
					setDeleteFieldId={setDeleteFieldId}
				/>
			)}

			{!!del && <DeleteComplexModal del={del} setDel={setDel} />}

			{!isFieldCreate && !isFieldEdit && !isEdit && !isCreate && (
				<>
					{isLoading ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Spin indicator={antIcon} />
						</div>
					) : generatedData.length ? (
						<DirectoryTree
							defaultExpandAll={false}
							onSelect={onSelect}
							treeData={generatedData}
							itemHeight={64}
							rootStyle={{
								background: "transparent",
								border: "0.5px solid #DADADA",
							}}
						/>
					) : (
						<></>
					)}
				</>
			)}
		</div>
	)
}

export default memo(DiscountFields)
