import { FC, memo, useCallback, useEffect, useMemo, useState } from "react"
import styles from "./MultiDatePicker.module.scss"
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/teal.css"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { ReactComponent as CalendarIcon } from "../../../../constans/icons/calendar.svg"

type Props = {
	selectedDate: number[]
	setSelectedDate: (value: any[]) => void
}

const weekDays = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"]

const months = [
	"Январь",
	"Февраль",
	"Март",
	"Апрель",
	"Май",
	"Июнь",
	"Июль",
	"Август",
	"Сентябрь",
	"Октябрь",
	"Ноябрь",
	"Декабрь",
]

const MultiDatePicker: FC<Props> = ({ selectedDate, setSelectedDate }) => {
	const onClear = () => {
		setSelectedDate([])
	}

	const onChangeDate = useCallback(
		(dates: any[]) => {
			setSelectedDate(dates.map(d => new Date(d)))
		},
		[setSelectedDate]
	)

	const [startDate, setStartDate] = useState<DateObject>()
	const [endDate, setEndDate] = useState<DateObject>()

	const minAndMaxDates = useMemo(() => {
		if (selectedDate.length) {
			// Преобразование строк в объекты Date
			const dateObjects = selectedDate.map(dateStr => +new Date(dateStr))

			// Находим минимальную и максимальную дату
			const minDate = new Date(Math.min.apply(null, dateObjects))
			const maxDate = new Date(Math.max.apply(null, dateObjects))

			return { minDate, maxDate }
		}
	}, [selectedDate])

	const generateDateArray = useCallback(() => {
		if (startDate && endDate) {
			const start = new Date(+startDate)
			const end = new Date(+endDate)

			const dates = []
			let currentDate = start

			while (currentDate <= end) {
				dates.push(currentDate.toISOString().split("T")[0])
				currentDate.setDate(currentDate.getDate() + 1)
			}

			onChangeDate(dates)
		}
	}, [endDate, onChangeDate, startDate])

	useEffect(() => {
		if (endDate && startDate) {
			generateDateArray()
		}
	}, [endDate, startDate, generateDateArray])

	return (
		<div>
			<p className={styles.title}>Выбор даты скидки</p>

			<div className={styles.inputs}>
				<div className={styles.inputWrapper}>
					<DatePicker
						placeholder='Начало скидки'
						value={minAndMaxDates?.minDate}
						onChange={setStartDate as any}
						className='teal'
						inputClass={styles.input}
						inputMode='input'
					/>

					<span className={styles.calendarIcon}>
						<CalendarIcon />
					</span>
				</div>

				<div className={styles.inputWrapper}>
					<DatePicker
						placeholder='Завершение скидки'
						value={minAndMaxDates?.maxDate}
						onChange={(date: any) => {
							setEndDate(date + 86400000)
						}}
						className='teal'
						inputClass={styles.input}
					/>

					<span className={styles.calendarIcon}>
						<CalendarIcon />
					</span>
				</div>
			</div>

			<div className={styles.pickerWrapper}>
				<Calendar
					multiple
					value={selectedDate}
					onChange={onChangeDate}
					className='teal'
					plugins={[
						<DatePanel
							header='Даты'
							sort='date'
							title='Даты'
							translate='yes'
						/>,
					]}
					showOtherDays
					months={months}
					weekDays={weekDays}
					highlightToday={false} // подсветка текущего дня
				>
					<div className={styles.btns}>
						<button className={styles.btn} onClick={onClear}>
							Сбросить
						</button>
					</div>
				</Calendar>
			</div>
		</div>
	)
}

export default memo(MultiDatePicker)
