import styles from "./ComplexesPage.module.scss";
import { useSearchParams } from "react-router-dom";
import { useGetCompelxPriceQuery, useGetCompelxPricesQuery } from "../../services/complexes";
import PriceComplexCitiesList from "./PriceComplexCitiesList";
import PriceComplexCitiesEdit from "./PriceComplexCitiesEdit";

type Props = {
  onPriceEdit: (id: number, price: number, old_price: number, discount_percent: number) => void;
  onPriceCreate: (price: number, old_price: number, discount_percent: number, city_id: number, service_combo_id: number) => void;
  onPriceEditCancel: () => void;
};

const PriceCitiesComplexPage = ({ onPriceEdit, onPriceCreate, onPriceEditCancel }: Props) => {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const currentId = params?.complexId;
  const isPriceEdit = params?.priceId;
  const isCreatePrice = params?.createPrice;

  const { data: pricesData } = useGetCompelxPricesQuery({ service_combo_id: +currentId });

  return (
    <div className={styles.priceCities__wrapper}>
      {pricesData && !isPriceEdit && !isCreatePrice && <PriceComplexCitiesList servicePricesData={pricesData} />}
      {(isPriceEdit || isCreatePrice) && (
        <PriceComplexCitiesEdit onPriceEdit={onPriceEdit} onPriceCreate={onPriceCreate} onPriceEditCancel={onPriceEditCancel} />
      )}
    </div>
  );
};

export default PriceCitiesComplexPage;
