import React from "react"
import styles from "./MainTable.module.scss"
import TableHeader from "./TableHeader"
import TableBody from "./TableBody"

export type ColumnType = {
	title: string
	width: number
}

type Props = {
	columns: Array<ColumnType>
	data: any[]
	onEdit: () => void
	onDelete: (id: number) => void
	isPerson?: boolean
	isReviews?: boolean
	isSpecialist?: boolean
}

const MainTable = ({
	columns,
	data,
	onDelete,
	onEdit,
	isPerson = false,
	isReviews = false,
	isSpecialist,
}: Props) => {
	return (
		<div className={styles.tableWrapper}>
			<TableHeader data={columns} />
			<TableBody
				data={data}
				onDelete={onDelete}
				onEdit={onEdit}
				columns={columns.length}
				isPerson={isPerson}
				isReviews={isReviews}
				isSpecialist={isSpecialist}
			/>
		</div>
	)
}

export default MainTable
