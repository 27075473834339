import { FC } from "react"
import { ReactComponent as DeleteIcon } from "./icons/delete.svg"
import styles from "./FileField.module.scss"
import { useFileFieldController } from "./FileField.controller"
import cn from "classnames"

interface Props {
	Icon: FC
	src?: string
	onChange: (file: File) => void
	onDelete: () => void
	title?: string
	btnText?: string
	classes?: {
		flexContainer?: string
	}
}

const FileField: FC<Props> = ({
	Icon,
	onChange,
	onDelete,
	classes,
	src,
	btnText = "Загрузить изображение",
	title = "Иконка каталога",
}) => {
	const { url, handleChange, handleDelete } = useFileFieldController(
		onChange,
		onDelete,
		src
	)
	return (
		<div className={styles.wrapper}>
			<span className={styles.iconTitle}>{title}</span>
			<div className={cn(styles.flexContainer, classes?.flexContainer)}>
				{!!url ? (
					<img src={url} alt='icon' className={styles.icon} />
				) : (
					<div className={styles.iconWrapper}>
						<Icon />
					</div>
				)}

				{!!url ? (
					<button
						type='button'
						className={styles.deleteBtn}
						onClick={handleDelete}
					>
						<DeleteIcon />
						<span className={styles.deleteTitle}>удалить изображение</span>
					</button>
				) : (
					<label htmlFor='file' className={styles.inputWrapper}>
						<input
							id='file'
							onChange={handleChange}
							name='file'
							type='file'
							className={styles.input}
						/>
						<div className={styles.plus} />
						<span className={styles.inputTitle}>{btnText}</span>
					</label>
				)}
			</div>
		</div>
	)
}

export default FileField
