import { FC } from "react";
import styles from "./Loader.module.scss";

const loader: FC = () => {
  return (
    <div className={styles.overlay}>
      <div className={styles.loader} />
    </div>
  );
};

export default loader;
