/* eslint-disable array-callback-return */
import { FC, useEffect, useMemo, useState } from "react"
import styles from "./SpecialistsPage.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../common/Form/TextField"
import { Button } from "../../common/Button"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { useCreateSpecialistMutation } from "../../services/specialist"
import JoditEditor, { Jodit } from "jodit-react"
import { message } from "antd"
import { fileApi } from "../../services/file"
import { FileField } from "../../common/Form/FileField"
import { ReactComponent as NoFileIcon } from "../../constans/icons/noFileIcon.svg"
import { ClinicType, useGetClinicItemsQuery } from "../../services/clinics"
import {
	SpecializationType,
	useGetSpecializationsQuery,
} from "../../services/specialization"
import { Modal } from "../../common/Modal"
import "./SpecialistsPage.scss"
import { buttons } from "../../constans/constans"
import { Select } from "../../common/Select"
import { useSpecialistContext } from "./SpecialistContext"
import CheckBox from "../../common/CheckBox/CheckBox"
import AdditionalServicesModal from "./parts/AdditionalServicesModal/AdditionalServicesModal"
import DescriptionServicesModal from "./parts/DescriptionServicesModal/DescriptionServicesModal"
import CustomizeServices from "./parts/CustomizeServices/CustomizeServices"
import cn from "classnames"
import { useAdditionalServicesModalContext } from "./parts/AdditionalServicesModal/AdditionalServicesModalContext"

const config = {
	readonly: false,
	addNewLineOnDBLClick: false,
	toolbar: true,
	spellcheck: true,
	language: "ru",
	toolbarAdaptive: false,
	showCharsCounter: false,
	showWordsCounter: false,
	showXPathInStatusbar: false,
	askBeforePasteHTML: true,
	askBeforePasteFromWord: true,
	buttons: buttons,
	uploader: {
		...Jodit.defaultOptions.uploader,
		insertImageAsBase64URI: true,
	},
	width: 1000,
	height: "100%",
	tabIndex: 1,
}

const SpecialistPageCreate: FC = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.id

	const currentUrl = `${useLocation().pathname}${useLocation().search}`

	const {
		register,
		formState: { errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const {
		selectedServices,
		isCustomize,
		setSelectedServices,
		setService,
		setSelectedFieldsServices,
	} = useSpecialistContext()
	const { setDescription: setDescriptionModal, setSelectedAdditionalService } =
		useAdditionalServicesModalContext()

	const { data: specializationsData } = useGetSpecializationsQuery("")
	const { data: clinicsData } = useGetClinicItemsQuery({})

	const [createSpecialist, { isLoading: createSpecialistLoading }] =
		useCreateSpecialistMutation()
	const [sendFile] = fileApi.useSendFileMutation()

	const selectSpecializationsData = useMemo(() => {
		const result: { value: number; label: string; disabled?: boolean }[] = []
		if (specializationsData?.data) {
			specializationsData.data.forEach((spec: SpecializationType) => {
				const obj = {
					value: spec.id,
					label: spec.name,
				}
				result.push(obj)
			})
		}
		return result
	}, [specializationsData?.data])

	const selectClinicsData = useMemo(() => {
		const result: { value: number; label: string; disabled?: boolean }[] = []
		if (clinicsData?.data) {
			clinicsData.data.forEach((clinic: ClinicType) => {
				const obj = {
					value: clinic.id,
					label: clinic.name,
				}
				result.push(obj)
			})
		}
		return result
	}, [clinicsData?.data])

	const surname: string = watch("surname")
	const name: string = watch("name")
	const patronymic: string = watch("patronymic")
	const phone: string = watch("phone")
	const workExperience: string = watch("workExperience")
	const normalServiceDuration: string = watch("normalServiceDuration")
	const [specializationId, setSpecializationId] = useState<number>(0)
	const [fileId, setFileId] = useState<number>(0)
	const [photoUrl, setPhotoUrl] = useState("")
	const [description, setDescription] = useState("")
	const [education, setEducation] = useState("")
	const [error, setError] = useState("")

	const [selectedClinicsId, setSelectedClinicsId] = useState<number[]>([])
	const [isUzi, setIsUzi] = useState(false)

	const validateSelectService = () => {
		const types = selectedServices.map(serv => serv.type).flat()
		const manyPrimary = types.filter(type => type === "primary").length > 1
		const manySecondary = types.filter(type => type === "primary").length > 1

		if (manyPrimary) {
			setError("Первичная услуга может быть только одна")
			return false
		}

		if (manySecondary) {
			setError("Вторичная услуга может быть только одна")
			return false
		}

		return true
	}

	const chackUzi = () => {
		setIsUzi(prev => !prev)
	}

	const handleSave = () => {
		const valid = validateSelectService()

		if (!valid) {
			return
		}

		if (
			surname !== "" &&
			name !== "" &&
			surname !== "" &&
			patronymic !== "" &&
			phone !== "" &&
			workExperience !== "" &&
			selectedServices.length &&
			description !== "" &&
			normalServiceDuration !== ""
		) {
			const sendData = {
				id: +currentId,
				surname: surname,
				name: name,
				patronymic: patronymic,
				phone: phone,
				work_experience: +workExperience,
				servicesId: selectedServices.map(item => ({
					...item,
				})),
				description,
				education,
				photo_id: fileId !== 0 && fileId ? fileId : null,
				clinics: selectedClinicsId,
				specialization_id: specializationId,
				ultra_sound: isUzi,
				normal_service_duration: +normalServiceDuration,
			}

			createSpecialist(sendData)
				.unwrap()
				.then(result => {
					if (result.message === "Success") {
						message.success("Специалист успешно редактирован")
						navigate(AllRoutes.Specialists)
					}
				})
				.catch(err => {
					message.error("Не удалось редактировать специалиста")
				})
		} else {
			message.error("Заполните все необходимые поля")
		}
	}

	function handleDescrChange(newContent: string) {
		setDescription(newContent)
	}

	function handleEducationChange(newContent: string) {
		setEducation(newContent)
	}

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data
			setPhotoUrl(url)
			setFileId(id)
		}
	}

	const hadnleDeleteIcon = () => setFileId(0)

	useEffect(() => {
		setSelectedServices([])
		setSelectedAdditionalService([])
		setDescriptionModal("")
		setService(undefined)
		setSelectedFieldsServices([])
	}, [])

	return (
		<>
			<AdditionalServicesModal />
			<DescriptionServicesModal />

			<div
				className={cn(styles.page, {
					[styles.hidden]: !isCustomize,
				})}
			>
				<CustomizeServices />
			</div>

			<div
				className={cn(styles.page, {
					[styles.hidden]: isCustomize,
				})}
			>
				<div className={styles.editSpecialistWrapper}>
					<div className={styles.fileField}>
						<FileField
							Icon={NoFileIcon}
							onChange={handleChangeIcon}
							onDelete={hadnleDeleteIcon}
							src={photoUrl}
						/>
					</div>
					<TextField
						fieldname='surname'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.surname?.message as string}
						palcehoder='Фамилия*'
					/>
					<TextField
						fieldname='name'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.name?.message as string}
						palcehoder='Имя*'
					/>
					<TextField
						fieldname='patronymic'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.patronymic?.message as string}
						palcehoder='Отчество*'
					/>
					<TextField
						fieldname='phone'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.phone?.message as string}
						palcehoder='Номер телефона*'
					/>
					<TextField
						fieldname='workExperience'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors.workExperience?.message as string}
						palcehoder='Опыт работы (в годах)*'
					/>
					<Select
						style={{ width: "100%", marginBottom: 28 }}
						value={specializationId}
						placeholder='Специализация'
						onChange={e => (e ? setSpecializationId(e) : null)}
						options={selectSpecializationsData}
					/>
					<TextField
						fieldname='normalServiceDuration'
						register={register}
						options={{ required: "Поле обязательно для заполнения" }}
						error={errors?.normalServiceDuration?.message as string}
						palcehoder='Средняя длительность приема*'
					/>
					<Button
						title='Настроить услуги'
						onClick={() => navigate(currentUrl + "&cusomize=true")}
						isGrey
						disabled={false}
						classes={{ btn: styles.clinicAddBtn }}
					/>
					<Select
						mode='multiple'
						optionFilterProp='label'
						allowClear
						style={{ width: "100%" }}
						placeholder='Клиники'
						value={selectedClinicsId}
						onChange={setSelectedClinicsId}
						options={selectClinicsData}
					/>
					<div className={styles.textEditor__wrapper}>
						<h3 className={styles.textEditor__title}>Описание*</h3>
						<JoditEditor
							value={description}
							config={config}
							onBlur={handleDescrChange}
							onChange={handleDescrChange}
						/>
					</div>
					{/* <div
						style={{ marginBottom: 28 }}
						className={styles.textEditor__wrapper}
					>
						<h3 className={styles.textEditor__title}>Образование*</h3>
						<JoditEditor
							value={education}
							config={config}
							onBlur={handleEducationChange}
							onChange={handleEducationChange}
						/>
					</div> */}
					<div
						onClick={chackUzi}
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: 30,
							cursor: "pointer",
						}}
					>
						<CheckBox
							style={{ marginRight: 10 }}
							checked={isUzi}
							onClick={chackUzi}
						/>
						<span>Специалист по направлению УЗИ</span>
					</div>

					<div className={styles.editSpecialistBtnWrapper}>
						{
							<Button
								title='Сохранить'
								loading={createSpecialistLoading}
								disabled={
									surname === "" ||
									name === "" ||
									surname === "" ||
									patronymic === "" ||
									phone === "" ||
									workExperience === "" ||
									!selectedServices.length ||
									description === ""
								}
								onClick={handleSave}
							/>
						}
						{
							<Button
								title='Отмена'
								disabled={false}
								onClick={() => navigate(AllRoutes.Specialists)}
								isTransparent
							/>
						}
					</div>

					<Modal
						visible={!!error}
						onClose={setError.bind(null, "")}
						title='Внимание'
						subTitle={error}
						successBtn={{ onClick: () => setError(""), text: "Понятно" }}
					/>
				</div>
			</div>
		</>
	)
}

export default SpecialistPageCreate
