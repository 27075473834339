import { FC } from "react"
import Banners from "./Banners"
import { Modal } from "../../../../common/Modal"
import CreateOrEditBanners from "./parts/CreateOrEditBanners"
import { Loader } from "../../../../common/loader"
import styles from "./Main.module.scss"
import { useMainContext } from "./Main.context"

const Main: FC = () => {
	const {
		load,
		action,
		bannersData,
		handleAction,
		modal,
		handleDelete,
		name,
		clearName,
	} = useMainContext()

	return (
		<div>
			{load && (
				<div className={styles.load}>
					<Loader />
				</div>
			)}
			{!!action && <CreateOrEditBanners action={action} />}
			{!action && (
				<>
					<Banners banners={bannersData?.data || []} onAction={handleAction} />

					<Modal
						visible={modal}
						onClose={clearName}
						title='Удалить'
						subTitle={`Вы уверены, что хотите удалить группу баннеров "${name}"?`}
						cancelBtn={{ text: "Удалить", onClick: handleDelete }}
						successBtn={{ text: "Отмена", onClick: clearName }}
					/>
				</>
			)}
		</div>
	)
}

export default Main
