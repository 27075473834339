import React, { ChangeEvent } from "react";
import styles from "./CatalogPage.module.scss";
import { Button } from "../../common/Button";
import { SearchField } from "../../common/Form/SearchField";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "../../constans/appRoutes";
import { ICity } from "../../models/City";

type Props = {
  cities: ICity[];
  search: string;
  onSearch: ({ target }: ChangeEvent<HTMLInputElement>) => void;
};

const CatalogCitiesList = ({ cities, onSearch, search }: Props) => {
  const navigate = useNavigate();

  const goCityCatalog = (id: number) => {
    navigate(AllRoutes.CatalogCity + id);
  };

  return (
    <div className={styles.citiesList}>
      <SearchField value={search} onChange={onSearch} maxWidth={750} />
      {cities.map((city) => (
        <Button
          key={`${city.name}-${city.id}`}
          title={
            <div className={styles.priceCities__text}>
              <span>{city.name}</span>
            </div>
          }
          disabled={false}
          classes={{ btn: styles.citiesBtn }}
          isTransparent
          onClick={() => goCityCatalog(city.id)}
        />
      ))}
    </div>
  );
};

export default CatalogCitiesList;
