import { IServices } from "./../models/Specialist"

export function sortChildrenForOrder(arr: IServices[]) {
	const newArray = [...arr]
	newArray.sort((a, b) => {
		if (a.order === null) return 1
		if (b.order === null) return -1
		return a.order - b.order
	})
	for (let obj of newArray) {
		if (obj.children && obj.children.length) {
			obj.children = sortChildrenForOrder(obj.children)
		}
	}

	return newArray
}

export const sortCatalogForOrder = (arr: any[]) => {
	const newArray = [...arr]
	newArray.sort((a, b) => {
		if (a.order === null) return 1
		if (b.order === null) return -1
		return a.order - b.order
	})

	return newArray
}
