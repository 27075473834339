import { createApi } from "@reduxjs/toolkit/query/react";
import { CatalogItemType, ICatalogItem } from "../models/CatalogItems";
import { baseQuery } from "./baseQuery";

type RequestGetItemType = {
  search?: string;
  parentId?: number;
  offset?: number;
  limit?: number;
};

type ResponseGetItemType = {
  data: ICatalogItem[];
};

type RequestCreateParamsType = {
  name: string;
  type: CatalogItemType;
  parent_id: number | null;
  service_id?: number | null;
  icon_id: number | null;
};

type RequestUpdateParamsType = {
  id: number;
  item: {
    name: string;
    type: CatalogItemType;
    parent_id: number | null;
    service_id?: number | null;
    icon_id: number | null;
  };
};

type ResponseMessageType = {
  message: "Success" | "Forbiden";
};

const endpoind = "admin/catalog-items";

export const admin = createApi({
  reducerPath: "admin",
  baseQuery,
  tagTypes: ["getItems", "getItem"],
  endpoints: (build) => ({
    getItems: build.query<ResponseGetItemType, RequestGetItemType>({
      query: (data: RequestGetItemType) => ({
        url: endpoind,
        params: data,
      }),
      providesTags: () => ["getItems"],
    }),
    getItemOfId: build.query<{ data: ICatalogItem }, { id: number }>({
      query: (data: { id: number }) => ({
        url: endpoind + `/${data.id}`,
      }),
      providesTags: () => ["getItem"],
    }),
    createItem: build.mutation<ResponseMessageType, RequestCreateParamsType>({
      query: (data: RequestCreateParamsType) => ({
        url: endpoind,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getItems"],
    }),
    updateItem: build.mutation<ResponseMessageType, RequestUpdateParamsType>({
      query: (data: RequestUpdateParamsType) => ({
        url: endpoind + `/${data.id}`,
        method: "PUT",
        body: data.item,
      }),
      invalidatesTags: ["getItems", "getItem"],
    }),
    deleteItem: build.mutation<ResponseMessageType, { id: number }>({
      query: (data: { id: number }) => ({
        url: endpoind + `/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getItems"],
    }),
  }),
});
