import React, { ChangeEvent, FC, useEffect, useMemo } from "react"
import styles from "./DescriptionServicesModal.module.scss"

import { Modal } from "../../../../common/Modal"
import { useSpecialistContext } from "../../SpecialistContext"
import { TextArea } from "../../../../common/TextArea"
import { useAdditionalServicesModalContext } from "../AdditionalServicesModal/AdditionalServicesModalContext"

type Props = {}

const DescriptionServicesModal: FC<Props> = () => {
	const {
		descriptionModal,
		toggleDescriptionServiceModal,
		selectedServices,
		service,
	} = useSpecialistContext()

	const findRequiredServices = useMemo(() => {
		return selectedServices.find(it => it.service_id === service?.id)
	}, [selectedServices, service])

	const { description, setDescription, saveHandlerDescriprion } =
		useAdditionalServicesModalContext()

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	const saveHandler = () => {
		saveHandlerDescriprion()
	}

	useEffect(() => {
		if (findRequiredServices) {
			setDescription(findRequiredServices.req_service_description as string)
		}
	}, [findRequiredServices, setDescription])

	return (
		<Modal
			styled={{ container: { width: "60vw" } }}
			visible={descriptionModal}
			onClose={toggleDescriptionServiceModal}
		>
			<div className={styles.descriptionModal__wrapper}>
				<span className={styles.topText}>
					*будет отображаться в мобильном приложении
				</span>
				<p className={styles.title}>Введите текст-пояснение</p>

				<TextArea
					rows={5}
					draggable={false}
					placeholder='Текст модального окна'
					required
					defaultValue={"XXXX"}
					value={description}
					onChange={handleDescriptionOnChange}
					classesNames={{ placeholder: styles.placeholder }}
					className={styles.input}
				/>
				<div className={styles.description_bottom}>
					<span onClick={toggleDescriptionServiceModal}>Отмена</span>

					<button disabled={!description?.length} onClick={saveHandler}>
						Сохранить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default DescriptionServicesModal
