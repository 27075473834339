import { RootState } from "./../store/index";
import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { ResponseLoginType } from "./login";
import { loadFingerPrintId } from "../helpers/loadFingrPrintid";
import { setAccessToken } from "../store/Slices/authSlice";

type ResponseRefreshType = {
  data: ResponseLoginType;
};

const endpoint = "auth/refresh-token";

const baseQueryWithToken = fetchBaseQuery({
  baseUrl: "/api/",
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.access_token;

    if (!!accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const baseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await baseQueryWithToken(args, api, extraOptions);

  if (result.error?.status === 401) {
    const fingerprint = await loadFingerPrintId();

    const refreshResult = (await baseQueryWithToken(
      {
        url: endpoint,
        body: {
          fingerprint,
        },
        method: "POST",
      },
      api,
      extraOptions
    )) as ResponseRefreshType;

    if (refreshResult?.data) {
      const { data } = refreshResult.data;

      if (data.access_token) {
        api.dispatch(setAccessToken(data.access_token));
      }
    }
  }

  return result;
};
