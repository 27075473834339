import { ReactComponent as GreyField } from "../../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../../constans/icons/greenField.svg"
import { ReactComponent as ServiceIcon } from "../../../constans/icons/serviceIcon.svg"
import { useEffect, useState } from "react"
import {
	useGetCompelxesFieldsQuery,
	useGetComplexesQuery,
} from "../../../services/complexes"
import { IComboService } from "../../../models/ComboServices"
import { treeData } from "../../../pages/ServicesPage/ServicesPage"
import { PropsSelectedServicesFilds } from "./SelectedComplexesFilds"
import SelectedComplexFieldItem from "../SelectedComplexFieldItem/SelectedComplexFieldItem"
import SelectedTreeComplexesItem from "../SelectedTreeComplexesItem/SelectedTreeComplexesItem"

export type SelectedComplexes = {
	complex_id: number
	combo_field_id: number | null
}

export const useSelectedComplexesFilds = ({
	setComplexesSelected,
	complexesSelected,
}: PropsSelectedServicesFilds) => {
	const { data: complexesData } = useGetComplexesQuery("")

	const { data: complexesFieldData } = useGetCompelxesFieldsQuery("")

	const [selectedFieldsComplexes, setSelectedFieldsComplexes] = useState<
		number[]
	>([])
	const [selectedComplexes, setSelectedComplexes] = useState<
		SelectedComplexes[]
	>([])

	const isCheckedField = (fieldId: number) =>
		!!selectedFieldsComplexes.find(id => id === fieldId)

	const isHalfFill = (ids: number[], currentId: number) => {
		const complexesOfFieldId = complexesData?.data.filter(
			complex => complex.combo_field_id === currentId
		)
		const selectedComplex = selectedComplexes.filter(
			comx =>
				comx.complex_id ===
				complexesOfFieldId?.find(c => c.id === comx.complex_id)?.id
		)

		if (isCheckedField(currentId)) {
			if (!selectedComplex) {
				return false
			}

			const selectedFields = selectedFieldsComplexes.filter(
				fieldId => fieldId === ids.find(id => id === fieldId)
			)

			return (
				complexesOfFieldId?.length !== selectedComplex?.length ||
				selectedFields?.length !== ids?.length
			)
		} else {
			return false
		}
	}

	const handleSelectAllField = (ids: number[], item: any) => {
		const currentId = item.id
		let parentIds: number[] = []

		const map = (item: any, isDelete?: boolean) => {
			const condition = isDelete
				? !selectedFieldsComplexes.find(fieldId => fieldId === item.parent?.id)
				: true
			if (item.parent && condition) {
				parentIds = [...parentIds, item.parent?.id]
				map(item.parent)
			}
		}

		if (!isCheckedField(currentId)) {
			map(item, true)
			setSelectedFieldsComplexes(prev => [
				...prev,
				currentId,
				...ids,
				...parentIds,
			])
			;[currentId, ...ids].forEach(id => {
				const complexes = complexesData?.data
					.filter(complex => complex.combo_field_id === id)
					.map(complex => ({
						complex_id: complex.id,
						combo_field_id: complex.combo_field_id,
					}))
				if (complexes) {
					setSelectedComplexes(prev => [...prev, ...complexes])
				}
			})
		} else {
			map(item)
			const curentIds = [...ids, currentId]
			curentIds.forEach(id => {
				const complexes = complexesData?.data
					.filter(complex => complex.combo_field_id === id)
					.map(complex => ({
						complex_id: complex.id,
					}))
				if (complexes) {
					setSelectedComplexes(prev =>
						prev.filter(
							complex =>
								complex.complex_id !==
								complexes.find(c => c.complex_id === complex.complex_id)
									?.complex_id
						)
					)
				}
			})

			// eslint-disable-next-line array-callback-return
			const emptyFieldIdOfParent = parentIds.filter(id => {
				const complexOfId = complexesData?.data?.find(
					complex => complex.combo_field_id === id
				)
				const selectedComx = selectedComplexes.find(
					comx => comx.complex_id === complexOfId?.id
				)

				if (!selectedComx) {
					return id
				}
			})

			;[...curentIds, ...emptyFieldIdOfParent].forEach(id => {
				setSelectedFieldsComplexes(prev => prev.filter(f => f !== id))
			})
		}
	}

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}

			let ids: number[] = []

			const recurseId = (arr: any) => {
				if (arr) {
					arr.map((item: any) => {
						ids.push(item.id)
						if (item?.children) {
							recurseId(item?.children)
						}

						return ids
					})
				}

				return ids
			}

			const allIds: number[] =
				(!!item.children?.length && recurseId(item.children)) || []

			newItem.key = key
			newItem.icon = key?.length > 3 ? <GreyField /> : <GreenField />

			newItem.title = (
				<SelectedComplexFieldItem
					half={isHalfFill(allIds, item?.id)}
					checked={isCheckedField(item.id)}
					ids={allIds}
					selectField={handleSelectAllField}
					item={item}
				/>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const prepareData = (array: any[]) => {
		const mapItems = new Map(array.map(item => [item.id, item]))

		array.forEach(item => {
			if (item.parent) {
				const parent = mapItems.get(item.parent_id)
				mapItems.set(item.parent_id, {
					...parent,
					children: [...(parent.children ?? []), mapItems.get(item.id)],
				})
			}
		})
		const tt = Object.values(Object.fromEntries(mapItems)).filter(
			(item: any) => item.parent_id === null
		)
		return addKey(tt, "0")
	}

	const dataForPrepare = () => {
		if (complexesFieldData?.data) {
			return prepareData(complexesFieldData.data)
		}
	}

	const findSelectedComplexOfComplexFieldId = (complex: IComboService) => {
		return !!selectedComplexes.find(comx => comx.complex_id === complex.id)
	}

	const complexOFFieldId = (fieldId: number) => {
		const complexes: any = complexesData?.data.filter(
			comx => comx.combo_field_id === fieldId
		)
		const selectedComx = selectedComplexes.filter(
			c =>
				c.complex_id ===
				complexes?.find((comx: any) => comx.id === c.complex_id)?.id
		)
		return selectedComx
	}

	const onHandleSelectComplex = (complex: SelectedComplexes) => {
		const { complex_id } = complex

		const currentField = complexesData?.data.find(
			comx => comx.id === complex_id
		)?.combo_field_id

		const item = (generatedComplexesData() as any).find(
			(item: any) => item.external_id === currentField
		)

		const childrenIds = item?.children
			.filter((it: any) => it?.name)
			.filter(Boolean)
			.map((i: any) => i?.id)
			.flat()

		const complexesOfChildren = complexesData?.data.filter(
			comx =>
				comx.combo_field_id ===
				childrenIds?.find((id: number) => id === comx.combo_field_id)
		)

		const selectedComplexOfChildren = selectedComplexes.filter(
			comx =>
				comx.complex_id ===
				complexesOfChildren?.find(c => c.id === comx.complex_id)?.id
		)
		const parentIds = currentField ? [currentField] : []

		const isIncludeField = !!selectedFieldsComplexes.find(
			fieldId => fieldId === currentField
		)

		const isIncludes = selectedComplexes.find(
			comx => comx.complex_id === complex_id
		)

		if (isIncludes) {
			if (
				currentField &&
				complexOFFieldId(currentField).length === 1 &&
				!selectedComplexOfChildren?.length
			) {
				if (!complexOFFieldId(currentField)?.length) {
					parentIds.forEach(fid => {
						setSelectedFieldsComplexes(prev => prev.filter(id => id !== fid))
					})
				} else {
					setSelectedFieldsComplexes(prev =>
						prev.filter(id => id !== currentField)
					)
				}
			}

			setSelectedComplexes(prev =>
				prev.filter(comx => comx.complex_id !== complex_id)
			)
		} else if (isIncludes) {
			setSelectedComplexes(prev => prev)
		} else {
			if (!isIncludeField && currentField) {
				setSelectedFieldsComplexes(prev => [...prev, ...parentIds])
			}
			setSelectedComplexes(prev => [...prev, complex])
		}
	}

	const findParentField = (complex: IComboService, dataArr: treeData[]) => {
		dataArr.forEach(item => {
			if (complex.combo_field_id === item.id && item.type === "field") {
				item.children?.push({
					title: (
						<SelectedTreeComplexesItem
							checked={findSelectedComplexOfComplexFieldId(complex)}
							complex={complex}
							onSelectComplex={onHandleSelectComplex}
						/>
					),
					id: complex.id,
					key: `${item.key}-${item.children?.length}`,
					children: [],
					order: item.order || null,
					icon: <ServiceIcon />,
				})
			}

			if (complex.combo_field_id !== item.id) {
				findParentField(complex, item.children)
			}
		})
	}

	const complexesWithoutFild = (
		complex: IComboService,
		dataArr: treeData[]
	) => {
		dataArr?.push({
			title: (
				<SelectedTreeComplexesItem
					checked={findSelectedComplexOfComplexFieldId(complex)}
					complex={complex}
					onSelectComplex={onHandleSelectComplex}
				/>
			),
			id: complex.id,
			key: `${complex.id}`,
			children: [],
			order: null,
			icon: <ServiceIcon />,
		})
	}

	const generateData = (complexesData: IComboService[]) => {
		const data: treeData[] = []

		dataForPrepare().forEach((item: any) => {
			data.push(item)
		})

		complexesData.forEach(complex => {
			findParentField(complex, data)
		})

		complexesData
			.filter(c => !c.combo_field_id)
			.forEach(complex => {
				complexesWithoutFild(complex, data)
			})
		return data
	}
	const generatedComplexesData = () => {
		if (complexesData?.data) {
			return generateData(complexesData.data)
		}
		return []
	}

	useEffect(() => {
		if (
			complexesSelected?.length &&
			!selectedComplexes.length &&
			!selectedFieldsComplexes.length
		) {
			setSelectedComplexes(complexesSelected)

			const allFields = complexesSelected
				.map(c => c.combo_field_id || -1)
				.flat()
			setSelectedFieldsComplexes(allFields)
		}
	}, [complexesSelected, selectedComplexes, selectedFieldsComplexes])

	useEffect(() => {
		setComplexesSelected(selectedComplexes)
	}, [selectedComplexes, setComplexesSelected])

	return { generatedComplexesData }
}
