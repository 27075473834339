import { ChangeEvent, useEffect, useMemo, useState } from "react";
import styles from "./EnrollmentsPage.module.scss";
import "./EnrollmentsPage.scss";
import { Layout } from "../../common/Layout";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  EnrollmentType,
  useGetEnrollmentsQuery,
} from "../../services/enrollments";
import EnrollmentTable from "./EnrollmentTable";
import { useDebounce } from "../../hooks/useDebaunce";
import { DatePicker } from "antd";
import { ClinicType, useGetClinicItemsQuery } from "../../services/clinics";
import { useForm } from "react-hook-form";
import { TextField } from "../../common/Form/TextField";
import { Dayjs } from "dayjs";
import EnrollmentView from "./EnrollmentView";
import { Select } from "../../common/Select";

type Props = {};

const selectStatusData = [
  { value: "planned", label: "Запланирована" },
  { value: "in_progress", label: "В процессе" },
  { value: "completed", label: "Выполнена" },
  { value: "cancel", label: "Отменена" },
];

const EnrollmentsPage = (props: Props) => {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const isEnrollmentView = !!params?.id;

  const [offset, setOffset] = useState<number>(0);
  const limit = 15;

  const { register, watch } = useForm({ mode: "onBlur" });

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 1000);

  const [list, setList] = useState<EnrollmentType[]>([]);
  const [filterByClinicId, setFilterByClinicId] = useState<number>();
  const [filterByStatus, setFilterByStatus] = useState<string>();
  const [filterByDate, setFilterByDate] = useState<string | null>();
  const minPrice = watch("minPrice");
  const maxPrice = watch("maxPrice");

  const generateStringForRequest = useMemo(() => {
    const result: string[] = [`?search=${debouncedSearch}`];
    let isChanged = false;
    if (filterByClinicId) {
      result.push(`clinic_id=${filterByClinicId}`);
      isChanged = true;
    }
    if (filterByStatus) {
      result.push(`status=${filterByStatus}`);
      isChanged = true;
    }
    if (filterByDate) {
      result.push(`start_at=${filterByDate}`);
      isChanged = true;
    }
    if (minPrice) {
      result.push(`total_price_from=${minPrice}`);
      isChanged = true;
    }
    if (maxPrice) {
      result.push(`total_price_to=${maxPrice}`);
      isChanged = true;
    }
    result.push(`offset=${isChanged ? 0 : offset}`);
    result.push(`limit=${limit}`);

    return result.join("&");
  }, [
    debouncedSearch,
    filterByClinicId,
    filterByStatus,
    filterByDate,
    minPrice,
    maxPrice,
    offset,
    limit,
  ]);

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);
  const handleDateChange = (date: Dayjs | null, dateString: string) => {
    setFilterByDate(dateString);
  };

  const { data: enrollmentsData, isLoading } = useGetEnrollmentsQuery(
    generateStringForRequest
  );
  const { data: clinicsData } = useGetClinicItemsQuery({});

  useEffect(() => {
    const isOffset = Number(
      generateStringForRequest
        .split("&")
        .find((item) => item.includes("offset"))
        ?.split("=")
        .find((item) => item !== "offset")
    );
    if (enrollmentsData?.data && !!!isOffset) {
      setList(enrollmentsData.data);
    }
    if (enrollmentsData?.data && !!isOffset) {
      setList((prev) => [...prev, ...enrollmentsData.data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentsData?.data]);

  const selectClinicsData = useMemo(() => {
    const result: { value: number; label: string; disabled?: boolean }[] = [];
    if (clinicsData?.data) {
      clinicsData.data.forEach((clinic: ClinicType) => {
        const obj = {
          value: clinic.id,
          label: clinic.name,
        };
        result.push(obj);
      });
    }
    return result;
  }, [clinicsData?.data]);

  return (
    <div className={styles.EnrollmentsPage_wrapper}>
      <Layout
        noData={
          isLoading ? false : !enrollmentsData?.data.length && !list.length
        }
        search={{ value: search, onSearch: handleSearch }}
        currentUrl={{
          path: `${useLocation().pathname}${useLocation().search}`,
          title: !isEnrollmentView ? "" : "Просмотр заявки",
        }}
        prevLinks={
          isEnrollmentView ? [{ path: "/enrollments", title: "Заявки" }] : []
        }
      >
        {!isEnrollmentView && (
          <>
            <div className={styles.EnrollmentsPage_filterBlock}>
              <div className={styles.EnrollmentsPage_filterBlock_item}>
                <h3 className={styles.EnrollmentsPage_filterBlock_subtitle}>
                  Клиника
                </h3>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Не выбрана"
                  value={filterByClinicId}
                  onChange={setFilterByClinicId}
                  options={selectClinicsData}
                />
              </div>
              <div className={styles.EnrollmentsPage_filterBlock_item}>
                <h3 className={styles.EnrollmentsPage_filterBlock_subtitle}>
                  Дата
                </h3>
                <DatePicker
                  size="large"
                  format={"YYYY-MM-DD"}
                  onChange={handleDateChange}
                  placeholder="Не выбрана"
                />
              </div>

              <div className={styles.EnrollmentsPage_filterBlock_item}>
                <h3 className={styles.EnrollmentsPage_filterBlock_subtitle}>
                  Цена
                </h3>
                <div className={styles.flexRow}>
                  <TextField
                    fieldname="minPrice"
                    register={register}
                    options={{ min: 0 }}
                    palcehoder="От"
                    type={"number"}
                    isMarginBottom={false}
                    isTopDescr={false}
                  />
                  <TextField
                    fieldname="maxPrice"
                    register={register}
                    options={{ min: 0 }}
                    palcehoder="До"
                    type={"number"}
                    isMarginBottom={false}
                    isTopDescr={false}
                  />
                </div>
              </div>
              <div className={styles.EnrollmentsPage_filterBlock_item}>
                <h3 className={styles.EnrollmentsPage_filterBlock_subtitle}>
                  Статус
                </h3>
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Не выбран"
                  value={filterByStatus}
                  onChange={setFilterByStatus}
                  options={selectStatusData}
                />
              </div>
            </div>
            <EnrollmentTable
              isLoading={isLoading}
              data={list}
              onNext={() => setOffset((prev) => prev + 15)}
            />
          </>
        )}
        {isEnrollmentView && <EnrollmentView />}
      </Layout>
    </div>
  );
};

export default EnrollmentsPage;
