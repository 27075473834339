import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { IMainSpecialist } from "../models/MainEntities";

type RequestGetSpecialists = {
  offset?: number;
  limit?: number;
  city_id: number;
};

type RequestCreateSpecialist = {
  order: number;
  specialist_id: number;
  city_id: number;
  photo_id: number;
};

interface ReqestUpdateSpecialist extends RequestCreateSpecialist {
  id: number;
}

const Endpoint = "admin/main-specialists";

export const mainSpecialistApi = createApi({
  reducerPath: "mainSpecialistApi",
  baseQuery,
  tagTypes: ["getSpecialists"],
  endpoints: (build) => ({
    getSpecialists: build.query<
      { data: IMainSpecialist[] },
      RequestGetSpecialists
    >({
      query: (params) => ({
        url: Endpoint,
        params,
      }),
      providesTags: ["getSpecialists"],
    }),
    getSpecialistOfId: build.query<{ data: IMainSpecialist }, { id: number }>({
      query: ({ id }) => ({
        url: Endpoint + `/${id}`,
      }),
      providesTags: ["getSpecialists"],
    }),
    createSpecialist: build.mutation<
      { data: IMainSpecialist },
      RequestCreateSpecialist
    >({
      query: (body) => ({
        url: Endpoint,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["getSpecialists"],
    }),
    updateSpecialist: build.mutation<
      { data: IMainSpecialist },
      ReqestUpdateSpecialist
    >({
      query: (body) => ({
        url: Endpoint + `/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: () => ["getSpecialists"],
    }),
    deleteSpecialist: build.mutation<{ data: IMainSpecialist }, { id: number }>(
      {
        query: ({ id }) => ({
          url: Endpoint + `/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: () => ["getSpecialists"],
      }
    ),
  }),
});

export const {
  useGetSpecialistsQuery,
  useGetSpecialistOfIdQuery,
  useCreateSpecialistMutation,
  useUpdateSpecialistMutation,
  useDeleteSpecialistMutation,
} = mainSpecialistApi;
