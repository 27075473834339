import { Select } from "antd"
import { SelectProps } from "antd/es/select"
import { CSSProperties, FC, memo } from "react"
import styles from "./Select.module.scss"
import classNames from "classnames"

interface Props extends SelectProps {
	placeholder?: string
	stylePlaceholder?: CSSProperties
	classes?: {
		placeholder?: string
	}
}

const SelectField: FC<Props> = ({
	placeholder,
	children,
	classes,
	stylePlaceholder,
	...rest
}) => {
	return (
		<div className={styles.select}>
			{placeholder && (
				<span
					style={stylePlaceholder}
					className={classNames(styles.placeholder, classes?.placeholder)}
				>
					{placeholder}
				</span>
			)}

			{!!children ? (
				<Select style={{ width: "100%", ...rest.style }} {...rest}>
					{children}
				</Select>
			) : (
				<Select
					{...rest}
					style={{ width: "100%", height: "auto", ...rest.style }}
				/>
			)}
		</div>
	)
}

export default memo(SelectField)
