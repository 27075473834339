import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./SpecialistsPage.module.scss";
import "./SpecialistsPage.scss";
import { TextField } from "../../common/Form/TextField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RequestEditReviewType, useEditSpecialistReviewMutation, useGetSpecialistReviewQuery } from "../../services/specialist";
import { FileField } from "../../common/Form/FileField";
import { ReactComponent as NoFileIcon } from "../../constans/icons/noFileIcon.svg";
import { fileApi } from "../../services/file";
import { Radio, RadioChangeEvent, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Button } from "../../common/Button";
import { AllRoutes } from "../../constans/appRoutes";

type Props = {};

const SpecialistReviewEdit = (props: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const currentId = params?.id;
  const currentReviewId = params?.editReview;

  const { data: currentReview } = useGetSpecialistReviewQuery({ reviewId: +currentReviewId });
  const [sendFile] = fileApi.useSendFileMutation();
  const [editReview] = useEditSpecialistReviewMutation();

  const {
    register,
    formState: { errors },
    watch,
  } = useForm({ mode: "onBlur" });

  const name = watch("name");
  const sign = watch("sign");
  const [fileId, setFileId] = useState<number>(0);
  const [photoUrl, setPhotoUrl] = useState("");
  const [summary, setSummary] = useState<number>(1);
  const [reviewBody, setReviewBody] = useState("");

  const handleSave = () => {
    if (currentReview?.data) {
      const savingData: RequestEditReviewType = {
        id: +currentReviewId,
        body: reviewBody,
        summary: summary,
      };
      if (fileId !== 0) {
        savingData.avatar_id = fileId;
      }
      if (name) {
        savingData.name = name;
      }
      if (sign) {
        savingData.sign = sign;
      }
      editReview(savingData)
        .unwrap()
        .then((result) => {
          if (result.message === "Success") {
            message.success("Отзыв успешно редактирован");
            navigate(AllRoutes.Specialists + `?edit=true&id=${currentId}&reviews=true`);
          }
        })
        .catch((err) => {
          message.error("Не удалось редактировать отзыв");
        });
    }
  };

  const handleChangeIcon = async (file: File) => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("is_public", "true");

    const response = await sendFile(formData);

    if (response && "data" in response) {
      const { id, url } = response.data.data;
      setPhotoUrl(url);
      setFileId(id);
    }
  };

  const hadnleDeleteIcon = () => setFileId(0);

  const onChangeRadio = (e: RadioChangeEvent) => {
    setSummary(e.target.value);
  };

  const handleBodyOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReviewBody(event.target.value);
  };

  useEffect(() => {
    if (currentReview?.data && currentReview?.data.avatar) {
      setPhotoUrl(currentReview.data.avatar.url);
    }

    if (currentReview?.data && currentReview?.data.summary) {
      setSummary(currentReview.data.summary);
    }

    if (currentReview?.data && currentReview?.data.body) {
      setReviewBody(currentReview.data.body);
    }
  }, [currentReview?.data]);

  return (
    <div className={styles.SpecialistReviewEdit_wrapper}>
      <TextField
        fieldname="name"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.name?.message as string}
        palcehoder="Имя*"
        defaultValue={currentReview?.data.name}
      />
      <TextField
        fieldname="sign"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.sign?.message as string}
        palcehoder="Подпись*"
        defaultValue={currentReview?.data.sign}
      />
      <div className={styles.fileFieldBlock}>
        <FileField Icon={NoFileIcon} onChange={handleChangeIcon} onDelete={hadnleDeleteIcon} src={photoUrl} title="Аватар" />
      </div>
      <Radio.Group onChange={onChangeRadio} value={summary} style={{ paddingLeft: 8 }}>
        <Radio value={1}>1</Radio>
        <Radio value={2}>2</Radio>
        <Radio value={3}>3</Radio>
        <Radio value={4}>4</Radio>
        <Radio value={5}>5</Radio>
      </Radio.Group>
      <div className={styles.textAreaBlock}>
        <h3 className={styles.textAreaTitle}>Текст*</h3>
        <TextArea rows={9} draggable={false} placeholder="Текст*" required value={reviewBody} onChange={handleBodyOnChange} />
      </div>
      <div className={styles.editSpecialistBtnWrapper}>
        {<Button title="Сохранить" disabled={false} onClick={handleSave} />}
        {
          <Button
            title="Отмена"
            disabled={false}
            onClick={() => navigate(AllRoutes.Specialists + `?edit=true&id=${currentId}&reviews=true`)}
            isTransparent
          />
        }
      </div>
    </div>
  );
};

export default SpecialistReviewEdit;
