import { FC, useEffect, useState } from "react";
import { ActionType, RulesType } from "../types";
import { TextField } from "../../../common/Form/TextField";
import { useForm } from "react-hook-form";
import Rules from "./Rules";
import { Button } from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import JoditEditor, { Jodit } from "jodit-react";
import styles from "../Prepare.module.scss";
import { buttons } from "../../../constans/constans";
import {
  useCreatePreparaionsMutation,
  useGetPreparaionOfIdQuery,
  useUpdatePreparaionsMutation,
} from "../../../services/prepare";
import { message } from "antd";

const config = {
  readonly: false,
  addNewLineOnDBLClick: false,
  toolbar: true,
  spellcheck: true,
  language: "ru",
  toolbarAdaptive: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  buttons,
  uploader: {
    ...Jodit.defaultOptions.uploader,
    insertImageAsBase64URI: true,
  },
  width: 1000,
  height: "100%",
  tabIndex: 1,
};

type Props = {
  action: ActionType;
  editedId: number;
};

const RULL = {
  id: 0,
  services: [0],
  services_combo: [0],
};
const PrepareEdit: FC<Props> = ({ action, editedId }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const [rules, setRules] = useState<RulesType[]>([RULL]);
  const [note, setNote] = useState("");

  const { data: preparationData } = useGetPreparaionOfIdQuery({ id: editedId });
  const [createPreparation, { isLoading: createLoad }] =
    useCreatePreparaionsMutation();
  const [updatePreparation, { isLoading: updateLoad }] =
    useUpdatePreparaionsMutation();

  const preparation = preparationData?.data || null;
  const name = watch("name") as string;
  const isLoading = createLoad || updateLoad;

  const emptyRules = rules.filter(
    (rull) => !rull.services?.length && !rull.services_combo?.length
  );

  const isOnceEmptyRull =
    rules?.length === 1 &&
    rules[0].services[0] === 0 &&
    rules[0].services_combo[0] === 0;

  const disabled =
    !name?.length ||
    !!emptyRules?.length ||
    !!isOnceEmptyRull ||
    !rules?.length ||
    !note?.trim()?.length;

  const handleChange = (
    rullId: number,
    servId?: { index: number; id: number },
    comboId?: { index: number; id: number }
  ) => {
    if (servId) {
      setRules((prev) =>
        prev.map((rull) =>
          rull.id === rullId
            ? {
                ...rull,
                services: rull.services.map((serv, i) =>
                  i === servId.index ? servId.id : serv
                ),
              }
            : rull
        )
      );
    }
    if (comboId) {
      setRules((prev) =>
        prev.map((rull) =>
          rull.id === rullId
            ? {
                ...rull,
                services_combo: rull.services_combo.map((serv, i) =>
                  i === comboId.index ? comboId.id : serv
                ),
              }
            : rull
        )
      );
    }
  };

  const addRullEntiy = (type: "serv" | "combo", rullId: number) => {
    type === "serv"
      ? setRules((prev) =>
          prev.map((rull) =>
            rull.id === rullId
              ? { ...rull, services: [...rull.services, 0] }
              : rull
          )
        )
      : setRules((prev) =>
          prev.map((rull) =>
            rull.id === rullId
              ? { ...rull, services_combo: [...rull.services_combo, 0] }
              : rull
          )
        );
  };

  const handleDeleteEntity = (
    type: "serv" | "combo",
    entiyId: number,
    rullId: number
  ) => {
    const currentRull = rules.find((rull) => rull.id === rullId);
    if (type === "serv") {
      if (currentRull && currentRull.services.length === 1) {
        setRules((prev) =>
          prev.map((rull) =>
            rull.id === rullId
              ? {
                  ...rull,
                  services: [0],
                }
              : rull
          )
        );
      } else {
        setRules((prev) =>
          prev.map((rull) =>
            rull.id === rullId
              ? {
                  ...rull,
                  services: rull.services.filter((id) => id !== entiyId),
                }
              : rull
          )
        );
      }
    }
    if (type === "combo") {
      if (currentRull && currentRull.services_combo.length === 1) {
        setRules((prev) =>
          prev.map((rull) =>
            rull.id === rullId
              ? {
                  ...rull,
                  services_combo: [0],
                }
              : rull
          )
        );
      } else {
        setRules((prev) =>
          prev.map((rull) =>
            rull.id === rullId
              ? {
                  ...rull,
                  services_combo: rull.services_combo.filter(
                    (id) => id !== entiyId
                  ),
                }
              : rull
          )
        );
      }
    }
  };

  const goBack = () => navigate(-1);

  const addRule = () =>
    setRules((prev) => [...prev, { ...RULL, id: prev.length }]);

  const deleteRulle = (id: number) =>
    setRules((prev) => prev.filter((rull) => rull.id !== id));

  const handleCreatePreparation = async () => {
    const data = {
      note,
      rules: rules.map(({ services, services_combo }) => ({
        services,
        services_combos: services_combo.filter(Boolean),
      })),
      title: name,
    };
    const response = await createPreparation(data);

    if (response && "data" in response) {
      message.success("Правила обновленны");
      goBack();
    }
  };

  const handleUpdatePreparation = async () => {
    const data = {
      id: preparation?.id,
      note,
      rules: rules.map(({ services, services_combo }) => ({
        services,
        services_combos: services_combo.filter(Boolean),
      })),
      title: name,
    };
    const response = await updatePreparation(data);

    if (response && "data" in response) {
      message.success("Правила обновленны");
      goBack();
    }
  };

  const handleChangeNote = (val: string) => setNote(val);

  const onSubmit = () =>
    action === "create" ? handleCreatePreparation() : handleUpdatePreparation();

  useEffect(() => {
    if (preparation && action === "edit") {
      setRules(
        preparation.preparation_rules.map(
          ({ services, services_combos }, i) => ({
            id: i,
            services: services.map((serv) => serv.id),
            services_combo: services_combos.map((serv) => serv.id),
          })
        )
      );
      setNote(preparation.note);
      setValue("name", preparation.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preparation, action]);

  return (
    <div>
      <TextField
        register={register}
        fieldname="name"
        required
        styled={{ container: styles.input }}
        palcehoder="Название"
        error={errors.name?.message as string}
        options={{ required: "Поле обязательно для заполнения" }}
      />

      {!!rules &&
        rules.map((rull, i) => (
          <Rules
            onChange={handleChange}
            addField={addRullEntiy}
            onDelete={handleDeleteEntity}
            deleteRulle={deleteRulle}
            key={rull.id}
            id={i}
            services={rull.services}
            comboServices={rull.services_combo}
          />
        ))}

      <Button
        plusSvg
        isGrey
        onClick={addRule}
        classes={{ btn: styles.addRuleBtn }}
        title="Добавить новое правило"
        disabled={false}
        isTransparent
      />

      <div className={styles.textEditor}>
        <span className={styles.title}>Примечание</span>
        <JoditEditor
          value={note}
          config={config}
          onBlur={handleChangeNote}
          onChange={handleChangeNote}
        />
      </div>
      <div className={styles.bottomBtns}>
        <Button
          onClick={onSubmit}
          title="Сохранить"
          loading={isLoading}
          disabled={disabled}
        />
        <Button
          isTransparent
          disabled={false}
          onClick={goBack}
          title="Отмена"
        />
      </div>
    </div>
  );
};

export default PrepareEdit;
