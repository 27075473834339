import { ChangeEvent, useEffect, useState } from "react";

export const useFileFieldController = (
  onChange: (file: File) => void,
  onDelete: () => void,
  src?: string
) => {
  const [url, setUrl] = useState("");

  const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const fileReder = new FileReader();

    if (!!target.files) {
      onChange(target.files[0]);
      fileReder.onload = ({ target }) => {
        if (target?.result && typeof target.result === "string") {
          setUrl(target?.result);
        }
      };

      fileReder.readAsDataURL(target.files[0]);
    }
  };

  const handleDelete = () => {
    setUrl("");
    onDelete();
  };

  useEffect(() => {
    !!src && setUrl(src);
  }, [src]);

  return {
    url,
    handleChange,
    handleDelete,
  };
};
