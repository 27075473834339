import { ChangeEvent, useState } from "react";
import { Layout } from "../../common/Layout";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MainTable } from "../../common/MainTable";
import { AllRoutes } from "../../constans/appRoutes";
import {
  useCreateSpecializationMutation,
  useDeleteSpecializationMutation,
  useEditSpecializationMutation,
  useGetSpecializationsQuery,
} from "../../services/specialization";
import { message } from "antd";
import SpecializationPageCreate from "./SpecializationPageCreate";
import SpecializationPageEdit from "./SpecializationPageEdit";
import { useDebounce } from "../../hooks/useDebaunce";

type Props = {};

const SpecializationPage = (props: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  const isEdit = !!params?.edit;
  const isCreate = !!params?.create;

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const columns = [
    {
      title: "Индентификатор",
      width: 2,
    },
    {
      title: "Название",
      width: 2,
    },
    {
      title: "",
      width: 1,
    },
  ];

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);

  const { data, isLoading } = useGetSpecializationsQuery(debouncedSearch);
  const [deleteSpec] = useDeleteSpecializationMutation();
  const [createSpec] = useCreateSpecializationMutation();
  const [editSpec] = useEditSpecializationMutation();

  const onHandleDelete = (specialization_id: number) => {
    deleteSpec({ specialization_id })
      .unwrap()
      .then((result) => {
        if (result.message === "Success") {
          message.success("Специализация успешно удалена");
          navigate(AllRoutes.Specialization);
        }
      })
      .catch((err) => {
        message.error("Не удалось удалить специализацию");
      });
  };

  const onHandleCreate = (name: string, external_id: string) => {
    createSpec({ external_id, name })
      .unwrap()
      .then((result) => {
        if (result.message === "Success") {
          message.success("Специализация успешно создана");
          navigate(AllRoutes.Specialization);
        }
      })
      .catch((err) => {
        message.error("Не удалось создать специализацию");
      });
  };

  const onHandleEdit = (id: number, name: string) => {
    editSpec({ id, name })
      .unwrap()
      .then((result) => {
        if (result.message === "Success") {
          message.success("Специализация успешно изменена");
          navigate(AllRoutes.Specialization);
        }
      })
      .catch((err) => {
        message.error("Не удалось изменить специализацию");
      });
  };

  const goEdit = (id?: number) =>
    navigate(AllRoutes.Specialization + `?edit=true&id=${id}`);
  const goCreate = () => navigate(AllRoutes.Specialization + `?create=true`);

  const prevUrls = [{ title: "Специализации", path: "/specializations" }];

  return (
    <Layout
      search={
        !isEdit && !isCreate
          ? { value: search, onSearch: handleSearch }
          : undefined
      }
      createBtn={!isEdit}
      noData={isLoading ? false : !data}
      onClickCreateBtn={goCreate}
      currentUrl={{
        path: `${useLocation().pathname}${useLocation().search}`,
        title: isCreate
          ? "Добавить специализацию"
          : isEdit
          ? "Редактирование специализации"
          : "",
      }}
      prevLinks={!isCreate && !isEdit ? [] : prevUrls}
    >
      {!isEdit && !isCreate && (
        <MainTable
          columns={columns}
          data={data?.data ? data?.data : []}
          onDelete={onHandleDelete}
          onEdit={goEdit}
        />
      )}
      {!isEdit && isCreate && (
        <SpecializationPageCreate onSubmit={onHandleCreate} />
      )}
      {isEdit && !isCreate && (
        <SpecializationPageEdit onSubmit={onHandleEdit} />
      )}
    </Layout>
  );
};

export default SpecializationPage;
