import ForwardDirectoryTree from "antd/es/tree/DirectoryTree"
import { FC, memo } from "react"
import {
	SelectedComplexes,
	useSelectedComplexesFilds,
} from "./useSelectedComplexesFilds"

export type PropsSelectedServicesFilds = {
	setComplexesSelected: (complexesSelected: SelectedComplexes[]) => void
	complexesSelected?: SelectedComplexes[]
}

const SelectedComplexesFilds: FC<PropsSelectedServicesFilds> = props => {
	const { generatedComplexesData } = useSelectedComplexesFilds(props)

	return (
		<ForwardDirectoryTree
			defaultExpandAll={false}
			treeData={generatedComplexesData()}
			itemHeight={64}
			rootStyle={{
				background: "transparent",
				border: "0.5px solid #DADADA",
				height: "100%",
			}}
		/>
	)
}

export default memo(SelectedComplexesFilds)
