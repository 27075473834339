import React from "react";
import styles from "./MainTable.module.scss";
import { ColumnType } from "./MainTable";

type Props = {
  data: Array<ColumnType>;
};

const TableHeader = ({ data }: Props) => {
  return (
    <div className={styles.tableHeaderWrapper}>
      {data?.map((item) => (
        <div
          style={{
            flex: item.width,
            textAlign: "start",
          }}
          className={styles.tableHeaderLabel}
          key={item.title}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default TableHeader;
