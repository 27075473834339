import moment from "moment"

export const tzMoment = (date: Date | string | number) => moment(date).utc()

const normalizeDate = (date: number) => (date <= 9 ? "0" + date : date)

export function dateFormat(sec: number, fullTime?: boolean) {
	const date = new Date(sec * 1000)
	const Year = date.getFullYear()
	const Month = date.getMonth()
	const Day = date.getDate()
	const Hourse = date.getHours()
	const Minutes = date.getMinutes()

	const simpleDate = `${Year}-${normalizeDate(Month + 1)}-${normalizeDate(Day)}`
	const fullDate = `${simpleDate} ${normalizeDate(Hourse)}:${normalizeDate(
		Minutes
	)}`

	return fullTime ? fullDate : simpleDate
}
