import React, { ChangeEvent, memo, useEffect, useState } from "react"
import styles from "./SelectCityDiscount.module.scss"
import { useLocation, useNavigate } from "react-router-dom"
import { ICity } from "../../../../models/City"
import { SearchField } from "../../../../common/Form/SearchField"
import { useGetCityItemsQuery } from "../../../../services/cities"
import { Button } from "../../../../common/Button"

const SelectCityDiscount = () => {
	const navigate = useNavigate()
	const currentUrl = `${useLocation().pathname}${useLocation().search}`

	const [cities, setCities] = useState<ICity[]>([])
	const [search, setSearch] = useState("")

	const { data: citiesData } = useGetCityItemsQuery({})

	const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
		setSearch(target.value)

	const toCityCreate = (cityId: number) => {
		navigate(currentUrl + `&cityId=${cityId}`)
	}

	useEffect(() => {
		if (citiesData?.data) {
			setCities(citiesData?.data)
		}
	}, [citiesData?.data])

	useEffect(() => {
		if (search !== "") {
			setCities(prev =>
				prev.filter(item =>
					item.name.toLowerCase().includes(search.toLowerCase())
				)
			)
		} else if (search === "" && citiesData?.data) {
			setCities(citiesData.data)
		}
	}, [search, citiesData?.data])

	return (
		<div className={styles.cities__list}>
			{<SearchField value={search} onChange={handleSearch} maxWidth={750} />}

			{!!cities.length &&
				cities.map((city: ICity) => {
					return (
						<Button
							key={`${city.name} ${city.id}`}
							title={
								<div className={styles.cities__text}>
									<span>{city.name}</span>
								</div>
							}
							disabled={false}
							classes={{ btn: styles.citiesBtn }}
							isTransparent
							onClick={() => toCityCreate(city.id)}
						/>
					)
				})}
		</div>
	)
}

export default memo(SelectCityDiscount)
