import {
	useContext,
	createContext,
	useMemo,
	FC,
	ReactElement,
	useState,
	useCallback,
	useEffect,
} from "react"
import {
	useGetServicesFieldsQuery,
	useGetServicesQuery,
} from "../../../../services/services"
import { treeData } from "../../../ServicesPage/ServicesPage"
import { ReactComponent as GreyField } from "../../../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../../../constans/icons/greenField.svg"
import { ReactComponent as ServiceIcon } from "../../../../constans/icons/serviceIcon.svg"
import TreeServiceFieldItems from "../../TreeServiceFieldItems"
import { ServiceType, IServices } from "../../../../models/Specialist"
import TreeServiceItem from "../TreeServiceItem/TreeServiceItem"
import { useGetSpecialistQuery } from "../../../../services/specialist"
import { useNavigate, useSearchParams } from "react-router-dom"
import { SelectedServices, useSpecialistContext } from "../../SpecialistContext"

type IContext = {
	generatedData: () => any
	handleCancel: (callback?: () => void) => void
	selectedAdditionalService: { service_id: number; type: ServiceType }[]
	description: string
	setDescription: React.Dispatch<React.SetStateAction<string>>
	saveHandlerDescriprion: () => void
	setSelectedAdditionalService: React.Dispatch<
		React.SetStateAction<SelectedServices[]>
	>
}

const AdditionalServicesModalContext = createContext<IContext>({} as IContext)

type AppProviderType = {
	children: ReactElement
}

export const AdditionalServicesModalProvider: FC<AppProviderType> = ({
	children,
}) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.id

	const {
		selectedServices,
		service,
		setDescriptionModal,
		setSelectedServices,
		selectAdditionalServiceModal,
	} = useSpecialistContext()

	const { data: servicesData } = useGetServicesQuery("")
	const { data: servicesFieldData } = useGetServicesFieldsQuery("")
	const { data: specialistData } = useGetSpecialistQuery({ id: +currentId })

	const [selectedAdditionalService, setSelectedAdditionalService] = useState<
		SelectedServices[]
	>([])

	const [selectedFields, setSelectedFields] = useState<number[]>([])

	const [description, setDescription] = useState<string>("")

	const generatedData = () => {
		if (servicesData?.data) {
			return generateData(servicesData.data)
		}
		return []
	}

	const saveHandlerDescriprion = useCallback(() => {
		// if (!findRequiredServices) {
		// 	navigate(`/specialists?edit=true&id=${currentId}`)
		// }

		const serv = selectedAdditionalService[0]

		const newServices = selectedServices.map(item => {
			if (item.service_id === service?.id && serv) {
				return {
					...item,
					required_service_id: serv?.service_id,
					req_service_description: description,
				}
			}
			return item
		})

		setSelectedServices(newServices)
		setDescriptionModal(false)
		setDescription("")
	}, [
		currentId,
		navigate,
		setDescription,
		setDescriptionModal,
		setSelectedServices,
		service,
		description,
		selectedServices,
		selectedAdditionalService,
	])

	const isCheckedField = (fieldId: number) =>
		!!selectedFields.find(id => id === fieldId)

	const isHalfFill = (ids: number[], currentId: number) => {
		const servicesOfFieldId = servicesData?.data.filter(
			serv => serv.service_field.id === currentId
		)
		const selectedServ = selectedAdditionalService.filter(
			serv =>
				serv.service_id ===
				servicesOfFieldId?.find(s => s.id === serv.service_id)?.id
		)

		if (isCheckedField(currentId)) {
			if (!selectedServ) {
				return false
			}

			const fields = selectedFields.filter(
				fieldId => fieldId === ids.find(id => id === fieldId)
			)

			return (
				servicesOfFieldId?.length !== selectedServ?.length ||
				fields?.length !== ids?.length
			)
		} else {
			return false
		}
	}

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}

			let ids: number[] = []

			const recurseId = (arr: any) => {
				if (arr) {
					arr.map((item: any) => {
						ids.push(item.id)
						if (item?.children) {
							recurseId(item?.children)
						}

						return ids
					})
				}

				return ids
			}

			const allIds: number[] =
				(!!item.children?.length && recurseId(item.children)) || []

			newItem.key = key
			newItem.icon = key?.length > 3 ? <GreyField /> : <GreenField />

			newItem.title = (
				<TreeServiceFieldItems
					half={isHalfFill(allIds, item?.id)}
					checked={isCheckedField(item.id)}
					ids={allIds}
					showCheckBox={true}
					item={item}
				/>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const prepareData = (array: any[]) => {
		const mapItems = new Map(array.map(item => [item.id, item]))

		array.forEach(item => {
			if (item.parent) {
				const parent = mapItems.get(item.parent_id)
				mapItems.set(item.parent_id, {
					...parent,
					children: [...(parent.children ?? []), mapItems.get(item.id)],
				})
			}
		})
		const tt = Object.values(Object.fromEntries(mapItems)).filter(
			(item: any) => item.parent_id === null
		)
		return addKey(tt, "0")
	}

	const dataForPrepare = () => {
		if (servicesFieldData?.data) {
			return prepareData(servicesFieldData.data)
		}
	}

	const serviecOFFieldId = (fieldId: number) => {
		const services: any = servicesData?.data.filter(
			serv => serv.service_field.id === fieldId
		)
		const selectedServ = selectedAdditionalService.filter(
			s =>
				s.service_id ===
				services?.find((serv: any) => serv.id === s.service_id)?.id
		)
		return selectedServ
	}

	const findSelectedServcieOfServiceFieldId = (service: IServices) => {
		return !!selectedAdditionalService.find(
			serv => serv.service_id === service.id
		)
	}

	const findTypeOfServiceId = (id: number) => {
		return specialistData?.data?.services_specialists.find(
			serv => serv.service?.id === id
		)?.type
	}

	const onHandleSelectService = (service: {
		service_id: number
		type: ServiceType
	}) => {
		const { service_id, type } = service

		const currentField = servicesData?.data.find(
			serv => serv.id === service_id
		)?.service_field

		const item = (generatedData() as any).find(
			(item: any) => item.external_id === currentField?.name
		)

		const childrenIds = item?.children
			.filter((it: any) => it?.name)
			.filter(Boolean)
			.map((i: any) => i?.id)
			.flat()

		const servicesOfChildren = servicesData?.data.filter(
			serv =>
				serv.service_field.id ===
				childrenIds?.find((id: number) => id === serv.service_field.id)
		)

		const selectedServiceOfChildren = selectedAdditionalService.filter(
			serv =>
				serv.service_id ===
				servicesOfChildren?.find(s => s.id === serv.service_id)?.id
		)

		let parentId = currentField?.parent?.id
		const parentIds = currentField
			? parentId
				? [currentField?.id, parentId]
				: [currentField?.id]
			: []

		const isIncludeField = !!selectedFields.find(
			fieldId => fieldId === currentField?.id
		)

		const isIncludes = selectedAdditionalService.find(
			serv => serv.service_id === service_id
		)

		if (isIncludes && isIncludes.type === type) {
			if (
				currentField?.id &&
				serviecOFFieldId(currentField?.id)?.length === 1 &&
				!selectedServiceOfChildren?.length
			) {
				if (!serviecOFFieldId(parentId)?.length) {
					parentIds.forEach(fid => {
						setSelectedFields(prev => prev.filter(id => id !== fid))
					})
				} else {
					setSelectedFields(prev => prev.filter(id => id !== currentField?.id))
				}
			}

			setSelectedAdditionalService(prev =>
				prev.filter(serv => serv.service_id !== service_id)
			)
		} else if (isIncludes && isIncludes.type !== type) {
			setSelectedAdditionalService(prev =>
				prev.map(serv =>
					serv.service_id === service_id ? { ...serv, type } : serv
				)
			)
		} else {
			if (!isIncludeField && currentField?.id) {
				setSelectedFields(prev => [...prev, ...parentIds])
			}
			setSelectedAdditionalService(prev => [...prev, service])
		}
	}

	const findParentField = (service: IServices, dataArr: treeData[]) => {
		dataArr.forEach(item => {
			if (service.service_field.id === item.id && item.type === "field") {
				item.children?.push({
					title: (
						<TreeServiceItem
							checked={findSelectedServcieOfServiceFieldId(service)}
							service={service}
							onSelectService={onHandleSelectService}
							currentType={findTypeOfServiceId(service?.id)}
							showAdditional={false}
							showDescription={false}
						/>
					),
					id: service.id,
					key: `${item.key}-${item.children?.length}`,
					children: [],
					order: item.order || null,
					icon: <ServiceIcon />,
				})
			}

			if (service.service_field.id !== item.id) {
				findParentField(service, item.children)
			}
		})
	}

	const generateData = (servicesData: IServices[]) => {
		const data: treeData[] = []
		dataForPrepare().forEach((item: any) => {
			data.push(item)
		})
		servicesData.forEach(service => {
			findParentField(service, data)
		})
		return data
	}

	const initServiecFields = (fieldsIds: number[]) => {
		const data = generatedData()
		const arr: any[] = []
		const ids: number[] = []

		const looop = (items: any[]) => {
			items.forEach(i => {
				arr.push(i)

				if (i.children && i.name) {
					looop(i.children)
				}
			})
		}

		data.forEach(item => looop(item.children))
		const currentIds =
			arr.filter(
				(item: any) => item.id === fieldsIds.find(id => id === item.id)
			) || []

		const loopParent = (item: any) => {
			if (item.parent) {
				ids.push(item.parent.id)
				loopParent(item.parent)
			}
		}

		currentIds.forEach(itemData => {
			loopParent(itemData)
		})

		return [...fieldsIds, ...ids]
	}

	const handleCancel = (callback?: () => void) => {
		setSelectedFields([])
		setSelectedAdditionalService([])
		callback && callback()
	}

	useEffect(() => {
		if (specialistData?.data) {
			const findRequiredServices = selectedServices.find(
				it => it.service_id === service?.id
			)
			if (service && !!findRequiredServices?.required_service_id) {
				setSelectedAdditionalService([
					{
						required_service_id: findRequiredServices?.service_id as number,
						service_id: findRequiredServices?.required_service_id as number,
						type: findRequiredServices?.type,
					},
				])
			}

			const allFields = specialistData.data?.services_specialists
				.filter(
					serv =>
						serv.required_service?.id ===
							findRequiredServices?.required_service_id &&
						findRequiredServices?.required_service_id
				)
				.map(serv => serv?.required_service?.service_field?.id)
				.flat()

			if (!!allFields.length) setSelectedFields(initServiecFields(allFields))
		}
	}, [
		specialistData?.data,
		service,
		selectAdditionalServiceModal,
		setSelectedAdditionalService,
		selectedServices,
	])

	const value = useMemo(() => {
		return {
			generatedData,
			handleCancel,
			setDescription,
			saveHandlerDescriprion,
			setSelectedAdditionalService,
			selectedAdditionalService,
			description,
		}
	}, [
		generatedData,
		handleCancel,
		setDescription,
		setSelectedAdditionalService,
		selectedAdditionalService,
		saveHandlerDescriprion,
		description,
	])

	return (
		<AdditionalServicesModalContext.Provider value={value}>
			{children}
		</AdditionalServicesModalContext.Provider>
	)
}

export const useAdditionalServicesModalContext = () => {
	return useContext(AdditionalServicesModalContext)
}
