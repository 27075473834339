import { FC } from "react";
import styles from "./LoginPage.module.scss";
import { ReactComponent as LogoIcon } from "./icons/logo.svg";
import { Button } from "../../common/Button";
import { TextField } from "../../common/Form/TextField";
import { useLoginPageController } from "./LoginPage.controller";
import { ReactComponent as UserIcon } from "./icons/Union.svg";
import { ReactComponent as PasswordIcon } from "./icons/pasword.svg";
import { formOptions } from "../../constans/formOptions";

const LoginPage: FC = () => {
  const {
    isValid,
    errors,
    isLoading,
    onSubmit,
    register,
    handleSubmit,
    goRecovery,
  } = useLoginPageController();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <LogoIcon />
          <span className={styles.topTitle}>Спектр диагностика</span>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            Icon={UserIcon}
            options={formOptions.email}
            error={errors.email?.message as string}
            palcehoder="email"
            fieldname="email"
            type="email"
            register={register}
          />
          <TextField
            Icon={PasswordIcon}
            palcehoder="пароль"
            fieldname="password"
            register={register}
            options={{
              required: "Поле обязательно для заполнения",
              minLength: {
                value: 8,
                message: "Мининмум 8 символов",
              },
            }}
            type="password"
            error={errors.password?.message as string}
          />
          <Button loading={isLoading} title="Войти" disabled={!isValid} />
        </form>
        <span onClick={goRecovery} className={styles.recoveryPassword}>
          Восстановить пароль
        </span>
      </div>
    </div>
  );
};

export default LoginPage;
