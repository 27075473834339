import { ReactComponent as GreyField } from "../../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../../constans/icons/greenField.svg"
import { ReactComponent as ServiceIcon } from "../../../constans/icons/serviceIcon.svg"
import { ReactNode, useEffect, useState } from "react"
import {
	useGetServicesFieldsQuery,
	useGetServicesQuery,
} from "../../../services/services"
import { IServices, ServiceType } from "../../../models/Specialist"
import { PropsSelectedServicesFilds } from "./SelectedServicesFilds"
import SelectedServiceFieldItem from "../SelectedServiceFieldItem/SelectedServiceFieldItem"
import SelectedTreeServiceItem from "../SelectedTreeServiceItem/SelectedTreeServiceItem"

type treeData = {
	title: string | React.ReactNode
	id: number
	parent_id?: number | null
	key: string
	children: treeData[]
	order: number | null
	icon: ReactNode
	type?: string
}

export type ServicesSelected = {
	service_id: number
	service_field: number
	type: ServiceType
}

export const useSelectedServicesFilds = ({
	setServicesSelected,
	servicesSelected,
}: PropsSelectedServicesFilds) => {
	const { data: servicesData } = useGetServicesQuery("")

	const { data: servicesFieldData } = useGetServicesFieldsQuery("")

	const [selectedFieldsServices, setSelectedFieldsServices] = useState<
		number[]
	>([])
	const [selectedServices, setSelectedServices] = useState<ServicesSelected[]>(
		[]
	)

	const isCheckedField = (fieldId: number) =>
		!!selectedFieldsServices.find(id => id === fieldId)

	const isHalfFill = (ids: number[], currentId: number) => {
		const servicesOfFieldId = servicesData?.data.filter(
			serv => serv.service_field.id === currentId
		)
		const selectedServ = selectedServices.filter(
			serv =>
				serv.service_id ===
				servicesOfFieldId?.find(s => s.id === serv.service_id)?.id
		)

		if (isCheckedField(currentId)) {
			if (!selectedServ) {
				return false
			}

			const selectedFields = selectedFieldsServices.filter(
				fieldId => fieldId === ids.find(id => id === fieldId)
			)

			return (
				servicesOfFieldId?.length !== selectedServ?.length ||
				selectedFields?.length !== ids?.length
			)
		} else {
			return false
		}
	}

	const handleSelectAllField = (ids: number[], item: any) => {
		const currentId = item.id
		let parentIds: number[] = []

		const map = (item: any, isDelete?: boolean) => {
			const condition = isDelete
				? !selectedFieldsServices.find(fieldId => fieldId === item.parent?.id)
				: true
			if (item.parent && condition) {
				parentIds = [...parentIds, item.parent?.id]
				map(item.parent)
			}
		}

		if (!isCheckedField(currentId)) {
			map(item, true)
			setSelectedFieldsServices(prev => [
				...prev,
				currentId,
				...ids,
				...parentIds,
			])
			;[currentId, ...ids]?.forEach(id => {
				const services = servicesData?.data
					.filter(serv => serv.service_field.id === id)
					.map(serv => ({
						service_id: serv.id,
						service_field: serv.service_field.id,
						type: "additionaly" as ServiceType,
					}))
				if (services) {
					setSelectedServices(prev => [...prev, ...services])
				}
			})
		} else {
			map(item)
			const curentIds = [...ids, currentId]
			curentIds?.forEach(id => {
				const services = servicesData?.data
					.filter(serv => serv.service_field.id === id)
					.map(serv => ({
						service_id: serv.id,
						type: "additionaly" as ServiceType,
					}))
				if (services) {
					setSelectedServices(prev =>
						prev.filter(
							serv =>
								serv.service_id !==
								services.find(s => s.service_id === serv.service_id)?.service_id
						)
					)
				}
			})

			// eslint-disable-next-line array-callback-return
			const emptyFieldIdOfParent = parentIds.filter(id => {
				const serviceOfId = servicesData?.data?.find(
					serv => serv.service_field.id === id
				)
				const selectedServ = selectedServices.find(
					serv => serv.service_id === serviceOfId?.id
				)

				if (!selectedServ) {
					return id
				}
			})

			;[...curentIds, ...emptyFieldIdOfParent]?.forEach(id => {
				setSelectedFieldsServices(prev => prev.filter(f => f !== id))
			})
		}
	}

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}

			let ids: number[] = []

			const recurseId = (arr: any) => {
				if (arr) {
					arr.map((item: any) => {
						ids.push(item.id)
						if (item?.children) {
							recurseId(item?.children)
						}

						return ids
					})
				}

				return ids
			}

			const allIds: number[] =
				(!!item.children?.length && recurseId(item.children)) || []

			newItem.key = key
			newItem.icon = key?.length > 3 ? <GreyField /> : <GreenField />

			newItem.title = (
				<SelectedServiceFieldItem
					half={isHalfFill(allIds, item?.id)}
					checked={isCheckedField(item.id)}
					ids={allIds}
					selectField={handleSelectAllField}
					item={item}
				/>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const prepareData = (array: any[]) => {
		const mapItems = new Map(array.map(item => [item.id, item]))

		array?.forEach(item => {
			if (item.parent) {
				const parent = mapItems.get(item.parent_id)
				mapItems.set(item.parent_id, {
					...parent,
					children: [...(parent.children ?? []), mapItems.get(item.id)],
				})
			}
		})
		const tt = Object.values(Object.fromEntries(mapItems)).filter(
			(item: any) => item.parent_id === null
		)
		return addKey(tt, "0")
	}

	const dataForPrepare = () => {
		if (servicesFieldData?.data) {
			return prepareData(servicesFieldData.data)
		}
	}

	const findSelectedServcieOfServiceFieldId = (service: IServices) => {
		return !!selectedServices.find(serv => serv.service_id === service.id)
	}

	const serviecOFFieldId = (fieldId: number) => {
		const services: any = servicesData?.data.filter(
			serv => serv.service_field.id === fieldId
		)
		const selectedServ = selectedServices.filter(
			s =>
				s.service_id ===
				services?.find((serv: any) => serv.id === s.service_id)?.id
		)
		return selectedServ
	}

	const onHandleSelectService = (service: ServicesSelected) => {
		const { service_id, type } = service

		const currentField = servicesData?.data.find(
			serv => serv.id === service_id
		)?.service_field

		const item = (generatedServicesData() as any).find(
			(item: any) => item.external_id === currentField?.name
		)

		const childrenIds = item?.children
			.filter((it: any) => it?.name)
			.filter(Boolean)
			.map((i: any) => i?.id)
			.flat()

		const servicesOfChildren = servicesData?.data.filter(
			serv =>
				serv.service_field.id ===
				childrenIds?.find((id: number) => id === serv.service_field.id)
		)

		const selectedServiceOfChildren = selectedServices.filter(
			serv =>
				serv.service_id ===
				servicesOfChildren?.find(s => s.id === serv.service_id)?.id
		)

		let parentId = currentField?.parent?.id
		const parentIds = currentField
			? parentId
				? [currentField?.id, parentId]
				: [currentField?.id]
			: []

		const isIncludeField = !!selectedFieldsServices.find(
			fieldId => fieldId === currentField?.id
		)

		const isIncludes = selectedServices.find(
			serv => serv.service_id === service_id
		)

		if (isIncludes && isIncludes.type === type) {
			if (
				currentField?.id &&
				serviecOFFieldId(currentField?.id).length === 1 &&
				!selectedServiceOfChildren?.length
			) {
				if (!serviecOFFieldId(parentId)?.length) {
					parentIds?.forEach(fid => {
						setSelectedFieldsServices(prev => prev.filter(id => id !== fid))
					})
				} else {
					setSelectedFieldsServices(prev =>
						prev.filter(id => id !== currentField?.id)
					)
				}
			}

			setSelectedServices(prev =>
				prev.filter(serv => serv.service_id !== service_id)
			)
		} else if (isIncludes && isIncludes.type !== type) {
			setSelectedServices(prev =>
				prev.map(serv =>
					serv.service_id === service_id
						? { ...serv, type, service_field: serv.service_field }
						: serv
				)
			)
		} else {
			if (!isIncludeField && currentField?.id) {
				setSelectedFieldsServices(prev => [...prev, ...parentIds])
			}
			setSelectedServices(prev => [...prev, service])
		}
	}

	const findParentField = (service: IServices, dataArr: treeData[]) => {
		dataArr?.forEach(item => {
			if (service.service_field.id === item.id && item.type === "field") {
				item.children?.push({
					title: (
						<SelectedTreeServiceItem
							checked={findSelectedServcieOfServiceFieldId(service)}
							service={service}
							onSelectService={onHandleSelectService}
							currentType='primary'
						/>
					),
					id: service.id,
					key: `${item.key}-${item.children?.length}`,
					children: [],
					order: item.order || null,
					icon: <ServiceIcon />,
				})
			}

			if (service.service_field.id !== item.id) {
				findParentField(service, item.children)
			}
		})
	}

	const generateData = (servicesData: IServices[]) => {
		const data: treeData[] = []
		dataForPrepare()?.forEach((item: any) => {
			data.push(item)
		})
		servicesData?.forEach(service => {
			findParentField(service, data)
		})
		return data
	}
	const generatedServicesData = () => {
		if (servicesData?.data) {
			return generateData(servicesData.data)
		}
		return []
	}

	useEffect(() => {
		if (
			servicesSelected?.length &&
			!selectedServices.length &&
			!selectedFieldsServices.length
		) {
			setSelectedServices(servicesSelected)

			const allFields = servicesSelected.map(serv => serv.service_field).flat()
			setSelectedFieldsServices(allFields)
		}
	}, [servicesSelected, selectedServices, selectedFieldsServices])

	useEffect(() => {
		setServicesSelected(selectedServices)
	}, [selectedServices, setServicesSelected])

	return { generatedServicesData, selectedServices }
}
