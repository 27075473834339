import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ServicesSelected } from "../../common/Services/SelectedServicesFilds/useSelectedServicesFilds"
import { SelectedComplexes } from "../../common/Complexes/SelectedComplexesFilds/useSelectedComplexesFilds"
import { getTimePeriodText, prepareData, sortFields } from "./helpers"
import {
	DiscoutPeriods,
	useCreateDiscountMutation,
	useEditDiscountMutation,
	useGetDiscountByIdQuery,
	useGetDiscountPeriodsQuery,
	useGetDiscountsFieldsQuery,
} from "../../services/discounts"
import { message } from "antd"
import { AllRoutes } from "../../constans/appRoutes"

export type RangePeriodsDiscount = {
	start: number
	end: number
}

export type RangePeriodsDiscunt = {
	[key: string]: {
		id: number
		start: number
		end: number
	}
}

const optionsPeriods = [
	{
		label: DiscoutPeriods.morning,
		value: DiscoutPeriods.morning,
	},
	{
		label: DiscoutPeriods.day,
		value: DiscoutPeriods.day,
	},
	{
		label: DiscoutPeriods.evening,
		value: DiscoutPeriods.evening,
	},
	{
		label: DiscoutPeriods.night,
		value: DiscoutPeriods.night,
	},
]

export enum EntityDiscounts {
	services = "Услуги",
	complexes = "Комплексы",
}

export const EntityDiscountsOptions = [
	{
		label: EntityDiscounts.services,
		value: EntityDiscounts.services,
	},
	{
		label: EntityDiscounts.complexes,
		value: EntityDiscounts.complexes,
	},
]

type treeSelectorData = {
	children: treeSelectorData[]
	id: number
	name: string
	title: string
	value: string
}
export type PeriodDiscountType = {
	id: number
	start: string | Date | number
	end: string | Date
	rate: string | number
	descriprion: string
	period: string
	errorTime: boolean
}

export const useGenerateId = () => {}

export const useDiscountPageCreateAndEdit = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const cityId = params?.cityId
	const discountId = params?.discountId
	const isEdit = !!params?.edit

	const {
		register,
		formState: { isValid, errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const name = watch("name")
	const title = watch("title")

	const { data: discountPeriods } = useGetDiscountPeriodsQuery("")
	const { data: discountsFields } = useGetDiscountsFieldsQuery("")

	const { data: discountData } = useGetDiscountByIdQuery(
		{ id: +discountId },
		{ skip: !discountId }
	)

	const [createDiscount, { isLoading: isLoadingCreate }] =
		useCreateDiscountMutation()

	const [editDiscount, { isLoading: isLoadingEdit }] = useEditDiscountMutation()

	const [parentFieldValue, setParentFieldValue] = useState<string>()
	const [selectedRange, setSelectedRange] = useState<number[]>([])

	const [entitySelect, setEntitySelect] = useState<string>()

	const [periods, setPeriods] = useState<PeriodDiscountType[]>([
		{
			id: +new Date(),
			start: "",
			end: "",
			rate: "",
			descriprion: "",
			period: DiscoutPeriods.morning,
			errorTime: false,
		},
	])

	const [selectedServices, setSelectedServices] = useState<ServicesSelected[]>(
		[]
	)
	const [selectedComplexes, setSelectedComplexes] = useState<
		SelectedComplexes[]
	>([])

	const [rangePeriods, setRangePeriods] = useState<RangePeriodsDiscunt | null>(
		null
	)

	const disabled = useMemo(() => {
		const periodsIsValid = periods.reduce((acc, next) => {
			return (
				acc +
				+(
					!next.errorTime &&
					!!next.descriprion.trim().length &&
					!!next.rate.toString().trim().length
				)
			)
		}, 0)

		const servAndComx = [...selectedServices, ...selectedComplexes]

		return (
			!name?.toString()?.trim()?.length ||
			!title?.toString()?.trim()?.length ||
			!selectedRange.length ||
			periodsIsValid !== periods.length ||
			!servAndComx.length
		)
	}, [name, title, selectedRange, periods, selectedServices, selectedComplexes])

	const generatePeriodOptions = () => {
		if (discountPeriods?.data) {
			const ranges: RangePeriodsDiscunt = {}

			discountPeriods.data.forEach(it => {
				const timesStart = it.begin.split(":")
				const timesEnd = it.end.split(":")

				const date = new Date()

				const start = new Date(
					new Date(date.setHours(+timesStart[0])).setMinutes(+timesStart[1])
				).setSeconds(0) as any

				const end = new Date(
					new Date(date.setHours(+timesEnd[0])).setMinutes(+timesEnd[1])
				).setSeconds(0) as any

				ranges[it.name] = {
					id: it.id,
					start,
					end,
				}
			})

			setRangePeriods(ranges)
		}
	}

	const changePeriod = (period: PeriodDiscountType) => {
		setPeriods(prev => {
			return prev.map(it => {
				if (it.id === period.id) {
					return {
						...period,
						id: period.id,
					}
				}
				return it
			})
		})
	}

	const onChangeSelect = useCallback((newValue: string) => {
		setParentFieldValue(newValue)
	}, [])

	const onChangeEntity = useCallback((value: string) => {
		setEntitySelect(value)
	}, [])

	const discoutPeriodsOptions = useMemo(() => {
		if (rangePeriods) {
			const selectedPeriods = periods.map(it => it.period)

			const optionsRenameLabel = optionsPeriods.map(it => {
				return {
					...it,
					label: it.label + getTimePeriodText(rangePeriods, it.value),
				}
			})

			const freePeriods = optionsRenameLabel.filter(
				it => !selectedPeriods.includes(it.value)
			)

			return freePeriods
		}
	}, [periods, rangePeriods])

	const dataForPrepare = useMemo(() => {
		if (discountsFields) {
			const sortedData = [...discountsFields.data]

			const sortFieldsData = sortFields(sortedData as any)

			return prepareData(sortFieldsData)
		}
	}, [discountsFields])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (discountsFields) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}

		return data
	}, [discountsFields, dataForPrepare])

	const findFieldIdByValue = (value: string, fieldData: treeSelectorData[]) =>
		fieldData.find(field => {
			if (field.value === value) {
				return field
			}
		})

	const addPeriod = () => {
		const selectedPeriods = periods.map(it => it.period)

		if (selectedPeriods.length < 4 && discoutPeriodsOptions?.length) {
			const freePeriods = discoutPeriodsOptions?.filter(
				it => !selectedPeriods.includes(it.value)
			)
			let start = ""
			let end = ""
			if (rangePeriods) {
				start = new Date(rangePeriods[freePeriods?.[0].value].start) as any
				end = new Date(rangePeriods[freePeriods[0].value].end) as any
			}

			const newPerios: PeriodDiscountType = {
				id: +new Date(),
				descriprion: "",
				rate: "",
				start,
				end,
				errorTime: false,
				period: freePeriods[0].value,
			}

			setPeriods(prev => {
				return [...prev, newPerios]
			})
		}
	}

	const deletePeriod = (period: PeriodDiscountType) => {
		setPeriods(prev => {
			return prev.filter(it => it.period !== period.period)
		})
	}

	const onSave = () => {
		if (rangePeriods && !disabled) {
			const combos = selectedComplexes.map(it => it.complex_id)
			const services = selectedServices.map(it => it.service_id)

			const items = periods.map(it => {
				const startHours = `${new Date(it.start)
					.getHours()
					.toString()
					.padStart(2, "0")}`
				const startMinutes = `${new Date(it.start)
					.getMinutes()
					.toString()
					.padStart(2, "0")}`

				const endHours = `${new Date(it.end)
					.getHours()
					.toString()
					.padStart(2, "0")}`
				const endMinutes = `${new Date(it.end)
					.getMinutes()
					.toString()
					.padStart(2, "0")}`

				const begin = `${startHours}:${startMinutes}:00`
				const end = `${endHours}:${endMinutes}:00`

				return {
					time_of_day_id: rangePeriods[it.period].id,
					begin,
					end,
					percents: +it.rate,
					description: it.descriprion,
				}
			})

			const dates = selectedRange.map(d => new Date(d))

			const data: any = {
				combos,
				services,
				name,
				title,
				items,
				dates,
			}

			if (parentFieldValue) {
				const field_id = findFieldIdByValue(
					parentFieldValue,
					generatedFieldData
				)?.id

				data.field_id = field_id
			}

			if (!isEdit && cityId) {
				data.city_id = +cityId
				createDiscount(data)
					.unwrap()
					.then(result => {
						if (result.success) {
							message.success("Скидка успешно создана")
							navigate(AllRoutes.Discounts)
						}
					})
					.catch(err => {
						message.error("Не удалось создать скидку")
					})
			} else {
				data.id = discountData?.data.id
				editDiscount(data)
					.unwrap()
					.then(result => {
						if (result) {
							message.success("Скидка успешно изменена")
							navigate(AllRoutes.Discounts)
						}
					})
					.catch(err => {
						message.error("Не удалось изменить скидку")
					})
			}
		}
	}

	useEffect(() => {
		if (discountPeriods?.data.length) {
			generatePeriodOptions()
		}
	}, [discountPeriods])

	useEffect(() => {
		if (discountData && isEdit && rangePeriods && discountPeriods) {
			setValue("name", discountData.data.name)
			setValue("title", discountData.data.title)

			setSelectedRange(discountData.data.dates.map(d => +new Date(d.date)))

			const items: PeriodDiscountType[] = discountData.data.items.map(it => {
				const isPeriod = discountPeriods?.data.find(
					p => p.id === it.time_of_day_id
				)

				const timesStart = it.begin.split(":")
				const timesEnd = it.end.split(":")

				const date = new Date()

				const start = new Date(
					new Date(date.setHours(+timesStart[0])).setMinutes(+timesStart[1])
				).setSeconds(0) as any

				const isNextday =
					timesEnd[0].includes("0") && !timesStart[0].includes("0")

				const end: any = new Date(
					new Date(date.setHours(+timesEnd[0])).setMinutes(+timesEnd[1])
				).setSeconds(0)

				return {
					id: it.id,
					start,
					end: isNextday ? end + 86400000 : end,
					rate: it.percents,
					descriprion: it.description,
					period: isPeriod?.name || "",
					errorTime: false,
				}
			})

			setPeriods(items)

			setSelectedServices(
				discountData.data.services.map(s => {
					return {
						service_id: s.id,
						service_field: s.service_field.id,
						type: "primary",
					}
				})
			)

			setSelectedComplexes(
				discountData.data.combos.map(c => {
					return {
						combo_field_id: c.combo_field_id,
						complex_id: c.comboId,
					}
				})
			)

			const findFieldData = generatedFieldData?.find(
				it => it.id === discountData?.data.field_id
			)

			findFieldData && setParentFieldValue(findFieldData.value)
		}
	}, [
		discountData,
		isEdit,
		setValue,
		discountPeriods,
		rangePeriods,
		generatedFieldData,
	])

	return {
		navigate,
		register,
		errors,
		parentFieldValue,
		onChangeSelect,
		onSave,
		selectedRange,
		setSelectedRange,
		periods,
		changePeriod,
		addPeriod,
		discoutPeriodsOptions,
		entitySelect,
		onChangeEntity,
		setSelectedServices,
		selectedServices,
		setSelectedComplexes,
		selectedComplexes,
		deletePeriod,
		disabled,
		discountsFields,
		rangePeriods,
		isLoadingCreate,
		isLoadingEdit,
		generatedFieldData,
	}
}
