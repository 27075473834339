import { IComboService } from "./../models/ComboServices"
import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "./baseQuery"
import { IServices } from "../models/Specialist"

export interface IDiscountCombos {
	comboId: number
	title: string
	combo_field_id: number
}

export interface IDiscountDates {
	date: string
}

export interface IDiscountItems {
	begin: string // exemple 12:00:00
	description: string
	end: string // exemple 12:00:00
	id: number
	percents: number
	time_of_day_id: number
}

export interface IDiscount {
	id: number
	name: string
	title: string
	field_id: IComboService[]
	services: IServices[]
	combos: IDiscountCombos[]
	dates: IDiscountDates[]
	items: IDiscountItems[]
}

export enum DiscoutPeriods {
	morning = "Утро",
	day = "День",
	evening = "Вечер",
	night = "Ночь",
}

export interface IDiscountPeriod {
	id: number
	name: DiscoutPeriods
	begin: string // exemple 12:00:00
	end: string // exemple 12:00:00
}

type CreateDiscountTimeItem = {
	time_of_day_id: number
	begin: string // exemple "12:00"
	end: string // exemple "12:00"
	percents: number
	description: string
}

export type CreateDiscountRequest = {
	city_id: number
	name: string
	title: string
	field_id?: number
	combos: number[]
	services: number[]
	dates: string[] | Date[]
	items: CreateDiscountTimeItem[]
}

export type EditDiscountRequest = {
	id: number
	name: string
	title: string
	field_id?: number
	combos: number[]
	services: number[]
	dates: string[] | Date[]
	items: CreateDiscountTimeItem[]
}

export interface IDiscountField {
	id: number
	name: string
}

export const discountAPI = createApi({
	reducerPath: "adminDiscounts",
	baseQuery,
	tagTypes: [
		"discounts",
		"discountById",
		"discountPeriods",
		"discountsFields",
		"discountFieldById",
	],
	endpoints: build => ({
		// получение список скидок
		getDiscounts: build.query<{ data: IDiscount[] }, any>({
			query: () => ({
				url: "/admin/discount",
			}),
			providesTags: ["discounts"],
		}),
		// Получение временных промежутков.
		getDiscountPeriods: build.query<{ data: IDiscountPeriod[] }, any>({
			query: () => ({
				url: "/admin/time-of-days",
			}),
			providesTags: [{ type: "discountPeriods", id: "LIST" }],
		}),
		// создание скидки
		createDiscount: build.mutation<
			{ data: IDiscountPeriod; success: boolean },
			CreateDiscountRequest
		>({
			query: ({ city_id, ...body }) => ({
				url: `/admin/city/${city_id}/discount`,
				method: "POST",
				body,
			}),
			invalidatesTags: ["discounts"],
		}),
		// изминение скидки
		editDiscount: build.mutation<
			{ data: IDiscountPeriod; success: boolean },
			EditDiscountRequest
		>({
			query: ({ id, ...body }) => ({
				url: `/admin/discount/${id}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: result => [
				{ type: "discountById", id: result?.data.id },
				{ type: "discounts", id: "LIST" },
				"discounts",
			],
		}),
		// удаление скидки
		deleteDiscount: build.mutation<{ success: boolean }, { id: number }>({
			query: ({ id }) => ({
				url: `/admin/discount/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: [{ type: "discounts", id: "LIST" }, "discounts"],
		}),
		// получение скидки по id
		getDiscountById: build.query<
			{ data: IDiscount; success: boolean },
			{ id: number }
		>({
			query: ({ id }) => ({
				url: `/admin/discount/${id}`,
			}),
			providesTags: result => [{ type: "discountById", id: result?.data.id }],
		}),

		// получение списка папок для скидок
		getDiscountsFields: build.query<
			{ data: IDiscountField[]; success: boolean },
			any
		>({
			query: () => ({
				url: "/admin/discount_field",
			}),
			providesTags: [{ type: "discountsFields", id: "LIST" }],
		}),
		// создание папок для скидок
		createDiscountField: build.mutation<
			{ data: IDiscountField; success: boolean },
			{
				name: string
			}
		>({
			query: body => ({
				url: "/admin/discount_field",
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: "discountsFields", id: "LIST" }],
		}),
		// удаление папки
		deleteDiscountField: build.mutation<
			{ success: boolean },
			{
				id: number
			}
		>({
			query: ({ id }) => ({
				url: `/admin/discount_field/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: [{ type: "discountsFields", id: "LIST" }],
		}),
		// получение папки
		getDiscountFieldById: build.query<
			{ data: IDiscountField; success: boolean },
			{
				id: number
			}
		>({
			query: ({ id }) => ({
				url: `/admin/discount_field/${id}`,
				method: "GET",
			}),
			providesTags: ["discountFieldById"],
		}),
		// редактирование папки
		editDiscountField: build.mutation<
			{ data: IDiscountField; success: boolean },
			{
				id: number
				name: string
			}
		>({
			query: ({ id, name }) => ({
				url: `/admin/discount_field/${id}`,
				method: "PUT",
				body: {
					name,
				},
			}),
			invalidatesTags: [
				{ type: "discountsFields", id: "LIST" },
				"discountFieldById",
			],
		}),
	}),
})

export const {
	useGetDiscountsQuery,
	useGetDiscountPeriodsQuery,
	useCreateDiscountMutation,
	useGetDiscountByIdQuery,
	useEditDiscountMutation,
	useDeleteDiscountMutation,
	useGetDiscountsFieldsQuery,
	useCreateDiscountFieldMutation,
	useDeleteDiscountFieldMutation,
	useGetDiscountFieldByIdQuery,
	useEditDiscountFieldMutation,
} = discountAPI
