import React from "react"
import styles from "./CustomizeServices.module.scss"

import DirectoryTree from "antd/es/tree/DirectoryTree"

import { Button } from "../../../../common/Button"
import { useSpecialistContext } from "../../SpecialistContext"

const CustomizeServices = () => {
	const { generatedData, handleCancelSelectService, saveHandler } =
		useSpecialistContext()

	return (
		<div className={styles.catalogPage__wrapper}>
			<DirectoryTree
				defaultExpandAll={false}
				treeData={generatedData()}
				itemHeight={64}
				rootStyle={{
					background: "transparent",
					border: "0.5px solid #DADADA",
					height: "100%",
				}}
			/>
			<div className={styles.catolog_bottom}>
				<div className={styles.editSpecialistBtnWrapper}>
					<Button title='Сохранить' disabled={false} onClick={saveHandler} />

					<Button
						title='Отмена'
						disabled={false}
						onClick={handleCancelSelectService}
						isTransparent
					/>
				</div>
			</div>
		</div>
	)
}

export default CustomizeServices
