export enum DateType {
  Hource = "Часы",
  Minutes = "Минуты",
  Days = "Дни",
}

function ValidateHource(val: number) {
  let newVal = val;

  if (val > 24) {
    newVal = 24;
  }

  if (val < 0) {
    newVal = 0;
  }

  return newVal;
}

function ValidateMinutes(val: number) {
  let newVal = val;

  if (val > 60) {
    newVal = 60;
  }

  if (val < 0) {
    newVal = 0;
  }

  return newVal;
}

function ValidateDays(val: number) {
  let newVal = val;

  if (val > 10) {
    newVal = 10;
  }

  if (val < 0) {
    newVal = 0;
  }

  return newVal;
}

export const validateDate = (value: number, dateName: DateType) => {
  let newVal = value;

  switch (dateName) {
    case DateType.Hource:
      newVal = ValidateHource(value);
      break;
    case DateType.Minutes:
      newVal = ValidateMinutes(value);
      break;
    case DateType.Days:
      newVal = ValidateDays(value);
      break;
    default:
      newVal = value;
      break;
  }

  return newVal;
};
