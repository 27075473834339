import { FC } from "react"
import CheckBox from "../../common/CheckBox/CheckBox"
import styles from "./SpecialistsPage.module.scss"

type Props = {
	checked: boolean
	item: any
	ids: number[]
	half: boolean
	selectField?: (ids: number[], item: any) => void
	showCheckBox?: boolean
}

const TreeServiceFieldItems: FC<Props> = ({
	checked,
	item,
	ids,
	half,
	showCheckBox = true,
	selectField,
}) => {
	const handleSelectField = () => {
		selectField && selectField(ids, item)
	}
	return (
		<div className={styles.servicesHeader}>
			<div onClick={e => e.stopPropagation()} className={styles.flex}>
				{showCheckBox && (
					<CheckBox half={half} checked={checked} onClick={handleSelectField} />
				)}{" "}
				<span>{item.name}</span>
			</div>
		</div>
	)
}

export default TreeServiceFieldItems
