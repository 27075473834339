import { useCallback, useEffect, useMemo, useState } from "react"

import {
	useGetServicesFieldsQuery,
	useGetServicesQuery,
} from "../services/services"
import { ServicesFieldsType } from "../models/CatalogItems"
import { IServices } from "../models/Specialist"

export type Folder = ServicesFieldsType & {
	services: Set<number>
}
type FolderObj = { [key: number]: Folder }

export const useComplexServices = () => {
	const [data, setData] = useState<Folder[]>([])
	const { data: servicesFieldData } = useGetServicesFieldsQuery("")
	const { data: servicesData } = useGetServicesQuery("")

	const folderObj = useMemo(() => {
		const tempResult: FolderObj = {}
		servicesFieldData?.data.forEach(serviceFieldData => {
			tempResult[serviceFieldData.id] = {
				...serviceFieldData,
				services: new Set([]),
			}
		})

		return tempResult
	}, [servicesFieldData?.data])

	const addServiceToFolder = useCallback(
		(service: IServices, fieldId: number | null) => {
			if (fieldId === null) return
			folderObj[fieldId]?.services.add(service.id)
			// addServiceToFolder(service, folderObj[fieldId].parent_id)
		},
		[folderObj]
	)

	const addServiceToFolders = useCallback(() => {
		servicesData?.data.forEach(service =>
			addServiceToFolder(service, service.service_field.id)
		)
	}, [addServiceToFolder, servicesData?.data])

	useEffect(() => {
		addServiceToFolders()
		setData(Object.values(folderObj))
	}, [folderObj, addServiceToFolders])

	return data
}
