import { createApi } from "@reduxjs/toolkit/query/react"
import { ICatalogItem } from "../models/CatalogItems"
import { baseQuery } from "./baseQuery"

type ResponseCatalogType = {
	data: ICatalogItem[]
}

type RequestCatalogTypeParams = {
	cityId?: number | null
}

type ResponseDeleteFieldType = {
	message: string
}

type ResponseEditComplexType = {
	message: string
}

export type RequestEditComplexType = {
	id?: number
	parent_id?: number
	name: string
	type: string
	icon_id?: number
	city_id: number
	order: null | number
	specialization_id?: number
	service_field_id?: number
	contrasting_specialization_id?: number
	contrasting_service_field_id?: number
}

export const catalogItems = createApi({
	reducerPath: "catalogItems",
	tagTypes: ["CatalogItem"],
	baseQuery,
	endpoints: build => ({
		getItems: build.query<ResponseCatalogType, RequestCatalogTypeParams>({
			query: ({ cityId }: RequestCatalogTypeParams) => ({
				url: `/admin/catalog-items${cityId ? `?cityId=${cityId}` : ""}`,
			}),
			providesTags: result =>
				result && result.data.length
					? [
							...result.data.map(({ id }: { id: number }) => ({
								type: "CatalogItem" as const,
								id,
							})),
							{ type: "CatalogItem", id: "LIST" },
					  ]
					: [{ type: "CatalogItem", id: "LIST" }],
		}),
		deleteCatalogField: build.mutation<ResponseDeleteFieldType, { id: number }>(
			{
				query: ({ id }: { id: number }) => ({
					url: `/admin/catalog-items/${id}`,
					method: "DELETE",
				}),
				invalidatesTags: [{ type: "CatalogItem", id: "LIST" }],
			}
		),
		editCatalogItem: build.mutation<
			ResponseEditComplexType,
			RequestEditComplexType
		>({
			query: ({
				id,
				parent_id,
				name,
				type,
				icon_id,
				city_id,
				specialization_id,
				service_field_id,
				contrasting_specialization_id,
				contrasting_service_field_id,
				order,
			}: RequestEditComplexType) => ({
				url: `/admin/catalog-items/${id}`,
				method: "PUT",
				body: {
					parent_id,
					name,
					type,
					icon_id,
					city_id,
					specialization_id,
					service_field_id,
					contrasting_specialization_id,
					contrasting_service_field_id,
					order,
				},
			}),
			invalidatesTags: [{ type: "CatalogItem", id: "LIST" }],
		}),
		createCatalogItem: build.mutation<
			ResponseEditComplexType,
			RequestEditComplexType
		>({
			query: ({
				parent_id,
				name,
				type,
				icon_id,
				city_id,
				specialization_id,
				service_field_id,
				contrasting_specialization_id,
				contrasting_service_field_id,
			}: RequestEditComplexType) => ({
				url: `/admin/catalog-items/`,
				method: "POST",
				body: {
					parent_id,
					name,
					type,
					icon_id,
					city_id,
					specialization_id,
					service_field_id,
					contrasting_specialization_id,
					contrasting_service_field_id,
				},
			}),
			invalidatesTags: [{ type: "CatalogItem", id: "LIST" }],
		}),
	}),
})

export const {
	useGetItemsQuery,
	useDeleteCatalogFieldMutation,
	useEditCatalogItemMutation,
	useCreateCatalogItemMutation,
} = catalogItems
