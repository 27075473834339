import { FieldValues, RegisterOptions } from "react-hook-form";

const password: RegisterOptions<FieldValues, string> = {
  required: "Поле обязательно для заполнения",
  minLength: {
    value: 8,
    message: "Мининмум 8 символов",
  },
  pattern: {
    value: /\d/,
    message: "Должна присутствовать хотябы одна цифра",
  },

  validate: {
    isCapitalize: (value) =>
      !/[A-Z]+/g.test(value) ? "Отсутствуют символы в верхнем регистре" : true,
  },
};

const email: RegisterOptions<FieldValues, string> = {
  required: "Поле обязательно для заполнения",
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Не корректный email",
  },
};

export const subNumber: RegisterOptions<FieldValues, string> = {
  required: "Поле обязательно для заполнения",
  min: {
    value: 1,
    message: "Значение не может меньше чем 1",
  },
};

export const timeZone: RegisterOptions<FieldValues, string> = {
  required: "Поле обязательно для заполнения",
  min: {
    value: -12,
    message: "Значение не может меньше чем -12",
  },
  max: {
    value: 12,
    message: "Значение не может больше чем 12",
  },
};

export const formOptions = {
  password,
  email,
};
