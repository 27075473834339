import {
	IMainBanners,
	IMainServices,
	IMainSpecialist,
} from "../../models/MainEntities"

export enum EntityType {
	Banners = "banners",
	Service = "service",
	Specialists = "specialist",
	Complex = "service_combos",
}

export type EntityDataType = {
	[EntityType.Banners]?: {
		data: IMainBanners[]
	}
	[EntityType.Service]?: {
		data: IMainServices[]
	}
	[EntityType.Specialists]?: {
		data: IMainSpecialist[]
	}
}

export enum Entities {
	Spec = "Специалист",
	Serv = "Услуга",
	Complex = "Комплекс",
	Promotion = "Акция",
}

export type ActionTypes = "delete" | "create" | "edit"

export const EntitiesOption = [
	{ value: 1, label: Entities.Spec },
	{ value: 2, label: Entities.Serv },
	{ value: 3, label: Entities.Complex },
	{ value: 4, label: Entities.Promotion },
]
