import { useEffect, useMemo, useState } from "react"
import styles from "./CatalogEditPage.module.scss"
import "./CatalogEditPage.scss"
import { Button } from "../../common/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { message } from "antd"
import {
	RequestEditComplexType,
	useEditCatalogItemMutation,
	useGetItemsQuery,
} from "../../services/catalog"
import { TextField } from "../../common/Form/TextField"
import { useForm } from "react-hook-form"
import {
	SpecializationType,
	useGetSpecializationsQuery,
} from "../../services/specialization"
import { ReactComponent as NoFileIcon } from "../../constans/icons/noFileIcon.svg"
import { useGetServicesFieldsQuery } from "../../services/services"
import { fileApi } from "../../services/file"
import { FileField } from "../../common/Form/FileField"
import { Select } from "../../common/Select"
import { ServicesFieldsType } from "../../models/CatalogItems"
import { subNumber } from "../../constans/formOptions"

type Props = {
	cityId: number
}

const categoryTypesData = [
	{
		value: 0,
		name: "category",
		label: "Категория",
	},
	{
		value: 1,
		name: "category_icons",
		label: "Категория иконками",
	},
	{
		value: 2,
		name: "services",
		label: "Услуги",
	},
	{
		value: 3,
		name: "specialists",
		label: "Специалисты",
	},
	{
		value: 4,
		name: "ultrasound_diagnostics",
		label: "УЗИ диагностика",
	},
	{
		value: 5,
		name: "ultrasound_specialists",
		label: "УЗИ специалисты",
	},
	{
		value: 6,
		name: "analyzes",
		label: "Анализы",
	},
]

const CatalogEditPage = ({ cityId }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.id
	const currentCityId = params?.cityId

	const {
		register,
		formState: { errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const { data: catalogData } = useGetItemsQuery({ cityId: +cityId })
	const { data: specializationData } = useGetSpecializationsQuery("")
	const { data: serviceFieldsData } = useGetServicesFieldsQuery("")
	const [sendFile] = fileApi.useSendFileMutation()
	const [editCatalog] = useEditCatalogItemMutation()

	const currentCatalogItem = useMemo(() => {
		if (catalogData?.data) {
			return catalogData.data.find(item => item.id === +currentId)
		}
	}, [catalogData?.data, currentId])

	const selectCatalogData = useMemo(() => {
		const result: { value: number; label: string; disabled?: boolean }[] = [
			{ value: 0, label: "Нет родителя", disabled: true },
		]

		if (catalogData?.data) {
			catalogData.data.forEach(item => {
				const obj = {
					value: item.id,
					label: item.name,
				}
				result.push(obj)
			})
		}
		return result
	}, [catalogData?.data])

	const selectSpecData = useMemo(() => {
		const result: { value: number; label: string; disabled?: boolean }[] = [
			{ value: 0, label: "Нет специализации" },
		]

		if (specializationData?.data) {
			specializationData.data.forEach((item: SpecializationType) => {
				const obj = {
					value: item.id,
					label: item.name,
				}
				result.push(obj)
			})
		}
		return result
	}, [specializationData?.data])

	const selectServiceFieldData = useMemo(() => {
		const result: { value: number; label: string; disabled?: boolean }[] = [
			{ value: 0, label: "Не выбрана", disabled: true },
		]
		if (serviceFieldsData?.data) {
			serviceFieldsData.data.forEach((field: ServicesFieldsType) => {
				const obj = {
					value: field.id,
					label: field.name,
				}

				result.push(obj)
			})
		}
		return result
	}, [serviceFieldsData?.data])

	const [fileId, setFileId] = useState<number>(0)
	const [photoUrl, setPhotoUrl] = useState("")
	const [parentId, setParentId] = useState<number>(0)
	const [categoryValue, setCategoryValue] = useState<number>(0)
	const [specId, setSpecId] = useState<number>(0)
	const [serviceFieldId, setServiceFieldId] = useState<number>()
	const [contrastingSpecId, setContrastingSpecId] = useState<number>()
	const [contrastingFieldId, setContrastingFieldId] = useState<number>()
	const name = watch("name") !== "" ? watch("name") : currentCatalogItem?.name

	const order = watch("order") ?? currentCatalogItem?.order

	const handleChangeIcon = async (file: File) => {
		const formData = new FormData()

		formData.append("file", file)
		formData.append("is_public", "true")

		const response = await sendFile(formData)

		if (response && "data" in response) {
			const { id, url } = response.data.data
			setPhotoUrl(url)
			setFileId(id)
		}
	}

	const hadnleDeleteIcon = () => setFileId(0)

	const handleParentChange = (value: number) => {
		setParentId(value)
	}

	const handleCategoryTypeChange = (value: number) => {
		setCategoryValue(value)
	}

	const handleSpecIdChange = (value: number) => {
		setSpecId(value)
	}

	const handleServiceFieldIdChange = (value: number) => {
		setServiceFieldId(value)
	}

	const handleContrastingSpecIdChange = (value: number) => {
		setContrastingSpecId(value)
	}

	const handleContrastingFieldIdChange = (value: number) => {
		setContrastingFieldId(value)
	}

	useEffect(() => {
		if (currentCatalogItem) {
			setParentId(currentCatalogItem.parent_id ?? 0)
			setCategoryValue(
				categoryTypesData.find(type => type.name === currentCatalogItem?.type)
					?.value ?? 0
			)
			setSpecId(currentCatalogItem.specialization?.id ?? 0)
			setFileId(currentCatalogItem?.icon?.id ?? 0)
			setPhotoUrl(currentCatalogItem.icon?.url)
			setFileId(currentCatalogItem?.icon?.id ?? 0)
		}
	}, [currentCatalogItem])

	const handleSave = () => {
		const createData = () => {
			const obj: RequestEditComplexType = {
				id: +currentId,
				name: name,
				order,
				type:
					categoryTypesData.find(type => type.value === categoryValue)?.name ??
					"category",
				city_id: +currentCityId,
			}
			if (parentId && parentId !== 0) {
				obj.parent_id = parentId
			}
			if (specId !== 0 && specId) {
				obj.specialization_id = specId
			}
			if (fileId !== 0 && fileId) {
				obj.icon_id = fileId
			}
			if (serviceFieldId !== 0 && serviceFieldId) {
				obj.service_field_id = serviceFieldId
			}
			if (contrastingSpecId !== 0 && contrastingFieldId !== 0) {
				obj.contrasting_service_field_id = contrastingFieldId
				obj.contrasting_specialization_id = contrastingSpecId
			}
			return obj
		}

		const data = createData()

		editCatalog(data)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Каталог успешно редактирована")
					navigate(AllRoutes.Catalog + `?cityId=${currentCityId}`)
				}
			})
			.catch(err => {
				message.error("Не удалось редактировать каталог")
			})
	}

	return (
		<div className={styles.catalogEditPage__wrapper}>
			<FileField
				Icon={NoFileIcon}
				onChange={handleChangeIcon}
				onDelete={hadnleDeleteIcon}
				src={photoUrl}
			/>
			<Select
				placeholder='Категория'
				value={parentId}
				onChange={handleParentChange}
				options={selectCatalogData}
			/>
			<TextField
				fieldname='name'
				register={register}
				options={{ required: "" }}
				error={errors.name?.message as string}
				palcehoder='Название'
				isMarginBottom={false}
				defaultValue={currentCatalogItem?.name}
			/>
			<Select
				placeholder='Тип категории'
				value={categoryValue}
				onChange={handleCategoryTypeChange}
				options={categoryTypesData}
				defaultValue={
					categoryTypesData.find(type => type.name === currentCatalogItem?.type)
						?.value ?? 0
				}
			/>
			<Select
				placeholder='Специализация'
				value={specId}
				onChange={handleSpecIdChange}
				options={selectSpecData}
				defaultValue={currentCatalogItem?.specialization_id}
			/>
			{(categoryTypesData.find(type => type.value === categoryValue)?.name ===
				"services" ||
				categoryTypesData.find(type => type.value === categoryValue)?.name ===
					"analyzes") && (
				<Select
					placeholder='Папка услуг'
					value={serviceFieldId}
					onChange={handleServiceFieldIdChange}
					options={selectServiceFieldData} ///
					defaultValue={currentCatalogItem?.service_field_id}
				/>
			)}
			{categoryTypesData.find(type => type.value === categoryValue)?.name ===
				"category_icons" && (
				<Select
					placeholder='Специализация для контрастирования'
					value={contrastingSpecId}
					onChange={handleContrastingSpecIdChange}
					options={selectSpecData} ///
					defaultValue={currentCatalogItem?.contrasting_specialization_id}
				/>
			)}
			{categoryTypesData.find(type => type.value === categoryValue)?.name ===
				"category_icons" && (
				<Select
					placeholder='Папка услуг для контрастирвоания'
					value={contrastingFieldId}
					onChange={handleContrastingFieldIdChange}
					options={selectServiceFieldData} ///
					defaultValue={currentCatalogItem?.contrasting_service_field_id}
				/>
			)}

			<TextField
				defaultValue={currentCatalogItem?.order?.toString()}
				fieldname='order'
				register={register}
				min={1}
				palcehoder='Порядок'
				type='number'
				options={subNumber}
				error={errors.order?.message as string}
				styled={{
					container: styles.orderInput,
				}}
			/>
			<div className={styles.createCatalogBtnWrapper}>
				<Button title='Сохранить' disabled={false} onClick={handleSave} />
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.CatalogCity + `${currentCityId}`)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default CatalogEditPage
