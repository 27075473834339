import { CSSProperties, FC } from "react"
import styles from "./TextArea.module.scss"
import TextArea, { TextAreaProps } from "antd/es/input/TextArea"
import { ReactComponent as DeleteIcon } from "../../constans/icons/delete.svg"
import cn from "classnames"

interface Props extends TextAreaProps {
	placeholder?: string
	isDeletedet?: {
		onClick: () => void
	}
	styleContainer?: CSSProperties
	classesNames?: {
		placeholder?: string
	}
}

const TextAreaField: FC<Props> = props => {
	const { placeholder, isDeletedet, styleContainer, classesNames, ...rest } =
		props

	return (
		<div style={styleContainer} className={styles.textArea}>
			{placeholder && (
				<span className={cn(styles.placeholder, classesNames?.placeholder)}>
					{placeholder}
				</span>
			)}
			<TextArea {...rest} />
			{isDeletedet && <DeleteIcon onClick={isDeletedet.onClick} />}
		</div>
	)
}

export default TextAreaField
