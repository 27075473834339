import React, { FC } from "react"
import styles from "./AdditionalServicesModal.module.scss"

import DirectoryTree from "antd/es/tree/DirectoryTree"
import { Modal } from "../../../../common/Modal"
import { useAdditionalServicesModalContext } from "./AdditionalServicesModalContext"
import { useSpecialistContext } from "../../SpecialistContext"

type Props = {}

const AdditionalServicesModal: FC<Props> = () => {
	const {
		selectAdditionalServiceModal,
		setDescriptionModal,
		toggleSelectAdditionalServiceModal,
	} = useSpecialistContext()

	const { generatedData, handleCancel, selectedAdditionalService } =
		useAdditionalServicesModalContext()

	const handleToggl = () => {
		toggleSelectAdditionalServiceModal(handleCancel)
	}

	const saveHandler = () => {
		setDescriptionModal(true)
		toggleSelectAdditionalServiceModal()
	}

	return (
		<Modal
			styled={{ container: { width: "80vw", height: "90vh" } }}
			visible={!!selectAdditionalServiceModal}
			onClose={handleToggl}
		>
			<div className={styles.catalogPage__wrapper}>
				<DirectoryTree
					defaultExpandAll={false}
					treeData={generatedData()}
					itemHeight={64}
					rootStyle={{
						background: "transparent",
						border: "0.5px solid #DADADA",
						height: "100%",
					}}
				/>
				<div className={styles.catolog_bottom}>
					<span onClick={handleToggl}>Отмена</span>

					<button
						onClick={saveHandler}
						disabled={
							selectedAdditionalService?.length > 1 ||
							selectedAdditionalService?.length === 0
						}
					>
						Сохранить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default AdditionalServicesModal
