import { IComplexField } from "../../services/complexes"

export const addForChildrenKey = (array: any, parentKey: any) => {
	const res = array.map((item: any, i: number) => {
		const key = `${parentKey}-${i}`
		const newItem = { ...item }
		if (item.children) {
			newItem.children = addForChildrenKey(item.children, key)
		}
		if (!item.children) {
			newItem.children = []
		}
		newItem.value = key
		newItem.title = item.name
		return newItem
	})

	return res
}

export const prepareData = (array: any[], addKey?: any) => {
	const mapItems = new Map(array.map(item => [item.id, item]))
	array.forEach(it => {
		const item = { ...it }
		if (item.parent) {
			const parent = mapItems.get(item.parent_id)
			mapItems.set(item.parent_id, {
				...parent,
				children: [...(parent.children ?? []), mapItems.get(item.id)],
			})
		}
	})
	const tt = Object.values(Object.fromEntries(mapItems)).filter(
		(item: any) => item.parent_id === null
	)

	return addKey ? addKey(tt, "0") : addForChildrenKey(tt, "0")
}

export function sortFields(arr: IComplexField[]) {
	return arr.sort((a, b) => {
		if (b.parent_id === null || b.parent_id === undefined) return -1
		if (a.parent_id === null || a.parent_id === undefined) return 1
		return b.parent_id - a.parent_id
	})
}
