import { FC } from "react";
import { ReactComponent as LogoIcon } from "../LoginPage/icons/logo.svg";
import { Button } from "../../common/Button";
import { TextField } from "../../common/Form/TextField";
import { ReactComponent as PasswordIcon } from "../LoginPage/icons/pasword.svg";
import { formOptions } from "../../constans/formOptions";
import styles from "./SavePasswordPage.module.scss";
import { useSavePasswordController } from "./SavePasswordPage.controller";

const SavePasword: FC = () => {
  const { isLoading, isValid, errors, handleSubmit, onSubmit, register } =
    useSavePasswordController();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <LogoIcon />
          <span className={styles.topTitle}>Спектр диагностика</span>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            Icon={PasswordIcon}
            options={formOptions.password}
            error={errors.password?.message as string}
            palcehoder="новый пароль"
            fieldname="password"
            type="password"
            register={register}
          />
          <TextField
            Icon={PasswordIcon}
            options={formOptions.password}
            error={errors.repeatPassword?.message as string}
            palcehoder="повторите пароль"
            fieldname="repeatPassword"
            type="password"
            register={register}
          />

          <Button
            loading={isLoading}
            title="Сохранить пароль"
            disabled={!isValid}
          />
        </form>
      </div>
    </div>
  );
};

export default SavePasword;
