import { FC, HTMLAttributes } from "react";
import { ReactComponent as CheckedIcon } from "../../constans/icons/checked.svg";
import { ReactComponent as CheckedHalfIcon } from "../../constans/icons/checkHalf.svg";
import styles from "./CheckBox.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  checked: boolean;
  half?: boolean;
}

const CheckBox: FC<Props> = ({ checked, half, ...rest }) => {
  return (
    <div className={styles.root} {...rest}>
      {checked && (half ? <CheckedHalfIcon /> : <CheckedIcon />)}
      {!checked &&
        (half ? <CheckedHalfIcon /> : <div className={styles.checkbox} />)}
    </div>
  );
};

export default CheckBox;
