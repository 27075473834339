import React, { memo } from "react"
import styles from "./DiscountFieldCreate.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../../../common/Form/TextField"
import { Button } from "../../../../common/Button"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import { useCreateDiscountFieldMutation } from "../../../../services/discounts"
import { message } from "antd"

type Props = {}

const DiscountFieldCreate = ({}: Props) => {
	const navigate = useNavigate()
	const {
		register,
		formState: { errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const name = watch("name")

	const [createDiscount] = useCreateDiscountFieldMutation()

	const handleCreateField = async () => {
		const sendData = {
			name,
		}

		await createDiscount(sendData)
			.unwrap()
			.then(result => {
				if (result.success) {
					message.success("Папка успешно создана")
					navigate(AllRoutes.Discounts)
				}
			})
			.catch(err => {
				message.error("Не удалось создать папку")
			})
	}

	return (
		<div style={{ padding: "30px 0" }}>
			<div className={styles.halfContaienr}>
				<TextField
					fieldname='name'
					register={register}
					options={{ required: "" }}
					error={errors.name?.message as string}
					palcehoder='Название'
					isMarginBottom={false}
				/>
			</div>
			<div className={styles.saveBtnWrapper}>
				<Button
					title='Сохранить'
					disabled={!name}
					onClick={handleCreateField}
				/>
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Discounts)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default memo(DiscountFieldCreate)
