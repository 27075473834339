import { useNavigate, useSearchParams } from "react-router-dom"
import { useCallback, useMemo, useState } from "react"
import { ReactComponent as DeleteIcon } from "../../../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../../../constans/icons/redact.svg"
import { ReactComponent as ServiceIcon } from "../../../../constans/icons/serviceIcon.svg"
import { ReactComponent as GreyField } from "../../../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../../../constans/icons/greenField.svg"
import styles from "./DiscountFields.module.scss"
import { AllRoutes } from "../../../../constans/appRoutes"
import { prepareData, sortFields } from "../../helpers"
import type { DirectoryTreeProps } from "antd/es/tree"
import {
	IDiscount,
	useGetDiscountsFieldsQuery,
} from "../../../../services/discounts"

type Props = {
	discounts: IDiscount[] | undefined
	setComplexIds: (arr: number[]) => void
}

export const useDiscountFields = ({ discounts, setComplexIds }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const isFieldCreate = !!params?.fieldCreate
	const isFieldEdit = !!params?.fieldEdit
	const isEdit = !!params?.edit
	const isCreate = !!params?.create

	const { data: discountsFields, isLoading } = useGetDiscountsFieldsQuery("")

	const [selectedFieldId, setSelectedFieldId] = useState(0)
	const [deleteFieldId, setDeleteFieldId] = useState<string | number>(0)
	const [del, setDel] = useState<number>(0)

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}
			newItem.key = key
			newItem.icon = key.length > 3 ? <GreyField /> : <GreenField />
			newItem.title = (
				<>
					{item.name}
					<div className={styles.tree__btns} style={{ flex: 1 }}>
						<button
							onClick={() => navigate(AllRoutes.DiscountsFieldEdit + item.id)}
							className={styles.tree__btn}
						>
							<EditIcon />
						</button>
						<button
							onClick={() => setDeleteFieldId(item.id)}
							className={styles.tree__btn}
						>
							<DeleteIcon />
						</button>
					</div>
				</>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const goEdit = (id?: number) => navigate(AllRoutes.DiscountsEdit + `${id}`)

	const findParentField = useCallback((discount: IDiscount, dataArr: any[]) => {
		dataArr.forEach(item => {
			if (discount.field_id === item.id) {
				item.children?.push({
					title: (
						<>
							{discount.name}
							<div className={styles.tree__btns} style={{ flex: 1 }}>
								<button
									onClick={() => goEdit(discount.id)}
									className={styles.tree__btn}
								>
									<EditIcon />
								</button>
								<button
									onClick={() => setDel(discount.id)}
									className={styles.tree__btn}
								>
									<DeleteIcon />
								</button>
							</div>
						</>
					),
					id: discount.id,
					key: `${item.key}-${item.children.length}`,
					children: [],
					icon: <ServiceIcon />,
				})
			}
		})
	}, [])

	const dataForPrepare = useMemo(() => {
		if (discountsFields?.data) {
			const sortedData = [...discountsFields.data]

			const sortFieldsdata = [...sortFields(sortedData)]
			const prepareDataFields: any = [...prepareData(sortFieldsdata, addKey)]

			return prepareDataFields
		}
	}, [discountsFields, discounts])

	const generateData = useCallback(
		(discounts: IDiscount[]) => {
			const data: any = []

			dataForPrepare.forEach((item: any) => {
				data.push(item)
			})

			discounts.forEach(d => {
				findParentField(d, data)
			})

			const listIds = data
				.map((item: any) => {
					const childrenIds = item.children.map((it: any) => it.id)
					return childrenIds
				})
				.flat()

			setComplexIds(listIds)

			return data
		},
		[dataForPrepare, findParentField]
	)

	const generatedData = useMemo(() => {
		if (discountsFields?.data && discounts) {
			return generateData(discounts)
		}
		return []
	}, [discountsFields?.data, discounts, generateData])

	const onSelect: DirectoryTreeProps<any>["onSelect"] = (keys, info) => {
		if (info.node.type === "field") {
			setSelectedFieldId(info.node.id)
		} else {
			setSelectedFieldId(0)
		}
	}

	return {
		isFieldCreate,
		isFieldEdit,
		deleteFieldId,
		del,
		isEdit,
		isCreate,
		generatedData,
		discountsFields,
		setDeleteFieldId,
		setDel,
		onSelect,
		isLoading,
	}
}
