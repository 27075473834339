import { useEffect, useState } from "react"
import { BannerState } from "../CreateOrEditBanners.controller"
import {
	KeyTypesBanners,
	useGetTypesBannersQuery,
} from "../../../../../../services/mainBanner"

type Props = {
	state: BannerState[]
	setState: React.Dispatch<React.SetStateAction<BannerState[]>>
}

export const TypeBannerOptions = [
	{ value: "TYPE_1", label: 1 },
	{ value: "TYPE_2", label: 2 },
	{ value: "TYPE_3", label: 3 },
	{ value: "TYPE_4", label: 4 },
	{ value: "TYPE_5", label: 5 },
	{ value: "TYPE_6", label: 6 },
]

export const useTypeBanner = ({ setState, state }: Props) => {
	const [type, setType] = useState<KeyTypesBanners>("TYPE_1")

	const { data: typesBanners, isLoading: loadingTypes } =
		useGetTypesBannersQuery("")

	const handleTypeBannerChange = (value: KeyTypesBanners) => {
		if (value) {
			setType(value)
			setState([])
		}
	}

	useEffect(() => {
		if (type === "TYPE_1") {
			const dataState = new Array(1).fill({
				fileId: 0,
				entity: "",
				spec: 0,
				service: 0,
				complex: 0,
				photoUrl: "",
			})

			setState(dataState)
			return
		}
		if (type === "TYPE_2" || type === "TYPE_4") {
			const dataState = new Array(3).fill({
				fileId: 0,
				entity: "",
				spec: 0,
				service: 0,
				complex: 0,
				photoUrl: "",
			})

			setState(dataState)
			return
		}
		if (type === "TYPE_3") {
			const dataState = new Array(2).fill({
				fileId: 0,
				entity: "",
				spec: 0,
				service: 0,
				complex: 0,
				photoUrl: "",
			})

			setState(dataState)
			return
		}
		if (type === "TYPE_5") {
			const dataState = new Array(4).fill({
				fileId: 0,
				entity: "",
				spec: 0,
				service: 0,
				complex: 0,
				photoUrl: "",
			})

			setState(dataState)
			return
		}
		if (type === "TYPE_6") {
			const dataState = new Array(1).fill({
				fileId: 0,
				entity: "",
				spec: 0,
				service: 0,
				complex: 0,
				photoUrl: "",
			})

			setState(dataState)
			return
		}
	}, [type, setState])

	return { handleTypeBannerChange, setType, type, typesBanners, loadingTypes }
}
