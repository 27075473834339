import { AdminPage } from "../pages/AdminPage"
import { CatalogPage } from "../pages/CatalogPage"
import LoginPage from "../pages/LoginPage/LoginPage.view"
import { RecoveryPage } from "../pages/RecoveryPage"
import { SavePasswordPage } from "../pages/SavePasswordPage"
import { ReactComponent as AdminsAcon } from "./icons/admins.svg"
import { ReactComponent as CatalogIcon } from "./icons/catalog.svg"
import { ReactComponent as ChatIcon } from "./icons/chat.svg"
import { ReactComponent as OnbordingIcon } from "./icons/onboring.svg"
import { ReactComponent as UsersIcon } from "./icons/users.svg"
import { ReactComponent as ServicesIcon } from "./icons/services.svg"
import { ReactComponent as SalesIcon } from "./icons/sale.svg"
import { ReactComponent as StaticIcon } from "./icons/static.svg"
import { ReactComponent as BanersIcon } from "./icons/banners.svg"
import { ReactComponent as NotifyIcon } from "./icons/notify.svg"
import { ReactComponent as CitiesIcon } from "./icons/cities.svg"
import { ReactComponent as DiscountIcon } from "./icons/discount.svg"
import { ReactComponent as DiscountActiveIcon } from "./icons/discount_active.svg"
import { ReactComponent as SpecializationIcon } from "./icons/specialization.svg"
import { ReactComponent as SpecialistsIcon } from "./icons/specialistIcon.svg"
import { ReactComponent as EnrollmentsIcon } from "./icons/enrollments.svg"
import { ReactComponent as Homeicon } from "./icons/home.svg"
import { UsersPage } from "../pages/UsersPage"
import { CitiesPage } from "../pages/CitiesPage"
import { SpecializationPage } from "../pages/SpecializationPage"
import ServicesPage from "../pages/ServicesPage/ServicesPage"
import ComplexesPage from "../pages/ComplexesPage/ComplexesPage"
import SpecialistsPage from "../pages/SpecialistsPage/SpecialistsPage"
import EnrollmentsPage from "../pages/EnrollmentsPage/EnrollmentsPage"
import { NotifyPage } from "../pages/Notifycations"
import { MainEditPage } from "../pages/MainEditPage"
import { PreparePage } from "../pages/PreparePage"
import { CancellationIssues } from "../pages/CancellationIssues"
import { SpecialistProvider } from "../pages/SpecialistsPage/SpecialistContext"
import DiscountPage from "../pages/DiscountPage/DiscountPage"

export const AuthLinks = [
	{ path: "/login", Page: <LoginPage /> },
	{ path: "/recovery", Page: <RecoveryPage /> },
	{
		path: "/auth/recovery-password-confirm/:token",
		Page: <SavePasswordPage />,
	},
]

export const SidebarLinks = [
	// {
	// 	title: "Администраторы",
	// 	path: "/admins",
	// 	Page: <AdminPage />,
	// 	Icon: <AdminsAcon />,
	// },
	// {
	// 	title: "Пользователи",
	// 	path: "/users",
	// 	Page: <UsersPage />,
	// 	Icon: <UsersIcon />,
	// },
	{
		title: "Города",
		path: "/cities",
		Page: <CitiesPage />,
		Icon: <CitiesIcon />,
	},
	{
		title: "Специализации",
		path: "/specializations",
		Page: <SpecializationPage />,
		Icon: <SpecializationIcon />,
	},
	{
		title: "Главная",
		path: "/main",
		Page: <MainEditPage />,
		Icon: <Homeicon />,
	},
	{
		title: "Каталог",
		path: "/catalog",
		Page: <CatalogPage />,
		Icon: <CatalogIcon />,
	},
	{
		title: "Услуги",
		path: "/services",
		Page: <ServicesPage />,
		Icon: <ServicesIcon />,
	},
	{
		title: "Комплексы",
		path: "/complexes",
		Page: <ComplexesPage />,
		Icon: <ServicesIcon />,
	},
	{
		title: "Скидки",
		path: "/discounts",
		Page: <DiscountPage />,
		Icon: <DiscountIcon />,
		IconActive: <DiscountActiveIcon />,
	},
	{
		title: "Специалисты",
		path: "/specialists",
		Page: (
			<SpecialistProvider>
				<SpecialistsPage />
			</SpecialistProvider>
		),
		Icon: <SpecialistsIcon />,
	},
	{
		title: "Заявки",
		path: "/enrollments",
		Page: <EnrollmentsPage />,
		Icon: <EnrollmentsIcon />,
		dropDownMenu: [
			{
				title: "Вопросы отмены",
				path: "/cancellation-issues",
				Page: <CancellationIssues />,
			},
		],
	},
	// { title: "Чат регистратуры", path: "/chat-register", Icon: <ChatIcon /> },
	{
		title: "Подготовка",
		path: "/prepare",
		Icon: <ServicesIcon />,
		Page: <PreparePage />,
	},
	{
		title: "Рассылка уведомлений",
		path: "/notifications",
		Icon: <NotifyIcon />,
		Page: <NotifyPage />,
	},
	// { title: "Акции", path: "/stock", Icon: <SalesIcon /> },
	// { title: "Баннеры", path: "/banners", Icon: <BanersIcon /> },
	// { title: "Онбординг", path: "/onboarding", Icon: <OnbordingIcon /> },
	// { title: "Статичные экраны", path: "/static-screens", Icon: <StaticIcon /> },
]

export const RoutesArr = [
	{
		title: "Города",
		path: "/cities",
	},
	{
		title: "Специализации",
		path: "/specializations",
	},
	{
		title: "Добавить специализацию",
		path: "/specializations?create=true",
	},
]

export enum AllRoutes {
	Login = "/login",
	Admins = "/admins",
	Main = "/main",
	AdminsEdit = "/admins?isEdit=",
	AdminsCreate = "/admins?isCreate=true",
	Recovery = "/recovery",
	SetPassword = "/set-password",
	ConfirmPassword = "/auth/recovery-password-confirm/:token",
	Catalog = "/catalog",
	CatalogCity = "/catalog?cityId=",
	Cities = "/cities",
	CitiesEdit = "/cities?edit=true&id=",
	CitiesCreate = "/cities?create=true",
	ClinicEdit = "/cities/clinic?edit=true&id=:id",
	Specialization = "/specializations",
	SpecializationEdit = "/specializations?edit=true&id=",
	SpecializationCreate = "/specializations?create=true",
	Services = "/services",
	ServiceEdit = "/services?serviceEdit=true&serviceId=",
	ServiceCreate = "/services?serviceCreate=true&defaultParentId=",
	ServicesFieldCreate = "/services?fieldCreate=true",
	ServicesFieldEdit = "/services?fieldEdit=true&fieldId=",
	Complexes = "/complexes",
	ComplexesFieldCreate = "/complexes?fieldCreate=true",
	ComplexesFieldEdite = "/complexes?fieldEdit=true&fieldId=",
	ComplexesCreate = "/complexes?create=true",
	ComplexesEdit = "/complexes?edit=true&complexId=",
	Specialists = "/specialists",
	Enrollments = "/enrollments",
	EnrollmentsViewItem = "/enrollments?id=",
	Notifycations = "/notifications?action",
	NotifycationsCreate = "/notifications?action=",
	Prepare = "/prepare",
	CancellationIssues = "/cancellation-issues",
	Discounts = "/discounts",
	DiscountsCreate = "/discounts?create=true",
	DiscountsEdit = "/discounts?edit=true&discountId=",
	DiscountsFieldCreate = "/discounts?fieldCreate=true",
	DiscountsFieldEdit = "/discounts?fieldEdit=true&fieldId=",
}
