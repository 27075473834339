import {
	useGetBannerOfIdQuery,
	useGetBannersQuery,
} from "../../../../../../services/mainBanner"

type Props = {
	params: {
		[k: string]: string
	}
}

export const useBanner = ({ params }: Props) => {
	const { data: banners } = useGetBannersQuery({
		city_id: +params?.cityId,
	})

	const { data: bannerData, isLoading: loadingBanner } = useGetBannerOfIdQuery(
		{
			id: +params?.entity,
		},
		{ skip: !params?.entity }
	)

	return { banners, bannerData, loadingBanner }
}
