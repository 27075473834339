import React from "react"
import styles from "./DeleteModal.module.scss"
import {
	useDeleteCompelxFieldMutation,
	useGetCompelxFieldQuery,
} from "../../../../services/complexes"
import { Modal } from "../../../../common/Modal"
import { message } from "antd"
import cn from "classnames"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"

type Props = {
	deleteFieldId: number | string
	setDeleteFieldId: (id: number | string) => void
}

const DeleteModal = ({ deleteFieldId, setDeleteFieldId }: Props) => {
	const navigate = useNavigate()
	const [deleteComplexField] = useDeleteCompelxFieldMutation()
	const { data: currentField } = useGetCompelxFieldQuery(deleteFieldId)

	const handleDeleteField = async () => {
		await deleteComplexField(deleteFieldId)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Папка успешно удалена")
					setDeleteFieldId(0)
					navigate(AllRoutes.Complexes)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить папку")
			})
	}
	return (
		<Modal
			visible={!!deleteFieldId && !!currentField}
			onClose={() => setDeleteFieldId(0)}
		>
			<div className={styles.modalWrapper}>
				<span className={styles.modalTitle}>Удалить</span>
				<p className={styles.modalDescription}>
					Вы уверены, что хотите удалить {currentField?.data.name}
				</p>
				<div className={styles.modalButtons}>
					<button
						onClick={() => setDeleteFieldId(0)}
						className={cn(styles.modalBtn, styles.cancel)}
					>
						Отмена
					</button>
					<button onClick={handleDeleteField} className={styles.modalBtn}>
						Удалить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteModal
