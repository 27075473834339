import { FC } from "react"
import styles from "./Contraindications.module.scss"
import { ContraindicationsType } from "./types"
import { TextArea } from "../../common/TextArea"
import { Checkbox } from "../../common/CheckBox"

interface Props {
	contrindications: ContraindicationsType[]
	setContrindications: React.Dispatch<
		React.SetStateAction<ContraindicationsType[]>
	>
}

const Contraindications: FC<Props> = ({
	contrindications,
	setContrindications,
}) => {
	const addContraindications = () => {
		const newContraindicator = {
			contraindication: "",
			note: "",
			is_required: true,
		}
		setContrindications(prev => [...prev, newContraindicator])
	}

	const handleChange = (
		index: number,
		field: "contraindication" | "note" | "is_required",
		value: string | boolean | number
	) => {
		setContrindications(prev =>
			prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
		)
	}

	const remove = (i: number) => {
		setContrindications(prev => prev.filter((_, index) => i !== index))
	}

	return (
		<div>
			<h3 className={styles.title}>Противопоказания</h3>
			<button className={styles.btn} onClick={addContraindications}>
				<div className={styles.plus} />
				<span>Добавить противопоказание</span>
			</button>

			<form className={styles.form}>
				{contrindications.map((contr, i) => (
					<div className={styles.wrap} key={i.toString()}>
						<label className={styles.label}>Противопоказание {i + 1}</label>

						<div className={styles.contraindication}>
							<TextArea
								styleContainer={{ marginBottom: 20 }}
								styles={{ textarea: { height: 56 } }}
								value={contrindications[i].contraindication}
								onChange={e =>
									handleChange(i, "contraindication", e.target.value)
								}
								isDeletedet={{ onClick: remove.bind(null, i) }}
								placeholder='Противопоказание'
							/>

							<div
								className={styles.isRequired}
								onClick={e =>
									handleChange(
										i,
										"is_required",
										!contrindications[i].is_required
									)
								}
							>
								<Checkbox checked={!contrindications[i].is_required} />

								<span>Необязательное противоказание</span>
							</div>
						</div>

						<TextArea
							styles={{ textarea: { height: 56 } }}
							value={contrindications[i].note}
							onChange={e => handleChange(i, "note", e.target.value)}
							placeholder='Примечание'
						/>
					</div>
				))}
			</form>
		</div>
	)
}

export default Contraindications
