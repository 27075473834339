import classNames from "classnames"
import { ChangeEvent, FC, ReactNode } from "react"
import { Button } from "../Button"
import { SearchField } from "../Form/SearchField"
import { Sidebar } from "../Sidebar"
import { useLayoutController } from "./Layout.controller"
import styles from "./Layout.module.scss"
import { Link } from "react-router-dom"
import { Loader } from "../loader"

interface Props {
	createBtn?: boolean
	createBtnTitle?: string
	secondCreateBtn?: boolean
	secondCreateBtnTitle?: string
	onClickCreateBtn?: () => void
	onClickSecondCreateBtn?: () => void
	isCreateBtnDisabled?: boolean
	isSecondCreateBtnDisabled?: boolean
	children: ReactNode
	search?: {
		value: string
		onSearch: (e: ChangeEvent<HTMLInputElement>) => void
	}
	noData?: boolean
	currentUrl?: {
		title: string
		path: string
	}
	isLink?: boolean
	prevLinks?: Array<{ title: string; path: string }>
	load?: boolean
}

const Layout: FC<Props> = ({
	createBtn,
	secondCreateBtn,
	onClickCreateBtn,
	onClickSecondCreateBtn,
	children,
	search,
	noData,
	currentUrl,
	prevLinks = [],
	createBtnTitle = "Создать",
	secondCreateBtnTitle = "Создать",
	isCreateBtnDisabled = false,
	isSecondCreateBtnDisabled = false,
	load,
}) => {
	const { currentTitle } = useLayoutController()

	return (
		<div className={styles.container}>
			<Sidebar />
			<div className={styles.contentWrapper}>
				<div className={styles.top}>
					<div className={styles.leftTop}>
						<span className={styles.topTitle}>{currentTitle}</span>
						<div className={styles.linksList}>
							{prevLinks &&
								prevLinks.map(link => (
									<Link className={styles.link} to={link.path} key={link.path}>
										{link.title}
									</Link>
								))}
							{currentUrl && (
								<>
									<Link
										className={styles.currentLink}
										to={currentUrl.path}
										style={{
											background:
												currentUrl.title !== "" ? "#35aaa2" : "transparent",
										}}
									>
										{currentUrl.title}
									</Link>
								</>
							)}
						</div>
					</div>
					<div className={styles.rightTop}>
						{search && (
							<SearchField value={search.value} onChange={search.onSearch} />
						)}
						{createBtn && (
							<div className={styles.btnWrapper}>
								<Button
									title={createBtnTitle}
									onClick={onClickCreateBtn}
									disabled={isCreateBtnDisabled}
									plusSvg
									isHeader
								/>
							</div>
						)}
						{secondCreateBtn && (
							<div className={styles.btnWrapper}>
								<Button
									title={secondCreateBtnTitle}
									onClick={onClickSecondCreateBtn}
									disabled={isSecondCreateBtnDisabled}
									plusSvg
									isHeader
								/>
							</div>
						)}
					</div>
				</div>
				<div
					className={classNames(styles.content, { [styles.nodata]: noData })}
				>
					{load && <Loader />}
					{!load && children}
				</div>
			</div>
		</div>
	)
}

export default Layout
