import { useLocation } from "react-router-dom"
import { SidebarLinks } from "../../constans/appRoutes"

export const useLayoutController = () => {
	const { pathname } = useLocation()

	// поиск не только по первому уровню, но и по дроп меню
	const findTitle = SidebarLinks.find(
		link =>
			link.path === pathname ||
			link.dropDownMenu?.find(sub => sub.path === pathname)
	)

	// если находимся на ссылке первого уровня - то заголовок её, иначе мы находимся на ссылке дроп меню и берем заголовок от туда
	const currentTitle =
		findTitle?.dropDownMenu && pathname !== findTitle.path
			? findTitle.dropDownMenu?.find(sub => sub.path === pathname)?.title || ""
			: findTitle?.title

	return { currentTitle }
}
