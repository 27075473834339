import React from "react";
import styles from "./SpecializationPage.module.scss";
import { useForm } from "react-hook-form";
import { TextField } from "../../common/Form/TextField";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "../../constans/appRoutes";

type Props = {
  onSubmit: (name: string, external_id: string) => void;
};

const SpecializationPageCreate = ({ onSubmit }: Props) => {
  const navigate = useNavigate();
  const {
    register,
    formState: { isValid, errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  const name = watch("name");

  return (
    <div className={styles.createSpecializationWrapper}>
      <TextField
        fieldname="name"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.name?.message as string}
        palcehoder="Название"
        isTopDescr={false}
      />
      <div className={styles.createSpecializationBtnWrapper}>
        <Button title="Сохранить" disabled={!isValid} onClick={() => onSubmit(name, name)} />
        <Button title="Отмена" disabled={false} onClick={() => navigate(AllRoutes.Specialization)} isTransparent />
      </div>
    </div>
  );
};

export default SpecializationPageCreate;
