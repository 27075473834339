import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export type cabinetType = {
  id: number;
  name: string;
};

type RequestCabinetsTypeParams = {
  clinicId: number;
};

type ResponseDeleteCabinetType = {
  message: string;
  data: string[];
};

type ResponseEditCabinetType = {
  message: string;
  data: cabinetType;
};

type RequestDeleteCabinetTypeParams = {
  id: number;
};

type RequestEditCabinetTypeParams = {
  id: number;
  name: string;
};

type RequestCreateCabinetTypeParams = {
  clinic_id: number;
  external_id: string;
  name: string;
};

export const cabinetItems = createApi({
  reducerPath: "adminCabinets",
  baseQuery,
  tagTypes: ["Cabinets"],
  endpoints: (build) => ({
    getCabinetItems: build.query<any, RequestCabinetsTypeParams>({
      query: ({ clinicId }: RequestCabinetsTypeParams) => ({
        url: `/admin/cabinets?clinicId=${clinicId}`,
      }),
      providesTags: (result) =>
        result && result.length
          ? [...result.map(({ id }: { id: number }) => ({ type: "Cabinets" as const, id })), { type: "Cabinets", id: "LIST" }]
          : [{ type: "Cabinets", id: "LIST" }],
    }),
    deleteCabinetItem: build.mutation<ResponseDeleteCabinetType, RequestDeleteCabinetTypeParams>({
      query: ({ id }: RequestDeleteCabinetTypeParams) => ({
        url: `/admin/cabinets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Cabinets", id: "LIST" }],
    }),
    editCabinetItem: build.mutation<ResponseEditCabinetType, RequestEditCabinetTypeParams>({
      query: ({ id, name }: RequestEditCabinetTypeParams) => ({
        url: `/admin/cabinets/${id}`,
        method: "PUT",
        body: { name },
      }),
      invalidatesTags: [{ type: "Cabinets", id: "LIST" }],
    }),
    createCabinetItem: build.mutation<ResponseEditCabinetType, RequestCreateCabinetTypeParams>({
      query: ({ clinic_id, external_id, name }: RequestCreateCabinetTypeParams) => ({
        url: `/admin/cabinets/`,
        method: "POST",
        body: { clinic_id, external_id, name },
      }),
      invalidatesTags: [{ type: "Cabinets", id: "LIST" }],
    }),
  }),
});

export const { useGetCabinetItemsQuery, useDeleteCabinetItemMutation, useEditCabinetItemMutation, useCreateCabinetItemMutation } = cabinetItems;
