import React from "react"
import styles from "./DeleteDiscountModal.module.scss"
import { Modal } from "../../../../common/Modal"
import { message } from "antd"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import cn from "classnames"
import {
	useDeleteDiscountMutation,
	useGetDiscountByIdQuery,
} from "../../../../services/discounts"

type Props = {
	del: number
	setDel: (id: number) => void
}

const DeleteDiscountModal = ({ del, setDel }: Props) => {
	const navigate = useNavigate()
	const { data: currentDiscount } = useGetDiscountByIdQuery(
		{ id: del },
		{ skip: !del }
	)
	const [deleteDiscount] = useDeleteDiscountMutation()

	const onHandleDelete = () => {
		deleteDiscount({ id: del })
			.unwrap()
			.then(result => {
				if (result.success) {
					message.success("Скидка успешно удалена")
					navigate(AllRoutes.Discounts)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить скидку")
			})
	}

	return (
		<Modal visible={!!del && !!currentDiscount} onClose={() => setDel(0)}>
			<div className={styles.modalWrapper}>
				<span className={styles.modalTitle}>Удалить</span>
				<p className={styles.modalDescription}>
					Вы уверены, что хотите удалить {currentDiscount?.data.name}?
				</p>
				<div className={styles.modalButtons}>
					<button
						onClick={() => setDel(0)}
						className={cn(styles.modalBtn, styles.cancel)}
					>
						Отмена
					</button>
					<button onClick={onHandleDelete} className={styles.modalBtn}>
						Удалить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteDiscountModal
