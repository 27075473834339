import React from "react";
import styles from "./CitiesPage.module.scss";
import { useForm } from "react-hook-form";
import { TextField } from "../../common/Form/TextField";
import { Button } from "../../common/Button";
import { useGetClinicItemQuery } from "../../services/clinics";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cabinets from "./Cabinets";
import { AllRoutes } from "../../constans/appRoutes";

type Props = {
  id?: number;
  onEditSubmit: (
    clinic_id: number,
    name: string,
    address: string,
    phone_number: string
  ) => void;
  onCreateSubmit: (
    city_id: number,
    external_id: string,
    name: string,
    address: string,
    phone_number: string
  ) => void;
};

const ClinicEdit = ({ onEditSubmit, onCreateSubmit, id }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const currentId = params.clinicId?.toString();
  const city_id = params.id;

  const {
    register,
    formState: { errors },
    watch,
  } = useForm({ mode: "onBlur" });
  const { data } = useGetClinicItemQuery(
    { clinic_id: +currentId },
    { skip: !currentId }
  );

  const name = watch("name") ? watch("name") : data?.data?.name;
  const address = watch("address") ? watch("address") : data?.data?.address;
  const phone = watch("phone") ? watch("phone") : data?.data?.phone_number;

  return (
    <div className={styles.createCityWrapper}>
      <TextField
        defaultValue={data?.data?.name}
        fieldname="name"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.name?.message as string}
        palcehoder="Название*"
        isTopDescr={false}
      />
      <TextField
        defaultValue={data?.data?.address}
        fieldname="address"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.address?.message as string}
        palcehoder="Адрес*"
        isTopDescr={false}
      />
      <TextField
        defaultValue={data?.data?.phone_number}
        fieldname="phone"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.phone?.message as string}
        palcehoder="Номер телефона*"
        isTopDescr={false}
      />
      <div className={styles.createCityBtnWrapper}>
        {id ? (
          <Button
            title="Сохранить"
            disabled={false}
            onClick={() => onEditSubmit(id, name, address, phone)}
          />
        ) : null}
        {!id ? (
          <Button
            title="Создать"
            disabled={false}
            onClick={() =>
              onCreateSubmit(+city_id, phone, name, address, phone)
            }
          />
        ) : null}
        <Button
          title="Отмена"
          disabled={false}
          onClick={() => navigate(AllRoutes.CitiesEdit + city_id)}
          isTransparent
        />
      </div>
      {id ? <Cabinets clinic_id={+currentId} /> : null}
    </div>
  );
};

export default ClinicEdit;
