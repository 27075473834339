import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AllRoutes } from "../../constans/appRoutes";
import { loadFingerPrintId } from "../../helpers/loadFingrPrintid";
import { login } from "../../services/login";

export const useSavePasswordController = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [fetchSetPassword, { isLoading }] = login.useSetPassswordMutation();
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    reset,
    watch,
    setError,
  } = useForm({
    mode: "onBlur",
  });

  const watchFields = watch(["password", "repeatPassword"]);

  const onSubmit = async (data: any) => {
    if (!isValid) return;

    const fingerprint = await loadFingerPrintId();

    if (watchFields[0] !== watchFields[1]) {
      return setError("repeatPassword", {
        type: "onBlur",
        message: "Пароли должны совпадать",
      });
    }

    if (token) {
      const response = await fetchSetPassword({
        token,
        password: data.password,
        fingerprint,
      });

      if (response && "data" in response) {
        const { message } = response.data;

        if (message === "Success") {
          navigate(AllRoutes.Login);
        }
      }
    }

    reset();
  };

  return {
    isValid,
    errors,
    isLoading,
    register,
    onSubmit,
    handleSubmit,
  };
};
