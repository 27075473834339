import { ChangeEvent, useMemo, useState } from "react"
import { Layout } from "../../common/Layout"
import { message } from "antd"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { MainTable } from "../../common/MainTable"
import {
	useCreateCityItemMutation,
	useDeleteCityItemMutation,
	useEditCityItemMutation,
	useGetCityItemsQuery,
} from "../../services/cities"
import { AllRoutes } from "../../constans/appRoutes"
import CitiesPageEdit from "./CitiesPageEdit"
import CitiesPageCreate from "./CitiesPageCreate"
import ClinicEdit from "./ClinicEdit"
import {
	useCreateClinicItemMutation,
	useEditClinicItemMutation,
} from "../../services/clinics"
import { useDebounce } from "../../hooks/useDebaunce"

type Props = {}

const CitiesPage = (props: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const isEdit = !!params?.edit
	const isCreate = !!params?.create
	const isClinicEdit = !!params?.clinicedit

	const [search, setSearch] = useState("")
	const debouncedSearch = useDebounce(search, 500)

	const columns = [
		{
			title: "Индентификатор",
			width: 2,
		},
		{
			title: "Название",
			width: 2,
		},
		{
			title: "",
			width: 1,
		},
	]

	const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
		setSearch(target.value)

	const { data, isLoading } = useGetCityItemsQuery({
		search: debouncedSearch || "",
	})
	const [deleteCity] = useDeleteCityItemMutation()
	const [createCity] = useCreateCityItemMutation()
	const [editCity] = useEditCityItemMutation()
	const [editClinic] = useEditClinicItemMutation()
	const [createClinic] = useCreateClinicItemMutation()

	const onHandleDelete = async (city_id: number) => {
		await deleteCity({ city_id })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Город успешно удалён")
					navigate(AllRoutes.Cities)
				}
			})
			.catch(err => {
				if (err.data.errors.message) {
					message.error(err.data.errors.message)
				} else {
					message.error("Не удалось удалить город")
				}
			})
	}

	const onHandleCreate = async (
		name: string,
		phone: string,
		timezone: number
	) => {
		await createCity({ name, phone, timezone })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Город успешно добавлен")
					navigate(AllRoutes.Cities)
				}
			})
			.catch(err => {
				message.error("Не удалось добавить город")
			})
	}

	const onHandleEdit = async (
		name: string,
		city_id: number,
		phone: string,
		timezone: number
	) => {
		await editCity({ name, city_id, phone, timezone })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Город успешно изменён")
					navigate(AllRoutes.Cities)
				}
			})
			.catch(err => {
				message.error("Не удалось редактировать город")
			})
	}

	const onHandleClinicEdit = async (
		clinic_id: number,
		name: string,
		address: string,
		phone_number: string
	) => {
		await editClinic({ clinic_id, name, address, phone_number })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Клиника успешно изменена")
				}
			})
			.catch(err => {
				message.error("Не удалось изменить клинику")
			})
	}

	const onHandleClinicCreate = async (
		city_id: number,
		external_id: string,
		name: string,
		address: string,
		phone_number: string
	) => {
		await createClinic({ city_id, external_id, name, address, phone_number })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Клиника успешно создана")
					navigate(AllRoutes.Cities + `?edit=true&id=${params?.id}`)
				}
			})
			.catch(err => {
				message.error("Не удалось создать клинику")
			})
	}

	const goEdit = (id?: number) =>
		navigate(AllRoutes.Cities + `?edit=true&id=${id}`)
	const goCreate = () => navigate(AllRoutes.Cities + `?create=true`)

	const prevUrls = useMemo(() => {
		return [{ title: "Города", path: "/cities" }]
	}, [])

	const prevUrlsForClinicEdit = useMemo(() => {
		return [
			{ title: "Города", path: "/cities" },
			{
				title: isEdit ? "Редактировать город" : "Добавить город",
				path: isEdit
					? `/cities?edit=true&id=${params?.id}`
					: "/cities?create=true",
			},
		]
	}, [isEdit, params])

	const prevUrlsForCityEdit = useMemo(() => {
		return [{ title: "Города", path: "/cities" }]
	}, [])

	const prevLinks = useMemo(() => {
		if (isClinicEdit) {
			return prevUrlsForClinicEdit
		}

		if (isCreate) {
			return prevUrls
		}

		if (isEdit) {
			return prevUrlsForCityEdit
		}

		return []
	}, [
		isClinicEdit,
		isCreate,
		isEdit,
		prevUrls,
		prevUrlsForCityEdit,
		prevUrlsForClinicEdit,
	])

	const title = useMemo(() => {
		if (isCreate) {
			return "Добавить город"
		}

		if (!isCreate && isEdit && !isClinicEdit) {
			return "Редактирование города"
		}

		if (isClinicEdit && params.clinicId) {
			return "Редактирование клиники"
		}

		if (isClinicEdit && !params.clinicId) {
			return "Добавить клинику"
		}

		return ""
	}, [isCreate, isEdit, isClinicEdit, params])

	return (
		<Layout
			search={!isEdit ? { value: search, onSearch: handleSearch } : undefined}
			createBtn={!isEdit}
			noData={isLoading ? false : !data}
			onClickCreateBtn={goCreate}
			currentUrl={{
				path: `${useLocation().pathname}${useLocation().search}`,
				title: title,
			}}
			isLink={isCreate || isEdit}
			prevLinks={prevLinks}
		>
			{!isEdit && !isCreate && !isClinicEdit && (
				<MainTable
					columns={columns}
					data={data?.data ? data?.data : []}
					onDelete={onHandleDelete}
					onEdit={goEdit}
				/>
			)}
			{isCreate && !isClinicEdit && (
				<CitiesPageCreate onSubmit={onHandleCreate} />
			)}
			{isEdit && !isClinicEdit && (
				<CitiesPageEdit onNameChange={onHandleEdit} />
			)}
			{isClinicEdit && (
				<ClinicEdit
					id={+params.clinicId}
					onEditSubmit={onHandleClinicEdit}
					onCreateSubmit={onHandleClinicCreate}
				/>
			)}
		</Layout>
	)
}

export default CitiesPage
