import React from "react"
import styles from "./DeleteComplexModal.module.scss"
import {
	useDeleteComplexMutation,
	useGetComplexQuery,
} from "../../../../services/complexes"
import { Modal } from "../../../../common/Modal"
import { message } from "antd"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import cn from "classnames"

type Props = {
	del: number
	setDel: (id: number) => void
}

const DeleteComplexModal = ({ del, setDel }: Props) => {
	const navigate = useNavigate()
	const { data: currentComplex } = useGetComplexQuery({ complex_id: del })
	const [deleteComplex] = useDeleteComplexMutation()

	const onHandleDelete = () => {
		deleteComplex({ complex_id: del })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Комплекс успешно удален")
					navigate(AllRoutes.Complexes)
					setDel(0)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить комплекс")
			})
	}

	return (
		<Modal visible={!!del && !!currentComplex} onClose={() => setDel(0)}>
			<div className={styles.modalWrapper}>
				<span className={styles.modalTitle}>Удалить</span>
				<p className={styles.modalDescription}>
					Вы уверены, что хотите удалить {currentComplex?.data.name}?
				</p>
				<div className={styles.modalButtons}>
					<button
						onClick={() => setDel(0)}
						className={cn(styles.modalBtn, styles.cancel)}
					>
						Отмена
					</button>
					<button onClick={onHandleDelete} className={styles.modalBtn}>
						Удалить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteComplexModal
