import { Jodit } from "jodit-react"

export const columns = [
	{
		title: "Индентификатор",
		width: 2,
	},
	{
		title: "ФИО",
		width: 2,
	},
	{
		title: "Специальность",
		width: 2,
	},
	{
		title: "",
		width: 1,
	},
]

export const columnsReviews = [
	{
		title: "Индентификатор",
		width: 2,
	},
	{
		title: "Имя",
		width: 2,
	},
	{
		title: "Оценка",
		width: 2,
	},
	{
		title: "Дата",
		width: 2,
	},
	{
		title: "",
		width: 1,
	},
]

export const buttons = [
	"undo",
	"redo",
	"|",
	"bold",
	"strikethrough",
	"underline",
	"italic",
	"|",
	"superscript",
	"subscript",
	"|",
	"align",
	"|",
	"ul",
	"ol",
	"outdent",
	"indent",
	"|",
	"font",
	"fontsize",
	"brush",
	"paragraph",
	"|",
	"image",
	"link",
	"table",
	"|",
	"hr",
	"eraser",
	"copyformat",
	"|",
	"fullsize",
	"selectall",
	"print",
	"|",
	"source",
	"|",
]

export const config = {
	readonly: false,
	addNewLineOnDBLClick: false,
	toolbar: true,
	spellcheck: true,
	language: "ru",
	toolbarAdaptive: false,
	showCharsCounter: false,
	showWordsCounter: false,
	showXPathInStatusbar: false,
	askBeforePasteHTML: true,
	askBeforePasteFromWord: true,
	buttons: buttons,
	uploader: {
		...Jodit.defaultOptions.uploader,
		insertImageAsBase64URI: true,
	},
	width: 1000,
	height: "100%",
	tabIndex: 1,
}

type TitleCreate = {
	isCreate: boolean
	isEdit: boolean
	isReviewEdit: boolean
	isReviews: boolean
	isReviewCreate: boolean
	isCustomize: boolean
	currentSpecialistId?: string | number
}

export const getTitle = ({
	isCreate,
	isEdit,
	isReviewEdit,
	isReviews,
	isReviewCreate,
	isCustomize,
}: TitleCreate) => {
	if (isCreate) {
		if (
			!isEdit &&
			!isReviews &&
			!isReviewEdit &&
			!isCustomize &&
			!isReviewCreate &&
			isCreate
		) {
			return "Добавить специалиста"
		} else {
			return "Настройка услуг"
		}
	}

	if (isEdit && !isReviews && !isReviewEdit && !isReviewCreate) {
		if (
			isEdit &&
			!isReviews &&
			!isReviewEdit &&
			!isCustomize &&
			!isReviewCreate
		) {
			return "Редактирование специалиста"
		} else {
			return "Настройка услуг"
		}
	}

	if (isEdit && isReviews && !isReviewEdit && !isReviewCreate) {
		return "Отзывы"
	}

	if (isReviewCreate || isReviewEdit) {
		return "Создание/редактирование отзыва"
	}

	return ""
}

export const getUrlsList = ({
	isCreate,
	isEdit,
	isReviewEdit,
	isReviews,
	isReviewCreate,
	isCustomize,
	currentSpecialistId,
}: TitleCreate) => {
	const prevUrls = [{ title: "Специалисты", path: "/specialists" }]

	const cusomizeUrls = [
		...prevUrls,
		{ title: "Добавить специалиста", path: "/specialists?create=true" },
	]

	const prevUrlsReviews = [
		...prevUrls,
		{
			title: "Редактирование специалиста",
			path: `/specialists?edit=true&id=${currentSpecialistId}`,
		},
	]

	const prevUrlsReviewEdit = [
		...prevUrlsReviews,
		{
			title: "Отзывы",
			path: `/specialists?edit=true&id=${currentSpecialistId}&reviews=true`,
		},
	]
	if (isCreate && !isCustomize) {
		return prevUrls
	}

	if (isCreate && isCustomize) {
		return cusomizeUrls
	}

	if (isEdit && !isReviews && !isReviewCreate && !isReviewEdit && isCustomize) {
		return prevUrlsReviews
	}

	if (isEdit && isReviews && !isReviewCreate && !isReviewEdit && !isCustomize) {
		return prevUrlsReviews
	}

	if (isEdit && !isReviews && !isReviewCreate && !isReviewEdit) {
		return prevUrls
	}

	if (isEdit && isReviewCreate) {
		return prevUrlsReviewEdit
	}

	if (isEdit && isReviewEdit) {
		return prevUrlsReviewEdit
	}

	return []
}
