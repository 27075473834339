import { FC, memo } from "react"
import styles from "./SelectedTreeComplexesItem.module.scss"
import { Checkbox } from "../../CheckBox"
import { IComboService } from "../../../models/ComboServices"
import { SelectedComplexes } from "../SelectedComplexesFilds/useSelectedComplexesFilds"

type Props = {
	complex: IComboService
	checked: boolean
	onSelectComplex: (complex: SelectedComplexes) => void
}

const SelectedTreeComplexesItem: FC<Props> = ({
	checked,
	complex,
	onSelectComplex,
}) => {
	const handleSelectComx = () => {
		onSelectComplex({
			complex_id: complex.id,
			combo_field_id: complex.combo_field_id,
		})
	}

	return (
		<div className={styles.treeComplexes}>
			<div
				onClick={handleSelectComx}
				style={{ display: "flex", alignItems: "center" }}
			>
				<Checkbox checked={checked} />
				<div className={styles.complexNameBlock}>
					<span className={styles.complexName}>{complex.name}</span>
				</div>
			</div>
		</div>
	)
}

export default memo(SelectedTreeComplexesItem)
