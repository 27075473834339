import React, { useEffect, useMemo } from "react"
import styles from "./DiscountFieldEdite.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../../../common/Form/TextField"
import { Button } from "../../../../common/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import { prepareData, sortFields } from "../../helpers"
import {
	IDiscountField,
	useEditDiscountFieldMutation,
	useGetDiscountFieldByIdQuery,
} from "../../../../services/discounts"
import { message } from "antd"

type Props = {
	fieldsData: IDiscountField[]
}

const DiscountFieldEdite = ({ fieldsData }: Props) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const currentId = params?.fieldId

	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const name = watch("name")

	const [editDiscountField] = useEditDiscountFieldMutation()
	const { data: currentField } = useGetDiscountFieldByIdQuery(
		{ id: +currentId },
		{ skip: !currentId }
	)

	const dataForPrepare = useMemo(() => {
		if (fieldsData) {
			const sortedData = [...fieldsData]
			return prepareData(sortFields(sortedData as any))
		}
	}, [fieldsData])

	// не трогать так надо
	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (fieldsData) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [fieldsData, dataForPrepare])

	const handleEditeField = async () => {
		if (currentId) {
			await editDiscountField({ id: +currentId, name })
				.unwrap()
				.then(result => {
					if (result.success) {
						message.success("Папка успешно изменена")
						navigate(AllRoutes.Discounts)
					}
				})
				.catch(err => {
					message.error("Не удалось изменить папку")
				})
		}
	}

	useEffect(() => {
		if (currentField && currentId) {
			setValue("name", currentField.data.name)
		}
	}, [currentId, currentField, setValue])

	return (
		<div style={{ padding: "30px 0" }}>
			<div className={styles.halfContaienr}>
				<TextField
					defaultValue={currentField?.data.name}
					fieldname='name'
					register={register}
					options={{ required: "" }}
					error={errors.name?.message as string}
					palcehoder='Названи'
					isMarginBottom={false}
				/>
			</div>
			<div className={styles.saveBtnWrapper}>
				<Button title='Сохранить' disabled={!name} onClick={handleEditeField} />
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Discounts)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default DiscountFieldEdite
