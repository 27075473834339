import {
	ChangeEvent,
	FC,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react"
import styles from "./DiscountPeriodCard.module.scss"
import {
	PeriodDiscountType,
	RangePeriodsDiscunt,
} from "../../useDiscountPageCreateAndEdit"
import { Select } from "../../../../common/Select"
import "react-multi-date-picker/styles/colors/teal.css"
import TimePicker from "react-multi-date-picker/plugins/time_picker"
import DatePicker from "react-multi-date-picker"
import { TextField } from "../../../../common/Form/TextField"
import { useForm } from "react-hook-form"
import { TextArea } from "../../../../common/TextArea"
import { ReactComponent as AddIcon } from "../../../../constans/icons/add.svg"
import { ReactComponent as DeleteIcon } from "../../../../constans/icons/delete.svg"
import cn from "classnames"
import { getTimePeriodText } from "../../helpers"
import { DiscoutPeriods } from "../../../../services/discounts"

type Props = {
	period: PeriodDiscountType
	changePeriod: (period: PeriodDiscountType) => void
	hiddenLastCardBtn: boolean
	isLast: boolean
	isFirst: boolean
	addPeriod: () => void
	deletePeriod: (period: PeriodDiscountType) => void
	discoutPeriodsOptions?: {
		label: string
		value: DiscoutPeriods
	}[]
	rangePeriods: RangePeriodsDiscunt | null
}

const DiscountPeriodCard: FC<Props> = ({
	period,
	changePeriod,
	addPeriod,
	hiddenLastCardBtn,
	isLast,
	discoutPeriodsOptions,
	isFirst,
	deletePeriod,
	rangePeriods,
}) => {
	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm({ mode: "onBlur" })

	const rate = watch("rate")

	const [start, setStart] = useState<any | undefined>()
	const [end, setEnd] = useState<any | undefined>()

	useEffect(() => {
		if (period.start && !start) {
			setStart(period.start)
		}
	}, [period, start])

	useEffect(() => {
		if (period.end && !end) {
			setEnd(period.end)
		}
	}, [period, end])

	const startError = useMemo(() => {
		if (start && end) return start >= end
	}, [start, end])

	const periodsOptions = useMemo(() => {
		return discoutPeriodsOptions?.filter(it => it.value !== period.period)
	}, [period, discoutPeriodsOptions])

	const onChangeErrorPeriod = useCallback(() => {
		const newPeriod: PeriodDiscountType = {
			...period,
			errorTime: !!startError,
		}

		changePeriod(newPeriod)
	}, [period, startError, changePeriod])

	const onChangePeriod = (value: DiscoutPeriods) => {
		if (rangePeriods) {
			const start = new Date(rangePeriods?.[value]?.start)
			const end = new Date(rangePeriods?.[value]?.end)

			const newPeriod: PeriodDiscountType = {
				id: period.id,
				start,
				end,
				rate: "",
				descriprion: "",
				period: value,
				errorTime: false,
			}

			changePeriod(newPeriod)
		}
	}

	const onChangeRate = useCallback(
		(value: string) => {
			const newPeriod: PeriodDiscountType = {
				...period,
				rate: value,
			}

			changePeriod(newPeriod)
		},
		[changePeriod, period]
	)

	const onDescriprionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const newPeriod: PeriodDiscountType = {
			...period,
			descriprion: event.target.value,
		}

		changePeriod(newPeriod)
	}

	const onChangeStart = (value: any) => {
		const date = new Date(value)

		const newPeriod: PeriodDiscountType = {
			...period,
			start: date,
		}

		setStart(+date)
		changePeriod(newPeriod)
	}

	const onChangeEnd = (value: any) => {
		const date = new Date(value)

		const newPeriod: PeriodDiscountType = {
			...period,
			end: date,
		}

		setEnd(+date)
		changePeriod(newPeriod)
	}

	// useEffect(() => {
	// 	setStart(rangePeriods[period.period].start)
	// 	setEnd(rangePeriods[period.period].end)
	// }, [period])

	useEffect(() => {
		onChangeErrorPeriod()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startError])

	useEffect(() => {
		if (rangePeriods) {
			const start = period.start
			const end = period.end

			const newPeriod: PeriodDiscountType = {
				...period,
				end,
				start,
			}

			changePeriod(newPeriod)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rangePeriods])

	useEffect(() => {
		if (rate) onChangeRate(rate)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rate])

	useEffect(() => {
		if (!rate && period.rate) {
			setValue("rate", period.rate)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rate, period])

	const timePeriodText = useMemo(() => {
		return getTimePeriodText(rangePeriods, period.period)
	}, [period])

	return (
		<div className={styles.card}>
			<Select
				optionFilterProp='label'
				style={{ width: "100%", marginBottom: 34 }}
				placeholder='Время суток действия скидки'
				value={period.period + timePeriodText}
				onChange={onChangePeriod}
				options={periodsOptions}
				classes={{ placeholder: styles.placeholderSelect }}
			/>

			<p className={styles.timeTitle}>Выбор времени скидки</p>

			<div className={styles.timePickerBlock}>
				<div className={styles.pickerWrapper}>
					<span
						className={cn(styles.pickerTitle, {
							[styles.pickerTitleError]: startError,
						})}
					>
						Начало скидки
					</span>
					<DatePicker
						disableDayPicker
						style={{ width: 200 }}
						format='HH:mm'
						value={start}
						minDate={rangePeriods?.[period.period]?.start}
						maxDate={rangePeriods?.[period.period]?.end}
						plugins={[<TimePicker hideSeconds />]}
						inputClass={cn(styles.pickerInput, {
							[styles.pickerInputError]: startError,
						})}
						onChange={onChangeStart}
					/>
				</div>

				<div className={styles.pickerWrapper}>
					<span
						className={cn(styles.pickerTitle, {
							[styles.pickerTitleError]: startError,
						})}
					>
						Завершение скидки
					</span>
					<DatePicker
						disableDayPicker
						style={{ width: 200 }}
						format='HH:mm'
						value={end}
						minDate={
							period.period === DiscoutPeriods.night
								? rangePeriods?.[period.period]?.start
								: rangePeriods?.[period.period].start
						}
						maxDate={
							period.period === DiscoutPeriods.night
								? rangePeriods?.[period.period]?.end
								: rangePeriods?.[period.period]?.end
						}
						onChange={onChangeEnd}
						plugins={[<TimePicker hideSeconds />]}
						inputClass={cn(styles.pickerInput, {
							[styles.pickerInputError]: startError,
						})}
					/>
				</div>
			</div>

			<TextField
				fieldname='rate'
				register={register}
				options={{ required: "Поле обязательно для заполнения" }}
				error={errors.name?.message as string}
				palcehoder='Введите процент скидки'
				isMarginBottom={false}
				type='number'
				classes={{ title: styles.placeholderSelect }}
			/>

			<div className={styles.description}>
				<TextArea
					rows={1}
					draggable={false}
					placeholder='Введите описание скидки'
					required
					defaultValue={""}
					classesNames={{
						placeholder: styles.placeholderSelect,
					}}
					value={period.descriprion}
					onChange={onDescriprionChange}
				/>
			</div>

			<div
				className={cn(styles.btns, {
					[styles.btnsEnd]: hiddenLastCardBtn
						? hiddenLastCardBtn
						: !hiddenLastCardBtn && !isLast && !isFirst,
				})}
			>
				{!hiddenLastCardBtn && isLast && (
					<button className={styles.addBtn} onClick={addPeriod}>
						<AddIcon />
						<span>Добавить время</span>
					</button>
				)}

				{!isFirst && (
					<button
						className={styles.deleteBtn}
						onClick={() => deletePeriod(period)}
					>
						<DeleteIcon />
					</button>
				)}
			</div>
		</div>
	)
}

export default memo(DiscountPeriodCard)
