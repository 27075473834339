import { FC, memo } from "react"
import { Button } from "../../../../../common/Button"
import { ActionTypes } from "../../../types"
import styles from "../Main.module.scss"
import BannerTypeItem from "./BannerTypeItem/BannerTypeItem"
import { useCreateBanners } from "./CreateOrEditBanners.controller"
import { Loader } from "../../../../../common/loader"

type Props = {
	action: ActionTypes
}

const CreateOrEditBanners: FC<Props> = ({ action }) => {
	const {
		onSubmit,
		goBack,
		errors,
		findServOfId,
		findSpecOfId,
		handleChangeIcon,
		handleEntiyChange,
		handleServChange,
		handleSpecChange,
		handleTypeBannerChange,
		handledeleteImage,
		register,
		servOptions,
		description,
		specOptions,
		type,
		state,
		loading,
		loadingData,
		disabled,
		complexOptions,
		findComplexOfId,
		handleComplexChange,
		setDescription,
	} = useCreateBanners({
		action,
	})

	return (
		<>
			{loadingData && (
				<div className={styles.load}>
					<Loader />
				</div>
			)}
			<div className={styles.inner}>
				<BannerTypeItem
					errors={errors}
					setDescription={setDescription}
					description={description}
					findServOfId={findServOfId}
					findSpecOfId={findSpecOfId}
					findComplexOfId={findComplexOfId}
					handleChangeIcon={handleChangeIcon}
					handleEntiyChange={handleEntiyChange}
					handleServChange={handleServChange}
					handleSpecChange={handleSpecChange}
					handleComplexChange={handleComplexChange}
					handleTypeBannerChange={handleTypeBannerChange}
					handledeleteImage={handledeleteImage}
					register={register}
					servOptions={servOptions}
					specOptions={specOptions}
					complexOptions={complexOptions}
					state={state}
					type={type}
				/>

				<div className={styles.footer}>
					<div className={styles.bottom}>
						<Button
							disabled={disabled}
							loading={loading}
							title={action === "create" ? "Создать" : "Сохранить"}
							onClick={onSubmit}
						/>
						<div onClick={goBack} className={styles.btnBottom}>
							Отмена
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default memo(CreateOrEditBanners)
