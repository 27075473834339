import { useState } from "react"
import { useGetCityItemsQuery } from "../../services/cities"
import { useDebounce } from "../../hooks/useDebaunce"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { ActionTypes, EntityType } from "./types"

export const useMAinEditContrller = () => {
	const nav = useNavigate()
	const { pathname } = useLocation()

	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)
	const [search, setSeach] = useState("")
	const debauncedSearch = useDebounce(search, 200)
	const { data: citydata, isLoading } = useGetCityItemsQuery({
		search: debauncedSearch,
	})

	const cityId = params?.cityId
	const entity = params.entity as EntityType
	const action = params?.action as ActionTypes

	const isEntityBanners = entity === EntityType.Banners
	const isEntityServices = entity === EntityType.Service

	const isCreate = action === "create"
	const isEdit = action === "edit"

	const prefix = isCreate
		? "Создание группировки"
		: isEdit && "Редактирование группировки"

	const bredCrumbsTitle = `${prefix}`
	const actionparams = Object.entries(params)
		.map(([key, value]) => `${key}=${value}`)
		.flat()
		.join("&")

	const cities = citydata?.data
	const cityOfId = cities?.find(city => city.id === +cityId)

	const handleSearch = (text: string) => {
		setSeach(text)
	}

	const handleAction = (
		id: number,
		entity: EntityType,
		action: ActionTypes
	) => {
		if (action === "delete") {
		} else if (action === "edit") {
		} else {
			nav(pathname + `?cityId=${cityId}&action=${action}&entity=${entity}`)
		}
	}

	const onCreateAction = () => {
		handleAction(0, EntityType.Banners, "create")
	}

	const handleSelectCity = (id: number) => nav(AllRoutes.Main + "?cityId=" + id)

	return {
		action,
		cityId,
		cities,
		cityOfId,
		isLoading,
		actionparams,
		bredCrumbsTitle,
		entity,
		handleSearch,
		handleSelectCity,
		onCreateAction,
	}
}
