import React, { ChangeEvent, useMemo, useState } from "react"
import styles from "./ComplexesFieldCreate.module.scss"
import { useForm } from "react-hook-form"
import { TextField } from "../../../../common/Form/TextField"
import { Button } from "../../../../common/Button"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../../../constans/appRoutes"
import { message } from "antd"
import { RequestCreateFieldComplexType } from "../../../../services/services"
import { TextArea } from "../../../../common/TextArea"
import { prepareData, sortFields } from "../../helpers"
import {
	IComplexField,
	useCreateComplexFieldMutation,
} from "../../../../services/complexes"

export type treeSelectorData = {
	title: string | React.ReactNode
	id: number
	parent_id?: number | null
	value: string
	children: treeSelectorData[]
}

type Props = {
	fieldsData: IComplexField[]
}

const ComplexesFieldCreate = ({ fieldsData }: Props) => {
	const navigate = useNavigate()
	const {
		register,
		formState: { errors },
		watch,
	} = useForm({ mode: "onBlur" })

	const dataForPrepare = useMemo(() => {
		if (fieldsData) {
			const sortedData = [...fieldsData]
			return prepareData(sortFields(sortedData as any))
		}
	}, [fieldsData])

	const generatedFieldData = useMemo(() => {
		const data: any[] = []
		if (fieldsData) {
			dataForPrepare.forEach((item: any) => {
				if (item) {
					data.push(item)
				}
			})
		}
		return data
	}, [fieldsData, dataForPrepare])

	const [createComplexField] = useCreateComplexFieldMutation()

	const [parentFieldValue, setParentFieldValue] = useState<string>()
	const [parentId, setParentId] = useState<number>(0)
	const [description, setDescription] = useState("description")

	const onChangeSelect = (newValue: string) => {
		setParentFieldValue(newValue)
	}

	const name = watch("name")

	const handleDescriptionOnChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		setDescription(event.target.value)
	}

	const handleCreateField = async () => {
		const sendData: RequestCreateFieldComplexType = {
			name,
			description,
			// parent_id: parentId.toString(),
		}

		await createComplexField(sendData)
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Папка успешно создана")
					navigate(AllRoutes.Complexes)
				}
			})
			.catch(err => {
				console.log(err)

				message.error("Не удалось создать папку")
			})
	}

	return (
		<div style={{ padding: "30px 0" }}>
			<div className={styles.halfContaienr}>
				{/* <TreeSelect
					value={parentFieldValue}
					dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
					placeholder='Родительская папка'
					allowClear
					treeDefaultExpandAll
					onChange={onChangeSelect}
					treeData={generatedFieldData}
				/> */}
				<TextField
					fieldname='name'
					register={register}
					options={{ required: "" }}
					error={errors.name?.message as string}
					palcehoder='Название'
					isMarginBottom={false}
				/>
				{/* <TextArea
					rows={9}
					draggable={false}
					placeholder='Описание*'
					required
					defaultValue={""}
					value={description}
					onChange={handleDescriptionOnChange}
				/> */}
			</div>
			<div className={styles.createServiceBtnWrapper}>
				<Button
					title='Сохранить'
					// disabled={description.length === 0 || !name}
					disabled={!name}
					onClick={handleCreateField}
				/>
				<Button
					title='Отмена'
					disabled={false}
					onClick={() => navigate(AllRoutes.Complexes)}
					isTransparent
				/>
			</div>
		</div>
	)
}

export default ComplexesFieldCreate
