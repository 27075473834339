import { ChangeEvent, useEffect, useState } from "react";
import styles from "./ServicesPage.module.scss";
import { Button } from "../../common/Button";
import { ServicePricesType } from "../../services/services";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCityItemsQuery } from "../../services/cities";
import { SearchField } from "../../common/Form/SearchField";
import { ICity } from "../../models/City";

type Props = {
  servicePricesData: ServicePricesType[];
};

const PriceCitiesList = ({ servicePricesData }: Props) => {
  const navigate = useNavigate();
  const currentUrl = `${useLocation().pathname}${useLocation().search}`;

  const [search, setSearch] = useState("");
  const [list, setList] = useState<ICity[]>([]);

  const goEditPrice = (id: number, cityId: number) => {
    navigate(currentUrl + `&priceId=${id}&cityId=${cityId}`);
  };

  const goCreatePrice = (city_id: number) => {
    navigate(currentUrl + `&createPrice=true&cityId=${city_id}`);
  };

  const { data: citiesData } = useGetCityItemsQuery({});

  const getPriceInCity = (
    cityId: number,
    servicePricesData: ServicePricesType[]
  ) => {
    return servicePricesData.find((price) => price.city.id === cityId)?.price;
  };

  const getPriceIdInCity = (
    cityId: number,
    servicePricesData: ServicePricesType[]
  ) => {
    return servicePricesData.find((price) => price.city.id === cityId)?.id;
  };

  useEffect(() => {
    if (citiesData?.data) {
      setList(citiesData?.data);
    }
  }, [citiesData?.data]);

  useEffect(() => {
    if (search !== "") {
      setList(
        list.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else if (search === "" && citiesData?.data) {
      setList(citiesData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, citiesData?.data]);

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);

  return (
    <div className={styles.priceCities__list}>
      <SearchField value={search} onChange={handleSearch} maxWidth={750} />

      {list.map((city: ICity) => {
        return (
          <Button
            key={`${city.name} ${city.id}`}
            title={
              <div className={styles.priceCities__text}>
                <span>{city.name}</span>
                <span>
                  {getPriceInCity(city.id, servicePricesData)
                    ? `${getPriceInCity(city.id, servicePricesData)} ₽`
                    : "Нет цены"}
                </span>
              </div>
            }
            disabled={false}
            classes={{ btn: styles.citiesBtn }}
            isTransparent
            onClick={() =>
              getPriceIdInCity(city.id, servicePricesData)
                ? goEditPrice(
                    getPriceIdInCity(city.id, servicePricesData) ?? 0,
                    city.id
                  )
                : goCreatePrice(city.id)
            }
          />
        );
      })}
    </div>
  );
};

export default PriceCitiesList;
