import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export type AdministratorType = {
  id: number;
  name: string;
  surname: string;
  patronymic: string;
  email: string;
  role_code: string;
  password: string;
  password_confirmation: string;
  cities: {
    id: number;
    name: string;
  }[];
  role: {
    code: string;
    name: string;
  };
};

export type RequestAdministratorType = {
  id: number;
};

export type ResponseAdministratorType = {
  data: AdministratorType;
};

export type ResponseAdministratorsType = {
  data: AdministratorType[];
};

export type ResponseUserRolesType = {
  data: {
    code: string;
    name: string;
  }[];
};

export type RequestCreateAdminType = {
  name: string;
  surname: string;
  patronymic: string;
  email: string;
  role_code: string;
  cities: number[];
  password: string;
  password_confirmation: string;
};

export type RequestEditAdminType = {
  id: number;
  name: string;
  surname: string;
  patronymic: string;
  email: string;
  role_code: string;
  cities: number[];
  password?: string;
  password_confirmation?: string;
};

export const administratorItems = createApi({
  reducerPath: "administrators",
  baseQuery,
  tagTypes: ["Administrator"],
  endpoints: (build) => ({
    getAdminItem: build.query<ResponseAdministratorType, RequestAdministratorType>({
      query: ({ id }: RequestAdministratorType) => ({
        url: `/admin/users/${id}`,
      }),
      providesTags: (result) => (result ? [{ type: "Administrator", id: "LIST" }] : [{ type: "Administrator", id: "LIST" }]),
    }),
    getAdminItems: build.query<ResponseAdministratorsType, any>({
      query: (search: string) => ({
        url: `/admin/users${search}`,
      }),
      providesTags: (result) => (result && result.data.length ? [{ type: "Administrator", id: "LIST" }] : [{ type: "Administrator", id: "LIST" }]),
    }),
    createAdmin: build.mutation<any, RequestCreateAdminType>({
      query: ({ name, surname, patronymic, email, role_code, cities, password, password_confirmation }: RequestCreateAdminType) => ({
        url: `/admin/users`,
        method: "POST",
        body: {
          name,
          surname,
          patronymic,
          email,
          role_code,
          cities,
          password,
          password_confirmation,
        },
      }),
      invalidatesTags: [{ type: "Administrator", id: "LIST" }],
    }),
    updateAdmin: build.mutation<any, RequestEditAdminType>({
      query: ({ id, name, surname, patronymic, email, role_code, cities, password, password_confirmation }: RequestEditAdminType) => ({
        url: `/admin/users/${id}`,
        method: "PUT",
        body: {
          name,
          surname,
          patronymic,
          email,
          role_code,
          cities,
          password,
          password_confirmation,
        },
      }),
      invalidatesTags: [{ type: "Administrator", id: "LIST" }],
    }),
    getUserRoles: build.query<ResponseUserRolesType, any>({
      query: () => ({
        url: `/admin/user-roles`,
      }),
      providesTags: (result) => (result && result.data.length ? [{ type: "Administrator", id: "LIST" }] : [{ type: "Administrator", id: "LIST" }]),
    }),
  }),
});

export const {
  useGetAdminItemQuery,
  useGetAdminItemsQuery,
  useGetUserRolesQuery,
  useCreateAdminMutation,
  useUpdateAdminMutation,
  useLazyGetAdminItemsQuery,
} = administratorItems;
