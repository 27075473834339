import { FC, useMemo, useState } from "react"
import { Layout } from "../../common/Layout"
import { useCatalogPageController } from "./CatalogPage.controller"
import CatalogCitiesList from "./CatalogCitiesList"
import styles from "./CatalogPage.module.scss"
import "./CatalogPage.scss"
import { ReactComponent as GreyField } from "../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../constans/icons/greenField.svg"
import { ReactComponent as DeleteIcon } from "../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../constans/icons/redact.svg"
import DirectoryTree from "antd/es/tree/DirectoryTree"
import { useLocation, useNavigate } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { sortFields } from "../ServicesPage/ServicesPage"
import { Modal } from "../../common/Modal"
import classNames from "classnames"
import { ICatalogItem } from "../../models/CatalogItems"
import { Spin, message } from "antd"
import CatalogEditPage from "../CatalogEditPage/CatalogEditPage"
import CatalogCreatePage from "../CatalogEditPage/CatalogCreatePage"
import { LoadingOutlined } from "@ant-design/icons"
import { sortCatalogForOrder, sortChildrenForOrder } from "../../helpers/sort"

const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: 20 }} spin />

const CataogPage: FC = () => {
	const {
		cities,
		catalogItems,
		search,
		loading,
		isEdit,
		isCreate,
		isCatalogTree,
		goCreate,
		handleSearch,
		onSelectField,
		handleSetDeleteId,
		deleteCatalogField,
	} = useCatalogPageController()
	const navigate = useNavigate()

	const [deleteCatalogId, setDeleteCatalogId] = useState(0)

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}
			newItem.key = key
			newItem.icon = key.length > 3 ? <GreyField /> : <GreenField />
			newItem.title = (
				<>
					{item.name}
					<div className={styles.tree__btns} style={{ flex: 1 }}>
						<button
							onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
								navigate(
									AllRoutes.Catalog +
										`?edit=true&cityId=${isCatalogTree}&id=${item.id}`
								)
							}
							className={styles.tree__btn}
						>
							<EditIcon />
						</button>
						<button
							onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
								handleSetDeleteId(event, setDeleteCatalogId, item.id)
							}}
							className={styles.tree__btn}
						>
							<DeleteIcon />
						</button>
					</div>
				</>
			)
			return newItem
		})

		return res
	}

	const prepareData = (array: any[]) => {
		const mapItems = new Map(array.map(item => [item.id, item]))
		array.forEach(item => {
			if (item.parent) {
				const parent = mapItems.get(item.parent_id)
				if (!parent) return
				mapItems.set(item.parent_id, {
					...parent,
					children: [...(parent.children ?? []), mapItems.get(item.id)],
				})
			}
		})
		const tt = Object.values(Object.fromEntries(mapItems)).filter(
			(item: any) => item.parent_id === null
		)
		console.log("🚀 ~ file: CatalogPage.view.tsx:105 ~ prepareData ~ tt:", tt)
		return addKey(tt, "0")
	}

	const catalogDataTree = useMemo(() => {
		// первичная сортировка
		const sortingOrder = sortCatalogForOrder([...catalogItems])

		const sortingData = sortFields([...sortingOrder])

		const prepareDataItems = prepareData(sortingData)

		// сортировка итогового массива
		const sortingChildren: any = sortChildrenForOrder(prepareDataItems)

		return sortingChildren
	}, [catalogItems])

	const handleDeleteCatalogItem = async () => {
		await deleteCatalogField({ id: deleteCatalogId })
			.unwrap()
			.then(result => {
				if (result.message === "Success") {
					message.success("Элемент каталога успешно удалена")
					setDeleteCatalogId(0)
					navigate(AllRoutes.Catalog)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить папку")
			})
	}

	const prevUrls = [{ title: "Города", path: "/catalog" }]
	const prevUrldForCreate = [
		...prevUrls,
		{
			title: cities.find(city => city.id === +isCatalogTree)?.name ?? "",
			path: `/catalog?cityId=${isCatalogTree}`,
		},
	]

	return (
		<>
			<Layout
				createBtn={!isEdit && !isCreate && !!isCatalogTree}
				onClickCreateBtn={goCreate}
				noData={
					loading
						? false
						: !catalogItems.length && isCatalogTree && !isCreate && !isEdit
						? true
						: false
				}
				currentUrl={{
					path: `${useLocation().pathname}${useLocation().search}`,
					title: isEdit
						? "Редактирование каталога"
						: isCreate
						? "Создание каталога"
						: isCatalogTree && !isEdit && !isCreate
						? cities.find(city => city.id === +isCatalogTree)?.name ?? ""
						: "",
				}}
				prevLinks={
					isEdit || isCreate
						? prevUrldForCreate
						: isCatalogTree && !isEdit && !isCreate
						? prevUrls
						: []
				}
			>
				<div className={styles.catalogPage__wrapper}>
					{!isEdit && !isCatalogTree && !isCreate && (
						<CatalogCitiesList
							cities={cities}
							search={search}
							onSearch={handleSearch}
						/>
					)}
					{!isCreate && !isEdit && isCatalogTree && (
						<>
							{!catalogDataTree.length && loading ? (
								<Spin indicator={antIcon} />
							) : (
								<DirectoryTree
									defaultExpandAll={false}
									onSelect={onSelectField}
									treeData={catalogDataTree}
									itemHeight={64}
									rootStyle={{
										background: "transparent",
										border: catalogItems.length
											? "0.5px solid #DADADA"
											: "none",
									}}
								/>
							)}
						</>
					)}
					{isEdit && <CatalogEditPage cityId={+isCatalogTree} />}
					{isCreate && !isEdit && <CatalogCreatePage cityId={+isCatalogTree} />}
				</div>
			</Layout>
			{!!deleteCatalogId && (
				<Modal
					visible={!!deleteCatalogId}
					onClose={() => setDeleteCatalogId(0)}
				>
					<div className={styles.modalWrapper}>
						<span className={styles.modalTitle}>Удалить</span>
						<p className={styles.modalDescription}>
							Вы уверены, что хотите удалить{" "}
							{
								catalogItems.filter(
									(item: ICatalogItem) => item.id === deleteCatalogId
								)[0].name
							}
							?
						</p>
						<div className={styles.modalButtons}>
							<button
								onClick={() => setDeleteCatalogId(0)}
								className={classNames(styles.modalBtn, styles.cancel)}
							>
								Отмена
							</button>
							<button
								onClick={handleDeleteCatalogItem}
								className={styles.modalBtn}
							>
								Удалить
							</button>
						</div>
					</div>
				</Modal>
			)}
		</>
	)
}

export default CataogPage
