import { Layout } from "../../common/Layout"
import { useLocation } from "react-router-dom"
import { MainTable } from "../../common/MainTable"
import { SearchField } from "../../common/Form/SearchField"
import DiscountFields from "./parts/DiscountFields/DiscountFields"
import { useDiscountPage } from "./useDiscountPage.controller"
import SelectCityDiscount from "./parts/SelectCityDiscount/SelectCityDiscount"
import DiscountPageCreate from "./parts/DiscountPageCreate/DiscountPageCreate"

type Props = {}

const DiscountPage = (props: Props) => {
	const {
		prevLinks,
		isShowCities,
		isFieldCreate,
		isFieldEdit,
		isCreate,
		isEdit,
		isLoading,
		loading,
		data,
		title,
		showSearch,
		search,
		columns,
		dataFilter,
		showSelectCity,
		handleSearch,
		goToCreateField,
		goCreate,
		onHandleDelete,
		goEdit,
		setComplexIds,
		showPageController,
	} = useDiscountPage()

	return (
		<Layout
			secondCreateBtnTitle='Добавить папку'
			onClickSecondCreateBtn={goToCreateField}
			onClickCreateBtn={goCreate}
			createBtnTitle='Добавить скидку'
			secondCreateBtn={
				!isFieldCreate && !isFieldEdit && !isCreate && !isEdit ? true : false
			}
			createBtn={
				!isFieldCreate && !isFieldEdit && !isEdit && !isCreate ? true : false
			}
			noData={isLoading ? false : !data}
			load={loading}
			currentUrl={{
				path: `${useLocation().pathname}${useLocation().search}`,
				title,
			}}
			prevLinks={prevLinks}
		>
			{!!showSearch && (
				<SearchField value={search} onChange={handleSearch} maxWidth={750} />
			)}

			{showSelectCity && <SelectCityDiscount />}

			{!isEdit &&
				!isCreate &&
				!isFieldCreate &&
				!isFieldEdit &&
				!isShowCities && (
					<MainTable
						columns={columns}
						data={dataFilter || []}
						onDelete={onHandleDelete}
						onEdit={goEdit}
					/>
				)}
			{showPageController && <DiscountPageCreate />}

			<DiscountFields discounts={data?.data} setComplexIds={setComplexIds} />
		</Layout>
	)
}

export default DiscountPage
