import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { IUser } from "../../models/User";
import { setUser } from "./userSlice";

interface AuthState {
  isAuth: boolean;
  access_token: string;
}

const initialState: AuthState = {
  isAuth: false,
  access_token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.access_token = action.payload;
    },
  },
});

const { reducer: authReducer, actions } = authSlice;
export const { setAuth, setAccessToken } = actions;

export const signIn =
  (accessToken: string, user: IUser) => async (dispatch: AppDispatch) => {
    dispatch(setAuth(true));
    dispatch(setAccessToken(accessToken));
    dispatch(setUser(user));
  };

export default authReducer;
