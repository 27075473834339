import { FC } from "react";
import { Layout } from "../../common/Layout";
import { SearchField } from "../../common/Form/SearchField";
import { ReactComponent as EditIcon } from "../../constans/icons/redact.svg";
import { ReactComponent as DeleteIcon } from "../../constans/icons/delete.svg";
import styles from "./Prepare.module.scss";
import { usePrepareController } from "./Prepare.controller";
import { Modal } from "../../common/Modal";
import PrepareEdit from "./parts/PrepareEdit";
import { AllRoutes } from "../../constans/appRoutes";

const Prepare: FC = () => {
  const {
    action,
    search,
    editedId,
    deletedId,
    preparations,
    goAction,
    closeModal,
    handleSearch,
    handleDelete,
    fetchDeeltePreparation,
  } = usePrepareController();
  return (
    <Layout
      currentUrl={
        !!action && {
          path: AllRoutes.Prepare + `action=${action}`,
          title:
            action === "create"
              ? "Создание подготовки"
              : "Обновление подготовки",
        }
      }
      prevLinks={!!action && [{ path: AllRoutes.Prepare, title: "Подготовка" }]}
      createBtn={!action}
      onClickCreateBtn={() => goAction("create")}
    >
      {action && <PrepareEdit editedId={editedId} action={action} />}

      {!action && (
        <>
          <SearchField maxWidth={530} value={search} onChange={handleSearch} />

          <ul className={styles.list}>
            {preparations.map((preparation) => (
              <li key={preparation.id.toString()}>
                <div className={styles.left}>{preparation.title}</div>
                <div className={styles.right}>
                  <EditIcon
                    onClick={goAction.bind(null, "edit", preparation.id)}
                    style={{ marginRight: 20 }}
                  />
                  <DeleteIcon
                    onClick={handleDelete.bind(null, preparation.id)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </>
      )}

      <Modal
        visible={deletedId > 0}
        onClose={closeModal}
        title="Удалить"
        subTitle={`Вы уверены, что хотите удалить подготовку “Название подготовки”?`}
        successBtn={{ text: "Отмена", onClick: closeModal }}
        cancelBtn={{ text: "Удалить", onClick: fetchDeeltePreparation }}
      />
    </Layout>
  );
};

export default Prepare;
