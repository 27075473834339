import { useState } from "react";
import { useForm } from "react-hook-form";
import { loadFingerPrintId } from "../../helpers/loadFingrPrintid";
import { login } from "../../services/login";

export const useRecoveryPageController = () => {
  const [isRecovery, setRecovery] = useState(false);
  const [fetchRecovery, { isLoading }] = login.useRecoveryPasswordMutation();
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data: any) => {
    if (!isValid) return;

    const fingerprint = await loadFingerPrintId();

    const response = await fetchRecovery({
      email: data.email,
      fingerprint,
    });

    if ("data" in response) {
      const { message } = response.data;

      message === "Success" && setRecovery(true);
    }

    reset();
  };

  return {
    isValid,
    errors,
    isLoading,
    isRecovery,
    register,
    onSubmit,
    handleSubmit,
  };
};
