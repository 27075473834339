import React from "react"
import styles from "./EnrollmentsPage.module.scss"
import { EnrollmentType } from "../../services/enrollments"
import TableHeader from "../../common/MainTable/TableHeader"
import { ClinicType, useGetClinicItemsQuery } from "../../services/clinics"
import { ReactComponent as EnrollmentInProcessIcon } from "../../constans/icons/inprocess.svg"
import { ReactComponent as EnrollmentPlannedIcon } from "../../constans/icons/planned.svg"
import { ReactComponent as EnrollmentCancelledIcon } from "../../constans/icons/cancelled.svg"
import { ReactComponent as EnrollmentCompletedIcon } from "../../constans/icons/completed.svg"
import { useNavigate } from "react-router-dom"
import { AllRoutes } from "../../constans/appRoutes"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import InfiniteScroll from "react-infinite-scroll-component"
import { tzMoment } from "../../helpers/date"

const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: 20 }} spin />

type Props = {
	data: EnrollmentType[]
	onNext: () => void
	isLoading: boolean
}

const columns = [
	{
		title: "ID",
		width: 0.5,
	},
	{
		title: "ФИО пациента",
		width: 3,
	},
	{
		title: "ФИО врача",
		width: 3,
	},
	{
		title: "Специализация",
		width: 3,
	},
	{
		title: "Клиника",
		width: 3,
	},
	{
		title: "Дата приёма",
		width: 1.5,
	},
	{
		title: "Цена",
		width: 1,
	},
	{
		title: "",
		width: 0.5,
	},
]

const EnrollmentTable = ({ data, onNext, isLoading }: Props) => {
	const navigate = useNavigate()

	// const { data: clinicsData } = useGetClinicItemsQuery({});

	return (
		<div className={styles.EnrollmentTable}>
			<TableHeader data={columns} />
			<div className={styles.tableBodyWrapper}>
				{!data.length && isLoading ? (
					<Spin indicator={antIcon} />
				) : (
					<>
						<InfiniteScroll
							dataLength={data.length}
							next={onNext}
							hasMore={true}
							loader={""}
						>
							{data?.map((item, index) => (
								<div
									className={styles.tableBody__row}
									key={`${item.status}${item.id}${item.total_price}${index}`}
									onClick={() =>
										navigate(AllRoutes.EnrollmentsViewItem + item.id)
									}
								>
									<div className={styles.tableBody__col} style={{ flex: 0.5 }}>
										{item.id}
									</div>
									<div className={styles.tableBody__col} style={{ flex: 3 }}>
										{item?.patient_surname ?? "-"} {item?.patient_name ?? "-"}{" "}
										{item?.patient_patronymic ?? "-"}
									</div>
									<div className={styles.tableBody__col} style={{ flex: 3 }}>
										{item?.specialist_surname ?? "-"}{" "}
										{item?.specialist_name ?? "-"}{" "}
										{item?.specialist_patronymic ?? "-"}
									</div>
									<div className={styles.tableBody__col} style={{ flex: 3 }}>
										{item?.specialization_name ?? "- - -"}
									</div>
									<div className={styles.tableBody__col} style={{ flex: 3 }}>
										{item?.clinic_name}
									</div>
									<div className={styles.tableBody__col} style={{ flex: 1.5 }}>
										{item.start_at
											? tzMoment(new Date(item.start_at * 1000)).format(
													"DD.MM.YYYY"
											  )
											: "- - -"}
									</div>
									<div className={styles.tableBody__col} style={{ flex: 1 }}>
										{item.total_price} ₽
									</div>
									<div className={styles.tableBody__col} style={{ flex: 0.5 }}>
										{item.status === "planned" ? (
											<EnrollmentPlannedIcon className={styles.statusIcon} />
										) : item.status === "in_progress" ? (
											<EnrollmentInProcessIcon className={styles.statusIcon} />
										) : item.status === "completed" ? (
											<EnrollmentCompletedIcon className={styles.statusIcon} />
										) : item.status === "cancel" ? (
											<EnrollmentCancelledIcon className={styles.statusIcon} />
										) : null}
										<div className={styles.statusTooltip}>
											{item.status === "planned"
												? "Запланирована"
												: item.status === "in_progress"
												? "В процессе"
												: item.status === "completed"
												? "Выполнена"
												: item.status === "cancel"
												? "Отменена"
												: null}
										</div>
									</div>
								</div>
							))}
						</InfiniteScroll>
					</>
				)}
			</div>
		</div>
	)
}

export default EnrollmentTable
