import { FC } from "react"
import { Layout } from "../../common/Layout"
import FieldsList from "./parts/FieldsList/FieldsList"
import { useGetQuestionsQuery } from "../../services/questions"

const CancellationIssues: FC = props => {
	const { data: questions, isLoading } = useGetQuestionsQuery("")

	return (
		<Layout load={isLoading} noData={!questions?.data?.length}>
			<FieldsList />
		</Layout>
	)
}

export default CancellationIssues
