import React, { useState } from "react";
import styles from "./CitiesPage.module.scss";
import {
  cabinetType,
  useCreateCabinetItemMutation,
  useDeleteCabinetItemMutation,
  useEditCabinetItemMutation,
  useGetCabinetItemsQuery,
} from "../../services/cabinets";
import CabinetInput from "./CabinetInput";
import { RegisterOptions, FieldValues, UseFormRegisterReturn, useForm } from "react-hook-form";
import { message } from "antd";
import classNames from "classnames";
import { Modal } from "../../common/Modal";
import { Button } from "../../common/Button";

type Props = {
  clinic_id: number;
};

const Cabinets = ({ clinic_id }: Props) => {
  const [del, setDel] = useState(0);

  const { data: cabinetsData } = useGetCabinetItemsQuery({ clinicId: clinic_id });
  const [deleteCabinet] = useDeleteCabinetItemMutation();
  const [editCabinet] = useEditCabinetItemMutation();
  const [createCabinet] = useCreateCabinetItemMutation();

  const handleEdit = async (id: number, name: string) => {
    await editCabinet({ id, name })
      .unwrap()
      .then((result) => {
        if (result.message === "Success") {
          message.success("Кабинет успешно изменён");
        }
      })
      .catch((err) => {
        message.error("Не удалось изменить кабинет");
      });
  };

  const handleDelete = () => {
    deleteCabinet({ id: del }).then((response) => {
      setDel(0);
    });
  };

  const handleCreate = (clinic_id: number, external_id: string, name: string) => {
    createCabinet({ clinic_id, external_id, name })
      .unwrap()
      .then((result) => {
        if (result.message === "Success") {
          message.success("Кабинет успешно создан");
        }
      })
      .catch((err) => {
        message.error("Не удалось создать кабинет");
      });
  };

  const {
    register,
    formState: { isValid, errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  return (
    <div className={styles.cabinetsWrapper}>
      <h3 className={styles.cabinetsTitle}>Кабинеты</h3>
      <Button
        plusSvg
        disabled={false}
        onClick={() => handleCreate(clinic_id, "Название кабинета", "Название кабинета")}
        title={"ДОБАВИТЬ КАБИНЕТ"}
        classes={{ btn: styles.cabinetAddBtn }}
        isTransparent
      />
      {cabinetsData?.data?.map((cabinet: cabinetType) => {
        const name = watch(`name${cabinet.id}`);
        return (
          <CabinetInput
            fieldname={`name${cabinet.id}`}
            defaultValue={cabinet.name ?? ""}
            isDisabled={false}
            register={register}
            options={{ required: "Поле обязательно для заполнения" }}
            key={cabinet.name}
            onSubmit={() => handleEdit(cabinet.id, name)}
            onDelete={() => setDel(cabinet.id)}
          />
        );
      })}

      {!!del && (
        <Modal visible={!!del} onClose={() => setDel(0)}>
          <div className={styles.modalWrapper}>
            <span className={styles.modalTitle}>Удалить</span>
            <p className={styles.modalDescription}>
              Вы уверены, что хотите удалить {cabinetsData?.data?.filter((item: cabinetType) => item.id === del)[0].name}?
            </p>
            <div className={styles.modalButtons}>
              <button onClick={() => setDel(0)} className={classNames(styles.modalBtn, styles.cancel)}>
                Отмена
              </button>
              <button onClick={handleDelete} className={styles.modalBtn}>
                Удалить
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Cabinets;
