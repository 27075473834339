import { createApi } from "@reduxjs/toolkit/query/react";
import { IUser } from "../models/User";
import { baseQuery } from "./baseQuery";

type RequestLogintype = {
  email: string;
  password: string;
  fingerprint: string;
};

export type ResponseLoginType = {
  data: {
    user: IUser;
    access_token: string;
    refresh_token: string;
  };
  message: string;
};

type RequestRecoveryType = {
  email: string;
  fingerprint: string;
};

type ResponseRecoveryType = {
  data: {
    access_token: string;
    refresh_token: string;
    user: IUser;
  };
  message: "Success" | "Forbiden";
};

type RequestSetPasswordType = {
  token: string;
  fingerprint: string;
  password: string;
};

enum endpoinds {
  login = "auth/login",
  recovery = "auth/recovery-password-request",
  setPassword = "auth/recovery-password-confirm",
  refresh = "auth/refresh-token",
}

export const login = createApi({
  reducerPath: "login",
  baseQuery,
  endpoints: (build) => ({
    login: build.mutation<ResponseLoginType, RequestLogintype>({
      query: (data: RequestLogintype) => ({
        url: endpoinds.login,
        method: "POST",
        body: data,
      }),
    }),
    recoveryPassword: build.mutation<ResponseRecoveryType, RequestRecoveryType>(
      {
        query: (data: RequestRecoveryType) => ({
          url: endpoinds.recovery,
          method: "POST",
          body: data,
        }),
      }
    ),
    setPasssword: build.mutation<ResponseRecoveryType, RequestSetPasswordType>({
      query: (data: RequestSetPasswordType) => ({
        url: endpoinds.setPassword,
        method: "POST",
        body: data,
      }),
    }),
    sendRefresh: build.mutation<ResponseRecoveryType, { fingerprint: string }>({
      query: (data: { fingerprint: string }) => ({
        url: endpoinds.refresh,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
