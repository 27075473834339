import { ChangeEvent, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDebounce } from "../../hooks/useDebaunce"
import { useDeleteComplexMutation } from "../../services/complexes"
import { AllRoutes } from "../../constans/appRoutes"
import { message } from "antd"
import { useGetCityItemsQuery } from "../../services/cities"
import {
	useDeleteDiscountMutation,
	useGetDiscountByIdQuery,
	useGetDiscountPeriodsQuery,
	useGetDiscountsFieldsQuery,
	useGetDiscountsQuery,
} from "../../services/discounts"

export const useDiscountPage = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const isEdit = !!params?.edit
	const isCreate = !!params?.create

	const isFieldCreate = !!params?.fieldCreate
	const isFieldEdit = !!params?.fieldEdit

	const isShowCities = !!params?.selectCity
	const cityId = params?.cityId

	const discountId = params?.discountId

	const [search, setSearch] = useState("")
	const debouncedSearch = useDebounce(search, 500)

	const columns = [
		{
			title: "Индентификатор",
			width: 2,
		},
		{
			title: "Название",
			width: 2,
		},
		{
			title: "",
			width: 1,
		},
	]

	const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
		setSearch(target.value)

	const { data, isLoading } = useGetDiscountsQuery("")

	const { data: discountData, isLoading: loadingDiscount } =
		useGetDiscountByIdQuery({ id: +discountId }, { skip: !discountId })

	const { data: discountPeriods, isLoading: loadingPeriods } =
		useGetDiscountPeriodsQuery("")

	const { data: discountsFields, isLoading: loadingFields } =
		useGetDiscountsFieldsQuery("")

	const { data: cities } = useGetCityItemsQuery({})

	const [deleteDiscoun] = useDeleteDiscountMutation()

	const loading = useMemo(() => {
		return isLoading || loadingDiscount || loadingPeriods || loadingFields
	}, [isLoading, loadingDiscount, loadingPeriods, loadingFields])

	const [complexIds, setComplexIds] = useState<number[]>([])

	const dataSearch = useMemo(() => {
		return data?.data.filter(it =>
			it.name.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase())
		)
	}, [data, debouncedSearch])

	const dataFilter = useMemo(() => {
		return dataSearch?.filter(it => !complexIds.includes(it.id))
	}, [dataSearch, complexIds])

	const onHandleDelete = (id: number) => {
		deleteDiscoun({ id })
			.unwrap()
			.then(result => {
				if (result.success) {
					message.success("Скидка успешно удалена")
					navigate(AllRoutes.Discounts)
				}
			})
			.catch(err => {
				message.error("Не удалось удалить скидку")
			})
	}

	const goEdit = (id?: number) => navigate(AllRoutes.DiscountsEdit + `${id}`)
	const goCreate = () => {
		navigate(AllRoutes.DiscountsCreate + "&selectCity=true")
	}

	const goToCreateField = () => {
		navigate(AllRoutes.DiscountsFieldCreate)
	}

	const currentCity = useMemo(() => {
		return cities?.data.find(city => +city.id === +cityId)
	}, [cityId, cities])

	const prevUrls = useMemo(() => {
		return [{ title: "Скидки", path: AllRoutes.Discounts }]
	}, [])

	const showSelectCity = useMemo(() => {
		return isCreate && isShowCities && !cityId
	}, [isCreate, isShowCities, cityId])

	const showSearch = useMemo(() => {
		return (
			!isFieldCreate && !isFieldEdit && !isCreate && !isEdit && !showSelectCity
		)
	}, [isFieldCreate, isFieldEdit, isCreate, isEdit, showSelectCity])

	const title = useMemo(() => {
		if (isCreate && isShowCities && !cityId) {
			return "Выбрать город"
		}

		if (isCreate && isShowCities && cityId) {
			return "Создать скидку"
		}

		if (isEdit) {
			return "Редактирование скидки"
		}

		if (isFieldCreate) {
			return "Создание папки"
		}

		if (isFieldEdit) {
			return "Редактирование папки"
		}

		return ""
	}, [isCreate, isEdit, isShowCities, cityId, isFieldCreate, isFieldEdit])

	const prevLinks = useMemo(() => {
		if (isCreate && isShowCities && cityId && currentCity) {
			return [
				...prevUrls,
				{
					title: currentCity?.name,
					path: AllRoutes.DiscountsCreate + "&selectCity=true",
				},
			]
		}

		if (isCreate || isEdit || isFieldCreate || isFieldEdit) {
			return prevUrls
		}
		return []
	}, [
		isCreate,
		isEdit,
		prevUrls,
		cityId,
		isShowCities,
		currentCity,
		isFieldCreate,
		isFieldEdit,
	])

	const showPageController = useMemo(() => {
		if (isShowCities && isCreate && !!cityId) {
			return true
		}

		if (isEdit && discountId) {
			return true
		}

		return false
	}, [isShowCities, isCreate, cityId, isEdit, discountId])

	return {
		prevLinks,
		isShowCities,
		isFieldCreate,
		isFieldEdit,
		isCreate,
		isEdit,
		isLoading,
		loading,
		data,
		title,
		showSearch,
		search,
		columns,
		dataFilter,
		showSelectCity,
		goToCreateField,
		goCreate,
		handleSearch,
		onHandleDelete,
		goEdit,
		setComplexIds,
		showPageController,
	}
}
