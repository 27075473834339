import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { IUser } from "../../models/User";

const initialState = {
  user: {} as IUser,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = { ...action.payload };
    },
  },
});

const { reducer: userReducer, actions } = userSlice;
export const { setUser } = actions;

export const saveUserData = (user: IUser) => (dispatch: AppDispatch) => {
  dispatch(setUser(user));
};

export default userReducer;
