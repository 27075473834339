import styles from "./SpecializationPage.module.scss";
import { useForm } from "react-hook-form";
import { TextField } from "../../common/Form/TextField";
import { Button } from "../../common/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetSpecializationQuery } from "../../services/specialization";
import { AllRoutes } from "../../constans/appRoutes";

type Props = {
  onSubmit: (id: number, name: string) => void;
};

const SpecializationPageCreate = ({ onSubmit }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const {
    register,
    formState: { isValid, errors },
    watch,
  } = useForm({ mode: "onBlur" });
  const name = watch("name");
  const { data } = useGetSpecializationQuery({ specialization_id: +params.id });

  return (
    <div className={styles.createSpecializationWrapper}>
      <TextField
        fieldname="name"
        register={register}
        options={{ required: "Поле обязательно для заполнения" }}
        error={errors.name?.message as string}
        palcehoder="Название"
        defaultValue={data?.data?.name ?? ""}
      />
      <div className={styles.createSpecializationBtnWrapper}>
        {data?.data?.id && (
          <Button
            title="Сохранить"
            disabled={!isValid}
            onClick={() => onSubmit(data.data.id, name)}
          />
        )}
        {data?.data?.id && (
          <Button
            title="Отмена"
            disabled={false}
            onClick={() => navigate(AllRoutes.Specialization)}
            isTransparent
          />
        )}
      </div>
    </div>
  );
};

export default SpecializationPageCreate;
