import React, { useCallback, useMemo, useState, memo, FC } from "react"
import styles from "./ComplexesFields.module.scss"
import "./Tree.scss"
import { useNavigate, useSearchParams } from "react-router-dom"
import ComplexesFieldCreate from "../ComplexesFieldCreate/ComplexesFieldCreate"
import { useGetCompelxesFieldsQuery } from "../../../../services/complexes"
import { Spin, Tree } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { sortFields, prepareData } from "../../helpers"
import { ReactComponent as DeleteIcon } from "../../../../constans/icons/delete.svg"
import { ReactComponent as EditIcon } from "../../../../constans/icons/redact.svg"
import { ReactComponent as ServiceIcon } from "../../../../constans/icons/serviceIcon.svg"
import { ReactComponent as GreyField } from "../../../../constans/icons/greyField.svg"
import { ReactComponent as GreenField } from "../../../../constans/icons/greenField.svg"
import type { DirectoryTreeProps } from "antd/es/tree"
import { IComboService } from "../../../../models/ComboServices"
import { AllRoutes } from "../../../../constans/appRoutes"
import ComplexesFieldEdite from "../ComplexesFieldEdite/ComplexesFieldEdite"
import DeleteModal from "../DeleteModal/DeleteModal"
import DeleteComplexModal from "../DeleteComplexModal/DeleteComplexModal"

const { DirectoryTree } = Tree

const antIcon = <LoadingOutlined style={{ fontSize: 24, marginTop: 20 }} spin />

type Props = {
	complexes: IComboService[] | undefined
	setComplexIds: (arr: number[]) => void
}

const ComplexesFields: FC<Props> = ({ complexes, setComplexIds }) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = Object.fromEntries(searchParams)

	const isFieldCreate = !!params?.fieldCreate
	const isFieldEdit = !!params?.fieldEdit
	const isEdit = !!params?.edit
	const isCreate = !!params?.create

	const { data: complexesFields } = useGetCompelxesFieldsQuery("")

	const [selectedFieldId, setSelectedFieldId] = useState(0)
	const [deleteFieldId, setDeleteFieldId] = useState<string | number>(0)
	const [del, setDel] = useState<number>(0)

	const addKey = (array: any, parentKey: any) => {
		const res = array.map((item: any, i: number) => {
			const key = `${parentKey}-${i}`
			const newItem = { ...item }
			if (item.children) {
				newItem.children = addKey(item.children, key)
			}
			if (!item.children) {
				newItem.children = []
			}
			newItem.key = key
			newItem.icon = key.length > 3 ? <GreyField /> : <GreenField />
			newItem.title = (
				<>
					{item.name}
					<div className={styles.tree__btns} style={{ flex: 1 }}>
						<button
							onClick={() => navigate(AllRoutes.ComplexesFieldEdite + item.id)}
							className={styles.tree__btn}
						>
							<EditIcon />
						</button>
						<button
							onClick={() => setDeleteFieldId(item.id)}
							className={styles.tree__btn}
						>
							<DeleteIcon />
						</button>
					</div>
				</>
			)
			newItem.type = "field"
			return newItem
		})

		return res
	}

	const goEdit = (id?: number) => navigate(AllRoutes.ComplexesEdit + `${id}`)

	const findParentField = useCallback(
		(complex: IComboService, dataArr: any[]) => {
			dataArr.forEach(item => {
				if (complex.combo_field_id === item.id) {
					item.children?.push({
						title: (
							<>
								{complex.name}
								<div className={styles.tree__btns} style={{ flex: 1 }}>
									<button
										onClick={() => goEdit(complex.id)}
										className={styles.tree__btn}
									>
										<EditIcon />
									</button>
									<button
										onClick={() => setDel(complex.id)}
										className={styles.tree__btn}
									>
										<DeleteIcon />
									</button>
								</div>
							</>
						),
						id: complex.id,
						key: `${item.key}-${item.children.length}`,
						children: [],
						icon: <ServiceIcon />,
					})
				}

				// if (complex.combo_field_id !== item.id && item.children?.length) {
				// 	findParentField(complex, item.children)
				// }
			})
		},
		[]
	)

	const dataForPrepare = useMemo(() => {
		if (complexesFields?.data) {
			const sortedData = [...complexesFields.data]

			const sortFieldsdata = [...sortFields(sortedData)]
			const prepareDataFields: any = [...prepareData(sortFieldsdata, addKey)]

			return prepareDataFields
		}
	}, [complexesFields, complexes])

	const generateData = useCallback(
		(complexes: any[]) => {
			const data: any = []

			dataForPrepare.forEach((item: any) => {
				data.push(item)
			})

			complexes.forEach(service => {
				findParentField(service, data)
			})

			const listIds = data
				.map((item: any) => {
					const childrenIds = item.children.map((it: any) => it.id)
					return childrenIds
				})
				.flat()

			setComplexIds(listIds)

			return data
		},
		[dataForPrepare, findParentField]
	)

	const generatedData = useMemo(() => {
		if (complexesFields?.data && complexes) {
			return generateData(complexes)
		}
		return []
	}, [complexesFields?.data, complexes, generateData])

	const onSelect: DirectoryTreeProps<any>["onSelect"] = (keys, info) => {
		if (info.node.type === "field") {
			setSelectedFieldId(info.node.id)
		} else {
			setSelectedFieldId(0)
		}
	}

	return (
		<div className={styles.fields}>
			{isFieldCreate && !isFieldEdit && complexesFields && (
				<ComplexesFieldCreate fieldsData={complexesFields?.data} />
			)}
			{!isFieldCreate && isFieldEdit && complexesFields && (
				<ComplexesFieldEdite fieldsData={complexesFields?.data} />
			)}

			{!!deleteFieldId && (
				<DeleteModal
					deleteFieldId={deleteFieldId}
					setDeleteFieldId={setDeleteFieldId}
				/>
			)}

			{!!del && <DeleteComplexModal del={del} setDel={setDel} />}

			{!isFieldCreate && !isFieldEdit && !isEdit && !isCreate && (
				<>
					{!generatedData?.length ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Spin indicator={antIcon} />
						</div>
					) : (
						<DirectoryTree
							defaultExpandAll={false}
							onSelect={onSelect}
							treeData={generatedData}
							itemHeight={64}
							rootStyle={{
								background: "transparent",
								border: "0.5px solid #DADADA",
							}}
						/>
					)}
				</>
			)}
		</div>
	)
}

export default memo(ComplexesFields)
